import React from "react";

import { Box, Typography } from "@material-ui/core";

import { SpecialOfferWrapper } from "./styles";

type SpecialOfferProps = {
	// price: number;
	discount: number;
};

const SpecialOffer = ({ discount }: SpecialOfferProps) => (
	<SpecialOfferWrapper>
		{/*<Typography className="price">$ {price > 0 ? price.toLocaleString() : 0}</Typography>*/}
		<Box className="discount-block">
			<Typography className="offer-text">Special Offer</Typography>
			<Typography className="discount">- {discount <= 100 ? discount : 0}%</Typography>
		</Box>
	</SpecialOfferWrapper>
);

export default SpecialOffer;
