import React, { useEffect, useRef, useState } from "react";

import { Box, Checkbox, FormControl, FormControlLabel, Typography } from "@material-ui/core";
import cn from "classnames";

export const MultipleCheckbox = ({ className, selectOptions, otherInputProps, readonly, values, onChange }) => {
	const [selectedIds, setSelectedIds] = useState<number[]>([...values]);
	const initialRender = useRef(true);

	useEffect(() => {
		if (selectedIds.length !== values.length) {
			setSelectedIds(values);
		}
	}, [values]);

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			onChange(selectedIds);
		}
	}, [selectedIds]);

	return (
		<Box className={className}>
			<FormControl size="small" variant="filled" fullWidth>
				{selectOptions.map(item => (
					<FormControlLabel
						key={`${item.value}`}
						control={
							<Checkbox
								{...otherInputProps}
								color="primary"
								checked={selectedIds.includes(item.value)}
								disabled={item.disabled}
								onChange={e => {
									if (e.target.checked) {
										setSelectedIds(prevValue => [...prevValue, item.value]);
									} else {
										setSelectedIds(prevValue => [...prevValue.filter(v => v !== item.value)]);
									}
								}}
							/>
						}
						label={
							<Typography
								className={cn({
									disabled: item.disabled
								})}
							>
								{item.text}
							</Typography>
						}
						{...(readonly ? { readonly: true } : {})}
					/>
				))}
			</FormControl>
		</Box>
	);
};
