import { BaseModel } from "./base.model";
import { BaseListModel } from "./baseList.model";
import { Lesson } from "./lesson.model";
import { Question } from "./question.model";
import { UserQuestionAttempt } from "./userQuestionAttempt.model";

export enum ContentAreaPassingStatus {
	AbovePassing = "AbovePassing",
	NearPassing = "NearPassing",
	BelowPassing = "BelowPassing"
}

export interface ContentArea {
	name: string;
	status: ContentAreaPassingStatus;
	maximumPercentage: number;
	minimumPercentage: number;
	description: string | null;
}

export interface CustomTestData {
	questions?: Question[];
	userQuestionAttempts?: UserQuestionAttempt[];
	isTimed: boolean;
	timeLengthInSeconds?: number;
	result?: {
		contentAreas: ContentArea[];
	};
}

export interface UserCustomTest extends BaseModel {
	userId: number;
	typeId: number;
	name: string;
	data: CustomTestData;
	isLoading?: boolean;
	percentageGrade?: number;
	difficultyTierId?: number;
	difficultyTier?: { name: string };
	type?: {
		name: string;
	};
}

export interface UserTestSnapshot extends BaseModel {
	abandoned: boolean;
	courseId: number;
	difficultyTier: number;
	difficultyTierId: number;
	inProgress: boolean;
	name: string;
	passed: boolean;
	percentageGrade: number;
	timeSpent: number;
	userId: number;
	userQuestionAttempts: UserQuestionAttempt[];
	type: { id: number };
	data: {
		questions: [];
	};
	lesson: Lesson;
}

export interface UserCustomTestHistory extends BaseModel {
	snapshot: UserTestSnapshot;
}
export interface UserCustomTestHistoryResult extends BaseListModel {
	items: UserCustomTestHistory[];
}
