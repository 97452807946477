import React, { useMemo } from "react";

import { QuotesList } from "./QuotesList";
import {
	MotivationIllustration,
	MotivationalQuoteContainer,
	MotivationalQuoteText,
	MotivationalQuoteTextContainer
} from "./styles";

const MotivationalQuotes = () => {
	const randomNumber = useMemo(() => Math.floor(Math.random() * (29 + 1)), []);
	return (
		<MotivationalQuoteContainer>
			<MotivationIllustration />
			<MotivationalQuoteTextContainer display="flex" alignItems="center" ml={7}>
				<MotivationalQuoteText>{QuotesList[randomNumber]}</MotivationalQuoteText>
			</MotivationalQuoteTextContainer>
		</MotivationalQuoteContainer>
	);
};

export default MotivationalQuotes;
