const success = {
	100: "hsl(108,87%,91%)",
	200: "hsl(114,89%,83%)",
	300: "hsl(119,80%,73%)",
	400: "hsl(125,70%,63%)",
	500: "hsl(131,64%,50%)",
	600: "hsl(136,67%,42%)",
	700: "hsl(142,72%,34%)",
	800: "hsl(148,78%,27%)",
	900: "hsl(154,83%,21%)",
	1000: "hsl(162,79%,11%)",
	1100: "hsl(136,53%,43%)"
};

export default success;
