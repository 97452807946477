import React from "react";

import { Step, StepConnector, StepIconProps, StepLabel, Stepper, withStyles } from "@material-ui/core";
import { RadioButtonChecked } from "@material-ui/icons";
import cn from "classnames";

import { useStepIconStyles, useStyles } from "./styles";

import { basic } from "../../colors";

const StepsConnector = withStyles(theme => ({
	alternativeLabel: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)"
	},
	active: {
		"& $line": {
			borderColor: theme.palette.info[500]
		}
	},
	completed: {
		"& $line": {
			borderColor: theme.palette.info[500]
		}
	},
	line: {
		borderColor: basic[1300],
		borderTopWidth: 2,
		borderRadius: 1
	}
}))(StepConnector);

const QontoStepIcon = ({ active, completed }: StepIconProps) => {
	const classes = useStepIconStyles();

	return (
		<RadioButtonChecked className={cn(classes.root, { [classes.active]: active, [classes.completed]: completed })} />
	);
};

interface IPropTypes {
	steps: { label: string }[];
	activeStep: number;
}
const StepperComponent = ({ steps, activeStep }: IPropTypes) => {
	const classes = useStyles();

	return (
		<Stepper connector={<StepsConnector />} activeStep={activeStep} alternativeLabel>
			{steps.map(({ label }, index) => (
				<Step key={`${label}-${index}`}>
					<StepLabel
						classes={{
							label: classes.stepLabel
						}}
						StepIconComponent={QontoStepIcon}
					>
						{label}
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

export default StepperComponent;
