import { useState } from "react";

import { useInterval } from "./useInterval";

import { COUNTDOWN_STATUS } from "../constants";

const startOrResetSignature = (initialTime: number) => {
	console.log(initialTime);
};

export const useTimer = (): {
	seconds: number;
	startOrResetTimer: typeof startOrResetSignature;
	stopTimer: () => void;
} => {
	const [startTime, setStartTime] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [status, setStatus] = useState(COUNTDOWN_STATUS.STARTED);

	const startOrResetTimer = (initialTime: number) => {
		setStatus(COUNTDOWN_STATUS.STARTED);
		setStartTime(Date.now() - initialTime * 1000);
	};

	const stopTimer = () => {
		setStatus(COUNTDOWN_STATUS.STOPPED);
	};

	useInterval(
		() => {
			if (status !== COUNTDOWN_STATUS.STOPPED) {
				const seconds = Math.round((Date.now() - startTime) / 1000);
				setSeconds(seconds);
			}

			return null;
		},
		startTime !== 0 ? 1000 : null
	);

	return { seconds, startOrResetTimer, stopTimer };
};
