import orderBy from "lodash/orderBy";
import { UserSubscription } from "models/userSubscription.model";

export const getCurrentSubscription = (subscriptions?: UserSubscription[]): UserSubscription => {
	const sortSubscriptions = orderBy(
		subscriptions?.filter(f => !f.hasExpired),
		["id"],
		["desc"]
	);
	return sortSubscriptions && sortSubscriptions.length ? sortSubscriptions[0] : <UserSubscription>{};
};

export const getStudentSubscription = (
	subscription: UserSubscription[]
): "No Subscription" | "RECURRING" | "TRIAL" | "INITIAL" => {
	const currentSubscription = getCurrentSubscription(subscription);
	const subscriptionType = currentSubscription?.type;
	if (!subscriptionType) {
		return "No Subscription";
	}
	if (subscriptionType.isRecurring) {
		return "RECURRING";
	}
	if (subscriptionType.isTrial) {
		return "TRIAL";
	}
	return "INITIAL";
};
