import React, { FC, useState } from "react";

import { Box, Collapse } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import ChevronDownSvg from "../../../../assets/icons/icon-chevron-down.svg";
import { NavItem } from "../SideNav/styles";
import { SideNavItemNested } from "../types";

interface Props extends Omit<SideNavItemNested, "id" | "icon"> {
	Icon?: FC;
	isTrial: boolean;
	clickSideEffect?: (identifier: string) => void;
}
const NestedSideNavItem = ({ Icon, title, subItems, disabled, visible, clickSideEffect }: Props) => {
	const [expanded, setExpanded] = useState(false);
	const history = useHistory();

	const SubItem = (item, i) => (
		<NavItem
			route
			onClick={() => {
				if (item.disabled) return;
				clickSideEffect && clickSideEffect(item.title);
				history.push(item.route);
			}}
			className="navItem"
			key={i}
			highlight={history.location.pathname === item.route}
			disabled={item.disabled}
		>
			<div className="item-box">
				<div className="title nested">{item.title}</div>
			</div>
		</NavItem>
	);

	if (!visible) {
		return null;
	}

	return (
		<NavItem onClick={() => !disabled && setExpanded(!expanded)} className="navItem">
			<Box display="flex" flexDirection="column" width="100%">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
					className={cn("item-box", { highlight: expanded })}
				>
					<Box display="flex">
						{Icon && (
							<SvgIcon>
								<Icon />
							</SvgIcon>
						)}
						<div className="title">{title}</div>
					</Box>
					<img
						alt="chevron down"
						src={ChevronDownSvg}
						style={{ transform: `rotate(${expanded ? "0deg" : "270deg"})` }}
						className="expand-chevron"
					/>
				</Box>
				<Collapse in={expanded} unmountOnExit>
					{subItems.filter(item => item.visible).map(SubItem)}
				</Collapse>
			</Box>
		</NavItem>
	);
};

export default NestedSideNavItem;
