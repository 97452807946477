import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, get, set } from "lodash";

import {
	CreateFormOptions,
	CreateFormOptionsInputItem,
	FormInputItemsModel,
	FormStateModel,
	GetPopulateInputsActionOptions,
	PopulateInputsActionPayload,
	PopulateInputsOptions,
	TemplatesModel,
	ValidateFormActionPayload,
	ValidateInputsOptions
} from "./form.model";

import {
	CustomInputModel,
	CustomInputType,
	DefaultValueGettersObject
} from "../../components/CustomInput/customInput.model";
import { checkInputValidity, isCustomInputObject } from "../../components/CustomInput/customInput.utils";

import { isObject } from "../isObject";

const customInputValues = Object.values(CustomInputType);

export function createForm<InputItemsModel extends FormInputItemsModel, RawDataModel, TM extends TemplatesModel>(
	options: CreateFormOptions
): FormStateModel<InputItemsModel, RawDataModel, TM> {
	const {
		defaultValueGetters = {},
		generateTemplatesFromPaths = [],
		inputs,
		isTopLevel = true,
		statePath: optStatePath,
		templateReferencePath: optTemplateReferencePath
	} = options;
	const form: FormStateModel<InputItemsModel, RawDataModel, TM> = {
		inputs: {} as InputItemsModel,
		pristine: true,
		rawData: {} as RawDataModel,
		statePath: optStatePath,
		templates: {} as TM,
		valid: false
	};
	const formStatePath = options.formStatePath || optStatePath;
	const inputsStatePath = `${optStatePath}${isTopLevel ? ".inputs" : ""}`;
	const templateReferencePath = optTemplateReferencePath || "";
	const generateTemplate = generateTemplatesFromPaths.indexOf(templateReferencePath) !== -1;
	const template: Record<string, CustomInputModel<unknown> | {} | []> = {};
	for (const inputName in inputs) {
		const inputConfig = inputs[inputName]!;
		const innerTemplateReferencePath =
			`${templateReferencePath.length ? `${templateReferencePath}.` : ""}` + `${inputName}`;
		const innerGenerateTemplatesFromPaths = [...generateTemplatesFromPaths];
		if (generateTemplate) {
			innerGenerateTemplatesFromPaths.push(innerTemplateReferencePath);
		}
		// handle nested arrays
		if (inputConfig instanceof Array) {
			const innerRawData: Record<string, unknown>[] = [];
			inputConfig.forEach((inputConfigItem, index) => {
				const { rawData: itemRawData, templates: itemTemplates } = createForm({
					...options,
					formStatePath,
					generateTemplatesFromPaths: innerGenerateTemplatesFromPaths,
					inputs: inputConfigItem,
					isTopLevel: false,
					statePath: `${inputsStatePath}.${inputName}.${index}`,
					templateReferencePath: innerTemplateReferencePath
				});
				innerRawData.push(itemRawData as Record<string, unknown>);
				if (index === 0) {
					form.templates = { ...form.templates, ...(itemTemplates as Record<string, unknown>) };
					template[inputName] = [itemTemplates[innerTemplateReferencePath]];
				}
			});
			(form.inputs as Record<string, unknown>)[inputName] = [];
			form.rawData[inputName] = innerRawData;
			continue;
		}
		// handle nested non-array objects
		if (isObject(inputConfig) && !isCreateFormOptionsInputItem(inputConfig)) {
			const {
				inputs: itemInputs,
				rawData: itemRawData,
				templates: itemTemplates
			} = createForm({
				...options,
				formStatePath,
				generateTemplatesFromPaths: innerGenerateTemplatesFromPaths,
				inputs: inputConfig,
				isTopLevel: false,
				statePath: `${inputsStatePath}.${inputName}`,
				templateReferencePath: innerTemplateReferencePath
			});
			(form.inputs as Record<string, unknown>)[inputName] = itemInputs;
			form.rawData[inputName] = itemRawData;
			form.templates = { ...form.templates, ...(itemTemplates as Record<string, unknown>) };
			template[inputName] = itemTemplates[innerTemplateReferencePath];
			continue;
		}
		// handle an actual input config item
		const actualInputConfig = inputConfig as CreateFormOptionsInputItem<unknown>;
		const inputItem: CustomInputModel<unknown> = {
			error: null,
			defaultValue: actualInputConfig.defaultValue,
			emptySelectOption: actualInputConfig.emptySelectOption,
			formStatePath,
			hasClearButton: actualInputConfig.hasClearButton,
			imageExtensionName: actualInputConfig.imageExtensionName,
			imageFileType: actualInputConfig.imageFileType,
			imageUrl: actualInputConfig.imageUrl,
			isImageFile: actualInputConfig.isImageFile,
			label: actualInputConfig.label,
			pristine: true,
			placeholder: actualInputConfig.placeholder,
			selectOptions: actualInputConfig.selectOptions,
			statePath: `${inputsStatePath}.${inputName}`,
			type: actualInputConfig.type,
			uploaderIconName: actualInputConfig.uploaderIconName,
			uploaderStateLoaderKey: actualInputConfig.uploaderStateLoaderKey,
			useDefaultValueGetterName: actualInputConfig.useDefaultValueGetterName,
			validations: cloneDeep(actualInputConfig.validations)
		};
		const defaultValue = getDefaultValue(inputItem, defaultValueGetters);
		inputItem.defaultValue = defaultValue;
		inputItem.value = defaultValue;
		(form.inputs as Record<string, unknown>)[inputName] = inputItem;
		if (typeof inputItem.value !== "undefined" && inputItem.value !== null) {
			form.rawData[inputName] = inputItem.value;
		}
		if (generateTemplate) {
			const templateItem = cloneDeep(inputItem);
			templateItem.statePath = templateItem.statePath.replace(inputsStatePath, "__PARENT_ITEM_PATH");
			template[inputName] = templateItem;
		}
	}
	if (generateTemplate) {
		(form.templates as unknown as {})[templateReferencePath] = template;
	}
	return form;
}

export function getBaseCreateFormSubItemConfig(): {
	id: CreateFormOptionsInputItem<number>;
	draftId: CreateFormOptionsInputItem<unknown>;
	deleted: CreateFormOptionsInputItem<boolean>;
} {
	return {
		id: { type: CustomInputType.Number },
		draftId: {
			type: CustomInputType.Text,
			useDefaultValueGetterName: "uuidV4"
		},
		deleted: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		}
	};
}

export function getDefaultValue<ValueType>(
	inputData: CustomInputModel<ValueType>,
	defaultValueGetters: DefaultValueGettersObject
): ValueType {
	const { defaultValue, useDefaultValueGetterName } = inputData;
	let actualDefaultValue = defaultValue;
	if (useDefaultValueGetterName) {
		const defaultValueGetter = defaultValueGetters[useDefaultValueGetterName];
		if (typeof defaultValueGetter === "function") {
			actualDefaultValue = defaultValueGetter();
		}
	}
	if (typeof actualDefaultValue === "undefined") {
		if (inputData.type === CustomInputType.Checkbox) {
			actualDefaultValue = false as unknown as ValueType;
		} else if (inputData.type === CustomInputType.Chips) {
			actualDefaultValue = [] as unknown as ValueType;
		} else {
			actualDefaultValue = "" as unknown as ValueType;
		}
	}
	return actualDefaultValue as ValueType;
}

export function getPopulateInputsAction<State>(
	options?: GetPopulateInputsActionOptions
): CaseReducer<State, PayloadAction<PopulateInputsActionPayload>> {
	const { defaultValueGetters } = options || {};
	return function populateInputsAction(state: State, action: PayloadAction<PopulateInputsActionPayload>): void {
		const { inputsStatePath, templatesStatePath, values } = action.payload;
		populateInputs(get(state, inputsStatePath), values, {
			currentPath: inputsStatePath,
			defaultValueGetters,
			setter: (statePath, value) => set(state as Record<string, unknown>, statePath, value),
			templates: templatesStatePath ? get(state, templatesStatePath) : {}
		});
	} as CaseReducer<State, PayloadAction<PopulateInputsActionPayload>>;
}

export function isCreateFormOptionsInputItem<ValueType>(
	inputConfig: CreateFormOptionsInputItem<ValueType> | Record<string, unknown>
): inputConfig is CreateFormOptionsInputItem<ValueType> {
	return inputConfig && customInputValues.includes(inputConfig.type as CustomInputType);
}

export function parseTemplate(
	template: Record<string, CustomInputModel<unknown>>,
	parentPath: string,
	options?: { defaultValueGetters?: DefaultValueGettersObject; pathSuffix?: string }
): FormInputItemsModel {
	const { defaultValueGetters, pathSuffix } = options || {};
	const templateObject =
		cloneDeep<
			Record<
				string,
				| CustomInputModel<unknown>
				| Record<string, CustomInputModel<unknown>>
				| Record<string, CustomInputModel<unknown>>[]
			>
		>(template);
	const actualParentPath = `${parentPath}${pathSuffix ? `.${pathSuffix}` : ""}`;
	for (const fieldName in templateObject) {
		const inputData = templateObject[fieldName];
		if (inputData instanceof Array) {
			const newInputData: Record<string, CustomInputModel<unknown>>[] = [];
			const innerParentPath = `${actualParentPath}.${fieldName}`;
			inputData.forEach((item, index) => {
				newInputData.push(
					parseTemplate(item, `${innerParentPath}.${index}`) as Record<string, CustomInputModel<unknown>>
				);
			});
			templateObject[fieldName] = newInputData;
			continue;
		}
		if (typeof inputData.statePath !== "string") {
			templateObject[fieldName] = parseTemplate(
				inputData as Record<string, CustomInputModel<unknown>>,
				`${actualParentPath}.${fieldName}`
			) as Record<string, CustomInputModel<unknown>>;
			continue;
		}
		inputData.statePath = inputData.statePath.replace(/^__PARENT_ITEM_PATH/, actualParentPath);
		inputData.value = getDefaultValue(inputData as CustomInputModel<unknown>, defaultValueGetters || {});
	}
	return templateObject;
}

export function populateInputs(
	inputs: FormInputItemsModel,
	values: Record<string, unknown>,
	options: PopulateInputsOptions
): void {
	const { currentPath, defaultValueGetters = {}, setter, templates = {} } = options;
	const templateBasePath = options.currentTemplatesPath || currentPath.replace(/\.\d+$/, "");
	for (const fieldName in inputs) {
		const fieldValue = values[fieldName];
		const itemPath = `${currentPath}.${fieldName}`;
		const templatePath = `${templateBasePath}.${fieldName}`;
		const inputData = inputs[fieldName];
		// handle nested arrays
		if (inputData instanceof Array) {
			const actualFieldValue = fieldValue instanceof Array ? fieldValue : [];
			let template = get(templates, [templatePath]);
			if (!template) {
				template = get(templates, [templatePath.replace(/(^.+Form(Group)?\.(.+\.)?inputs\.)/, "")]);
			}
			// let newInputData = inputData as FormInputItemsModel[];
			let newInputData = [] as FormInputItemsModel[];
			setter(itemPath, []); // reset the array
			actualFieldValue.forEach((fieldValueItem, fieldValueItemIndex) => {
				newInputData = [
					...newInputData,
					parseTemplate(template, itemPath, {
						defaultValueGetters,
						pathSuffix: `${fieldValueItemIndex}`
					})
				];
				setter(itemPath, newInputData);
				populateInputs(newInputData[fieldValueItemIndex], fieldValueItem, {
					...options,
					currentPath: `${itemPath}.${fieldValueItemIndex}`,
					currentTemplatesPath: templatePath
				});
			});
			continue;
		}
		// handle nested non-array objects
		else if (isObject(inputData) && !isCustomInputObject(inputData as CustomInputModel<unknown>)) {
			let template = get(templates, [templatePath]);
			if (!template) {
				template = get(templates, [templatePath.replace(/(^.+Form\.inputs\.)/, "")]);
			}
			if (template) {
				template = parseTemplate(template, itemPath, { defaultValueGetters }) as Record<
					string,
					CustomInputModel<unknown>
				>;
				setter(itemPath, template); // reset the object
			}
			populateInputs(template || (inputData as FormInputItemsModel), (fieldValue || {}) as Record<string, unknown>, {
				...options,
				currentPath: itemPath,
				currentTemplatesPath: templatePath
			});
			continue;
		}
		let valueToSet = fieldValue;
		if (typeof fieldValue === "undefined") {
			valueToSet = getDefaultValue(inputData as CustomInputModel<unknown>, defaultValueGetters);
		}
		setter(`${(inputData as CustomInputModel<unknown>).statePath}.value`, valueToSet);
	}
}

export function validateFormAction<State>(state: State, action: PayloadAction<ValidateFormActionPayload>): void {
	const { formStatePath, markInputsAsDirty } = action.payload;
	const form = get(state, formStatePath) as FormStateModel<
		FormInputItemsModel,
		Record<string, unknown>,
		TemplatesModel
	>;
	const { pristine, rawData, valid } = validateInputs(form.inputs, {
		markInputsAsDirty,
		recheckInputs: true,
		setter: (statePath, value) => set(state as Record<string, unknown>, statePath, value)
	});
	set(state as Record<string, unknown>, `${formStatePath}.rawData`, rawData);
	set(state as Record<string, unknown>, `${formStatePath}.valid`, valid);
	if (typeof pristine !== "undefined") {
		set(state as Record<string, unknown>, `${formStatePath}.pristine`, pristine);
	}
}

export function updateFormInputsPath<ValueType>(
	input: FormInputItemsModel | CustomInputModel<ValueType> | Record<string, unknown>,
	path: string
): void {
	// handle nested arrays
	if (input instanceof Array) {
		input.forEach((inputDataItem, index) => {
			//Update array paths recursively
			updateFormInputsPath(inputDataItem, `${path}.${index}`);
		});
	} else if (isObject(input) && !isCustomInputObject(input)) {
		//update object paths recursively
		for (const key in input) {
			updateFormInputsPath(input[key] as FormInputItemsModel, `${path}.${key}`);
		}
	} else {
		//Update path
		input!.statePath = `${path}`;
	}
}

export function validateInputs(
	inputs: FormInputItemsModel,
	options?: ValidateInputsOptions
): { pristine?: boolean; rawData: Record<string, unknown>; valid: boolean } {
	const rawData: Record<string, unknown> = {};
	const opt = options || ({} as ValidateInputsOptions);
	const { markInputsAsDirty, recheckInputs = true, setter } = opt;
	const { deleted } = inputs;
	let valid = true;
	for (const fieldName in inputs) {
		let inputData = inputs[fieldName];
		// handle nested arrays
		if (inputData instanceof Array) {
			const inputRawData: Record<string, unknown>[] = [];
			inputData.forEach(inputDataItem => {
				if (inputDataItem.deleted?.value) return;

				const inputValidationData = validateInputs(inputDataItem, opt);
				if (!inputValidationData.valid && valid) {
					valid = false;
				}
				inputRawData.push(inputValidationData.rawData);
			});
			rawData[fieldName] = inputRawData;
			continue;
		}
		if (isObject(inputData) && !isCustomInputObject(inputData as CustomInputModel<unknown>)) {
			const inputValidationData = validateInputs(inputData as FormInputItemsModel, opt);
			if (!inputValidationData.valid && valid) {
				valid = false;
			}
			rawData[fieldName] = inputValidationData.rawData;
			continue;
		}
		inputData = inputData as CustomInputModel<unknown>;
		if (recheckInputs) {
			const { statePath } = inputData;
			inputData = checkInputValidity(inputData, {
				markAsDirty: markInputsAsDirty
			}) as CustomInputModel<unknown>;
			if (setter) {
				for (const fieldName in inputData) {
					setter(`${statePath}.${fieldName}`, inputData[fieldName]);
				}
			}
		}
		if (inputData.value !== null && (inputData.type !== CustomInputType.Text || inputData.value !== "")) {
			rawData[fieldName] = inputData.value;
		}
		if (inputData.error) {
			if (valid && (!deleted || !deleted["value"])) {
				valid = false;
			}
		}
	}
	const returnData: { pristine?: boolean; rawData: Record<string, unknown>; valid: boolean } = {
		rawData,
		valid
	};
	if (markInputsAsDirty) {
		returnData.pristine = false;
	}
	return returnData;
}
