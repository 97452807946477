import React from "react";

import { Checkbox } from "@material-ui/core";

import { concat } from "lodash";

import { QuizQuestionAnswerOption, StyledText } from "./style";

const MultipleChoiceQuestion = ({ question, onChange, userAnswers }) => {
	let { answerOptions } = question;
	answerOptions = answerOptions ?? question.data.answerOptions;
	const handleChangeAnswer = (checked, answerId) => {
		onChange(checked ? concat(userAnswers, { id: answerId }) : userAnswers.filter(answer => answer.id !== answerId));
	};

	return answerOptions?.map(({ id, text }) => (
		<QuizQuestionAnswerOption key={id}>
			<Checkbox
				color="default"
				key={id}
				checked={userAnswers.some(item => item.id == id)}
				onChange={({ target: { checked } }) => handleChangeAnswer(checked, id)}
			/>
			<StyledText>{text}</StyledText>
		</QuizQuestionAnswerOption>
	));
};

export default MultipleChoiceQuestion;
