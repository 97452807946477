import { Box } from "@material-ui/core";
import styled from "styled-components";

export const SpecialOfferWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.price {
		position: relative;
		font-family: var(--poppins);
		font-size: 26px;
		font-weight: bold;
		line-height: 1.231;
		opacity: 0.87;
		color: ${({ theme }) => theme.palette.text.primary};

		&:before {
			position: absolute;
			content: "";
			left: 0;
			top: 40%;
			right: 0;
			border-top: 1px solid;
			border-color: inherit;
			transform: rotate(-15deg);
		}
	}

	.discount-block {
		display: flex;
		gap: 8px;
		align-items: center;

		.offer-text {
			font-family: var(--openSans);
			font-size: 12px;
			font-weight: 400;
			line-height: 1.333;
			opacity: 0.87;
			color: ${({ theme }) => theme.palette.text.primary};
		}

		.discount {
			font-family: var(--poppins);
			font-size: 18px;
			font-weight: bold;
			line-height: 1.333;
			color: ${({ theme }) => theme.palette.text.primary};
			opacity: 0.87;
		}
	}
`;
