import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "../../api/baseApiService";
import { GLOBAL_CONSTANTS } from "../../constants";

import { Course } from "../../models";

export class CoursesService extends BaseApiService<
	Course,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const coursesService = new CoursesService(GLOBAL_CONSTANTS.COURSES);
