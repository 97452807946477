import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto,
	FindResponse
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { FAQItem, FAQTopic } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class FAQIitemsService extends BaseApiService<
	FAQItem,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	findTopics(data: BaseFindDto): Promise<FindResponse<FAQTopic>> {
		return request(
			`${this.baseUrl}/topics${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<FindResponse<FAQTopic>>;
	}
}

export const faqItemsService = new FAQIitemsService(URLS.FAQ_ITEMS);
