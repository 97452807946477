export const checkHtmlIndex = (content: string, index: number): boolean => {
	let i = 0;
	const arrayOfLength = [{}];
	while (i <= content.length - 1) {
		if (content.substr(i).startsWith('<span class="highlighted"><i class="selected">')) {
			arrayOfLength.push({ start: i, end: i + 44 });
			i += 44;
		} else if (content.substr(i).startsWith('<span class="highlighted">')) {
			arrayOfLength.push({ start: i, end: i + 26 });
			i += 26;
		} else if (content.substr(i).startsWith("</li>")) {
			arrayOfLength.push({ start: i, end: i + 5 });
			i += 5;
		} else {
			i++;
		}
	}
	for (let j = 0; j < arrayOfLength.length; j++) {
		const element = arrayOfLength[j];
		if (element["start"] < index && element["end"] > index) {
			return false;
		}
	}
	return true;
};
