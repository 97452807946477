import React, { Suspense, createContext } from "react";

import ErrorBoundary from "@remar/shared/dist/components/ErrorBoundary";
import { FooterNav } from "@remar/shared/dist/layouts/BaseLayout";
import { getTracingId } from "@remar/shared/dist/utils/getUniqueValue";
import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

import ReactDOM from "react-dom";
import "./index.css";

import ReactPixel, { AdvancedMatching } from "react-facebook-pixel";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { Provider } from "react-redux";
import { setupStore } from "store";

import { GLOBAL_CONSTANTS } from "./constants";

import GlobalDndContext from "./core/dndGlobalContext";

import Routes from "./core/routes";

import NotificationsWrapper from "./modules/Layout/Wrappers/NotificationsWrapper";
import InitializeTheme from "./theme/InitializeTheme";
const Loading: React.FC = () => <span>Loading...</span>;
const stripePromise = GLOBAL_CONSTANTS.STRIPE_KEY ? loadStripe(GLOBAL_CONSTANTS.STRIPE_KEY) : null;

// TODO: Hack for demo
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.showTrial = false;
const firebaseConfig = {
	apiKey: GLOBAL_CONSTANTS.GOOGLE_API_KEY,
	authDomain: GLOBAL_CONSTANTS.GOOGLE_AUTH_DOMAIN,
	projectId: GLOBAL_CONSTANTS.GOOGLE_PROJECT_ID,
	storageBucket: GLOBAL_CONSTANTS.GOOGLE_STORAGE_BUCKET,
	messagingSenderId: GLOBAL_CONSTANTS.GOOGLE_MEASUREMENT_ID,
	appId: GLOBAL_CONSTANTS.GOOGLE_APP_ID,
	measurementId: GLOBAL_CONSTANTS.GOOGLE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics
const analytics = getAnalytics(app);

const advancedMatching = {} as AdvancedMatching; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false // enable logs
};
ReactPixel.init(GLOBAL_CONSTANTS.REACT_APP_FACEBOOK_PIXEL_ID as string, advancedMatching, options);

export const GoogleAnalytics = createContext(analytics);

Sentry.init({
	dsn: GLOBAL_CONSTANTS.SENTRY_DSN,
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	environment: GLOBAL_CONSTANTS.ENVIRONMENT,
	tracesSampleRate: GLOBAL_CONSTANTS.SENTRY_TRACE_RATE,
	sampleRate: GLOBAL_CONSTANTS.SENTRY_RATE,
	replaysOnErrorSampleRate: GLOBAL_CONSTANTS.REPLAYS_ONERROR_SAMPLE_RATE,
	maxBreadcrumbs: 50,
	initialScope: {
		tags: {
			tracingNumber: getTracingId()
		}
	},
	beforeSend(event, hint) {
		// workaround to avoid 'Timeout' error thrown by Google Recaptcha https://remar-review.sentry.io/issues/4380413789
		if (hint.originalException === "Timeout") return null;
		return event;
	}
});

const store = setupStore();

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary footer={<FooterNav />}>
			<GoogleAnalytics.Provider value={analytics}>
				<Provider store={store}>
					<NotificationsWrapper>
						<GoogleReCaptchaProvider reCaptchaKey={GLOBAL_CONSTANTS.RECAPTCHA_SITE_KEY as string}>
							<GlobalDndContext>
								<Suspense fallback={<Loading />}>
									<InitializeTheme>
										<Elements stripe={stripePromise}>
											<Routes />
										</Elements>
									</InitializeTheme>
								</Suspense>
							</GlobalDndContext>
						</GoogleReCaptchaProvider>
					</NotificationsWrapper>
				</Provider>
			</GoogleAnalytics.Provider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);
