import React, { useState } from "react";

import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";

import { CancellationActions } from "core/constants";

import { CancellationWrapper, SadIllustrationImage } from "../styles";

import { ICancellationProps } from "../subscriptionCancellation.dto";

const INITIAL_STEP = 1;
const CancellationOptions = ({ action, cancellationOptions }: ICancellationProps) => {
	const [selectedOption, setSelectedOption] = useState(INITIAL_STEP);

	const handleCancel = () => {
		const findOption = cancellationOptions!.find(option => option.id === selectedOption);
		if (findOption?.data.selectPrimary) {
			const primaryOption = findOption.options.find(o => o.primary);
			action!({
				type: CancellationActions.PrimaryOption,
				link: primaryOption!.data.link,
				cancellationReasonId: primaryOption!.id
			});
		}
		action!({ type: CancellationActions.Option, option: findOption, cancellationReasonId: 0 });
	};

	return (
		<CancellationWrapper container>
			<Grid item xs={12} md={6} className={"left-Grid"}>
				<Box my={1} mt={3}>
					<SadIllustrationImage />
				</Box>

				<Typography className={"title"}>Why Are You Cancelling Your Subscription?</Typography>
				<Typography className={"description"}>
					We are sad to see you go. Please select the reason for cancelling the subscription to help us better
					understand your needs.
				</Typography>
			</Grid>
			<Grid item xs={12} md={6} className={"right-Grid"}>
				<Box mt={3} alignSelf={"flex-start"}>
					<FormControl component="fieldset">
						<RadioGroup
							aria-label="cancellation_options"
							name="cancellation_options"
							value={selectedOption}
							onChange={e => setSelectedOption(+e.target.value)}
						>
							{cancellationOptions?.map(({ id, text }) => (
								<FormControlLabel key={id} value={id} control={<Radio color={"primary"} />} label={text} />
							))}
						</RadioGroup>
					</FormControl>
				</Box>
				<Box mt={8} width={"100%"}>
					<Button variant={"filled"} color={"primary"} fullWidth onClick={handleCancel}>
						Cancel Subscription
					</Button>
				</Box>
			</Grid>
		</CancellationWrapper>
	);
};

export default CancellationOptions;
