import React, { FC } from "react";

import { useTheme } from "@material-ui/core";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { StyledSvg } from "./style";

interface CircularProgressBarProps {
	sqSize: number;
	percentage: number;
	strokeWidth: number;
	hideIndicatorCircle?: boolean;
	stroke?: string;
}

const CircularProgressBar: FC<CircularProgressBarProps> = ({
	sqSize,
	percentage,
	strokeWidth,
	hideIndicatorCircle = false,
	stroke
}) => {
	const radius = (sqSize - strokeWidth) / 2;
	const viewBox = `0 0 ${sqSize} ${sqSize}`;
	const dashArray = radius * Math.PI * 2;
	const dashOffset = dashArray - (dashArray * percentage) / 100;
	const theme = useTheme<IExtendedTheme>();

	const getPointerAngle = () => {
		return 270 + percentage * 3.6;
	};

	return (
		<StyledSvg width={sqSize} height={sqSize} viewBox={viewBox}>
			<circle
				stroke="#3d4047"
				fill="none"
				cx={sqSize / 2}
				cy={sqSize / 2}
				r={radius}
				strokeWidth={`${strokeWidth}px`}
			/>
			<circle
				fill="none"
				stroke={stroke || theme.palette.colors.primary[1600]}
				strokeLinecap="round"
				strokeLinejoin="round"
				cx={sqSize / 2}
				cy={sqSize / 2}
				r={radius}
				strokeWidth={`${strokeWidth}px`}
				transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
				style={{
					strokeDasharray: dashArray,
					strokeDashoffset: dashOffset,
					transition: "stroke-dashoffset  0.9s ease"
				}}
			/>
			{!hideIndicatorCircle && (
				<circle
					cx="200"
					cy="50%"
					r="8"
					fill="#4a99f9"
					stroke="#5174a3"
					strokeWidth="5"
					style={{
						transformOrigin: "50% 50%",
						transform: `rotate(${getPointerAngle()}deg) translate(-53px)`,
						transition: "all 0.9s ease"
					}}
				/>
			)}
		</StyledSvg>
	);
};

export default CircularProgressBar;
