import React, { ChangeEvent } from "react";

import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { IExtendedTheme } from "theme/default";

import { PaginationWrapper } from "./styles";

const useStyles = makeStyles((theme: IExtendedTheme) => ({
	ul: {
		"& .Mui-selected": {
			backgroundColor: theme.palette.primary.main // "#264fae",
		}
	}
}));

interface Props {
	page: number;
	count: number;
	rowsPerPage: number;
	shape?: "rounded" | "round";
	onChange: (event: ChangeEvent<unknown>, page: number) => void;
}

export const TablePagination = ({ page: currentPage, count, rowsPerPage, shape = "rounded", onChange }: Props) => {
	const classes = useStyles();
	const totalPages = Math.ceil(count / rowsPerPage);

	return (
		<PaginationWrapper>
			<Pagination
				classes={{ ul: classes.ul }}
				page={currentPage}
				size="medium"
				count={totalPages}
				shape={shape}
				onChange={(event, page) => {
					if (currentPage !== page) {
						onChange(event, page);
					}
				}}
			/>
		</PaginationWrapper>
	);
};
