import { useEffect, useState } from "react";

import { differenceInMinutes } from "date-fns";

import { useInterval } from "./useInterval";

import { COUNTDOWN_STATUS } from "../constants";
import { userQuizAttemptsService } from "../services/userQuizAttempts";

const MIN_DIFF_IN_MINUTES = 1;
export const useCountdown = ({
	totalTimeInMinutes,
	startTime,
	onExpire
}: {
	totalTimeInMinutes: number;
	startTime?: string;
	onExpire: () => void;
}): {
	secondsToDisplay: number;
	minutesToDisplay: number;
	hoursToDisplay: number;
	status: string;
	startCountdown: () => void;
	stopCountdown: () => void;
	handleReset: () => void;
	isTimeSynced: boolean;
} => {
	const [endTime, setEndTime] = useState(0);
	const [secondsRemaining, setSecondsRemaining] = useState(totalTimeInMinutes * 60);
	const [status, setStatus] = useState("");
	const [isTimeSynced, setIsTimeSynced] = useState(true);
	const secondsToDisplay = +(secondsRemaining % 60).toFixed(0);
	const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
	const minutesToDisplay = minutesRemaining % 60;
	const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

	useEffect(() => {
		userQuizAttemptsService
			.fetchServerTime()
			.then(res => {
				const clientTime = new Date();
				const serverTime = new Date(res.dateTime);
				const minsDiff = Math.abs(differenceInMinutes(serverTime, clientTime));
				const isSynced = minsDiff < MIN_DIFF_IN_MINUTES;
				if (!isSynced) {
					stopCountdown();
				}
				setIsTimeSynced(isSynced);
			})
			.catch(() => {
				setIsTimeSynced(false);
			});
	}, []);

	const startCountdown = () => {
		isTimeSynced && setStatus(COUNTDOWN_STATUS.STARTED);
	};

	const stopCountdown = () => {
		setStatus(COUNTDOWN_STATUS.STOPPED);
		setSecondsRemaining(totalTimeInMinutes * 60);
	};

	const handleReset = () => {
		setSecondsRemaining(totalTimeInMinutes * 60);
		setStatus(COUNTDOWN_STATUS.STARTED);
	};

	useEffect(() => {
		if (status === COUNTDOWN_STATUS.STARTED && startTime) {
			const startDateTime = new Date(startTime!);
			setEndTime(startDateTime!.getTime() + totalTimeInMinutes * 60 * 1000);
		}
	}, [status, startTime, totalTimeInMinutes]);

	useEffect(() => {
		if (status === COUNTDOWN_STATUS.STOPPED) {
			onExpire();
		}
	}, [status]);

	useInterval(
		() => {
			const currentTime = Date.now();
			const seconds = +((endTime - currentTime) / 1000).toFixed(0);
			seconds > 0 && isTimeSynced ? setSecondsRemaining(seconds) : setSecondsRemaining(0);
			return null;
		},
		status === COUNTDOWN_STATUS.STARTED ? 1000 : null
	);
	return {
		secondsToDisplay,
		minutesToDisplay,
		hoursToDisplay,
		status,
		startCountdown,
		stopCountdown,
		handleReset,
		isTimeSynced
	};
};
