import { UserQuizAttemptCreateDto, UserQuizAttemptUpdateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";
import { request } from "../../api/request";
import { UserLessonAttempt, UserQuizAttempt } from "../../models";

import { URLS } from "../services.constants";

export class UserQuizAttemptsService extends BaseApiService<
	UserLessonAttempt,
	UserQuizAttemptCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	UserQuizAttemptUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	fetchServerTime = (): Promise<{ dateTime: string }> => {
		return request("/current-time", "GET") as Promise<{
			dateTime: string;
		}>;
	};

	retakeQuiz = async (quizId: number): Promise<UserQuizAttempt> => {
		return request(
			`${this.baseUrl}/retake`,
			"POST",
			this.emptyStringToNull({ quizId }) as Record<string, unknown>
		) as Promise<UserQuizAttempt>;
	};
}

export const userQuizAttemptsService = new UserQuizAttemptsService(URLS.USER_QUIZ_ATTEMPTS);
