const green = {
	100: "hsl(136,94%,93%)",
	200: "hsl(139,58%,85%)",
	300: "hsl(140,58%,85%)",
	400: "hsl(137,75%,55%)",
	500: "hsl(137,56%,43%)",
	600: "hsl(138,67%,30%)",
	700: "hsl(141,50%,24%)",
	800: "hsl(153,56%,12%)"
};

export default green;
