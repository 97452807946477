import { Box, Modal, Typography, alpha, createStyles, darken, makeStyles } from "@material-ui/core";

import { CustomInput as Input } from "@remar/shared/dist/components/CustomInput/CustomInput";
import styled from "styled-components";

import { NotesIllustration } from "assets/icons";

export const useStyles = makeStyles(() =>
	createStyles({
		optionsIconsGap: {
			gap: "24px",

			"& > svg": {
				width: "18px",
				height: "18px",
				fill: "#d3d6e0",
				cursor: "pointer",

				"&:hover": {
					fill: darken("#d3d6e0", 0.2)
				}
			}
		},
		iconClass: {
			cursor: "pointer"
		}
	})
);

export const StyledDateTitleCellText = styled(Typography)`
	opacity: 0.87;
	font-size: ${({ theme }) => theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: ${({ theme }) => theme.palette.text.secondary};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledTimeTitleCellText = styled(Typography)`
	opacity: 0.87;
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.text.secondary};
`;

export const FieldLabel = styled.label`
	font-family: var(--openSans);
	font-size: ${({ theme }) => theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
	margin: 20px 24px 0 0;
	display: flex;
	flex-direction: column;

	${({ theme }) => theme.breakpoints.down("sm")} {
		margin-right: 0;
	}
`;

export const ButtonsRow = styled(Box)`
	display: flex;
	flex-flow: row nowrap;
	justify-content: end;
	border-top: solid 1px #2a2e37;
	padding: 15px 20px 0;
	gap: 16px;
	${({ theme }) => theme.breakpoints.down("sm")} {
		flex-direction: column-reverse;
		> button {
			width: 100%;
		}
	}
`;

export const CustomInput = styled(Input)`
	width: 100%;
	margin: 5px 0 0;
`;

// todo: extract to shared and make generic
export const ColumnHeader = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	font-weight: 500;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
`;

export const DeleteModalTitle = styled(Typography)`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: ${({ theme }) => theme.palette.primary.main};
	padding-left: 10px;
	margin: 0;
	text-align: left;
	text-transform: capitalize;
	${({ theme }) => theme.breakpoints.down("sm")} {
		padding-left: 20px;
	}
`;

export const DeleteConfirmContent = styled(Box)`
	width: 480px;
	position: relative;
	background: ${({ theme }) => theme.palette.background.paper};
	padding: 16px 8px;
	overflow: hidden;
`;

export const NoteTextContainer = styled(Box)`
	height: 75px;
	filter: grayscale(1);
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	font-size: ${({ theme }) => theme.typography.pxToRem(15)};
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
	padding-left: 10px;
	${({ theme }) => theme.breakpoints.down("sm")} {
		padding-left: 20px;
	}
`;

export const FormModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledIllustration = styled(NotesIllustration)`
	#Path_1 {
		fill: ${({ theme }) => theme.palette.primary.main};
	}
`;
