export const QuotesList = [
	"You can, you will, you must pass NCLEX!",
	"You didn't come this far to turn around now!",
	"Anything worth having is worth fighting for!",
	"If it were easy everyone would be doing it!",
	"Nobody is going to hand you your dreams! You have to work for it!",
	"You have survived 100% of your worst days, keep going!",
	"There are a lot of things that come easy, this isn't one of them!",
	"Don't remember the failures, remember the lessons they taught you!",
	"You get what you WORK for, not what you wish for!",
	"Don't decrease the goal, increase the effort!",
	"The expert in everything was once a beginner!",
	"Your past does not equal your future!",
	"Your income right now is not enough, level up!",
	"Your family is depending on your success!",
	"Goals in front of you, fears behind you!",
	"Live your dreams or live your fears! Choose wisely!",
	"Your own laziness will be your biggest competition, beat it!",
	"With God it's possible!",
	"You are never too old to dream another dream!",
	"Don't come here unless you are ready to give 110%!",
	"	It's NOT over until you get a nursing license!",
	"Your nursing license will bless generations!",
	"To earn more, you must learn more!",
	"ReMar Review is cheering for you!",
	"Nothing can stop God's plan for your life!",
	"If God is for you, who can be against you?",
	"Your faith can move mountains!",
	"Every new day is a gift, make the most of it!",
	"F.A.I.L. means First Attempt In Learning",
	"Failure is not falling down, it's staying down!"
];
