import React from "react";

import { TypographyProps } from "@material-ui/core";

import { StyledText } from "./styles";

type TextProps = Omit<TypographyProps, "variant" | "color"> & {
	type?:
		| "h1"
		| "h2"
		| "h3"
		| "h4"
		| "h5"
		| "h6"
		| "p1"
		| "p2"
		| "subtitle1"
		| "subtitle2"
		| "caption1"
		| "caption2"
		| "small1"
		| "small2"
		| "label"
		| "button"
		| "highlight"
		| "highlight1"
		| "highlight2";
	color?: "primary" | "secondary" | "tertiary" | "info" | "basic" | "success" | "control" | "danger";
};

const getVariant = (type: TextProps["type"]): TypographyProps["variant"] => {
	switch (type) {
		case "h1":
			return "h1";
		case "h2":
			return "h2";
		case "h3":
			return "h3";
		case "h4":
			return "h4";
		case "h5":
			return "h5";
		case "h6":
			return "h6";
		case "p1":
			return "body1";
		case "p2":
			return "body2";
		case "subtitle1":
			return "subtitle1";
		case "subtitle2":
			return "subtitle2";
		case "caption1":
		case "caption2":
			return "caption";
		case "button":
			return "button";
		default:
			return "body1";
	}
};

const Text: React.FC<TextProps> = ({ children, ...rest }) => (
	<StyledText variant={getVariant(rest.type)} {...rest}>
		{children}
	</StyledText>
);

export default Text;
