import React from "react";

import { Box, Radio } from "@material-ui/core";
import cn from "classnames";

import {
	AnswerContainer,
	MatrixTableBody,
	MatrixTableHeader,
	RadioWrapper,
	RowLabelContainer,
	RowOptionsContainer,
	StyledText
} from "./styles";

import { useStyles } from "../styles";

const MatrixSingleChoiceResult = ({ result, question }) => {
	const classes = useStyles();

	const {
		data: { tableLabel, answerOptions, groups }
	} = question;

	const selectedAnswers = {};

	result?.selectedAnswers.forEach(
		({ questionAnswerOptionId, questionGroupId }) => (selectedAnswers[questionGroupId] = questionAnswerOptionId)
	);

	return (
		<>
			<MatrixTableHeader display="flex" maxWidth="595px" flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<RowLabelContainer>
						<StyledText>{tableLabel}</StyledText>
					</RowLabelContainer>

					<RowOptionsContainer>
						{answerOptions?.map(({ text }, answerOptionIndex) => (
							<Box
								key={answerOptionIndex}
								justifyContent="center"
								display="flex"
								alignItems="center"
								height="100%"
								flexDirection="row"
								flex={1}
								{...(answerOptionIndex !== 2 && {
									borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
								})}
							>
								<StyledText>{text}</StyledText>
							</Box>
						))}
					</RowOptionsContainer>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody isinresults>
				{groups?.map(({ text, id: gId, selectedAnswerOptions }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom={gIndex < groups.length - 1 ? "solid 1px rgba(151, 151, 151, 0.3)" : ""}
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<RowLabelContainer>
								<StyledText>{text}</StyledText>
							</RowLabelContainer>
							<RowOptionsContainer>
								<RadioWrapper>
									{answerOptions.map(({ id: optId }, _index) => {
										const correctAnswer = !!selectedAnswerOptions.find(id => id === optId);
										const yourAnswer = selectedAnswers[gId] === optId;

										return (
											<Box
												className={`${correctAnswer ? classes.correct : ""} ${yourAnswer ? classes.your : ""}`}
												key={_index}
												display="flex"
												flexDirection="row"
												justifyContent="center"
												alignItems="center"
												flexWrap="wrap"
												flex={1}
												{...(_index !== 2 && {
													borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
												})}
											>
												<Radio
													color="default"
													value={optId}
													readOnly
													checked={selectedAnswers[gId] === optId || !!selectedAnswerOptions.find(id => id === optId)}
												/>
												{correctAnswer && yourAnswer ? (
													<div className={cn(classes.radioLabel, classes.openSansFontFamily)}>
														<AnswerContainer>Correct Answer</AnswerContainer>
													</div>
												) : !correctAnswer && yourAnswer ? (
													<div className={cn(classes.radioLabel, classes.openSansFontFamily)}>
														<AnswerContainer>Incorrect answer</AnswerContainer>
													</div>
												) : correctAnswer ? (
													<div className={cn(classes.radioLabel, classes.openSansFontFamily)}>
														{" "}
														<AnswerContainer>Unmarked correct answer</AnswerContainer>
													</div>
												) : null}
											</Box>
										);
									})}
								</RadioWrapper>
							</RowOptionsContainer>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</>
	);
};

export default MatrixSingleChoiceResult;
