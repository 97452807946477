import { IExtendedPaletteOptions } from "../theme/default";

export interface DoubleExtendedPalette extends IExtendedPaletteOptions {
	RawColorPicker: {
		borderColor: string;
		titleColor: string;
	};
	customBackground: {
		shade1: string;
	};
	checkMark: {
		validColor: string;
		color: string;
	};
}
export enum Themes {
	DARK = "default",
	DARK_PURPLE = "purple",
	DARK_CYAN_BLUE = "cyanBlue",
	DARK_GREEN = "green",
	DARK_YELLOW = "yellow",
	DARK_ORANGE = "orange",
	DARK_RED = "red",
	DARK_BLUE = "blue",
	LIGHT_PURPLE = "light_purple",
	LIGHT_CYAN_BLUE = "light_cyanBlue",
	LIGHT_GREEN = "light_green",
	LIGHT_YELLOW = "light_yellow",
	LIGHT_ORANGE = "light_orange",
	LIGHT_RED = "light_red",
	LIGHT_BLUE = "light_blue"
}
