import { Color } from "@material-ui/lab";
import { Action, ThunkAction } from "@reduxjs/toolkit";

import { SetStateValue } from "./setStateValue";

function _emitSignature<
	ReturnType,
	RootState,
	AppThunk extends ThunkAction<ReturnType, RootState, unknown, Action<string>>
>(message: string, color: Color): AppThunk {
	console.log(message, color);
	return undefined as unknown as AppThunk;
}

export interface SetImageUrlGenericApiService {
	// eslint-disable-next-line no-unused-vars
	getImageUrl: (data: { imageKey: string }) => Promise<{ url: string }>;
}

export interface SetImageUrlFromFileOption {
	_emit: unknown;
	genericApiService: SetImageUrlGenericApiService;
	setStateValue: SetStateValue;
}

export function setImageUrlFromFile<
	ReturnType,
	RootState,
	AppThunk extends ThunkAction<ReturnType, RootState, unknown, Action<string>>
>(data: { imageKey: string; imageUrlStatePath: string }, options: SetImageUrlFromFileOption): AppThunk {
	const { _emit, genericApiService, setStateValue } = options;
	return (async dispatch => {
		try {
			const { url } = await genericApiService.getImageUrl({ imageKey: data.imageKey });
			dispatch(setStateValue({ key: data.imageUrlStatePath, value: url }));
		} catch (e) {
			console.error(e);
			dispatch((_emit as typeof _emitSignature)("Error getting the image url.", "error"));
		}
	}) as unknown as AppThunk;
}
