import React from "react";

import { easeQuadInOut } from "d3-ease";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

import AnimatedProgressProvider from "./AnimatedProgressProvider";

import { Graph, GraphContainer, ProgressCount, ProgressPercentage } from "./style";

import { GraphLabel } from "../styles";
import "react-circular-progressbar/dist/styles.css";

interface Props {
	data: {
		count: number;
		label: string;
		percentage: number;
		primaryColor: string;
		secondaryColor: string;
	};
}

const RadialBar = ({ data: { count, label, percentage, primaryColor, secondaryColor } }: Props) => (
	<GraphContainer display="flex" paddingTop="25px" width="100%" justifyContent="center" alignItems="center">
		<Graph display={"flex"} maxWidth="200px" flexDirection="column">
			<GraphLabel> {label}</GraphLabel>
			<AnimatedProgressProvider
				valueStart={0}
				valueEnd={Math.round(percentage)}
				duration={1.4}
				easingFunction={easeQuadInOut}
			>
				{value => (
					<CircularProgressbarWithChildren
						styles={buildStyles({
							pathTransitionDuration: 0.1,
							pathTransition: "none",
							pathColor: primaryColor,
							trailColor: secondaryColor
						})}
						value={value}
					>
						<ProgressCount>{count}</ProgressCount>
						<ProgressPercentage>{`${Math.round(percentage)}%`}</ProgressPercentage>
					</CircularProgressbarWithChildren>
				)}
			</AnimatedProgressProvider>
		</Graph>
	</GraphContainer>
);

export default RadialBar;
