import { makeStyles } from "@material-ui/core/styles";

import { IExtendedTheme } from "../../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	columnFilterIcon: {
		margin: "15px 5px",
		width: "18px",
		cursor: "pointer"
	},
	filterContainer: {
		display: "flex",
		alignItems: "center",
		position: "relative",
		gap: 10
	},
	filterTitleText: {
		cursor: "pointer",
		color: theme.palette.text.secondary
	},
	filbersBlock: {
		borderRadius: "4px",
		position: "absolute",
		zIndex: 4,
		minWidth: 170,
		top: "40px",
		left: -60,
		background: theme.palette.colors.basic[750],
		padding: "16px"
	},
	labelText: {
		fontFamily: "var(--openSans)",
		fontSize: theme.typography.pxToRem(13),
		fontWeight: 600,
		opacity: 0.87
	}
}));
