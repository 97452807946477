import { BaseModel } from "../../../models";

export interface SubjectCategory extends BaseModel {
	createdAt: string;
	deletedAt: string | null;
	description: string | null;
	id: number;
	locationId: number;
	name: string;
	updatedAt: string;
}

export interface SubjectCategories {
	more: boolean;
	page: number;
	perPage: number;
	totalItems: number;
	totalPages: number;
	items: SubjectCategory[];
}

export enum LocationEnum {
	Main = 1,
	Common = 2
}
