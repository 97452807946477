import React from "react";

import { Box } from "@material-ui/core";

import { StyledGroupText } from "modules/Lesson/style";

import { MatrixTableBody, MatrixTableHeader } from "../../styles";
import { useStyles } from "../styles";

const MultipleResponseGroupResult = ({ question, result }) => {
	const classes = useStyles();
	const {
		data: { tableLabel, groups, answerOptionLabel }
	} = question;

	const selectedAnswers = {};

	result?.selectedAnswers.forEach(
		({ questionAnswerOptionId, questionGroupId }) => (selectedAnswers[questionAnswerOptionId] = questionGroupId)
	);

	return (
		<>
			<MatrixTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<Box
						display="flex"
						borderRight="solid 1px rgba(151, 151, 151, 0.3)"
						flexDirection="row"
						width="50%"
						height="100%"
						alignItems="center"
						pl="16px"
					>
						<StyledGroupText>{tableLabel}</StyledGroupText>
					</Box>

					<Box
						display="flex"
						borderRight="solid 1px rgba(151, 151, 151, 0.3)"
						flexDirection="row"
						width="50%"
						height="100%"
						alignItems="center"
						pl="16px"
					>
						<StyledGroupText>{answerOptionLabel}</StyledGroupText>
					</Box>
				</Box>
			</MatrixTableHeader>

			<MatrixTableBody isinresults>
				{groups?.map(({ text, id: gId, answerOptions }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom={gIndex < groups.length - 1 ? "solid 1px rgba(151, 151, 151, 0.3)" : ""}
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<Box
								display="flex"
								flexDirection="row"
								borderRight="solid 1px rgba(151, 151, 151, 0.3)"
								height="100%"
								width="50%"
								pl="16px"
								pt="13px"
							>
								<StyledGroupText>{text}</StyledGroupText>
							</Box>
							<Box display="flex" flexDirection="column" width="50%" padding={"10px 12px"} className={classes.gap5}>
								{answerOptions.map(({ id: optId, text, isCorrect }, _index) => {
									const correctAnswer = isCorrect && selectedAnswers[optId] === gId;
									const yourAnswer = selectedAnswers[optId] === gId;
									return (
										<Box
											className={`${
												correctAnswer
													? classes.correctBg
													: yourAnswer
													? classes.yourBg
													: isCorrect
													? classes.correctOptBg
													: ""
											} `}
											key={_index}
											display="flex"
											minHeight={"30px"}
											flexDirection="column"
											justifyContent={"center"}
											flexWrap="wrap"
										>
											{correctAnswer && yourAnswer ? (
												<span className={classes.correctAnsText}>Correct Answer</span>
											) : !correctAnswer && yourAnswer ? (
												<span className={classes.yourAnsText}>Incorrect answer</span>
											) : isCorrect ? (
												<span className={classes.correctOptText}>Unmarked correct answer</span>
											) : null}
											<span className={classes.optText}>{text}</span>
										</Box>
									);
								})}
							</Box>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</>
	);
};

export default MultipleResponseGroupResult;
