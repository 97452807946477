import React from "react";

import { Box, Card, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@remar/shared/dist/components/Button";

import { useStyles } from "@remar/shared/dist/styles";

import { MulticolorLockSvg } from "assets/icons";

type UnlockChapterModalProps = {
	open: boolean;
	disclaimer: string;
	buttonText: string;
	onClose: () => void;
	onUnlock: () => void;
	loading?: boolean;
};
const UnlockChapterModal = ({ open, onClose, disclaimer, buttonText, onUnlock, loading }: UnlockChapterModalProps) => {
	const classes = useStyles();
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={open} onClose={onClose}>
			<Box maxWidth={555} width="100%">
				<Card className={classes.card}>
					<Box display="flex" alignItems="flex-end" justifyContent="flex-end" mb={"30px"}>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					<Box
						margin="auto"
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="space-between"
						gridGap="24px"
						maxWidth={360}
					>
						<MulticolorLockSvg width={80} height={80} />
						<Typography className={classes.modalMainText}>Chapter is locked</Typography>
						<Typography className={classes.modalSubText}>{disclaimer}</Typography>
						<Button
							variant="filled"
							color="primary"
							onClick={onUnlock}
							fullWidth
							size="large"
							loading={loading}
							disabled={loading}
						>
							{buttonText}
						</Button>
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default UnlockChapterModal;
