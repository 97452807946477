const red = {
	100: "hsl(0,92%,95%)",
	200: "hsl(0,97%,89%)",
	300: "hsl(360,100%,63%)",
	400: "hsl(0,100%,63%)",
	500: "hsl(359,99%,43%)",
	600: "hsl(0,100%,33%)",
	650: "hsl(352, 48%, 32%)",
	700: "hsl(359,94%,18%)",
	800: "hsl(352,51%,23%)"
};

export default red;
