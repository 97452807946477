export const isToday = startDate => {
	const today = new Date();
	const currentStartDate = new Date(startDate);
	return (
		currentStartDate.getDate() === today.getDate() &&
		currentStartDate.getMonth() === today.getMonth() &&
		currentStartDate.getFullYear() === today.getFullYear()
	);
};

export const dateInPast = (inputDate: string) => {
	const today = new Date();
	const startDate = new Date(inputDate);
	return startDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
};
