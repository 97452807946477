export interface IUserActivityTrackingPayload {
	startTime: number;
	activityType: string;
	id: number;
	endTime: number;
}

export enum ACTIVITY_TYPE {
	testAttempt = "testAttempt",
	videoWatch = "videoWatch"
}
