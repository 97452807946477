import { Button } from "@material-ui/core";
import styled from "styled-components";

// todo: leave colors for this styles in hex due too this page mb open in old browser

export const Layout = styled.div`
	margin: 0;
	padding: 0;
	background: #fff;
	height: 100%;
`;

export const Container = styled.div`
	height: 100vh;
	overflow: auto;
`;

export const FirstSection = styled.section`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 15%;
`;

export const SecondSection = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 5rem;
`;

export const ThirdSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 3rem;
`;

export const Heading = styled.h1`
	color: #4d5058;
	font-family: var(--poppins);
	font-weight: bold;
	text-align: center;
	font-size: 2rem;
	padding: 0.2rem 1rem;
`;

export const Message = styled.p`
	color: #4d5058;
	font-family: var(--poppins);
	font-weight: bold;
	text-align: center;
	font-size: 1.5em;
	padding: 0.2rem 1rem;
	margin: 0;
`;

export const Text = styled.div`
	color: #4d5058;
	font-family: var(--poppins);
	font-weight: bold;
	text-align: center;
	font-size: 1.5rem;
	margin-top: 2rem;
`;

export const DownloadChromeButton = styled(Button)`
	margin-top: 1rem;
	background: #264fae;
`;
