import React, { ReactNode, memo } from "react";

import { useMediaQuery, useTheme } from "@material-ui/core";

import { FooterNav } from "./components";
import { Container, LeftContainer, RightBodyContainer, RightContainer } from "./styles";

interface Props {
	children: ReactNode;
	isLoggedIn: boolean;
	showFooter?: boolean;
	topNav: React.ReactNode;
	sideBarNav: React.ReactNode;
	isTrial?: boolean;
}

const BaseLayout: React.FC<Props> = ({
	isLoggedIn,
	showFooter = true,
	children,
	topNav,
	sideBarNav,
	isTrial = false
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Container>
			<LeftContainer>{isLoggedIn && sideBarNav}</LeftContainer>
			<RightContainer loggedIn={isLoggedIn} isMobile={isMobile} isTrial={isTrial}>
				{isLoggedIn && topNav}
				<RightBodyContainer isMobile={isMobile} isTrial={isTrial}>
					{children}
				</RightBodyContainer>
				{showFooter && <FooterNav showSidebar={!!sideBarNav} />}
			</RightContainer>
		</Container>
	);
};

export default memo(BaseLayout);
