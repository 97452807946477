import React, { useState } from "react";

import { Box, Theme, useTheme } from "@material-ui/core";

import { ReactComponent as DiscountIcon } from "@remar/shared/dist/assets/icons/icon-coupon-multicolour-light.svg";
import Button from "@remar/shared/dist/components/Button";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { useAppDispatch, useAppSelector } from "store";
import { applyValidatedCoupon, selectInactiveSubscription, setUpgradeModal } from "store/features/Auth/authSlice";
import { resumeSubscription } from "store/features/MyAccount/myAccountSlice";
import { getFullState as getFullNotificationsState } from "store/features/notifications/notifications.slice";

import { useStyles } from "./styles";

const ResumeAndRenewSubscriptionButton = ({ id, sideEffect = () => {} }: { id: number; sideEffect: () => void }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const [modal, setModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useAppDispatch();
	const { isRenewEnabled, subscriptionId } = useAppSelector(selectInactiveSubscription);
	const { bannerData } = useAppSelector(getFullNotificationsState);

	const btnText = isRenewEnabled ? "Renew Subscription" : "Resume Subscription";
	const onClose = () => {
		if (!isLoading) {
			setModal(false);
		}
	};
	const onConfirm = () => {
		setIsLoading(true);
		dispatch(
			resumeSubscription({
				id: isRenewEnabled ? (subscriptionId as number) : id,
				sideEffect: () => {
					sideEffect();
					setModal(false);
					setIsLoading(false);
				}
			})
		);
	};
	return (
		<Box display="flex" justifyContent={"center"}>
			<Button
				variant={"filled"}
				color={"primary"}
				onClick={() => {
					if (bannerData?.coupon) {
						dispatch(applyValidatedCoupon(bannerData?.coupon));
					}
					if (isRenewEnabled) {
						dispatch(setUpgradeModal(true));
					} else {
						setModal(true);
					}
				}}
			>
				{!!bannerData?.coupon && (
					<Box display={"flex"} mr={1}>
						<DiscountIcon />
					</Box>
				)}
				{btnText}
			</Button>
			{!isRenewEnabled && (
				<SimpleModal
					theme={theme}
					title={btnText}
					open={modal}
					onClose={onClose}
					text="Are you sure you want to proceed?"
					footer={
						<Box className={classes.buttonContainer}>
							<Button
								loading={isLoading}
								variant={"outlined"}
								color={"basic"}
								size="medium"
								disabled={isLoading}
								onClick={onClose}
							>
								No
							</Button>
							<Button loading={isLoading} variant={"filled"} color={"danger"} size="medium" onClick={() => onConfirm()}>
								Yes
							</Button>
						</Box>
					}
				/>
			)}
		</Box>
	);
};

export default ResumeAndRenewSubscriptionButton;
