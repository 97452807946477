import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import { concat } from "lodash";

import { StyledText } from "modules/Lesson/style";

import {
	MatrixTableBody,
	MatrixTableHeader,
	MultipleChoiceOptionContainer,
	MultipleResponseLeftBodyText,
	MultipleResponseLeftHeaderText,
	MultipleResponseRightBodyText,
	MultipleResponseRightHeaderText,
	TestQuestionStyledContainer,
	TestQuizQuestionText
} from "../../styles";

const MultipleResponseGroupQuestion = ({
	question: {
		data: { tableLabel, groups, answerOptionLabel }
	},
	onChange,
	userAnswers,
	key: uniqueIdentifier
}) => {
	const handleChangeAnswer = (checked, gId, optId) => {
		let arr: Record<string, unknown>[];
		if (checked) {
			arr = concat(userAnswers, { id: optId, groupId: gId });
		} else {
			const index = userAnswers.findIndex(({ id, groupId }) => id === optId && groupId === gId);
			userAnswers.splice(index, 1);
			arr = userAnswers;
		}
		onChange([...arr]);
	};

	return (
		<TestQuestionStyledContainer>
			<TestQuizQuestionText key={uniqueIdentifier} />
			<MatrixTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<MultipleResponseLeftHeaderText
						display="flex"
						flexDirection="row"
						height={"100%"}
						alignItems="center"
						justifyContent="center"
						p="0 8px"
					>
						<StyledText>{tableLabel}</StyledText>
					</MultipleResponseLeftHeaderText>

					<MultipleResponseRightHeaderText
						height={"100%"}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="center"
						p="0 8px"
						borderLeft={"solid 1px rgba(151, 151, 151, 0.3)"}
					>
						<StyledText>{answerOptionLabel}</StyledText>
					</MultipleResponseRightHeaderText>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody>
				{groups.map(({ text, id: gId, answerOptions }) => (
					<Box
						key={gId}
						borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%" height="100%">
							<MultipleResponseLeftBodyText display="flex" flexDirection="row" height={"100%"} alignItems="center">
								<StyledText>{text}</StyledText>
							</MultipleResponseLeftBodyText>
							<MultipleResponseRightBodyText
								display="flex"
								flexDirection="row"
								flexWrap="wrap"
								borderLeft="solid 1px rgba(151, 151, 151, 0.3)"
								padding={1}
							>
								{answerOptions.map(({ id: optId, text }) => (
									<MultipleChoiceOptionContainer key={optId}>
										<Checkbox
											color="default"
											checked={userAnswers.some(({ groupId, id }) => id === optId && groupId === gId)}
											onChange={({ target: { checked } }) => handleChangeAnswer(checked, gId, optId)}
										/>
										<StyledText>{text}</StyledText>
									</MultipleChoiceOptionContainer>
								))}
							</MultipleResponseRightBodyText>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</TestQuestionStyledContainer>
	);
};

export default MultipleResponseGroupQuestion;
