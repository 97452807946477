export const validateEmail = (email: string): boolean => {
	const emailRegex = new RegExp(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
	return emailRegex.test(email);
};

export const validZipCodeCharacter = (character: string): boolean => !!character.match(/^[a-z0-9-\s]+$/i);

export const validatePositiveNumber = (num: string): boolean => {
	const numberRegex = new RegExp(/^\d*$/);

	return numberRegex.test(num);
};

export const validatePhoneNumber = (num: string): boolean => {
	const numberRegex = new RegExp(/^\+?[0-9-+]*$/);

	return numberRegex.test(num);
};
