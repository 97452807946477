import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";

import { Coupon, IValidateCouponResponse, IValidateCoupons } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class CouponsService extends BaseApiService<
	Coupon,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	validateCoupon(data: IValidateCoupons): Promise<IValidateCouponResponse> {
		return request(`${this.baseUrl}/validate`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<IValidateCouponResponse>;
	}
}

export const couponsService = new CouponsService(URLS.COUPONS);
