import { useMemo } from "react";

import { IUserCourseCompletionDetailForAdmin, Lesson } from "../../models";

export const useCourseCompletionDetails = (
	courseCompletion: IUserCourseCompletionDetailForAdmin | null
): { nextLesson: Lesson; sections: any[] } => {
	const chapters = courseCompletion?.course.chapters;
	const { lessonId, sectionId } = useMemo(() => {
		if (courseCompletion && !courseCompletion.isCompleted) {
			const { lessonId, sectionId } = courseCompletion.nextLessonSections![0];
			return { lessonId, sectionId };
		}
		return {
			lessonId: null,
			sectionId: null
		};
	}, [courseCompletion]);

	const unCompletedLessonsIds = useMemo(
		() =>
			courseCompletion && !courseCompletion.isCompleted
				? courseCompletion.nextLessonSections!.map(({ lessonId }) => lessonId)
				: [],
		[courseCompletion]
	);
	const sections = useMemo(() => {
		return [...(chapters || []).flatMap(item => item!.sections)].map(section => ({
			...section,
			sectionLessons: section!.sectionLessons?.map(sectionLesson => ({
				...sectionLesson,
				isCompleted: !unCompletedLessonsIds.includes(sectionLesson.lessonId)
			}))
		}));
	}, [unCompletedLessonsIds, chapters]);

	const nextLesson = useMemo(() => {
		const section = sections.find(s => s.id === sectionId);
		const lesson = section ? { ...section.sectionLessons?.find(l => l.lessonId === lessonId) } : null;
		if (lesson) {
			lesson["sectionMainImageUrl"] = section?.mainImageUrl;
			lesson["sectionMainThumbnailUrl"] = section?.mainImageThumbnailUrl;
		}
		return lesson;
	}, [lessonId, sectionId, sections]);

	return {
		nextLesson: nextLesson as Lesson,
		sections
	};
};
