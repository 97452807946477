import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Assets, IBaseState } from "@remar/shared/dist/models";
import { PaymentMethod } from "@stripe/stripe-js";
import { RootState } from "store";
import { assetsService } from "store/services";
export interface CardPaymentMethod extends PaymentMethod.Card {
	id: string;
}

interface AssetsState extends IBaseState {
	assets: Assets;
}
const initialState: AssetsState = {
	isLoading: false,
	error: "",
	assets: {
		isLoading: false,
		assets: null,
		errorMessage: "",
		page: 1,
		perPage: 10,
		totalItems: 0,
		totalCount: 0
	}
};

export const getAssets = createAsyncThunk(
	"assets/find",
	async ({ page: optPage, searchText }: { searchText?: string; page?: number }, { rejectWithValue, getState }) => {
		const {
			assets: { page }
		} = (getState() as { assets: AssetsState }).assets;
		return await assetsService
			.find({
				page: optPage || page,
				orderBy: { createdAt: "ASC" },
				include: ["type"],
				...(searchText && {
					filters: {
						name: {
							$ilike: `%${searchText}%`
						}
					}
				})
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const AssetsSlice = createSlice({
	name: "assets",
	initialState,
	reducers: {
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		}
	},
	extraReducers: {
		[getAssets.pending.type]: state => {
			state.assets.isLoading = true;
		},
		[getAssets.fulfilled.type]: (state, { payload: { items, page, perPage, totalItems } }) => {
			state.assets.assets = items;
			state.assets.page = page;
			state.assets.perPage = perPage;
			state.assets.totalItems = totalItems;
			if (!state.assets.totalCount) {
				state.assets.totalCount = totalItems;
			}
			state.assets.isLoading = false;
		},
		[getAssets.rejected.type]: (state, { payload }) => {
			state.assets.errorMessage = payload;
			state.assets.isLoading = false;
		}
	}
});

export const getFullState = (state: RootState): AssetsState => state.assets;

export const { setError } = AssetsSlice.actions;

export default AssetsSlice.reducer;
