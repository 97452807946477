import { Box } from "@material-ui/core";

import styled from "styled-components";

export const PasswordMatchBoxWrapper = styled(Box)`
	background: ${({ theme }) => theme.palette.background.default};
	position: absolute;
	right: -250px;
	top: -10px;
	padding: 10px;
	border-radius: 4px;
	z-index: 2;
	:before {
		content: "";
		position: absolute;
		left: -15px;
		top: 35.5px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 15px 15px 0;
		border-color: transparent ${({ theme }) => theme.palette.background.default} transparent transparent;
	}
	p {
		margin: 0;
		font-family: var(--openSans);
	}
	.heading {
		color: ${props => props.theme.palette.text.primary};
		font-weight: 600;
		font-size: 13px;
	}
	.validation-text {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 20px;
		img {
			width: 15px;
			height: 15px;
			margin-right: 5px;
		}
		p {
			color: ${({ theme }) => theme.palette.text.primary};
			font-size: 12px;
			font-weight: 400;
		}
	}
	${props => props.theme.breakpoints.down("sm")} {
		left: 8px;
		top: 90px;
		width: 95%;
		:before {
			top: -22px;
			left: 45%;
			transform: rotate(90deg);
		}
	}
`;
