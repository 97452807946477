import { validateZipCode } from "@remar/shared/dist/utils/validateZipCode";
import * as Yup from "yup";

export const BillingAddressSchema = Yup.object().shape({
	countryId: Yup.number().required("Required"),
	address1: Yup.string().required("Required"),
	address2: Yup.string().optional(),
	city: Yup.string().min(2).required("Required"),
	state: Yup.string().min(2).required("Required"),
	zipCode: validateZipCode(Yup).max(10).required("Required"),
	fullName: Yup.string().required("Required")
});
