import React, { useEffect, useState } from "react";

import { Box, FormControl, MenuItem, makeStyles } from "@material-ui/core";

import { concat } from "lodash";

import { StyledText } from "modules/Lesson/style";

import {
	MatrixSingleChoiceGroupText,
	MatrixTableBody,
	MatrixTableHeader,
	StyledDropdown,
	TestQuestionStyledContainer
} from "../../styles";

const useStyles = makeStyles(() => ({
	formControl: {
		verticalAlign: "middle"
	},
	list: {
		backgroundColor: "white",
		"& > li": {
			color: "#4d5058",
			fontSize: "0.9rem",
			fontWeight: "500"
		}
	}
}));

export const DropDown = ({ userAnswers, groupId, answerOptions, handleDropDownAnswer }) => {
	const classes = useStyles();
	const [currentId, setCurrentId] = useState("");

	const currentAnswer = userAnswers.find(answer => answer.groupId === groupId);

	useEffect(() => {
		currentAnswer && setCurrentId(currentAnswer.id);
	}, [currentAnswer]);

	const handleChange = val => {
		setCurrentId(val.id);
		handleDropDownAnswer(val);
	};

	return (
		<>
			<FormControl fullWidth={true} className={classes.formControl}>
				<>
					<StyledDropdown
						disableUnderline
						inTable
						value={currentId}
						onChange={event => handleChange({ id: event.target.value, groupId })}
						displayEmpty
						MenuProps={{
							classes: { list: classes.list },
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left"
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left"
							},
							getContentAnchorEl: null
						}}
					>
						<MenuItem value="" disabled>
							Select...
						</MenuItem>
						{answerOptions.map(({ id, text }) => (
							<MenuItem key={id} value={id}>
								{text}
							</MenuItem>
						))}
					</StyledDropdown>
				</>
			</FormControl>
		</>
	);
};

const DropDownTableQuestion = ({
	question: {
		data: { answerOptionLabel, tableLabel, groups }
	},
	onChange,
	userAnswers
}) => {
	const handleDropDownAnswer = selectedAnswer => {
		let arr: Record<string, unknown>[];
		const { id: optId, groupId: gId } = selectedAnswer;
		const answerExist = userAnswers.some(({ groupId }) => groupId === gId);
		if (!answerExist) {
			arr = concat(userAnswers, { id: optId, groupId: gId });
		} else {
			arr = userAnswers.map(answer => {
				if (answer.groupId === gId) {
					return { ...answer, id: optId };
				}
				return answer;
			});
		}
		onChange([...arr]);
	};

	return (
		<>
			<TestQuestionStyledContainer>
				<MatrixTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
					<Box display="flex" flexDirection="row" alignItems="center" width="100%">
						<MatrixSingleChoiceGroupText>
							<StyledText>{tableLabel}</StyledText>
						</MatrixSingleChoiceGroupText>

						<Box
							justifyContent="center"
							display="flex"
							alignItems="center"
							height={"100%"}
							flexDirection="row"
							width="50%"
							borderRight="solid 1px rgba(151, 151, 151, 0.3)"
							p="0 8px"
						>
							<StyledText>{answerOptionLabel}</StyledText>
						</Box>
					</Box>
				</MatrixTableHeader>
				<MatrixTableBody>
					{groups.map(({ text, answerOptions, id: gId }, gIndex) => (
						<Box
							key={gIndex}
							borderBottom="solid 1px rgba(151, 151, 151, 0.3)"
							height="auto"
							display="flex"
							minHeight="51px"
							flexDirection="row"
							width="100%"
						>
							<Box display="flex" flexDirection="row" alignItems="center" width="100%">
								<MatrixSingleChoiceGroupText>
									<StyledText>{text}</StyledText>
								</MatrixSingleChoiceGroupText>
								<Box
									display="flex"
									flexDirection="row"
									width="50%"
									height="100%"
									p="0 8px"
									justifyContent="center"
									alignItems="center"
								>
									<DropDown
										groupId={gId}
										userAnswers={userAnswers}
										answerOptions={answerOptions}
										handleDropDownAnswer={handleDropDownAnswer}
									/>
								</Box>
							</Box>
						</Box>
					))}
				</MatrixTableBody>
			</TestQuestionStyledContainer>
		</>
	);
};

export default DropDownTableQuestion;
