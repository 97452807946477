import { UserLessonProgressItemCreateDto, UserLessonProgressItemUpdateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";
import { UserLessonProgressItem } from "../../models";

import { URLS } from "../services.constants";

export class UserLessonProgressItemsService extends BaseApiService<
	UserLessonProgressItem,
	UserLessonProgressItemCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	UserLessonProgressItemUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userLessonProgressItemsService = new UserLessonProgressItemsService(URLS.USER_LESSON_PROGRESS_ITEMS);
