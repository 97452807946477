import { useEffect, useState } from "react";

import * as pdfjs from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

interface PDFThumbnailProps {
	pdfUrl: string;
	page?: number;
}

export const usePDFThumbnail = ({
	pdfUrl,
	page = 1
}: PDFThumbnailProps): { thumbnail: string | null; loading: boolean } => {
	const [thumbnail, setThumbnail] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchAndRenderThumbnail = async () => {
			try {
				const response = await fetch(pdfUrl);
				const arrayBuffer = await response.arrayBuffer();
				const uint8Array = new Uint8Array(arrayBuffer);
				const pdf = await pdfjs.getDocument({ data: uint8Array }).promise;
				const firstPage = await pdf.getPage(page);
				const viewport = firstPage.getViewport({ scale: 1 });
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context!,
					viewport: viewport
				};

				await firstPage.render(renderContext).promise;

				const dataUrl = canvas.toDataURL("image/png");

				setThumbnail(dataUrl);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching or rendering PDF:", error);
				setLoading(false);
			}
		};

		fetchAndRenderThumbnail();
	}, [pdfUrl]);

	return {
		thumbnail,
		loading
	};
};
