import React from "react";

import { Box } from "@material-ui/core";

const Intro = ({ classes, introVideo, videoRef, handleIntroVITClick }) => {
	return (
		<div className={classes.videoContainer}>
			{introVideo && <video ref={videoRef} className="video-js vjs-big-play-centered" />}
			<div className="vjs-text-track-display questions-overlay">
				<div className={classes.videoQuestionContainer}>
					<Box display="flex">
						<Box width="85%">
							<div className="question-head">
								Would you like to take a tutorial to learn how to use the ReMar V2 faster?
							</div>
						</Box>
					</Box>
					<Box display="flex" height="400px" flexDirection="column" flexWrap="wrap" width="80%">
						<div className={classes.videoQuestionOption} onClick={() => handleIntroVITClick(true)}>
							{"Yes, let's go!"}
						</div>
						<div className={classes.videoQuestionOption} onClick={() => handleIntroVITClick(false)}>
							{"No, skip the tutorial."}
						</div>
					</Box>
				</div>
			</div>
		</div>
	);
};

export default Intro;
