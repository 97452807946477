import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";

import { BaseModel } from "@remar/shared/dist/models";

import { ThemeDto } from "./dto";

import { URLS } from "../services.constants";

export class LocationPackageService extends BaseApiService<
	BaseModel,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<{}>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getLocationTheme(): Promise<ThemeDto> {
		return request(`${this.baseUrl}/location-theme`, "GET") as Promise<ThemeDto>;
	}

	getLocationThemeByDomain(subDomainName: string): Promise<ThemeDto> {
		const params = { subDomainName };
		return request(
			`${this.baseUrl}/location-theme-by-domain${this.serializeQuery(params, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<ThemeDto>;
	}
}

export const locationPackageService = new LocationPackageService(URLS.LOCATION_PACKAGE);
