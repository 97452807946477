import React, { FC, ReactNode, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { useDrag, useDrop } from "react-dnd";

import { dragCollect, getTouchBackendStyle } from "modules/QuestionBank/Test/utils/dragAndDrop";

import { DragContainer, SequenceAnswerOptionContainer, SequenceDrag, SequenceIndex, SequenceText } from "./style";
interface SequenceAnswerOptionProps {
	text: string;
	id: string;
	index: number;
	/* eslint-disable-next-line */
	onDrop: (_options: { id: string; index?: number }) => unknown;
}

const SequenceAnswerOptionDropZone: FC<{
	children?: ReactNode | ReactNode[];
	index: number;
	/* eslint-disable-next-line */
	onDrop: (_options: { id: string; index?: number }) => unknown;
}> = ({ children, index, onDrop }) => {
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "group",
		drop: ({ id }) => onDrop({ id, index }),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
		canDrop: (dropItem: { index?: number; id: string; type: string }) =>
			dropItem.index !== index && dropItem.index !== index - 1
	});
	const overStyles = {
		border: "2px dashed #000",
		height: 48,
		margin: "8px 8px 0 0",
		width: `${isMobile ? "" : "50%"}`
	};
	return (
		<DragContainer ref={drop}>
			{children}
			<div style={isOver && canDrop ? overStyles : index === 0 ? { minHeight: 8 } : undefined} />
		</DragContainer>
	);
};

const SequenceAnswerOption: FC<SequenceAnswerOptionProps> = ({ text, id, index, onDrop }) => {
	const [{ position, isDragging, opacity }, drag] = useDrag({
		item: { id, index },
		type: "group",
		collect: monitor => dragCollect(monitor)
	});
	return (
		<SequenceAnswerOptionDropZone index={index + 1} onDrop={onDrop}>
			<SequenceAnswerOptionContainer
				ref={drag}
				isMobile={isMobile}
				style={isMobile && isDragging ? { ...getTouchBackendStyle(position), opacity } : { opacity }}
			>
				<SequenceIndex>{index + 1}</SequenceIndex>
				<SequenceText>{text}</SequenceText>
				<SequenceDrag> =</SequenceDrag>
			</SequenceAnswerOptionContainer>
		</SequenceAnswerOptionDropZone>
	);
};

const SequencingQuestion = ({ question, userAnswers, onChange, answersRef }) => {
	const {
		data: { answerOptions },
		id: questionId
	} = question;

	const getAnswerOptions = () => {
		onChange(answerOptions?.map(({ id }, order) => ({ id, order })) ?? []);
		if (!answersRef?.current[questionId]?.length) {
			answersRef.current[questionId] = answerOptions?.map(({ id }, order) => ({ id, order })) ?? [];
		}
	};

	useEffect(() => {
		if (question) {
			getAnswerOptions();
		}
	}, [question]);

	useEffect(() => {
		if (question) {
			getAnswerOptions();
			onChange(answerOptions?.map(({ id }, order) => ({ id, order })) ?? []);
		}
	}, [questionId, userAnswers.length]);

	const handleDrop = ({ id, index }: { id: string; index?: number }) => {
		if (index === undefined) return;
		const oldIndex = userAnswers?.findIndex(option => option.id === id);
		const item = userAnswers[oldIndex];
		const newItems = userAnswers.filter(answer => answer.id !== id);

		newItems.splice(oldIndex < index ? index - 1 : index, 0, item);

		newItems.forEach((item, index) => {
			item.order = index;
		});

		onChange(newItems);
	};

	return (
		<>
			<SequenceAnswerOptionDropZone
				index={0}
				onDrop={({ id }) => handleDrop({ id, index: 0 })}
			></SequenceAnswerOptionDropZone>
			{userAnswers
				?.map(({ id }) => answerOptions?.find(option => option.id === id) ?? { id: "", text: "" })
				.map(
					({ id, text }, index) =>
						text && (
							<SequenceAnswerOption
								text={text}
								index={index}
								id={id}
								key={id}
								onDrop={({ id }) => handleDrop({ id, index: index + 1 })}
							/>
						)
				)}
		</>
	);
};

export default SequencingQuestion;
