import React from "react";

import { Box, Button, Typography, useTheme } from "@material-ui/core";

import { IExtendedTheme } from "../../../theme/default";
import { EmptyWrapper } from "../styles";

type EmptyStateProps = {
	description: string;
	addtionalDesc?: string;
	buttonTitle?: string;
	placeHolder?: React.ReactNode;
	onButtonClick?: () => void;
};
const EmptyState = ({ description, addtionalDesc, buttonTitle, onButtonClick, placeHolder }: EmptyStateProps) => {
	const theme = useTheme<IExtendedTheme>();

	return (
		<Box bgcolor={theme.palette.background.paper} padding="75px 0">
			<EmptyWrapper>
				{placeHolder}
				<Typography variant="h3">{description}</Typography>
				{!!addtionalDesc && <Typography>{addtionalDesc}</Typography>}
				{buttonTitle && (
					<Button variant={"contained"} color={"primary"} onClick={onButtonClick}>
						{buttonTitle}
					</Button>
				)}
			</EmptyWrapper>
		</Box>
	);
};

export default EmptyState;
