import { Question, QuestionAnswerOption, QuestionGroup } from "@remar/shared/dist/models";

import { shuffle } from "lodash";

export const reorder = (
	startIndex: number,
	endIndex: number,
	list?: QuestionAnswerOption[]
): QuestionAnswerOption[] => {
	const result = Array.from(list!);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export const getQuestionGroups = ({ data: { answerOptions, groups } }: Question): QuestionGroup[] => {
	const list = shuffle([...(answerOptions ?? [])]);
	const half = Math.ceil(list?.length / 2);
	const firstHalf = list.splice(0, half);
	const secondHalf = list.splice(-half);
	const questionGroups = groups as QuestionGroup[];

	return questionGroups?.map((x, i) =>
		i
			? { ...x, answerOptions: shuffle(secondHalf) }
			: {
					...x,
					answerOptions: shuffle(firstHalf)
			  }
	);
};

export const getQuestionCorrectGroups = ({ data: { answerOptions, groups } }: Question): QuestionGroup[] => {
	const list: QuestionAnswerOption[] = [...(answerOptions ?? [])];

	const questionGroups = groups as QuestionGroup[];

	return questionGroups?.map(x => {
		const filterGroupItems = list.filter(({ questionGroupId }) => questionGroupId === x.id);
		return { ...x, answerOptions: filterGroupItems };
	});
};
