import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { UserSubscriptionType } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class UserSubscriptionTypesService extends BaseApiService<
	UserSubscriptionType,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userSubscriptionTypesService = new UserSubscriptionTypesService(URLS.USER_SUBSCRIPTION_TYPES);
