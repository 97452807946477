import React, { useState } from "react";

import { CalcBody, CalcButton, CalcContainer, CalcDisplay, CalcKeypad, Close } from "./styles";

enum Operation { // eslint-disable-line no-unused-vars
	Plus = "+", // eslint-disable-line no-unused-vars
	Minus = "-", // eslint-disable-line no-unused-vars
	Multiply = "*", // eslint-disable-line no-unused-vars
	Division = "/" // eslint-disable-line no-unused-vars
}

const plusMinus = value => (value[0] === "-" ? value.substr(1) : "-" + value);
const MaxDigits = 8;

const calcFormat = value => {
	if (value.replace(/\D/g, "").length <= MaxDigits) return value.toString();

	const intLength = parseInt(value).toString().length;

	if (intLength < MaxDigits) {
		const factor = Math.pow(10, MaxDigits - intLength);
		return (Math.round(value * factor) / factor).toString();
	} else {
		const factor = Math.pow(10, intLength - MaxDigits + 3);
		const roundedValue = Math.round(value / factor) * factor;
		const power = roundedValue.toString().length - 1;

		return `${roundedValue / Math.pow(10, power)}e+${power}`;
	}
};

export const QuizCalculator = ({ onClose, backgroundColor }) => {
	const [display, setDisplay] = useState("0");
	const [operation, setOperation] = useState<Operation | undefined>();
	const [newInput, setNewInput] = useState(true);
	const [operand, setOperand] = useState<number | undefined>();

	const [error, setError] = useState("");

	const handleSymbol = symbol => {
		if (
			(display.replace(/\D/g, "").length === MaxDigits && symbol !== "-" && !newInput) ||
			(symbol === "0" && display === "0") ||
			(symbol === "." && display.match(/\./) && !newInput) ||
			(newInput && symbol === "-")
		)
			return;

		if (newInput) {
			setDisplay((symbol === "." ? "0" : "") + symbol);
			setNewInput(false);
		} else {
			if (symbol === "-") setDisplay(plusMinus(display));
			else setDisplay(display + symbol);
		}
	};

	const handleCalc = () => {
		if (!operand) return;

		const parsedDisplay = parseFloat(display);

		const result =
			operation === Operation.Plus
				? operand + parsedDisplay
				: operation === Operation.Minus
				? operand - parsedDisplay
				: operation === Operation.Division
				? operand / parsedDisplay
				: operation === Operation.Multiply
				? operand * parsedDisplay
				: NaN;

		if (isNaN(result) || Math.abs(result) === Infinity || result > Number.MAX_SAFE_INTEGER) {
			setError(
				result > Number.MAX_SAFE_INTEGER
					? "Number is too large"
					: Math.abs(result) === Infinity
					? "Division by zero"
					: "Error"
			);
		}

		setDisplay(result.toString());
		setOperand(undefined);
		setOperation(undefined);
		setNewInput(true);

		return result.toString();
	};
	const handleOperation = newOperation => {
		if (operation && (operand === undefined || newInput)) {
			setOperation(newOperation);
		} else {
			const newDisplay = handleCalc();
			setOperation(newOperation);
			setNewInput(true);
			setOperand(parseFloat(newDisplay ?? display));
		}
	};

	const handleReset = () => {
		if (display === "0" || error) {
			setOperation(undefined);
			setOperand(undefined);
			setError("");
		}
		setDisplay("0");
		setNewInput(true);
	};

	const handlePercent = () => {
		const parsedDisplay = parseFloat(display);

		if (!operand || !operation) return;

		const result =
			operation === Operation.Plus
				? (operand * (100 + parsedDisplay)) / 100
				: operation === Operation.Minus
				? (operand * (100 - parsedDisplay)) / 100
				: operation === Operation.Multiply
				? (operand * parsedDisplay) / 100
				: operation === Operation.Division
				? (operand * 100) / parsedDisplay
				: NaN;
		if (isNaN(result) || Math.abs(result) === Infinity || result > Number.MAX_SAFE_INTEGER) {
			setError(
				result > Number.MAX_SAFE_INTEGER
					? "Number is too large"
					: Math.abs(result) === Infinity
					? "Division by zero"
					: "Error"
			);
		}
		setDisplay(result.toString());
		setOperation(undefined);
		setOperand(undefined);
		setNewInput(true);
	};
	return (
		<CalcContainer backgroundColor={backgroundColor}>
			<CalcBody>
				<CalcDisplay error={!!error}>
					{error ? error : calcFormat(display)}
					<Close onClick={onClose}>&times;</Close>
				</CalcDisplay>
				<CalcKeypad>
					<CalcButton onClick={() => handleReset()}>AC</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("-")}>
						+/-
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handlePercent()}>
						%
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleOperation(Operation.Division)}>
						÷
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("7")}>
						7
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("8")}>
						8
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("9")}>
						9
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleOperation(Operation.Multiply)}>
						&times;
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("4")}>
						4
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("5")}>
						5
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("6")}>
						6
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleOperation(Operation.Minus)}>
						&ndash;
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("1")}>
						1
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("2")}>
						2
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol("3")}>
						3
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleOperation(Operation.Plus)}>
						+
					</CalcButton>
					<CalcButton disabled={error} double={true} onClick={() => handleSymbol("0")}>
						0
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleSymbol(".")}>
						.
					</CalcButton>
					<CalcButton disabled={error} onClick={() => handleCalc()}>
						=
					</CalcButton>
				</CalcKeypad>
			</CalcBody>
		</CalcContainer>
	);
};
