import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { ReactComponent as FolderSvg } from "@remar/shared/dist/assets/icons/icon-folder.svg";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";

import { EmptyState, Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useParams } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "store";

import { selectCurrentlyAllowedFullCourses } from "store/features/Auth/authSlice";
import { fetchFolders, getFullState } from "store/features/FileVault/fileVault";

import { routes } from "core/constants";

import { Folder, FolderTitle, FoldersContainer, RoundedIcon, SearchPanel, SvgIcon } from "./styles";

const breadcrumbs = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "File Vault", key: 1, link: routes.fileVault.getPath() }
];

const FileVault = () => {
	const { folderId } = useParams<{ folderId: string }>();
	const { isLoading, folderList: folders } = useAppSelector(getFullState);
	const dispatch = useAppDispatch();
	const { subscription } = useAppSelector(({ auth }: RootState) => auth);
	const currentlyAllowedFullCourses = useAppSelector(selectCurrentlyAllowedFullCourses);
	const analytics = useAnalyticsEventTracker("File Vault");
	const currentFolder = useMemo(() => folderId && folders.find(({ id }) => id === +folderId), [folderId, folders]);
	const content = useMemo(
		() => folders.filter(({ parentId }) => (!parentId && !folderId) || +folderId === parentId),
		[folderId, folders]
	);

	const [inputValue, setInputValue] = useState("");
	const [currentCourseId, setCurrentCourseId] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (currentFolder && breadcrumbs.length < 3) {
			breadcrumbs.push({
				title: currentFolder.name,
				key: 2,
				link: `${routes.fileVault.getPath()}/${currentFolder.id}`
			});
		}
	}, [currentFolder]);

	useEffect(() => {
		if (currentlyAllowedFullCourses && currentlyAllowedFullCourses.length) {
			setCurrentCourseId(currentlyAllowedFullCourses[0]?.id);
			dispatch(fetchFolders({ id: currentlyAllowedFullCourses[0]?.id, searchText: inputValue }));
		}
	}, [currentlyAllowedFullCourses, dispatch, inputValue]);

	const handleSearchBarChange = useCallback(
		(searchText: string, courseId?: number) => {
			if (courseId && currentCourseId) {
				dispatch(fetchFolders({ id: courseId, searchText }));
			}
		},
		[currentCourseId, dispatch]
	);

	return (
		<Wrapper heading="File Vault" breadcrumb={breadcrumbs}>
			<SearchPanel>
				<Box>File Vault</Box>
				<SearchBarComponent
					inputValue={inputValue}
					setInputValue={setInputValue}
					onChange={handleSearchBarChange}
					isDebounced={true}
					debounceTime={1000}
					placeHolder={"Type to filter by keywords"}
				/>
			</SearchPanel>

			{isLoading ? (
				<ContentLoader />
			) : content.length === 0 && currentlyAllowedFullCourses && currentlyAllowedFullCourses.length ? (
				<EmptyState
					description="There are no folders yet"
					placeHolder={
						<SvgIcon>
							<FolderSvg />
						</SvgIcon>
					}
				/>
			) : subscription.isTrial ? (
				<EmptyState
					description="You do not have access the file Vault"
					placeHolder={
						<SvgIcon>
							<FolderSvg />
						</SvgIcon>
					}
				/>
			) : (
				<FoldersContainer>
					{content?.map(folder => (
						<Folder key={`folder-${folder.id}`}>
							<RoundedIcon>
								<FolderSvg />
							</RoundedIcon>
							<FolderTitle
								to={`${routes.fileVault.getPath()}/${folder.id}`}
								onClick={() => {
									analytics({
										eventName: "clicked_main_course_folder",
										eventIdentifier: `${folder.id}-${folder.name}`
									});
								}}
							>
								{folder.name}
							</FolderTitle>
						</Folder>
					))}
				</FoldersContainer>
			)}
		</Wrapper>
	);
};

export default FileVault;
