import React from "react";

import { ReactComponent as MaintenanceSvg } from "assets/icons/screwdriver-wrench.svg";

import { BannerContainer, Text, TextBox } from "./styles";

const MaintenanceBanner = ({ isMobile }) => (
	<BannerContainer $isMobile={isMobile}>
		<MaintenanceSvg />
		<TextBox>
			<Text fs={1.3}>Be Right Back</Text>
			<Text>
				We are making updates to the ReMar platform. Maintenance will complete in 1 hour (started at 12am PST).
			</Text>
		</TextBox>
	</BannerContainer>
);

export default MaintenanceBanner;
