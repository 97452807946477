import { PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";

function setStateValueSignature<ValueType>(data: { key: string; value: ValueType }): void {
	console.log(data);
}

export interface ISetStateValuePayloadBody<ValueType> {
	key: string;
	value: ValueType;
}

export type SetStateValue = typeof setStateValueSignature;

export function setStateValue<State, ValueType>(
	state: State,
	action: PayloadAction<ISetStateValuePayloadBody<ValueType>>
): void {
	const { key, value } = action.payload;
	set(state as Record<string, unknown>, key, value);
}
