import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
	linkText: {
		color: "#4a99f9 !important",
		textDecoration: "underline"
	},
	description: {
		fontSize: 13,
		textAlign: "center"
	},
	checkbox: {
		marginBottom: 32
	}
});
