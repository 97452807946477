import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { Question } from "@remar/shared/dist/models";

import { QuestionCreateDto, QuestionUpdateDto, UserQuestionBankStats } from "./dto";

import { URLS } from "../services.constants";

export class QuestionsService extends BaseApiService<
	Question,
	QuestionCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	QuestionUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	getUserQuestionBankStats(): Promise<UserQuestionBankStats> {
		return request(
			`${this.baseUrl}/question-bank-stats${this.serializeQuery({} as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<UserQuestionBankStats>;
	}

	getUserQuestionBankPerformance(): Promise<{ percentage: number }> {
		return request(`${this.baseUrl}/question-bank-performance`, "GET") as Promise<{ percentage: number }>;
	}
}

export const questionsService = new QuestionsService(URLS.QUESTIONS);
