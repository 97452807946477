import { QuestionBankTestModes, QuestionTypes } from "@remar/shared/dist/constants";
import { Question } from "@remar/shared/dist/models";

export const getPercentageLevel = (quizPercentage: number): string => {
	const percentage = Math.round(quizPercentage);
	return percentage <= 49
		? "Low"
		: percentage >= 50 && percentage <= 60
		? "Fair"
		: percentage >= 61 && percentage <= 70
		? "Good"
		: percentage >= 71 && percentage <= 100
		? "Excellent"
		: "";
};

export const questionCount = (questions: Question[], typeId: number, inProgress: boolean): number => {
	const questionLength =
		typeId === QuestionBankTestModes.CAT && inProgress && questions.length > 0
			? questions?.length - 1
			: questions?.length;
	// Five questions for each case study, including the case study question count
	return questionLength + (questions?.filter(f => f.typeId === QuestionTypes.CaseStudy)?.length || 0) * 5;
};
