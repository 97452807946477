import React from "react";

import { Box } from "@material-ui/core";

import { ForgotPasswordWrapper, LinkText, SecondaryText } from "./styles";

import { AuthFormLayout } from "../AuthFormLayout";

type Props = {
	title?: string;
	logo: React.ReactNode;
	signUpRoute: string;
	forgotPasswordRoute?: string;
	disableOnMaintenanceMode?: boolean;
	refreshError?: () => void;
	customBgImage?: string;
	isLeftBlockTransparent?: boolean;
	gradient?: boolean;
};
const EntryScreenLayout: React.FC<Props> = ({
	children,
	title,
	logo,
	disableOnMaintenanceMode = false,
	signUpRoute,
	forgotPasswordRoute,
	refreshError,
	customBgImage,
	isLeftBlockTransparent,
	gradient = false
}) => (
	<AuthFormLayout
		title={title}
		logo={logo}
		customBgImage={customBgImage}
		gradient={gradient}
		isLeftBlockTransparent={isLeftBlockTransparent}
	>
		{children}
		{forgotPasswordRoute && (
			<ForgotPasswordWrapper>
				<LinkText to={forgotPasswordRoute}>Forgot Password?</LinkText>
			</ForgotPasswordWrapper>
		)}
		{!disableOnMaintenanceMode && (
			<Box mt={2} textAlign="center">
				<SecondaryText mt={1}>
					Don’t have an account yet?
					<LinkText className="margin-right" to={signUpRoute} onClick={refreshError}>
						Sign Up
					</LinkText>
				</SecondaryText>
			</Box>
		)}
	</AuthFormLayout>
);

export default EntryScreenLayout;
