import { UserQuestionAttemptCreateDto, UserQuestionAttemptUpdateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";
import { UserQuestionAttempt } from "../../models";

import { URLS } from "../services.constants";

export class UserQuestionAttemptsService extends BaseApiService<
	UserQuestionAttempt,
	UserQuestionAttemptCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	UserQuestionAttemptUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userQuestionAttemptsService = new UserQuestionAttemptsService(URLS.USER_QUESTION_ATTEMPTS);
