import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, ClickAwayListener } from "@material-ui/core";

import { isEqual } from "lodash";

import FilterIcon from "../../../assets/icons/Icon_filter.svg";
import FilterIconActive from "../../../assets/icons/Icon_filter_active.svg";

import MultipleCheckboxFilter from "../columnFilters/MultipleCheckbox";

import { FilterTypes, IColumnFilter, filtersTypes } from "../columnFilters/types";

import { useStyles } from "../styles";

const ColumnFilterWrapper = ({ filter: { type, data, onChange, width } }: { filter: IColumnFilter }) => {
	const classes = useStyles();
	const [viewFilterWrapper, setViewFilterWrapper] = useState(false);
	const [filterValue, setFilterValue] = useState<filtersTypes>(data);
	const filterRef = useRef<filtersTypes>(data);

	useEffect(() => {
		if (filterRef.current) {
			filterRef.current = filterValue;
		}
	}, [filterValue]);

	const getFiltersIds = data => {
		return data.allFilters.checked
			? data.checkboxFilters.map(({ id }) => "" + id)
			: data.checkboxFilters.filter(item => item.checked).map(({ id }) => "" + id);
	};

	const handleClickAway = () => {
		if (!viewFilterWrapper) {
			return;
		}
		const oldValues = getFiltersIds(data);
		const newValues = getFiltersIds(filterRef.current);
		if (!isEqual(oldValues, newValues)) {
			onChange(filterRef.current);
		}
		setViewFilterWrapper(false);
	};

	const hasFilters = useMemo(
		() => filterRef.current.allFilters.checked || filterRef.current.checkboxFilters.some(filter => filter.checked),
		[filterRef.current]
	);

	const ColumnFilterComponents = {
		[FilterTypes.MultipleCheckbox]: (
			<MultipleCheckboxFilter multipleCheckbox={filterValue} setMultipleCheckbox={setFilterValue} />
		)
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div className={classes.filterContainer}>
				<img
					src={viewFilterWrapper || hasFilters ? FilterIconActive : FilterIcon}
					alt="filter icon"
					className={classes.columnFilterIcon}
					onClick={() => setViewFilterWrapper(true)}
				/>
				{viewFilterWrapper && (
					<Box className={classes.filterWrapper} style={{ width: width ?? "auto" }}>
						{ColumnFilterComponents[type]}
					</Box>
				)}
			</div>
		</ClickAwayListener>
	);
};

export default ColumnFilterWrapper;
