import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetCoupon } from "store/features/Auth/authSlice";

import RightSuccess from "assets/images/guest-create-acc.svg";

import { routes } from "core/constants";

import { useStyles } from "./styles";

export const GuestSignUpSuccessRight = ({ setActiveStep, bookPurchaseClaimCode }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(resetCoupon());
		};
	}, [dispatch]);

	return (
		<Box className={`${classes.summaryRoot} ${classes.customFlex}`}>
			<Box
				display="flex"
				justifyContent="center"
				margin="auto"
				flexDirection="column"
				minHeight="400px"
				alignItems="center"
			>
				<Box display="flex" justifyContent="center">
					<img src={RightSuccess} />
				</Box>
				<Typography className={classes.errorTitle}>{"Create Your Account Now"}</Typography>
				<Box mt={2} flexDirection="column" display="flex" justifyContent="center">
					<Typography className={classes.errorDescription}>
						{
							"Get instant access to the most affordable, easy to understand content review for nursing students & re-testers with 99.2 student success"
						}
					</Typography>
				</Box>
				<Box display="flex" justifyContent="center" width={"80%"} mt={6}>
					<Button
						color="primary"
						fullWidth
						variant="filled"
						onClick={() => {
							setActiveStep(0);
							history.push(`${routes.signUp.getPath()}/course?accountClaimCode=${bookPurchaseClaimCode}`);
						}}
					>
						Create Account
					</Button>
				</Box>

				<Typography
					onClick={() => {
						history.push(`${routes.signUp.getPath()}/course`);
					}}
					className={classes.goToHomePage}
				>
					{"Go to Homepage"}
				</Typography>
			</Box>
		</Box>
	);
};
