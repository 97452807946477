import { Box, Button, Typography } from "@material-ui/core";
import styled from "styled-components";

export const UpgradeBannerWrapper = styled(Box)`
	display: flex;
	background-color: ${({ theme }) => theme.palette.background.paper};
	margin-top: 24px;
	padding: 24px 48px;
	align-items: center;
	justify-content: space-between;
`;

export const UpgradeTopBannerWrapper = styled(Box)`
	margin-top: 25px;
	display: flex;
	background-color: ${({ theme }) => theme.palette.colors.primary[600]};
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`;

export const DescriptionWrapper = styled(Box)`
	width: 95%;
`;

export const UpgradeText = styled(Typography)`
	font-size: 18px;
	font-weight: bold;
`;

export const UpgradeTopBannerText = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.whiteShade1};
	margin-top: 0;
	padding: 5px 0;
	text-align: center;
	line-height: 1.846;
	opacity: 0.87;
	font-size: 13px;
	font-family: var(--poppins);
`;

export const ButtonWrapper = styled(Box)`
	button {
		padding: 7px 25px;
		font-weight: bold;
	}
`;

export const Description = styled(Typography)`
	color: ${({ theme }) => theme.palette.colors.basic[1250]};
	font-size: 13px;
	width: 65%;
`;

export const ActionText = styled(Typography)`
	display: inline-block;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	margin-left: 10px;
`;

export const CloseButton = styled(Button)`
	img {
		filter: brightness(0) invert(1);
	}
`;

export const TitleText = styled(Typography)`
	font-family: var(--poppins);
	font-size: 22px;
	font-weight: bold;
	line-height: 1.455;
`;

export const LockScreenWrapper = styled(Box)`
	margin-top: -270px;
	height: 270px;
	display: flex;

	div:first-child {
		display: flex;
		align-items: center;
		max-width: 300px;
		width: 100%;
		flex-direction: column;
		grid-gap: 8px;
		margin: auto;
		z-index: 1;
	}

	img {
		width: 27px;
		height: 30px;
		fill: ${({ theme }) => theme.palette.colors.basic[100]};
	}

	button {
		padding: 11px 36px;
		font-weight: bold;
		font-size: 14px;
	}
`;

export const BlurBox = styled(Box)`
	backdrop-filter: blur(10px);
	width: 100%;
	margin-top: -300px;
	height: 300px;
`;
