import React, { useEffect, useRef, useState } from "react";

import { Box, SvgIcon } from "@material-ui/core";
import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import isEmpty from "lodash/isEmpty";

import { isMobile } from "react-device-detect";

import { useDrag, useDrop } from "react-dnd";

import { ReactComponent as ConnectionsSVG } from "assets/icons/connections.svg";

import { DragText, HeadingText, useStyles } from "./styles";

import {
	AnswerDropZoneContainer,
	BowTieContainer,
	BowTieDropZone,
	BowTieGroupDropZoneContainer,
	DragOptionContainer,
	DragOptionContainerText,
	DragOptionReadOnlyContainer,
	DragOptionText,
	StyledGap
} from "../../styles";
import { dragCollect, getTouchBackendStyle } from "../utils/dragAndDrop";

const groupTypes = ["Actions To Take", "Potential Conditions", "Parameters To Monitor"];
interface Option {
	id: string;
	text: string;
}

interface SelectedAnswer {
	option: Option;
	groupId?: string;
}

const StandaloneDragOption = ({ option, groupId, sideEffect }) => {
	const [{ position, isDragging, opacity }, drag] = useDrag({
		item: { option, sideEffect },
		type: `standalone-${groupId}`,
		collect: monitor => dragCollect(monitor)
	});
	return (
		<DragOptionReadOnlyContainer
			ref={drag}
			id={option?.id}
			style={isMobile && isDragging ? getTouchBackendStyle(position) : { opacity }}
		>
			<DragOptionText>{option?.text}</DragOptionText>
			<Box mt={1} display="flex">
				<SvgIcon style={{ cursor: "move" }} fontSize="large">
					<IconEquals />
				</SvgIcon>
			</Box>
		</DragOptionReadOnlyContainer>
	);
};

const GroupDragOptions = ({ option, groupId }) => {
	const [{ position, opacity, isDragging }, drag] = useDrag({
		item: { option },
		type: `group-${groupId}`,
		collect: monitor => dragCollect(monitor)
	});
	return (
		<DragOptionContainer
			id={option?.id}
			ref={drag}
			style={isMobile && isDragging ? getTouchBackendStyle(position) : { opacity }}
		>
			<DragOptionContainerText>{option?.text}</DragOptionContainerText>
			<SvgIcon style={{ cursor: "move" }} fontSize="large">
				<IconEquals />
			</SvgIcon>
		</DragOptionContainer>
	);
};

const AnswerDropZone = ({ onDropped, groupId, userAnswer, zoneIndex }) => {
	const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswer>();
	const ref = useRef({
		selectedAnswer
	});

	useEffect(() => {
		if (userAnswer && userAnswer.option.id !== selectedAnswer?.option.id) {
			setSelectedAnswer({
				groupId: userAnswer.option.groupId,
				option: { id: userAnswer.option.id, text: userAnswer.option.text }
			});
		}
	}, [userAnswer]);

	const [{ isOver }, drop] = useDrop(() => ({
		accept: `group-${groupId}`,
		drop: (item: SelectedAnswer) => {
			const selectedAnswer = { ...item, groupId };
			if (!isEmpty(selectedAnswer)) {
				if (selectedAnswer?.option.id !== userAnswer?.option.id) {
					onDropped({ item: selectedAnswer, prevItem: ref.current.selectedAnswer, index: zoneIndex });
				}
				ref.current.selectedAnswer = selectedAnswer;
			}
		},
		collect: monitor => ({
			isOver: monitor.isOver({ shallow: true })
		})
	}));

	const cleanData = () => {
		ref.current.selectedAnswer = undefined;
		setSelectedAnswer(undefined);
	};

	return (
		<AnswerDropZoneContainer ref={drop} $isOver={isOver}>
			{!isEmpty(selectedAnswer) ? (
				<StyledGap>
					<StandaloneDragOption sideEffect={cleanData} groupId={groupId} option={selectedAnswer?.option} />
				</StyledGap>
			) : (
				<DragText>Drag your answer here</DragText>
			)}
		</AnswerDropZoneContainer>
	);
};

const GroupDropZone = ({ groupId, index, onDropped, answerOptions }) => {
	const [{ isOver, canDrop }, drop] = useDrop(() => ({
		accept: `standalone-${groupId}`,
		drop: (item: { option: Record<string, unknown>; sideEffect: () => void }) => {
			onDropped({ option: item.option as Record<string, unknown>, groupId: groupId });
			item?.sideEffect();
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}));

	const overStyles = {
		opacity: 0.7
	};

	return (
		<BowTieDropZone ref={drop} style={isOver && canDrop ? { ...overStyles } : {}}>
			<Box>{groupTypes[index]}</Box>
			{answerOptions.map((dragOption, i) => (
				<Box mt={2} key={i}>
					<GroupDragOptions groupId={groupId} option={dragOption} />
				</Box>
			))}
		</BowTieDropZone>
	);
};

const BowTieQuestion = ({ question, onChange, userAnswers, answersRef }) => {
	const classes = useStyles({ bgColor: "#fff", bg: "#dbdee4", padding: "16px" });
	const groups = question.data.groups;

	const onRemoveOption = value => {
		if (!isEmpty(value)) {
			const { groupId: _groupId, option } = value as SelectedAnswer;
			const arr = [...(answersRef ? answersRef.current[question.id] : userAnswers)];
			const index = arr.findIndex(({ id, groupId }) => id === option.id && groupId === _groupId);
			arr.splice(index, 1);
			onChange([...arr]);
		}
	};
	const onDroppedHandle = ({ item, prevItem, index }) => {
		const {
			groupId,
			option: { id }
		} = item;
		const _item = { groupId, id, order: index };
		const existedAnswerOpts = [...(answersRef ? answersRef.current[question.id] : userAnswers)];
		const answers = prevItem
			? existedAnswerOpts.filter(
					x => !(x.id === prevItem.option.id && x.groupId === prevItem.groupId && x.order === index)
			  )
			: existedAnswerOpts;
		onChange([...answers, _item]);
	};

	const getAnswerOptions = (groupId, answerOptions) => {
		return answerOptions.filter(item => !userAnswers.some(x => x.groupId === groupId && x.id === item.id));
	};

	const getGroupAnswerOptions = (groupId, index, groups) => {
		const answer = userAnswers.find(answer => answer.groupId === groupId && answer.order === index);
		if (answer && !answer.text) {
			answer.text = groups.find(f => f.id === groupId).answerOptions.find(({ id }) => id === answer.id)?.text;
		}
		return answer ? { option: answer } : undefined;
	};

	return (
		<BowTieContainer display={"flex"} flexDirection="column" maxWidth={"860px"}>
			<div className={classes.wrapperSchemaContainer}>
				{isMobile && (
					<Box zIndex={1} mt={3.5}>
						<ConnectionsSVG />
					</Box>
				)}
				<div className={classes.schemaContainer}>
					<div className={classes.shortSchemaColumn}>
						{isMobile && <HeadingText>{groupTypes[0]}</HeadingText>}
						<div className={classes.schemaBox}>
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[0].id}
								zoneIndex={0}
								userAnswer={getGroupAnswerOptions(groups[0].id, 0, groups)}
							/>
						</div>
						{isMobile && <HeadingText>{groupTypes[0]}</HeadingText>}
						<div className={classes.schemaBox}>
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[0].id}
								zoneIndex={1}
								userAnswer={getGroupAnswerOptions(groups[0].id, 1, groups)}
							/>
						</div>
					</div>
					{!isMobile ? (
						<div className={classes.schemaSeparator}>
							<div className={classes.schemaTopDiag}>
								<i></i>
								<i></i>
							</div>
							<div className={classes.schemaBottomDiag}>
								<i></i>
								<i></i>
							</div>
						</div>
					) : null}
					<div className={classes.longSchemaColumn}>
						{isMobile && <HeadingText>{groupTypes[1]}</HeadingText>}
						<div className={classes.schemaBox}>
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[1].id}
								zoneIndex={0}
								userAnswer={getGroupAnswerOptions(groups[1].id, 0, groups)}
							/>
						</div>
					</div>
					{!isMobile ? (
						<div className={classes.schemaSeparator}>
							<div className={classes.schemaBottomDiag}>
								<i></i>
								<i></i>
							</div>
							<div className={classes.schemaTopDiag}>
								<i></i>
								<i></i>
							</div>
						</div>
					) : null}
					<div className={classes.shortSchemaColumn}>
						{isMobile && <HeadingText>{groupTypes[2]}</HeadingText>}
						<div className={classes.schemaBox}>
							{" "}
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[2].id}
								zoneIndex={0}
								userAnswer={getGroupAnswerOptions(groups[2].id, 0, groups)}
							/>
						</div>
						{isMobile && <HeadingText>{groupTypes[2]}</HeadingText>}
						<div className={classes.schemaBox}>
							{" "}
							<AnswerDropZone
								onDropped={onDroppedHandle}
								groupId={groups[2].id}
								zoneIndex={1}
								userAnswer={getGroupAnswerOptions(groups[2].id, 1, groups)}
							/>
						</div>
					</div>
				</div>
			</div>

			<BowTieGroupDropZoneContainer display={"flex"} className={classes.gap25} width="100%">
				{groups?.map((group, i) => {
					return (
						<GroupDropZone
							onDropped={onRemoveOption}
							groupId={group.id}
							index={i}
							key={i}
							answerOptions={getAnswerOptions(group.id, group.answerOptions)}
						/>
					);
				})}
			</BowTieGroupDropZoneContainer>
		</BowTieContainer>
	);
};

export default BowTieQuestion;
