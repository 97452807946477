interface IAccountI {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}
export const accountInitialValue: IAccountI = {
	firstName: "",
	lastName: "",
	email: "",
	password: ""
};

interface IShipping {
	countryId: number | "";
	address1: string;
	city: string;
	state: string;
	zip: string;
	phoneNumber: string;
}
export const shippingInitValue: IShipping = {
	countryId: "",
	address1: "",
	city: "",
	state: "",
	zip: "",
	phoneNumber: ""
};

interface IPayment {
	nameOnCard: string;
	validCardDetails: boolean;
	terms: boolean;
}
export const paymentInitValue: IPayment = {
	nameOnCard: "",
	validCardDetails: false,
	terms: false
};
