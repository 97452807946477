import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { RootState } from "store";
import { emit } from "store/features/notifications/notifications.slice";
import { TestStatus, Tests } from "store/services/tests/dto";

import { TestSummary } from "store/services/tests/dto/testSummary.dto";
import { testsService } from "store/services/tests/tests.services";

interface TestsSummary extends TestSummary {
	isSummaryLoading: boolean;
}

export interface TestState extends FindResponse<Tests> {
	isLoading: boolean;
	testsSummary: TestsSummary;
}

const initialState: TestState = {
	isLoading: false,
	page: 1,
	perPage: 10,
	more: false,
	totalItems: 0,
	items: [],
	testsSummary: {
		isSummaryLoading: false,
		totalQuizzes: 0,
		totalPassed: 0,
		totalFailed: 0,
		lastAttemptedExam: "You haven’t taken any tests yet"
	}
};

export const fetchQuizzes = createAsyncThunk(
	"test/fetchQuizzes",
	async (
		{
			page: optPage,
			searchText,
			status,
			locationId,
			quizId
		}: {
			page: number;
			locationId: number | null;
			searchText?: string;
			userId?: number;
			status?: TestStatus;
			quizId?: number;
		},
		{ dispatch, getState, rejectWithValue }
	) => {
		const { page, perPage } = getState() as TestState;
		const filters = quizId ? { locationId, quizId, isPublished: true } : { locationId, isPublished: true };

		const prePayload = {
			filters,
			page: optPage || page || 1,
			perPage: perPage || 10,
			orderBy: { expiresOn: "DESC" },
			include: ["attempts"]
		};

		if (status) {
			prePayload["status"] = status;
		}

		if (searchText) {
			prePayload["searchKeyword"] = searchText;
		}

		try {
			return await testsService.find(prePayload);
		} catch (err) {
			dispatch(emit({ message: "Something went wrong during tests list load", color: "error" }));
			return rejectWithValue(err.message);
		}
	}
);

export const fetchQuizzesSummary = createAsyncThunk(
	"test/fetchQuizzesSummary",
	async (_, { dispatch, rejectWithValue }) => {
		try {
			return await testsService.summary();
		} catch (err) {
			dispatch(emit({ message: "Something went wrong during tests summary load", color: "error" }));
			return rejectWithValue(err.message);
		}
	}
);

export const testSlice = createSlice({
	name: "test",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue
	},
	extraReducers: {
		[fetchQuizzes.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchQuizzes.fulfilled.type]: (state, { payload: { page, perPage, more, items, totalItems } }) => {
			state.isLoading = false;
			state.page = page;
			state.perPage = perPage;
			state.more = more;
			state.totalItems = totalItems;
			state.items = items;
		},
		[fetchQuizzes.rejected.type]: state => {
			state.isLoading = false;
		},
		[fetchQuizzesSummary.pending.type]: state => {
			state.testsSummary.isSummaryLoading = true;
		},
		[fetchQuizzesSummary.fulfilled.type]: (
			state,
			{ payload: { lastAttemptedExam, totalQuizzes, totalPassed, totalFailed } }
		) => {
			state.testsSummary.isSummaryLoading = false;
			state.testsSummary.lastAttemptedExam = lastAttemptedExam || "You haven’t taken any tests yet";
			state.testsSummary.totalQuizzes = totalQuizzes;
			state.testsSummary.totalPassed = totalPassed;
			state.testsSummary.totalFailed = totalFailed;
		},
		[fetchQuizzesSummary.rejected.type]: state => {
			state.testsSummary.isSummaryLoading = false;
		}
	}
});

export function getFullState(state: RootState): TestState {
	return state.quiz;
}

export const { setStateValue } = testSlice.actions;

export default testSlice.reducer;
