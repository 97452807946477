import React from "react";

import { CircularProgress } from "@material-ui/core";

import { BaseButton, FilledButton, GhostButton, LightButton, OutlinedButton } from "./styles";
import { ButtonProps } from "./types";

const getComponent = (variant: ButtonProps["variant"]) => {
	switch (variant) {
		case "filled":
			return FilledButton;
		case "outlined":
			return OutlinedButton;
		case "light":
			return LightButton;
		case "ghost":
			return GhostButton;
		default:
			return BaseButton;
	}
};

const Button: React.FC<ButtonProps> = ({ children, variant, loading, ...rest }) => {
	const Component = getComponent(variant);

	return (
		<Component
			startIcon={loading && <CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />}
			{...rest}
		>
			{children}
		</Component>
	);
};

export default Button;
