import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { TextField } from "@remar/shared/dist/components/TextField";
import { MaintenanceModeTypes } from "@remar/shared/dist/constants";
import { Field, Form, Formik } from "formik";

import { Link, useHistory, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { clearForgotPassword, forgotPassword, selectAuth } from "store/features/Auth/authSlice";
import * as Yup from "yup";

import Email from "assets/images/email-illustration.svg";
import { routes } from "core/constants";

import EntryScreenLayout from "./EntryScreenLayout";

import { GLOBAL_CONSTANTS } from "../../constants";

const ResetPasswordEmailForm = ({ handleSubmit, errorMessage, isLoading }) => {
	const history = useHistory();
	const location = useLocation();
	const params = new URLSearchParams(location.search); // todo: use useSearchParams

	const isMaintenanceModeSevere = GLOBAL_CONSTANTS.REACT_APP_MAINTENANCE_MODE === MaintenanceModeTypes.SEVERE;
	const isMaintenanceModeOptional = GLOBAL_CONSTANTS.REACT_APP_MAINTENANCE_MODE === MaintenanceModeTypes.OPTIONAL;
	const isAuthLogin = params.get("authLogin") == "true";
	const disableOnMaintenanceMode = (!isAuthLogin && isMaintenanceModeOptional) || isMaintenanceModeSevere;

	useEffect(() => {
		if (disableOnMaintenanceMode) {
			history.push(routes.signIn.getPath());
		}
	}, [disableOnMaintenanceMode, history]);

	return (
		<Formik
			initialValues={{
				email: ""
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().email("Invalid email").required("Required")
			})}
			onSubmit={values => handleSubmit(values)}
		>
			{({ isValid, values }) => (
				<>
					{errorMessage && (
						<Box mt={2}>
							<Typography variant="caption" style={{ color: "red" }}>
								{errorMessage}
							</Typography>
						</Box>
					)}
					<Form>
						<Box mt={3}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography align="center" variant="subtitle1">
										We’ll send a reset link to
									</Typography>
									<Field
										component={p => <TextField {...p} hiddenLabel />}
										name="email"
										type="email"
										placeholder="Email"
										fullWidth
										autoFocus
									/>
								</Grid>
							</Grid>
						</Box>
					</Form>
					<Box mt={1}>
						<Button
							fullWidth
							color="primary"
							disabled={!isValid || isLoading}
							loading={isLoading}
							variant="filled"
							onClick={() => handleSubmit(values)}
						>
							Send Reset Link
						</Button>
					</Box>
				</>
			)}
		</Formik>
	);
};

const EmailSent = ({ passwordResetEmail }) => (
	<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
		<Box display="flex" justifyContent="center" alignItems="center" mb={2} flexDirection="column">
			<Box display="flex" justifyContent="center" alignItems="center" mb={4} flexDirection="column">
				<Box display="flex" justifyContent="center" alignItems="center">
					<Typography align="center">
						We sent a reset link to you at <b style={{ marginLeft: "10px" }}>{`[${passwordResetEmail}]`}</b>
					</Typography>
				</Box>
				<Typography>Please check your inbox.</Typography>
			</Box>
			<img alt="Envelope image" src={Email} width={150} />
		</Box>
	</Box>
);

const ForgotPassword = () => {
	const dispatch = useAppDispatch();
	const { errorMessage, isLoading, isPasswordResetLinkSent, passwordResetEmail } = useAppSelector(selectAuth);
	const handleSubmit = values => {
		dispatch(forgotPassword(values.email));
	};
	useEffect(
		() => () => {
			dispatch(clearForgotPassword());
		},
		[dispatch]
	);
	return (
		<EntryScreenLayout title="Forgot Password?">
			{!isPasswordResetLinkSent ? (
				<ResetPasswordEmailForm handleSubmit={handleSubmit} isLoading={isLoading} errorMessage={errorMessage} />
			) : (
				<EmailSent passwordResetEmail={passwordResetEmail} />
			)}
			<Box mt={4} textAlign="center">
				<Link
					to={{
						pathname: routes.signIn.getPath(),
						state: {
							from: routes.forgotPassword.getPath()
						}
					}}
				>
					<Typography color={"primary"} variant={"subtitle1"} style={{ textDecoration: "underline" }}>
						Return to Log In
					</Typography>
				</Link>
			</Box>
		</EntryScreenLayout>
	);
};

export default ForgotPassword;
