import { useEffect, useMemo } from "react";

import { Course, CourseChapter } from "@remar/shared/dist/models";

import { useAppDispatch, useAppSelector } from "store";
import {
	selectCurrentlyAllowedFullCourses,
	selectCurrentlyAllowedTrialCourses,
	selectIsTrial
} from "store/features/Auth/authSlice";
import { loadTrialCourse, selectTrialCoursesInfo } from "store/features/Course/courseSlice";

type TrialCoursesInfoSelection = { trialCourses: Course[]; isLoading: boolean };
type AllowedTrialCoursesSelection = Course[] | undefined;
type AllowedFullCoursesSelection = Course[] | undefined;

export const useLoadTrialCourses = (): { chapters: CourseChapter[] | null; loading: boolean } => {
	const dispatch = useAppDispatch();
	const { trialCourses, isLoading }: TrialCoursesInfoSelection = useAppSelector(selectTrialCoursesInfo);
	const currentlyAllowedTrialCourses: AllowedTrialCoursesSelection = useAppSelector(selectCurrentlyAllowedTrialCourses);
	const currentlyAllowedFullCourses: AllowedFullCoursesSelection = useAppSelector(selectCurrentlyAllowedFullCourses);
	const subTrail = useAppSelector(selectIsTrial);

	useEffect(() => {
		if (!trialCourses.length && (currentlyAllowedTrialCourses || currentlyAllowedFullCourses)) {
			const courses = [...currentlyAllowedTrialCourses!, ...currentlyAllowedFullCourses!];
			const ids = courses.map(e => e.id);
			const isTrial = subTrail === true ? subTrail : !!currentlyAllowedTrialCourses?.length;
			if (ids && ids.length) {
				dispatch(loadTrialCourse({ ids, isTrial }));
			}
		}
	}, [trialCourses.length, currentlyAllowedTrialCourses, currentlyAllowedFullCourses, subTrail, dispatch]);

	const chapters: CourseChapter[] | null = useMemo(
		() => (trialCourses?.length !== 0 ? trialCourses[0].chapters! : null),
		[trialCourses]
	);

	return { chapters, loading: isLoading };
};
