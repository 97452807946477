import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useFilesUploadedStyles = makeStyles(() => ({
	fileUploaded: {
		height: "50px",
		width: "auto",
		padding: "9px 13px 9px 10px",
		margin: "8px",
		borderRadius: "4px",
		border: props => `solid 1px ${props["borderColor"]}`,
		cursor: props => `${props["isDownload"] ? "pointer" : "auto"}`,
		"&>.container": {
			display: "flex",
			marginTop: "-2px",
			justifyContent: "center"
		}
	},
	spacer: {
		height: "1px",
		margin: "16px 0",
		border: "solid 1px hsl(224,14%,15%)"
	},
	subContainer: {
		width: "100%",
		paddingRight: "10px",
		marginTop: "-2px"
	},
	icon: {
		cursor: "pointer"
	}
}));

export const MediaUploadHeading = styled.div`
	margin-bottom: 10px;
	opacity: 0.38;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${props => props.theme.palette.text.primary};
`;

export const FileUploadedText = styled.p`
	margin-top: 2px;
	margin-bottom: 0;
	opacity: 0.87;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${props => props.color || props.theme.palette.text.secondary};
	white-space: nowrap;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const FileUploadedSize = styled.p`
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: hsl(220, 19%, 63%);
	margin-top: 4px;
`;
