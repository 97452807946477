import videojs from "video.js";

const videoOptions: typeof videojs.options = {
	fill: true,
	fluid: true,
	responsive: true,
	preload: "auto",
	controls: true,
	controlBar: {
		volumePanel: { inline: false },
		skipButtons: {
			forward: 10,
			backward: 10
		}
	},
	html5: {
		vhs: {
			enableLowInitialPlaylist: false,
			// smoothQualityChange: true, smoothQualityChange is deprecated and will be removed in the next major version
			overrideNative: true,
			withCredentials: true
		}
	},
	playbackRates: [0.5, 1, 1.5, 2],
	sourceOrder: true
};

export default videoOptions;
