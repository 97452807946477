import { DeleteResponse, FindResponse, UpdateResponse } from "./baseApiService.model";
import { BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "./dto";

import { BaseModel } from "../../models";
import { request } from "../request";

export class BaseApiService<
	ItemModel extends BaseModel,
	CreateDto,
	FindDto extends BaseFindDto,
	FindOneDto extends BaseFindOneDto,
	UpdateDto extends BaseUpdateDto<Record<string, unknown>>,
	DeleteDto extends BaseDeleteDto
> {
	constructor(
		public baseUrl: string // eslint-disable-line no-unused-vars
	) {}

	emptyStringToNull(
		object: Record<string, unknown> | Record<string, unknown>[] | unknown
	): Record<string, unknown> | Record<string, unknown>[] | unknown {
		if (object === "") {
			return null;
		}
		if (object instanceof Array) {
			return object.map(item => this.emptyStringToNull(item));
		}
		if (!!object && typeof object === "object" && !(object instanceof Date)) {
			const newObject: Record<string, unknown> = {};
			for (const key in object) {
				newObject[key] = this.emptyStringToNull(object[key]);
			}
			return newObject;
		}
		return object;
	}

	serializeQuery(
		params: Record<string, unknown> | unknown[] | null | undefined,
		options: { isTopLevel: boolean; prefix?: string }
	): string {
		if (typeof params === "undefined" || params === null) {
			return "";
		}
		const { isTopLevel, prefix } = options;
		const query: Array<string> = Object.keys(params).reduce((acc, key) => {
			const value = params[key];
			// not sure if null check is needed
			if (value === undefined /*|| value === null*/) {
				return acc;
			}

			let actualKey = "";

			if (params.constructor === Array) {
				if (typeof value === "object") {
					actualKey = `${prefix}[${key}]`;
				} else {
					actualKey = `${prefix}[]`;
				}
			} else if (params.constructor === Object) {
				actualKey = prefix ? `${prefix}[${key}]` : key;
			}

			if (typeof value === "object") {
				acc.push(this.serializeQuery(value, { isTopLevel: false, prefix: actualKey }));
				return acc;
			}
			acc.push(`${actualKey}=${encodeURIComponent(value)}`);
			return acc;
		}, [] as Array<string>);

		if (!query.length) {
			return "";
		}
		return `${isTopLevel ? "?" : ""}${query.join("&")}`;
	}

	create(data: CreateDto): Promise<ItemModel> {
		return request(
			`${this.baseUrl}`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<ItemModel>;
	}

	bulkCreate(data: CreateDto[]): Promise<ItemModel[]> {
		const sanitizedData = {
			data: data.map(item => this.emptyStringToNull(item) as Record<string, unknown>)
		};
		return request(`${this.baseUrl}/bulk`, "POST", sanitizedData) as Promise<ItemModel[]>;
	}

	find(data: FindDto): Promise<FindResponse<ItemModel>> {
		return request(
			`${this.baseUrl}${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<FindResponse<ItemModel>>;
	}

	findOne(id: number, data?: FindOneDto): Promise<ItemModel | null> {
		return request(
			`${this.baseUrl}/item/${id}${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<ItemModel | null>;
	}

	update(data: UpdateDto): Promise<UpdateResponse<ItemModel>> {
		return request(`${this.baseUrl}`, "PATCH", this.emptyStringToNull(data) as Record<string, unknown>) as Promise<
			UpdateResponse<ItemModel>
		>;
	}

	delete(data: DeleteDto): Promise<DeleteResponse> {
		return request(
			`${this.baseUrl}`,
			"DELETE",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<DeleteResponse>;
	}

	count(data: FindDto): Promise<number> {
		return request(
			`${this.baseUrl}/count/${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<number>;
	}
}
