import React from "react";

import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { EntryScreenLayout as SharedEmptyScreen } from "@remar/shared/dist/layouts";

import { useAppDispatch, useAppSelector } from "store";
import { setError } from "store/features/Auth/authSlice";

import { getFullState } from "store/features/Theme/theme.slice";

import LoginBgImage from "assets/images/login-background.png";

import Logo from "assets/images/logo.svg";
import { routes } from "core/constants";

type Props = {
	title?: string;
	disableOnMaintenanceMode?: boolean;
	isLeftBlockTransparent?: boolean;
};
const EntryScreenLayout: React.FC<Props> = ({ children, disableOnMaintenanceMode = true, ...rest }) => {
	const theme = useTheme<Theme>();
	const { logoImageUrl, isLoading } = useAppSelector(getFullState);
	const dispatch = useAppDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<SharedEmptyScreen
			logo={!isLoading && <img alt="institution logo" src={logoImageUrl || Logo} width={isMobile ? 100 : 150} />}
			signUpRoute={`${routes.signUp.getPath()}/course`}
			refreshError={() => dispatch(setError(""))}
			disableOnMaintenanceMode={disableOnMaintenanceMode}
			customBgImage={LoginBgImage}
			{...rest}
		>
			{children}
		</SharedEmptyScreen>
	);
};

export default EntryScreenLayout;
