import React, { memo } from "react";

import { Box, SvgIcon } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import NestedSideNavItem from "./NestedSideNavItem";

import IconArrowOut from "../../../../assets/icons/icon-arrow-out.svg";
import LogoComponent from "../../../../components/LogoComponent";
import { PoweredBlock } from "../../../../components/PoweredBlock";

import { Container, NavItem, NavItemContainer } from "../SideNav/styles";
import { SideNavItem, SideNavItemNested } from "../types";

type Props = {
	isMobile: boolean;
	logo: React.ReactNode;
	isCustomized: boolean;
	isTrial: boolean;
	navItemList: (SideNavItem | SideNavItemNested)[];
	clickSideEffect?: (identifier: string) => void;
};
const SideBar = ({ isMobile, navItemList, isTrial, logo, isCustomized, clickSideEffect }: Props) => {
	const history = useHistory();
	const { location } = history;

	return (
		<Container isMobile={isMobile}>
			<LogoComponent logo={logo} showLargeLogo={false} />
			<NavItemContainer>
				{navItemList
					.filter(item => item.visible)
					.map(item => {
						const Icon = item.icon;
						const single = item as SideNavItem;
						const nested = item as SideNavItemNested;
						// If item have one-level depth
						if (single.route) {
							const { id, route, title, disabled, external } = single;
							let highlight = false;

							if (route !== "/") {
								highlight = location.pathname.includes(route);
							} else if (location.pathname === "/" && route === location.pathname) {
								highlight = true;
							}

							return (
								<NavItem
									onClick={() => (external ? window.open(route, "_blank")?.focus() : !disabled && history.push(route))}
									className="navItem"
									key={id}
									route
									highlight={highlight}
									disabled={disabled}
								>
									<div className="item-box">
										{Icon && (
											<SvgIcon>
												<Icon />
											</SvgIcon>
										)}
										<div className="title">{title}</div>
										{external && (
											<Box flex={1} display="flex" justifyContent="flex-end" title="move to jobs website">
												<img className="icon-out" alt="external link" src={IconArrowOut} />
											</Box>
										)}
									</div>
								</NavItem>
							);
						}

						// If item have child routes
						if ((item as SideNavItemNested).subItems) {
							const { id, subItems, title, disabled, visible } = nested;
							return (
								<NestedSideNavItem
									clickSideEffect={clickSideEffect}
									subItems={subItems}
									title={title}
									Icon={Icon}
									key={id}
									isTrial={isTrial}
									visible={visible}
									disabled={disabled}
								/>
							);
						}

						return null;
					})}
			</NavItemContainer>
			{!isMobile && isCustomized && <PoweredBlock />}
		</Container>
	);
};

export default memo(SideBar);
