import { Box, Avatar as DefaultAvatar, Typography, alpha } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

export const useChangePasswordStyles = makeStyles((theme: IExtendedTheme) => ({
	title: {
		fontWeight: "bold",
		fontSize: `"${theme.typography.pxToRem(18)}"`,
		color: theme.palette.text.primary,
		alignItems: "center",
		display: "flex"
	},
	heading: {
		fontSize: `"${theme.typography.pxToRem(26)}"`,
		fontWeight: "bold",
		color: "#fff",
		alignItems: "center",
		display: "flex",
		marginBottom: "20px"
	},
	root: {
		flexGrow: 1
	},
	listStyle: { listStyle: "none", padding: 0 },
	listItem: {
		fontSize: `"${theme.typography.pxToRem(15)}"`,
		fontWeight: "normal",
		lineHeight: 1.6,
		color: "#ffffff",
		gap: "15px",
		display: "flex",
		alignItems: "center"
	},
	passContain: {
		fontSize: `"${theme.typography.pxToRem(13)}"`,
		fontWeight: 600,
		color: "#ffffff",
		opacity: 0.6
	},
	formSubtitle: {
		opacity: 0.6,
		fontSize: theme.typography.pxToRem(13)
	},
	cancelButton: {
		marginRight: 10
	}
}));

export const PaymentTitle = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.text.primary};
`;

export const PaymentBlock = styled(Box)`
	border-radius: 4px;
	margin-top: 16px;
	padding: 16px;
	background: ${props => props.theme.palette.background.default};
`;

export const PaymentDescription = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 500;
	line-height: 20px;
	color: ${props => props.theme.palette.text.secondary};
	margin-left: 12px;
	width: 80%;
`;

export const PaymentExpiredText = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(14)};
	line-height: 16px;
	margin-top: 2px;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
`;

export const CardName = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: bold;
	color: ${props => props.theme.palette.text.primary};
	text-transform: capitalize;
`;

export const AddressText = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(15)};
	color: ${props => alpha(props.theme.palette.text.primary, 0.86)};
	line-height: 24px;
`;

export const THeaderWrapper = styled(Box)`
	background-color: ${props => props.theme.palette.background.paper};
	padding: 20px;
	border-radius: 4px;
`;

export const THeaderTitle = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.text.priamry};
`;

export const ColumnHeader = styled(Typography)`
	font-size: ${props => props.theme.typography.pxToRem(12)};
	font-weight: 500;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
`;

export const StyledCellWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const StyledCellText = styled(Typography)`
	word-break: break-all;
	padding: 5px 0 5px 10px;
	align-self: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${props => props.theme.palette.text.primary};
	font-size: ${props => props.theme.typography.pxToRem(13)};
`;
export const Avatar = styled(DefaultAvatar)`
	background-color: #172e4b;
	cursor: pointer;
`;

export const InvoiceDownloadIcon = styled(Box)`
	> svg {
		height: 100% !important;
		cursor: pointer;
	}
`;

export const BillingsContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding-right: 8px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;

export const PastPaymentsContainer = styled.div`
	flex: 1;
	padding-right: 16px;

	${props => props.theme.breakpoints.down("sm")} {
		padding-right: 0;
		margin-bottom: 10px;
	}
`;
