export const hasAtleast8Chars = (value: string): boolean => {
	return value.length > 7;
};

export const hasLowerCase = (value: string): boolean => {
	return new RegExp(/[a-z]/).test(value);
};

export const hasUpperCase = (value: string): boolean => {
	return new RegExp(/[A-Z]/).test(value);
};

export const hasSpecial = (value: string): boolean => {
	return new RegExp(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/).test(value);
};
