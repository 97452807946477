import React from "react";

import { Box } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import styled, { ThemeProvider } from "styled-components";

import theme from "./theme/boundaryTheme";

import SomethingWrongImage from "../../assets/images/something-wrong.svg";
import LogoComponent from "../LogoComponent";

const ErrorContainer = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	background: ${props => props.theme.palette.background.paper};
`;

const Header = styled(Box)`
	padding: 20px;
`;

const Row = styled(Box)`
	display: flex;
	flex-flow: row nowrap;
	flex-grow: 1;
	color: ${props => props.theme.palette.text.primary};
`;

const TextHeader = styled.h1`
	padding: 0;
	margin: 0 0 27px;
	text-align: left;
`;

const TextContainer = styled(Box)`
	padding: 0 0 20vh 117px;
	font-size: 24px;
	display: flex;
	flex-flow: column;
	width: 60%;
	justify-content: center;
`;

const ImageContainer = styled(Box)`
	width: 40%;
	display: flex;
	flex-flow: row;
	align-items: center;
	min-width: 465px;
`;

const ErrorBoundary: React.FC<{ footer?: React.ReactNode }> = ({ children, footer }) => (
	<StylesProvider injectFirst>
		<ThemeProvider theme={theme}>
			<Sentry.ErrorBoundary
				fallback={() => (
					<ErrorContainer>
						<Header>
							<LogoComponent />
						</Header>
						<Row>
							<TextContainer>
								<TextHeader>Whoops!</TextHeader>
								Looks like something went wrong, but we are already looking into it.
							</TextContainer>
							<ImageContainer>
								<img src={SomethingWrongImage} alt="whoops" />
							</ImageContainer>
						</Row>
						{footer}
					</ErrorContainer>
				)}
			>
				{children}
			</Sentry.ErrorBoundary>
		</ThemeProvider>
	</StylesProvider>
);

export default ErrorBoundary;
