import React, { useMemo } from "react";

import { Box, Card, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";

import Button from "@remar/shared/dist/components/Button";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { IconCommentExclamation } from "../../assets/icons";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		card: {
			background: theme.palette.background.paper,
			borderRadius: 4,
			boxShadow: "0 1 15px 0 rgba(69, 65, 78, 0.08)",
			maxWidth: 555,
			maxHeight: 452,
			height: "100%",
			[theme.breakpoints.down("xs")]: {
				minHeight: "100%",
				maxHeight: "100%"
			}
		},
		root: {
			width: "100%",
			height: "100%",
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				marginRight: 0
			}
		},
		headerText: {
			fontFamily: "var(--poppins)",
			fontWeight: "bold",
			fontSize: 26,
			marginTop: 20,
			textAlign: "center",
			color: theme.palette.text.primary
		},
		descriptionText: {
			fontFamily: "var(--openSans)",
			fontSize: 13,
			marginBottom: 28,
			textAlign: "center",
			padding: "0 80px",
			color: theme.palette.text.secondary,
			opacity: 0.77
		}
	})
);

type PreventCancelSubscriptionProps = {
	onClose: () => void;
	locationName?: string;
};
const PreventCancelSubscription = ({ onClose, locationName }: PreventCancelSubscriptionProps) => {
	const classes = useStyles();

	const { mainText, subText } = useMemo(() => {
		const mainText = locationName
			? `This subscription is managed by ${locationName}`
			: "Your Subscription Cannot Be Cancelled";
		const subText = locationName
			? "Please contact the institution directly for account management and cancelation."
			: "You cannot cancel your subscription because your subscription fee is paid by the institution.";
		return { mainText, subText };
	}, [locationName]);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
			<Card className={classes.card}>
				<Box display="flex" justifyContent="flex-end">
					<IconButton onClick={onClose}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>
				<Box className={classes.root} flex flexDirection="column" alignItems="center" gridGap={12}>
					<IconCommentExclamation width={60} height={60} />
					<Typography className={classes.headerText}>{mainText}</Typography>
					<Typography className={classes.descriptionText}>{subText}</Typography>
					<Button variant="filled" size="giant" fullWidth color="primary" onClick={onClose}>
						Back to My Account
					</Button>
				</Box>
			</Card>
		</Box>
	);
};

export default PreventCancelSubscription;
