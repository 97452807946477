import { createStyles, makeStyles } from "@material-ui/core";

import { IExtendedTheme } from "../../theme/default";

export const useStepIconStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			color: theme.palette.primary.light,
			display: "flex",
			height: 22,
			alignItems: "center",
			fontSize: 22
		},
		active: {
			color: theme.palette.primary.main,
			height: 24
		},
		completed: {
			color: theme.palette.primary.main,
			zIndex: 1
		}
	})
);

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		stepLabel: {
			color: `${theme.palette.text.primary} !important`,
			opacity: 0.5
		}
	})
);
