import React from "react";

import ContentLoader from "./ContentLoader";

import { IColumn, MaterialTable } from "../../../components/MaterialTable";
import SearchBarComponent from "../../../components/SearchBar";
import { THeaderTitle } from "../../../components/Table/styles";
import { TablePagination } from "../../../components/TablePagination";
import LockScreenBanner from "../../../components/UpgradeBanner/LockScreenBanner";
import { RowWrapper, THeaderWrapper } from "../styles";

type TableContainerProps = {
	tableTitle?: string;
	isLoading: boolean;
	emptyState: React.ReactNode;
	onChangePage: (page: number) => void;
	filterBlock?: React.ReactNode;
	totalEntities?: null | number;
	totalItems: number;
	perPage: number;
	page: number;
	searchText?: string;
	setSearchText?: React.Dispatch<React.SetStateAction<string>>;
	handleSearchBarChange?: (s: string) => void;
	searchPlaceholder?: string;
	isTrial?: boolean;
	showLockScreenBanner?: boolean;
	onUpgrade?: () => void;
};
export const TableContainer: React.FC<TableContainerProps> = ({
	tableTitle,
	filterBlock,
	searchText,
	setSearchText,
	handleSearchBarChange,
	searchPlaceholder,
	isLoading,
	totalEntities,
	totalItems,
	emptyState,
	isTrial,
	page,
	onChangePage,
	perPage,
	onUpgrade,
	showLockScreenBanner = false,
	children
}) => {
	const canViewEmptyState = emptyState && totalEntities === 0;
	const shouldViewHeader = !canViewEmptyState;

	return (
		<>
			{shouldViewHeader && (
				<THeaderWrapper>
					{tableTitle && (
						<RowWrapper>
							<THeaderTitle>{tableTitle}</THeaderTitle>
						</RowWrapper>
					)}
					<RowWrapper $isFlex $alignItem="center">
						{filterBlock}
						{setSearchText && (
							<SearchBarComponent
								inputValue={searchText as string}
								setInputValue={setSearchText as React.Dispatch<React.SetStateAction<string>>}
								onChange={handleSearchBarChange as (searchText: string) => void}
								disabled={isTrial}
								isDebounced={true}
								debounceTime={1000}
								placeHolder={searchPlaceholder ?? "Type to filter by keywords"}
							/>
						)}
					</RowWrapper>
				</THeaderWrapper>
			)}
			{isLoading ? (
				<ContentLoader />
			) : canViewEmptyState ? (
				emptyState
			) : (
				<>
					{children}
					{showLockScreenBanner && page === 5 && <LockScreenBanner onButtonClick={() => onUpgrade && onUpgrade()} />}
					{perPage < totalItems && (
						<TablePagination
							count={isTrial && totalItems > 50 ? 50 : totalItems}
							page={page}
							onChange={(_, page) => onChangePage(page)}
							rowsPerPage={perPage}
						/>
					)}
				</>
			)}
		</>
	);
};

export type TableWrapperProps<TDto> = TableContainerProps & {
	tableColumns: Array<IColumn<TDto>>;
	data: TDto[];
	hideTableHeader?: boolean;
	tableCustomStyle?: React.CSSProperties;
};
const TableWrapper = <T extends Record<string, unknown>>({
	hideTableHeader,
	tableColumns,
	data,
	tableCustomStyle,
	...rest
}: TableWrapperProps<T>) => (
	<TableContainer {...rest}>
		<MaterialTable
			hideHeader={hideTableHeader}
			columns={tableColumns}
			data={data}
			height={800}
			customStyle={tableCustomStyle}
		/>
	</TableContainer>
);

export default TableWrapper;
