import React, { useMemo } from "react";

import { Box, Typography, darken } from "@material-ui/core";
import DialogModal from "@remar/shared/dist/modals/DialogModal/DialogModal";

import styled from "styled-components";

import { CheckSvg, ClockSvg, FileMinusSvg } from "assets/icons";

const ItemWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	gap: 24px;
	
	p {
		font-size: 13px;
		font-family: var(--openSans);
		font-weight: 400;
		color: ${({ theme }) => darken(theme.palette.text.secondary, 0.2)};
	&:first-child {
		font-size: 15px;
		font-weight: 600;
		color: ${({ theme }) => theme.palette.text.primary};
		opacity: 0.87;
	}
`;

type ItemProps = {
	icon?: React.ReactNode;
	title: string;
	description: string;
};
const Item = ({ icon, title, description }: ItemProps) => (
	<ItemWrapper>
		{icon}
		<Box display="flex" flexDirection="column" justifyContent="space-between" gridGap={4}>
			<Typography>{title}</Typography>
			<Typography>{description}</Typography>
		</Box>
	</ItemWrapper>
);

export const Items = ({ catQuotaAvailable }: { catQuotaAvailable: number | undefined }) => {
	const items = useMemo(
		() => [
			{
				icon: <ClockSvg />,
				title: "5 hours",
				description: "The timer kicks in right away and can't be paused until the end."
			},
			{
				icon: <CheckSvg />,
				title: "Results",
				description: "You'll receive a pass or fail result in your performance report."
			},
			{
				icon: <FileMinusSvg />,
				title: "1 CAT test",
				description: `Starting the test deducts one CAT credit. You have: ${catQuotaAvailable} test(s)`
			}
		],
		[catQuotaAvailable]
	);

	return (
		<Box display="flex" flexDirection="column" gridGap={24} padding={3}>
			{items.map(({ icon, title, description }, index) => (
				<Item key={index} icon={icon} title={title} description={description} />
			))}
		</Box>
	);
};

type StartCatTestModalProps = {
	open: boolean;
	onClose: () => void;
	onSuccess: () => void;
	catQuotaAvailable: number | undefined;
};
const StartCatTestModal = ({ open, onClose, onSuccess, catQuotaAvailable }: StartCatTestModalProps) => (
	<DialogModal
		open={open}
		onClose={onClose}
		onSuccess={onSuccess}
		title="Start CAT test"
		cancelBtnText="Maybe later"
		successBtnText="Start CAT test"
		maxWidth={390}
	>
		<Items catQuotaAvailable={catQuotaAvailable} />
	</DialogModal>
);

export default StartCatTestModal;
