import { Box, createStyles, makeStyles, RadioGroup } from "@material-ui/core";

import styled, { css } from "styled-components";

import { IExtendedTheme } from "../../../theme/default";

export const BowTieContainer = styled(Box)`
	width: ${props => (props.isBowTieResult ? "100%" : "70%; min-width: 800px;")};
	margin-left: ${props => (props.isBowTieResult ? 0 : "16px")};
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	${props => props.theme.breakpoints.down("md")} {
		width: 100%;
	}
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
		margin-left: 0;
		min-width: 0;
	}
`;

export const MatrixTableHeader = styled(Box)`
	min-height: 64px;
	border-radius: 3px 3px 0 0;
	background-color: ${props => props.theme.palette.colors.basic[100]};
`;

export const MatrixTableBody = styled(Box)`
	max-width: 595px;
	border-radius: 0 0 3px 3px;
	background-color: #${({ isinresults }) => (isinresults ? "f5f7f9" : "edeef1")};
	${({ isinresults }) =>
		!isinresults
			? "border-right: solid 1px rgba(151, 151, 151, 0.3);	border-left: solid 1px rgba(151, 151, 151, 0.3);"
			: ""}
`;

export const StyledOptText = styled.span`
	word-break: break-word;
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #4d5058;
`;

export const RadioWrapper = styled(RadioGroup)`
	flex-direction: row;
	width: 100%;
	height: 100%;
	display: flex;
`;

export const StyledText = styled.span`
	word-break: break-all;
	font-family: var(--poppins);
	cursor: default;
	display: flex;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
`;

export const CheckBoxWrapper = styled(Box)`
	flex-direction: row;
	width: 100%;
	height: 100%;
	display: flex;
`;

export const StyledGroupText = styled.div`
	border-right: solid 1px rgba(151, 151, 151, 0.3);
	padding: 13px 0 0 16px;
	word-break: break-word;
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	height: 100%;
	font-weight: 600;
	line-height: 1.85;
	color: #4d5058;
	max-width: 50%;
	flex: ${({ $flex }) => $flex && 1};

	&:last-child {
		border-right: none;
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		schemaContainer: {
			display: "flex",
			flexFlow: "row nowrap",
			width: "100%",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				marginLeft: "-4px",
				marginRight: "16px",
				overflow: "hidden"
			}
		},
		wrapperSchemaContainer: {
			padding: props => `${props["padding"]}`,
			borderRadius: "4px",
			backgroundColor: props => `${props["bgColor"]}`,
			display: "flex",
			flexDirection: "row",
			marginBottom: "16px",
			[theme.breakpoints.down("sm")]: {
				alignItems: "center",
				paddingRight: props => `${props["isBowTieResult"] ? "28px" : "10px"}`
			}
		},
		schemaBox: {
			background: props => `${props["bg"]}`,
			margin: "5px 0",
			padding: "0 14px",
			width: "100%",
			height: "48px",
			borderRadius: "4px",
			opacity: "0.87",
			fontFamily: "var(--openSans)",
			fontSize: `${theme.typography.pxToRem(13)}`,
			fontWeight: 600,
			color: "#4d5058",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			flexGrow: 0,
			flexShrink: 1,
			display: "flex",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				padding: "0px",
				height: "55px"
			}
		},
		flexTop: {
			alignItems: "start !important",
			justifyContent: "start !important"
		},
		gap25: {
			gap: "25px"
		},
		shortSchemaColumn: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "304px",
			flexShrink: 1,
			flexGrow: 1,
			[theme.breakpoints.up("sm")]: {
				maxWidth: "230px"
			},
			[theme.breakpoints.down("sm")]: {
				flexBasis: 0,
				maxWidth: "100%"
			}
		},
		longSchemaColumn: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "320px",
			[theme.breakpoints.up("sm")]: {
				maxWidth: "270px"
			},
			flexShrink: 1,
			flexGrow: 1,
			[theme.breakpoints.down("sm")]: {
				flexBasis: 0,
				margin: "12px 0",
				maxWidth: "100%"
			}
		},
		schemaSeparator: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			flexBasis: "114px",
			flexShrink: 1,
			flexGrow: 1
		},
		schemaTopDiag: {
			height: "25%",
			width: "100%",
			position: "relative",
			background: `linear-gradient(to top right,
					transparent 0%,
					transparent calc(50% - 1.3px),
					#dbdee4 calc(50% - 1px),
					#dbdee4 calc(50% + 1px),
					transparent calc(50% + 1.3px),
					transparent 100%
				)`,
			"& > i": {
				display: "block",
				borderRadius: "50%",
				position: "absolute",
				background: "#dbdee4",
				border: "2px solid #fff",
				width: "8px",
				height: "8px"
			},
			"& > i:first-child": {
				bottom: 0,
				right: 0,
				transform: "translate(50%, 50%)"
			},
			"& > i:last-child": {
				top: 0,
				left: 0,
				transform: "translate(-50%, -50%)"
			}
		},
		schemaBottomDiag: {
			height: "25%",
			width: "100%",
			position: "relative",
			background: `linear-gradient(to bottom right,
					transparent 0%,
					transparent calc(50% - 1.3px),
					#dbdee4 calc(50% - 1px),
					#dbdee4 calc(50% + 1px),
					transparent calc(50% + 1.3px),
					transparent 100%
				)`,
			"& > i": {
				display: "block",
				borderRadius: "50%",
				position: "absolute",
				background: "#dbdee4",
				border: "2px solid #fff",
				width: "8px",
				height: "8px"
			},
			"& > i:first-child": {
				top: 0,
				right: 0,
				transform: "translate(50%, -50%)"
			},
			"& > i:last-child": {
				bottom: 0,
				left: 0,
				transform: "translate(-50%, 50%)"
			}
		},
		groupsContainer: {
			display: "flex",
			flexFlow: "row nowrap"
		},
		addOptionHeader: {
			color: "#ffffff",
			fontSize: `"${theme.typography.pxToRem(12)}"`,
			fontWeight: "bold",
			lineHeight: 1.33,
			opacity: 0.38,
			marginTop: 16
		},
		alignSelf: {
			alignSelf: "center"
		}
	})
);

export const HightlightTableContainer = styled.div`
	border-radius: 3px;
	margin-top: 17px;
	margin-left: 16px;
	overflow: hidden;
	${props => props.theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
`;

export const HotspotHighlightQuestionBox = styled(Box)`
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.1);
	width: ${({ fullwidth }) => (fullwidth ? "100%" : "50%")};
	padding: 10px 10px 0 10px;
	letter-spacing: normal;
	color: #4d5058;

	${({ studentSide }) =>
		studentSide
			? "font-family: OpenSans; > div { font-size: ${theme.typography.pxToRem(13)}; font-weight: normal; line-height: 20px; }"
			: "font-size: ${theme.typography.pxToRem(16)};"}
`;
export const HighlightTable = styled.table`
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;

	> tbody > tr:not(:last-child) > td {
		border-bottom: 1px solid rgba(151, 151, 151, 0.1);
	}

	> tbody > tr > td {
		background: rgba(255, 255, 255, 0.5);
		vertical-align: top;
		color: #6d7078;
		font-weight: normal;

		&:first-child {
			border-right: 1px solid rgba(151, 151, 151, 0.1);
			padding: 20px;
			font-weight: 600;
		}

		${HotspotHighlightQuestionBox} {
			width: auto;
		}
	}
	> thead > tr > td {
		padding: 20px 10px;
		background: #fff;
		opacity: 0.87;
		font-size: ${props => props.theme.typography.pxToRem(13)};
		font-weight: 600;
		line-height: 1.85;
		letter-spacing: normal;
		color: #4d5058;
		text-align: center;

		&:first-child {
			border-right: 1px solid rgba(151, 151, 151, 0.1);
			width: 40%;
			${props => props.theme.breakpoints.down("sm")} {
				width: 35%;
			}
		}
	}
`;

export const CaseStudyRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	border-radius: 3px;
	${props => props.theme.breakpoints.down("sm")} {
		flex-flow: column;
	}
`;

export const CaseStudyColumn = styled.div`
	flex: 50% 0 0;
`;

export const HeadingText = styled(Box)`
	height: 24px;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #4d5058;
	width: 100%;
`;

export const DragText = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	color: #4d5058;
	opacity: 0.87;
	justify-content: center;
	display: flex;
	width: 100%;
	${props => props.theme.breakpoints.down("sm")} {
		justify-content: center;
	}
`;

export const Pages = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
`;

export const QuestionContainer = styled.div`
	margin-bottom: 10px;
`;

export const CurrentPageNum = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #4d5058;
`;

export const IconsContainer = styled.span`
	display: flex;
	flex-direction: row;
	gap: 6px;
`;

export const StyledIcon = styled.span`
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background-color: #c0c9e5;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	${({ right, disabled }) =>
		right &&
		disabled &&
		css`
			cursor: none;
			pointer-events: none;
		`};

	${({ left, disabled }) =>
		left &&
		disabled &&
		css`
			cursor: none;
			pointer-events: none;
		`}
`;

export const TestQuizQuestionText = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #4d5058;
	margin-left: 16px;
	${props => props.theme.breakpoints.down("sm")} {
		margin-left: 0;
	}
`;

export const TestQuizQuestionDesc = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #4d5058;
`;

export const CaseStudyTabs = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin: 0 -2px;
	align-items: end;
`;

export const CaseStudyTab = styled.div`
	height: 48px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	opacity: 0.87;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: #4d5058;
	background-color: #fff;
	${({ active }) =>
		!active &&
		css`
			height: 26px;
		`}
	padding: 0 11px;
	border-radius: 4px 4px 0 0;
	margin: 0 2px;
	cursor: pointer;
`;

export const CaseStudyTabContent = styled.div`
	background: #fff;
	padding: 15px;
	border-radius: 0 4px 4px 4px;

	> p {
		margin: 8px 0;
	}
`;

export const MainDragOptions = styled.div`
	min-height: 100px;
	${({ isShowBorder }) => isShowBorder && "opactiy: 0.5;border: 2px solid #dbdee4;"}
	${({ changeOpacity }) => changeOpacity && "opactiy: 0.7;"}
`;

export const QuestionRationalInfo = styled.div`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #4d5058;
	white-space: normal;
	text-overflow: ellipsis;
	overflow: hidden;
	a,
	a:visited,
	a:focus,
	a:hover {
		text-decoration: underline;
		color: blue;
	}

	p:first-child {
		margin: 0;
		white-space: normal;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const BowTieGroupName = styled.div`
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #4d5058;
`;

export const QuestionResult = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	color: #4d5058;
`;

export const PreviewNotesText = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #4d5058;
`;

export const RowLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	border-right: solid 1px rgba(151, 151, 151, 0.3);
	height: 100%;
	align-items: center;
	width: 50%;
	padding-left: 22px;
	${props => props.theme.breakpoints.down("sm")} {
		width: 35%;
	}
`;

export const RowOptionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: center;
	width: 50%;
	flex: 1;
`;

export const AnswerContainer = styled.span`
	${props => props.theme.breakpoints.down("sm")} {
		font-size: ${props => props.theme.typography.pxToRem(12)};
	}
`;
