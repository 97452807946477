export const GLOBAL_CONSTANTS = {
	ENVIRONMENT: process.env.REACT_APP_ENVIROMENT,
	BACKEND_API: process.env.REACT_APP_BACKEND_API,
	MAIL_ENCRYPTION_LINK_KEY: process.env.REACT_APP_MAIL_ENCRYPTION_LINK_SECRET_KEY,
	CONTACT_US_EMAIL: process.env.REACT_APP_CONTACT_US_EMAIL,
	TERMS_AND_CONDITIONS_LINK: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK,
	PRIVACY_POLICY_LINK: process.env.REACT_APP_PRIVACY_POLICY_LINK,
	COOKIE_USE_LINK: process.env.REACT_APP_COOKIE_USE_LINK,
	LOCAL_STORAGE_PREFIX: process.env.REACT_APP_LOCAL_STORAGE_PREFIX,
	APP_URL: process.env.REACT_APP_URL || window.location.origin,
	STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
	REACT_APP_JOB_SECRET: process.env.REACT_APP_JOB_SECRET,
	GOOGLE_KEY: process.env.REACT_APP_GOOGLE_KEY,
	FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
	REACT_APP_FACEBOOK_PIXEL_ID: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
	MARKETING_WEBSITE: process.env.REACT_APP_MARKETING_WEBSITE,
	GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
	GOOGLE_AUTH_DOMAIN: process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
	GOOGLE_PROJECT_ID: process.env.REACT_APP_GOOGLE_PROJECT_ID,
	GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
	GOOGLE_STORAGE_BUCKET: process.env.REACT_APP_GOOGLE_STORAGE_BUCKET,
	GOOGLE_MESSAGING_SENDER_ID: process.env.REACT_APP_GOOGLE_MESSAGING_SENDER_ID,
	GOOGLE_APP_ID: process.env.REACT_APP_GOOGLE_APP_ID,
	GOOGLE_MEASUREMENT_ID: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
	REACT_APP_SAFARI_DISABLED: JSON.parse(process.env.REACT_APP_SAFARI_DISABLED || "false"),
	REACT_APP_MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE,
	SENTRY_DSN: process.env.REACT_APP_SENTRY_URL,
	SENTRY_TRACE_RATE: parseFloat(process.env.REACT_APP_SENTRY_TRACE_RATE || "0.1"),
	SENTRY_RATE: parseFloat(process.env.REACT_APP_SENTRY_RATE || "0.1"),
	REPLAYS_ONERROR_SAMPLE_RATE: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ONERROR_SAMPLE_RATE || "0"),
	CALLBACK_URL_PARAM: process.env.REACT_APP_CALLBACK_URL_PARAM || "callback",
	RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
	AWS_API_GATEWAY_KEY: process.env.REACT_APP_AWS_VIDEO_GATEWAY_X_API_KEY,
	AWS_API_GATEWAY_INVOKE_URL: process.env.REACT_APP_AWS_VIDEO_GATEWAY_INVOKE_URL,
	AWS_API_GATEWAY_API_PATH: process.env.REACT_APP_AWS_VIDEO_GATEWAY_API_PATH,
	AWS_API_GATEWAY_REGION: process.env.REACT_APP_AWS_VIDEO_GATEWAY_REGION,
	PAP_ACCOUNT_ID: process.env.REACT_APP_PAP_ACCOUNT_ID || "default1",
	AWS_ANALYTICS_PATH: process.env.REACT_APP_AWS_ANALYTICS_PATH,
	TOGGLE_SCHOOL_MODAL: JSON.parse(process.env.REACT_APP_TOGGLE_SCHOOL_MODAL || "false"),
	SALE_BANNERS: process.env.REACT_APP_SALE_BANNERS || "[]",
	JOBS_LINK: process.env.REACT_APP_JOBS_LINK || ""
};
