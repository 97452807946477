import React, { useEffect, useMemo, useState } from "react";

import { Card } from "@material-ui/core";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useAppDispatch, useAppSelector } from "store";
import { getUserData } from "store/features/Auth/authSlice";
import {
	CancelUserSubscription,
	getSubscriptionCancellationOptions,
	getSubscriptionInfo,
	selectFullState
} from "store/features/MyAccount/myAccountSlice";

import { CancellationOption } from "store/services";

import { CancellationActions } from "core/constants";

import CancelSelectedOptionComponent from "./CancelSelectedOptionComponent";

import CancellationOptions from "./CancellationOptions";

const INITIAL_STEP = 0;
const SUCCESS_OPTION: CancellationOption = {
	id: 100,
	text: "",
	data: {
		body: "Your discount will be applied automatically for your future subscription renewals.",
		heading: "Congrats! \n Your discount will be applied automatically",
		leftInfo: "$20 OFF \n on future renewals",
		subtext: "",
		info: "",
		illustration: "nclex-gift"
	},
	options: [
		{
			id: 101,
			text: "Done",
			primary: true,
			paymentAndDiscountId: null,
			cancellationReasonId: 0,
			data: {
				action: CancellationActions.ExitAfterDiscount
			}
		}
	]
};
const SubscriptionCancellation = ({ subscriptionId, onClose }: { subscriptionId: number; onClose: () => void }) => {
	const dispatch = useAppDispatch();
	const { isLoadingCancellationOptions, cancellationOptions, isCancellingSubscription } =
		useAppSelector(selectFullState);
	const [selectedOption, setSelectedOption] = useState<CancellationOption>({
		id: INITIAL_STEP
	} as CancellationOption);

	useEffect(() => {
		if (selectedOption.id === INITIAL_STEP && cancellationOptions.length === 0) {
			dispatch(getSubscriptionCancellationOptions());
		}
	}, [selectedOption]);

	const ActiveCancellationOption = useMemo(
		() => (selectedOption.id === INITIAL_STEP ? CancellationOptions : CancelSelectedOptionComponent),
		[selectedOption]
	);

	const handleAction = ({
		type,
		cancellationReasonId,
		option,
		link,
		paymentAndDiscountId
	}: {
		type: string;
		cancellationReasonId: number;
		option?: CancellationOption;
		link?: string;
		paymentAndDiscountId?: number | null;
	}) => {
		if (type === CancellationActions.Option) {
			setSelectedOption(option!);
			return;
		}
		if (type === CancellationActions.ExitAfterDiscount) {
			dispatch(getSubscriptionInfo());
			dispatch(getUserData());
			return;
		}

		if (type === CancellationActions.PrimaryOption) {
			setSelectedOption(option!);
		}

		let refetchData = type !== CancellationActions.PrimaryOption;
		if (paymentAndDiscountId) {
			refetchData = false;
		}
		dispatch(
			CancelUserSubscription({
				userSubscriptionId: subscriptionId,
				cancellationReasonId,
				refetchData,
				cb: () => {
					if (type === CancellationActions.Option) {
						window.open(link, "_blank");
					}
					if (type !== CancellationActions.PrimaryOption) {
						if (paymentAndDiscountId) {
							setSelectedOption(SUCCESS_OPTION);
							return;
						}
						onClose();
					}
				}
			})
		);
	};

	if (isLoadingCancellationOptions) {
		return (
			<Card>
				<ContentLoader />
			</Card>
		);
	}
	return (
		<ActiveCancellationOption
			action={handleAction}
			cancellationOptions={cancellationOptions}
			selectedOption={selectedOption}
			isCancellingSubscription={isCancellingSubscription}
		/>
	);
};

export default SubscriptionCancellation;
