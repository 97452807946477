import React from "react";

import { Box, Button, IconButton, TextareaAutosize, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { FeedbackSent } from "./icons";

import { BoxContainer, useStyles } from "./styles";

export const WritingPad = ({
	text,
	onClose,
	onTextChange,
	onSave,
	title,
	maxLength = 500,
	placeholder,
	disabled = false,
	saveOnBlur = false,
	buttonText = "Save",
	backgroundColor
}) => {
	const classes = useStyles({ backgroundColor });
	return (
		<Box className={classes.container}>
			{disabled && (
				<Box
					style={{
						objectFit: "contain",
						position: "absolute",
						paddingLeft: "100px",
						paddingTop: "100px",
						userSelect: "none",
						zIndex: 99
					}}
				>
					<FeedbackSent width={104} height={104} />
				</Box>
			)}
			<BoxContainer
				width={310}
				display="flex"
				alignItems="center"
				className={classes.header}
				style={{ ...(disabled ? { opacity: 0.65 } : {}) }}
			>
				<Typography className={classes.title}>{title}</Typography>
				<IconButton size="small" onClick={onClose}>
					<Close />
				</IconButton>
			</BoxContainer>
			<BoxContainer width={310} className={classes.body} disabled={disabled}>
				<TextareaAutosize
					placeholder={placeholder}
					style={{
						width: "100%",
						border: "solid 1px #edf1f7",
						fontFamily: "Poppins",
						fontSize: "13px",
						resize: "none"
					}}
					value={text}
					onChange={({ target: { value } }) => onTextChange(value)}
					onBlur={() => saveOnBlur && onSave()}
					maxRows={9}
					minRows={9}
					maxLength={maxLength}
					disabled={disabled}
				/>
				{!saveOnBlur && (
					<Box display="flex" justifyContent="flex-end" mt={1}>
						<Button
							disabled={!text.length || disabled}
							className={classes.sendButton}
							size="small"
							variant="contained"
							onClick={onSave}
						>
							{buttonText}
						</Button>
					</Box>
				)}
			</BoxContainer>
		</Box>
	);
};
