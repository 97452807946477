import React, { useCallback } from "react";

import { Box, Card, Chip, Typography } from "@material-ui/core";
import { format } from "date-fns";

import IconCertificate from "../../../assets/icons/icon-certificate.svg";

import { UserTypes } from "../../../constants";

import { usePDFThumbnail } from "../../../hooks/usePDFThumbnail";
import ContentLoader from "../../../layouts/TableContentLayout/components/ContentLoader";
import { CertificateData, ICertificate } from "../../../models/certificate.model";
import { formatDate } from "../../../utils/myAccountUtils";
import { downloadExternalFile } from "../../../utils/serviceUtils";
import Button from "../../Button";
import { Certificate, ChipsWrapper, DownloadCertificate, StyledIconCertificate } from "../styles";

const CertificateCard = ({
	courseCompleted,
	certificate,
	userType,
	certificateLogs
}: {
	courseCompleted: boolean;
	certificate: string;
	userType?: UserTypes;
	certificateLogs: ICertificate[];
}) => {
	const { thumbnail: pdfThumbnail, loading: loadingPdfThumbnail } = usePDFThumbnail({
		pdfUrl: certificate as string
	});
	const getChipLabel = useCallback((data: CertificateData): string | null => {
		const { isPhoneUpdated, isEmailUpdated, isNameUpdated } = data;
		if (isEmailUpdated) {
			return "email";
		} else if (isNameUpdated) {
			return "name";
		} else if (isPhoneUpdated) {
			return "phone";
		}
		return null;
	}, []);

	const isStudent = userType === UserTypes.RegularStudent;
	return (
		<Card style={{ height: "100%", display: "flex", justifyContent: "center" }}>
			{courseCompleted ? (
				<DownloadCertificate>
					<Box className={"certificate_download"}>
						<Typography>{isStudent ? "Your" : "Student"} Certificate</Typography>
						<Typography>
							{isStudent ? "You" : "Student"} have completed the coursework for the Virtual Trainer program!{" "}
							{isStudent && "Your dedication and hard work have paid off in a big way!"}
						</Typography>
						<Button variant="filled" color="primary" onClick={() => downloadExternalFile(certificate)} fullWidth>
							Download Certificate
						</Button>
					</Box>
					<Box className={"certificate"}>
						{loadingPdfThumbnail ? (
							<ContentLoader height={100} size={"55px"} />
						) : pdfThumbnail ? (
							<Box my={1}>
								<img width={140} height={160} src={pdfThumbnail} alt="PDF Thumbnail" />
							</Box>
						) : (
							<StyledIconCertificate src={IconCertificate} alt={"certificate"} />
						)}
					</Box>
					<Box className={"certificate_logs"}>
						<Typography color={"textPrimary"} className={"title"}>
							Certificate Logs
						</Typography>
						<Box className={"logsWrapper"}>
							{(certificateLogs || []).map(({ id, createdAt, data }) => {
								const chipLabel = getChipLabel(data);
								return (
									<Box key={id} display={"flex"} flexDirection={"column"} className={"logs"}>
										<Typography color={"textPrimary"}>Certificate for {data.fullName}</Typography>
										<ChipsWrapper>
											{chipLabel && <Chip label={chipLabel} size={"small"} className={"chip"} />}
											<Typography color={"textSecondary"}>
												{`${formatDate(createdAt as string)} at ${format(new Date(createdAt as string), "p")}`}
											</Typography>
										</ChipsWrapper>
									</Box>
								);
							})}
						</Box>
					</Box>
				</DownloadCertificate>
			) : (
				<Certificate>
					<StyledIconCertificate src={IconCertificate} alt={"certificate"} />
					<Typography>No certificate yet</Typography>
				</Certificate>
			)}
		</Card>
	);
};

export default CertificateCard;
