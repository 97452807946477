import React from "react";

import { Box, Checkbox, Divider, Typography } from "@material-ui/core";

const MultipleCheckboxFilter = ({ multipleCheckbox, setMultipleCheckbox }) => {
	const { allFilters, checkboxFilters } = multipleCheckbox;
	return (
		<Box>
			<Box display="flex" justifyContent={"center"} alignItems={"center"}>
				<Box>
					<Checkbox
						color="primary"
						checked={allFilters.checked}
						onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
							setMultipleCheckbox(prevState => ({
								...prevState,
								allFilters: {
									...prevState.allFilters,
									checked: event.target.checked
								}
							}))
						}
					/>
				</Box>
				<Box width={200}>
					<Typography>{allFilters.label}</Typography>
				</Box>
			</Box>
			<Divider />
			<Box display={"flex"} style={{ flexFlow: "row wrap" }}>
				{checkboxFilters.map(({ checked, label, id }) => (
					<Box mt={2} key={id} display={"flex"} justifyContent={"center"} alignItems={"center"}>
						<Box>
							<Checkbox
								color="primary"
								checked={checked}
								onChange={(event: React.ChangeEvent<{ checked: unknown }>) =>
									setMultipleCheckbox(prevState => {
										const checkboxList = [...prevState.checkboxFilters];
										const checkboxIndex = checkboxList.findIndex(checkbox => checkbox.id === id);
										if (checkboxIndex === -1) {
											return prevState;
										}
										checkboxList[checkboxIndex] = {
											...checkboxList[checkboxIndex],
											checked: event.target.checked
										};
										return {
											...prevState,
											checkboxFilters: checkboxList
										};
									})
								}
							/>
						</Box>
						<Box width={200}>
							<Typography>{label}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default MultipleCheckboxFilter;
