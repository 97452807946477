import { createTheme } from "@material-ui/core/styles";

import * as colors from "../../../colors";
import { IExtendedPaletteOptions, IExtendedTheme } from "../../../theme/default";

const { palette: defaultPalette } = createTheme();

const colorPalette: Partial<IExtendedPaletteOptions> = {
	background: {
		default: colors.basic[1000],
		paper: colors.basic[900]
	},
	common: {
		black: "#000",
		white: colors.basic[100],
		whiteShade1: "#f8f8f8",
		grey: colors.basic[600]
	},
	type: "dark",
	error: {
		...defaultPalette.error,
		tabs: defaultPalette.error
	},
	logo: {
		dark: "#417dc8",
		main: colors.basic[100]
	}
};

const theme: IExtendedTheme = createTheme({
	typography: {
		allVariants: {
			color: "#fff",
			fontFamily: "var(--openSans)"
		}
	},
	palette: {
		...colorPalette
	}
}) as IExtendedTheme;

export default theme;
