import { isBefore } from "date-fns";

export enum State {
	Active = "Active",
	Expired = "Expired",
	ComingSoon = "Coming Soon"
}

export const getState = (liveAfter: string, expiresOn: string): State => {
	if (isBefore(new Date(), new Date(liveAfter))) {
		return State.ComingSoon;
	} else if (isBefore(new Date(liveAfter), new Date(expiresOn)) && isBefore(new Date(), new Date(expiresOn))) {
		return State.Active;
	}

	return State.Expired;
};
