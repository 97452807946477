import React from "react";

import { Box } from "@material-ui/core";

import { MatrixTableBody, MatrixTableHeader, StyledGroupText } from "./styles";

import { checkHtmlIndex } from "../../../utils/highlightsUtils";

import { AnswerOptionRow, AnswerOptionsIndication, AnswerOptionsText, HighlightedContent } from "../styles";

const html2text = html => html.replace(/<[^>]+>/g, "").replace(/\s{2,}/g, " ");

const HighlightedTableRow = ({
	group: { answerOptions: groupAnswerOptions, text, title },
	selectedAnswers,
	percentageGrade,
	key: gIndex
}) => {
	let content = text;
	const answerOptions: { text: string; isCorrect: boolean }[] = [];

	groupAnswerOptions.forEach(answerOption => {
		const startIndex = content.indexOf(answerOption.text);

		if (startIndex === -1) {
			throw new Error(`Answer option '${answerOption.text}' not found in group text '${content}`);
		}

		const start = content.substr(0, startIndex);

		if (start) {
			answerOptions.push({ text: start, isCorrect: false });
		}

		answerOptions.push({ text: answerOption.text, isCorrect: true });

		content = content.substr(startIndex + answerOption.text.length);
	});

	if (content.length > 0) answerOptions.push({ text: content, isCorrect: false });

	content = answerOptions
		.map(({ text, isCorrect }) => (isCorrect ? `<span class="highlighted">${text}</span>` : text))
		.join("");
	const correctAnswers = answerOptions.filter(({ isCorrect }) => isCorrect);

	selectedAnswers.forEach(({ text }) => {
		let i = 0;
		let opened = false;
		const answerText = text.replace(/\s{2,}/g, " ").trim();

		while (i <= content.length - 1) {
			if (
				!opened &&
				html2text(content.substr(i)).startsWith(answerText) &&
				content.charAt(i) !== "<" &&
				checkHtmlIndex(content, i)
			) {
				const newChunk = '<i class="selected">';
				content = content.substr(0, i) + newChunk + content.substr(i);
				i += newChunk.length;
				opened = true;
			} else if (html2text(content.substr(0, i)).endsWith(answerText) && checkHtmlIndex(content, i)) {
				const newChunk = "</i>";
				content = content.substr(0, i) + newChunk + content.substr(i);
				i += newChunk.length;

				return;
			}

			i++;
		}
	});

	content = content
		.replace(
			/<span class="highlighted"><i class="selected">([^<]+)<\/i><\/span>/g,
			'<span class="highlighted selected">$1</span>'
		)
		.replace(/<i class="selected">([^<]+)<\/span>/g, '<i class="selected">$1</i></span><i class="selected">')
		.replace(
			/<span class="highlighted">([^<]+)<\/i>/g,
			'<span class="highlighted">$1</span></i><span class="highlighted">'
		);

	return (
		<Box borderBottom="solid 1px rgba(151, 151, 151, 0.3)" paddingBottom={1}>
			<Box key={gIndex} display="flex" flexDirection="row" width="100%">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<StyledGroupText $flex>{title}</StyledGroupText>
					<Box pl="16px" pt="13px" pr="16px" width="50%">
						<HighlightedContent dangerouslySetInnerHTML={{ __html: content }} />
					</Box>
				</Box>
			</Box>

			{percentageGrade === 1 ? (
				selectedAnswers.map(({ text }, index) => (
					<AnswerOptionRow key={`correct-${index}`} bgColor="#C4E3D2" $highlightTable>
						<AnswerOptionsIndication color="#23b34a">Correct Answer</AnswerOptionsIndication>
						<AnswerOptionsText>{text}</AnswerOptionsText>
					</AnswerOptionRow>
				))
			) : (
				<>
					{correctAnswers.map(({ text }, index) => {
						const isCorrect = selectedAnswers.some(answer => answer.text.trim() === text.trim());

						return (
							<AnswerOptionRow key={`correct-${index}`} bgColor={isCorrect ? "#C4E3D2" : "#c0c9e5"} $highlightTable>
								<AnswerOptionsIndication color={isCorrect ? "#23b34a" : "#264fae"}>
									{isCorrect ? "Correct Answer" : "Unmarked correct answer"}
								</AnswerOptionsIndication>
								<AnswerOptionsText>{text}</AnswerOptionsText>
							</AnswerOptionRow>
						);
					})}
					{selectedAnswers
						.filter(({ text }) => !correctAnswers.some(answer => text.trim() === answer.text.trim()))
						.map(({ text }, index) => (
							<AnswerOptionRow key={`incorrect-${index}`} bgColor="#faccd0" $highlightTable>
								<AnswerOptionsIndication color="#772a34">Incorrect answer</AnswerOptionsIndication>
								<AnswerOptionsText>{text}</AnswerOptionsText>
							</AnswerOptionRow>
						))}
				</>
			)}
		</Box>
	);
};

const HighlightTableResult = ({ result, question }) => {
	const {
		data: { tableLabel, groups, answerOptionLabel }
	} = question;

	return (
		<>
			<MatrixTableHeader display="flex" maxWidth={"595px"} flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<StyledGroupText $flex>{tableLabel}</StyledGroupText>
					<StyledGroupText $flex>{answerOptionLabel}</StyledGroupText>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody isinresults>
				{groups.map((group, gIndex) => (
					<HighlightedTableRow
						key={gIndex}
						group={group}
						selectedAnswers={result?.selectedAnswers.filter(answer => answer.questionGroupId === group.id) || []}
						percentageGrade={result?.percentageGrade}
					></HighlightedTableRow>
				))}
			</MatrixTableBody>
		</>
	);
};

export default HighlightTableResult;
