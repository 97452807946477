import * as Yup from "yup";

export const ContactFormSchema = Yup.object().shape({
	firstName: Yup.string().max(50, "It must be at most 50 characters long").required("Required").trim(),
	lastName: Yup.string().max(50, "It must be at most 50 characters long").required("Required").trim(),
	email: Yup.string().email("Invalid email").max(50, "It must be at most 50 characters long").required("Required"),
	text: Yup.string().required("Required").trim(),
	phoneNumber: Yup.string()
		.max(20, "Phone number must be at most 20 characters")
		.min(7, "Phone number must be at least 7 characters")
		.matches(/^\+?\d(\-?)(.[^+]*[0-9])?$/g, "Please enter valid phone number")
});
