import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as FolderSvg } from "@remar/shared/dist/assets/icons/icon-folder.svg";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import { EmptyState, Wrapper } from "@remar/shared/dist/layouts";
import { FileVaultFolder } from "@remar/shared/dist/models/fileVaultFolder.model";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { selectCurrentlyAllowedFullCourses } from "store/features/Auth/authSlice";
import { fetchFolders, getFullState, setSelectedFolder } from "store/features/FileVault/fileVault";

import { routes } from "core/constants";

import { CircularProgress, Folder, FolderTitle, FoldersContainer, RoundedIcon, SearchPanel, SvgIcon } from "../styles";

const FileVaultSubfolder = () => {
	const { folderId } = useParams<{ folderId: string }>();
	const dispatch = useAppDispatch();
	const analytics = useAnalyticsEventTracker("File Vault");

	const { isLoading, folderList: topLevelFolders, selectedFolder: currentFolder } = useAppSelector(getFullState);
	const currentlyAllowedFullCourses = useAppSelector(selectCurrentlyAllowedFullCourses);

	useEffect(() => {
		dispatch(setSelectedFolder(+folderId));
	}, [dispatch, topLevelFolders.length]);

	useEffect(() => {
		topLevelFolders.length === 0 &&
			currentlyAllowedFullCourses &&
			currentlyAllowedFullCourses?.length !== 0 &&
			dispatch(fetchFolders({ id: currentlyAllowedFullCourses![0]!.id, searchText: "" }));
	}, [dispatch, currentlyAllowedFullCourses?.length]);

	useEffect(() => {
		setSubFolders(currentFolder?.subFolders);
	}, [currentFolder]);

	const [inputValue, setInputValue] = useState("");
	const [subFolders, setSubFolders] = useState<FileVaultFolder[] | undefined>(currentFolder?.subFolders);

	const searchText = (searchText: string) => {
		const subFolderFiltered = currentFolder?.subFolders.filter(f => f.name.indexOf(searchText) > -1);
		setSubFolders(subFolderFiltered);
	};

	const breadcrumbs = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Course Resources", key: 1, link: routes.fileVault.getPath() },
			{ title: currentFolder?.name || "", key: 1, link: `${routes.fileVault.getPath()}/${currentFolder?.id}` }
		],
		[currentFolder?.id, currentFolder?.name]
	);

	return (
		<Wrapper heading="Course Resources" breadcrumb={breadcrumbs}>
			<SearchPanel>
				<div>{currentFolder && currentFolder.name}</div>
				{currentFolder && (
					<SearchBarComponent
						inputValue={inputValue}
						setInputValue={setInputValue}
						onChange={value => {
							searchText(value);
						}}
						isDebounced={false}
						placeHolder={"Type to filter by keywords"}
					/>
				)}
			</SearchPanel>

			{isLoading ? (
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			) : currentFolder && subFolders?.length === 0 ? (
				<EmptyState
					description="There are no folders yet"
					placeHolder={
						<SvgIcon>
							<FolderSvg />
						</SvgIcon>
					}
				/>
			) : (
				<FoldersContainer>
					{currentFolder &&
						subFolders?.map(folder => (
							<Folder key={`folder-${folder.id}`}>
								<RoundedIcon>
									<FolderSvg />
								</RoundedIcon>
								<FolderTitle
									to={`${routes.fileVault.getPath()}/${folderId}/subFolders/${folder.id}/files`}
									onClick={() => {
										analytics({
											eventName: "clicked_on_subfolder",
											eventIdentifier: `${folder.id}-${folder.name}`
										});
									}}
								>
									{folder.name}
								</FolderTitle>
							</Folder>
						))}
				</FoldersContainer>
			)}
		</Wrapper>
	);
};

export default FileVaultSubfolder;
