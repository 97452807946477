import { DragSourceMonitor, XYCoord } from "react-dnd";

export const getTouchBackendStyle = (position: XYCoord | null): {} => {
	return {
		zIndex: 999,
		left: 0,
		top: 0,
		position: "fixed",
		transform: `translate(${position?.x}px, ${position?.y}px)`,
		width: "70%",
		opacity: 0.5
	};
};

export const dragCollect = (monitor: DragSourceMonitor): { position; isDragging; opacity } => {
	return {
		isDragging: monitor.isDragging(),
		opacity: monitor.isDragging() ? 0.5 : 1,
		position: monitor.getClientOffset()
	};
};
