import React, { useEffect } from "react";

import { Box, Card, Grid, Typography } from "@material-ui/core";

import AccordionLessonsProgress from "@remar/shared/dist/components/CourseCompletion/AccordionLessonsProgress";
import CertificateCard from "@remar/shared/dist/components/CourseCompletion/CertificateCard";
import CourseProgressCard from "@remar/shared/dist/components/CourseCompletion/CourseProgressCard";
import NextCourseCard from "@remar/shared/dist/components/CourseCompletion/NextCourseCard";
import { useCourseCompletionDetails } from "@remar/shared/dist/components/CourseCompletion/useCourseCompletionDetails";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
	fetchCertificateLogs,
	fetchCourseCompletion,
	selectFullState,
	selectSubscriptionInfo
} from "store/features/MyAccount/myAccountSlice";

import { routes } from "../../../core/constants";
import { useLoadTrialCourses } from "../../../hooks/useLoadTrialCourses";

const breadcrumbs = [
	{ title: "My Account", key: 0, link: routes.myAccount.getPath() },
	{ title: "Course Completion", key: 1 }
];

const DetailedCourseCompletion = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { courseCompletion, isLoadingCourseCompletion, certificateLogs, isLoadingCertificateLogs } =
		useAppSelector(selectFullState);
	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);
	const { chapters, loading } = useLoadTrialCourses();
	const { nextLesson, sections } = useCourseCompletionDetails(
		courseCompletion ? { ...courseCompletion, course: { chapters } } : null
	);
	useEffect(() => {
		dispatch(fetchCourseCompletion());
	}, [dispatch]);

	useEffect(() => {
		if (subscriptionInfo && subscriptionInfo.subscription.userId) {
			dispatch(fetchCertificateLogs({ userId: subscriptionInfo.subscription.userId, typeId: 2 }));
		}
	}, [subscriptionInfo]);

	if (loading || isLoadingCourseCompletion || isLoadingCertificateLogs) {
		return <ContentLoader />;
	}

	return (
		<Wrapper heading={"Course Completion"} breadcrumb={breadcrumbs}>
			<Box mt={2}>
				<Grid container spacing={2}>
					<Grid container spacing={2} item xs={12}>
						<Grid item xs={12} lg={3}>
							<CourseProgressCard
								courseCompleted={courseCompletion?.isCompleted}
								percentage={courseCompletion?.percentage}
								courseName={subscriptionInfo?.subscription?.subscriptionName as string}
							/>
						</Grid>
						<Grid item xs={12} lg={3}>
							<NextCourseCard
								courseCompleted={courseCompletion?.isCompleted}
								nextLesson={nextLesson}
								ContinueCourse={() =>
									history.push(
										`${routes.lesson.getPath()}/${courseCompletion?.nextLessonSections![0].lesson?.id}/${
											courseCompletion?.nextLessonSections![0].sectionId
										}`
									)
								}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CertificateCard
								courseCompleted={courseCompletion?.isCompleted}
								certificate={courseCompletion?.certificatePdfUrl}
								certificateLogs={certificateLogs}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<Box mb={2}>
								<Typography style={{ fontSize: "18px", fontWeight: 700 }}>
									Lessons Required for Course Completion
								</Typography>
							</Box>
							{sections && (
								<AccordionLessonsProgress
									sections={sections}
									nextLesson={nextLesson}
									onLessonNavigation={(lessonId, sectionId) =>
										history.push(`${routes.lesson.getPath()}/${lessonId}/${sectionId}`)
									}
								/>
							)}
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Wrapper>
	);
};

export default DetailedCourseCompletion;
