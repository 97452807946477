import { request } from "@remar/shared/dist/api/request";

import { UserSubscriptionTypeExternalIntegrationDataItem } from "@remar/shared/dist/models";

import { CATPaymentInfo, UploadFileDto } from "./dto";

import { URLS } from "../services.constants";

export class GenericService {
	constructor() {}

	getImageUrl(data: { imageKey: string }): Promise<{ url: string }> {
		return request(`${URLS.GET_IMAGE_URL}?imageKey=${data.imageKey}`, "GET") as Promise<{
			url: string;
		}>;
	}

	upload(data: UploadFileDto): Promise<{ key: string; url: string }> {
		return request(URLS.UPLOAD, "POST", data as unknown as Record<string, unknown>) as Promise<{
			key: string;
			url: string;
		}>;
	}

	getCATPaymentInfo({ courseId }: { courseId: number }): Promise<CATPaymentInfo> {
		return request(`${URLS.USER_PAYMENTS}/get-cat?filters[course.id]=${courseId}`) as Promise<CATPaymentInfo>;
	}
	getBooks(): Promise<UserSubscriptionTypeExternalIntegrationDataItem> {
		return request(
			`${URLS.USER_PAYMENTS}/get-books`,
			"GET"
		) as Promise<UserSubscriptionTypeExternalIntegrationDataItem>;
	}
}

export const genericService = new GenericService();
