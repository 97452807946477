import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress, TextField, Typography, useTheme } from "@material-ui/core";

import { IValidateCouponResponse } from "../../models";

interface ICouponProps {
	onApply: (coupon: string) => void;
	invalidCoupon: boolean;
	isApplying: boolean;
	validatedCoupon: IValidateCouponResponse;
	resetCoupon: () => void;
}

const Coupon = ({ onApply, invalidCoupon = false, isApplying = false, validatedCoupon, resetCoupon }: ICouponProps) => {
	const [coupon, setCoupon] = useState("");
	const theme = useTheme();

	useEffect(() => {
		if (validatedCoupon && validatedCoupon.coupon) {
			setCoupon(validatedCoupon.coupon.code);
		}
	}, [validatedCoupon]);

	useEffect(() => {
		if (!coupon.length && invalidCoupon) {
			resetCoupon();
		}
	}, [coupon, resetCoupon]);

	return (
		<Box>
			<Box display={"flex"} alignItems={"center"} my={1}>
				<TextField
					fullWidth
					hiddenLabel
					InputProps={{
						style: { color: theme.palette.text.primary, background: theme.palette.background.paper, height: 45 },
						disableUnderline: true
					}}
					color="primary"
					onChange={(event: React.ChangeEvent<{ value: string }>) => {
						setCoupon(event.target.value);
					}}
					variant="filled"
					size="small"
					placeholder="Coupon"
					value={coupon}
				/>
				<Button
					variant={"contained"}
					color={"primary"}
					onClick={() => onApply(coupon)}
					disabled={!coupon.length || isApplying}
					startIcon={
						isApplying && <CircularProgress size="1rem" color="inherit" thickness={2} variant="indeterminate" />
					}
					style={{ marginLeft: "10px" }}
				>
					Apply
				</Button>
			</Box>
			{invalidCoupon && (
				<Box>
					<Typography style={{ color: theme.palette.warning.contrastText, fontSize: "12px" }}>
						This promo code is not valid
					</Typography>
				</Box>
			)}
			{validatedCoupon && validatedCoupon.coupon.data && (
				<Box>
					<Typography style={{ color: theme.palette.text.secondary, fontSize: "12px" }}>
						{`${
							validatedCoupon.coupon.data.isPercentage
								? validatedCoupon.coupon.data.amount + "%"
								: "$" + validatedCoupon.coupon.data.amount
						} Discount`}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default Coupon;
