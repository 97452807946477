import { Typography } from "@material-ui/core";

import { Link } from "react-router-dom";
import styled from "styled-components";

export const ForgotPasswordWrapper = styled.div`
	text-align: center;
	margin-top: 2rem;
`;

export const LinkText = styled(Link)`
	color: ${({ theme }) => theme.palette.primary.main};
	display: inline;
	font-size: 0.8rem;
	text-align: center;
`;

export const SecondaryText = styled(Typography)`
	color: ${({ $isLight }) => ($isLight ? "hsla(0,0%,100%,0.5)" : "hsl(220,19%,63%)")}; // todo add into colorset
	font-size: 0.8rem;
	margin-top: ${({ mt }) => mt || 0}rem;
	display: flex;
	justify-content: center;

	.margin-right {
		margin-left: 10px;
	}

	.inner-text {
		margin-left: 4px;
		font-size: 0.8rem;
	}
`;
