import React from "react";

import { Box, IconButton, Tooltip } from "@material-ui/core";

import { RemarBadge } from "../../../../../components/RemarBadge";
import { UserBadgeTypeEnum, UserInfo } from "../../../../../models";
import { getInitials } from "../../../../../utils/auth";
import { Avatar as StyledAvatar } from "../styles";

type CustomAvatarProps = {
	userInfo: Pick<UserInfo, "firstName" | "lastName" | "profileImageUrl"> | null;
	setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
	badgeData?: {
		type: UserBadgeTypeEnum;
		description: string;
	};
};
const Avatar = ({ setAnchorEl, userInfo, badgeData }: CustomAvatarProps) => {
	return (
		<Box position="relative">
			<StyledAvatar
				width={32}
				height={32}
				variant="rounded"
				src={userInfo?.profileImageUrl ?? null}
				onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
			>
				{getInitials(userInfo)}
			</StyledAvatar>
			{badgeData && (
				<Box position="absolute" top="-12px" right="-8px">
					<Tooltip title={badgeData?.description}>
						<IconButton size="small">
							<RemarBadge size="small" type={badgeData?.type} />
						</IconButton>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
};

export default Avatar;
