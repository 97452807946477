import React, { useEffect, useMemo } from "react";

import { Button, CssBaseline } from "@material-ui/core";
import { MuiThemeProvider, StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import { Themes } from "@remar/shared/dist/models/theme.types";
import { getSubDomainName } from "@remar/shared/dist/utils/getSubDomainName";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState, loadThemeByDomain } from "store/features/Theme/theme.slice";
import { ThemeProvider } from "styled-components";

import {
	darkTheme,
	dark_cyanBlueTheme,
	dark_greenTheme,
	dark_orangeTheme,
	dark_purpleTheme,
	dark_redTheme,
	dark_yellowTheme,
	lightTheme,
	light_cyanBlueTheme,
	light_greenTheme,
	light_orangeTheme,
	light_purpleTheme,
	light_redTheme,
	light_yellowTheme
} from "./default";

const generateClassName = createGenerateClassName({
	/* THIS!!! fixes the issue with wrong / duplicate styles due to improper classnames, e.g.:
	 * MuiBox-root-2 appearing in totally unrelated places and causing wrong style overwrites
	 */
	disableGlobal: true,
	seed: "ReMar"
});
const InitializeTheme = ({ children }) => {
	const dispatch = useAppDispatch();
	const { colorShade } = useAppSelector(getFullState);

	useEffect(() => {
		const hostName = window.location.hostname;
		const subDomain = getSubDomainName(hostName);

		if (subDomain) {
			dispatch(loadThemeByDomain({ subDomain: subDomain }));
		}
	}, [dispatch]);

	const selectedTheme = useMemo(() => {
		switch (colorShade) {
			case Themes.DARK_PURPLE: {
				return dark_purpleTheme;
			}
			case Themes.DARK_CYAN_BLUE: {
				return dark_cyanBlueTheme;
			}
			case Themes.DARK_GREEN: {
				return dark_greenTheme;
			}
			case Themes.DARK_YELLOW: {
				return dark_yellowTheme;
			}
			case Themes.DARK_ORANGE: {
				return dark_orangeTheme;
			}
			case Themes.DARK_RED: {
				return dark_redTheme;
			}
			case Themes.DARK_BLUE: {
				return darkTheme;
			}
			case Themes.LIGHT_BLUE: {
				return lightTheme;
			}
			case Themes.LIGHT_PURPLE: {
				return light_purpleTheme;
			}
			case Themes.LIGHT_CYAN_BLUE: {
				return light_cyanBlueTheme;
			}
			case Themes.LIGHT_GREEN: {
				return light_greenTheme;
			}
			case Themes.LIGHT_YELLOW: {
				return light_yellowTheme;
			}
			case Themes.LIGHT_ORANGE: {
				return light_orangeTheme;
			}
			case Themes.LIGHT_RED: {
				return light_redTheme;
			}
			default: {
				return darkTheme || lightTheme;
			}
		}
	}, [colorShade]);

	return (
		<>
			{/* THIS button is very important, as it fixes the css/jss injection order.
				    Previously, after some dependency updates, most data-jss style tags from MUI used to be injected AFTER the custom theme, overwriting it.
						With this button here, we force styled-components to inject all its stuff before
				 */}
			<Button style={{ display: "none", visibility: "hidden" }}></Button>
			<MuiThemeProvider theme={selectedTheme}>
				<StylesProvider injectFirst generateClassName={generateClassName}>
					<ThemeProvider theme={selectedTheme}>
						<CssBaseline />
						{children}
					</ThemeProvider>
				</StylesProvider>
			</MuiThemeProvider>
		</>
	);
};

export default InitializeTheme;
