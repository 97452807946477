type RouteTree<T> = Record<keyof T, ReturnType<typeof getRouteData>>;

export const buildRouteTree = <T extends {}>(rawTree: T): RouteTree<T> =>
	(function loop(tree, path: string[] = []): RouteTree<T> {
		return Object.keys(tree)
			.map(key => [key, tree[key]])
			.reduce((acc, [key, value]) => {
				const xPath: string[] = [...path, key];

				const routeData = getRouteData(xPath, key);

				if (value === null) {
					return { ...acc, [key]: routeData };
				}

				return {
					...acc,
					[key]: {
						...loop(value, xPath),
						...routeData
					}
				};
			}, {} as RouteTree<T>);
	})(rawTree);

const makeGetPath = (path: string) => (queryParams?: Record<string, string>) => {
	const params = queryParams
		? `?${Object.entries(queryParams)
				.map(x => x.join("="))
				.join("&")}`
		: "";
	return `${path}${params}`;
};

const getRouteData = (xPath: string[], key: string) => ({
	getPath: makeGetPath(`/${xPath.join("/")}`),
	getElementKey: () => key
});
