export const getTimeRemaining = (dateTime = "", durationInMinutes: number): number => {
	const startDateTime = new Date(dateTime);
	const endTime = startDateTime?.getTime() + durationInMinutes * 60 * 1000;
	const currentTime = Date.now();
	const result = +((endTime - currentTime) / 1000).toFixed(0);
	return result;
};

export const toMilliseconds = (min: number, sec: number): number => (min * 60 + sec) * 1000;
export const getRemainingMinutes = (num: number): number => Math.floor(num % 60);
export const getRemainingHours = (num: number): number => Math.floor(num / 60);
