import { Box, Button, createStyles, makeStyles } from "@material-ui/core";

import { CheckCircleOutline, ErrorOutline } from "@material-ui/icons";
import styled, { css } from "styled-components";

import * as colors from "../../colors";

import { IExtendedTheme } from "../../theme/default";

export const QuestionResultInfoContainer = styled.div`
	margin: ${({ isMobile }) => (isMobile ? "0px 24px" : "0px")};
	flex: 50%;
	overflow: hidden;
`;

export const CATButtonRow = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 24px;
	${({ fullWidth }) => (fullWidth ? "flex-direction: column" : "")}
`;

export const CATResultsTypography = styled.p`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.6;
	color: #4d5058;
	padding: 8px 0;
	margin: 0;
	${({ extraBottomPadding }) => extraBottomPadding && "padding-bottom: 24px"}

	${({ flexColumn }) =>
		flexColumn ? "display: flex; flex-flow: column nowrap; align-items: center; justify-content: center" : ""}

	${({ fullWidth }) => (fullWidth ? "width: 100%" : "")}
`;

export const CATContentAreaContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	${CATResultsTypography} {
		flex-grow: 1;
	}
`;
export const CATContentAreaDesc = styled(CATResultsTypography)`
	width: 100%;
	flex-shrink: 0;
	padding: 0;
`;

export const CATContentAreas = styled(CATResultsTypography)`
	font-weight: bold;
`;

export const CATContentAreaStatus = styled.div`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	white-space: nowrap;
	flex: 0;
	color: ${({ passed, medium }) => (passed ? "#23b34a" : medium ? "#e47e11" : "#ed5465")};
`;

export const CATExpand = styled.span`
	color: #4a99f9;
	font-weight: bold;
`;

export const CATResultsContainer = styled.div`
	padding: 24px;
`;

export const CATResultsHeader = styled.h1`
	padding: 0;
	margin: 0 0 ${({ noMargin }) => (noMargin ? 0 : "16px")};
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: #4d5058;
`;

export const CATTestStatus = styled.span`
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: ${({ passed }) => (passed ? "#23b34a" : "#ed5465")};
`;

export const NoResults = styled(Box)`
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #4d5058;
	font-size: 2rem;
`;

export const CorrectAnswersIcon = styled(CheckCircleOutline)`
	color: ${({ theme }) => theme.palette.colors.success[600]};
	margin-right: 5px;
	width: 32px;
	height: 32px;
`;
export const IncorrectAnswersIcon = styled(ErrorOutline)`
	color: ${({ theme }) => theme.palette.colors.danger[500]};
	margin-right: 5px;
	width: 32px;
	height: 32px;
`;
export const QuizButtonsRow = styled.div<{ bgColor: string }>`
	padding: 72px 16px 16px;
	background: ${({ theme }) => theme.palette.colors.basic[1500]};
	width: 100%;
	gap: 16px;
	display: flex;
	justify-content: flex-end;
`;
export const QuizeSequenceInCorrectContainer = styled.div`
	width: 100%;
	padding: 30px 0;
`;
export const QuizLessonTestTitle = styled.div`
	width: 100%;
	padding: 16px 16px;
	color: ${({ theme }) => theme.palette.common.white};
	background: ${({ theme }) => theme.palette.colors.primary[600]};
	opacity: 0.87;
	font: bold 18px/1.33 var(--poppins);
`;
export const QuizQuestionAnswerOption = styled.div`
	width: 100%;
	padding: 5px 16px;
	display: flex;
	align-items: center;
	font-family: var(--openSans);
	flex-flow: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
	${props => props.theme.breakpoints.down("sm")} {
		padding: 0;
	}
	${({ isChecked, isCorrect }) =>
		isCorrect && isChecked
			? css`
	background: rgba(35, 179, 74, .32);
	margin: 2px 0;
	> div:first-child  { color: ${({ theme }) => theme.palette.colors.success[600]}; {}
	`
			: isCorrect
			? css`
					background: ${({ theme }) => theme.palette.colors.primary[100]};
					margin: 2px 0;
					> div:first-child {
						color: ${({ theme }) => theme.palette.colors.primary[500]};
					}
			  `
			: isChecked
			? `
	margin: 2px 0;
	background: ${({ theme }) => theme.palette.colors.basic[200]};
	> div:first-child  { color: ${({ theme }) => theme.palette.colors.basic[1450]}; }
			`
			: ""}
`;

export const QuizQuestionContent = styled.div<{ offWhiteBg: boolean }>`
	width: 100%;
	flex-grow: 1;
	background: ${({ theme }) => theme.palette.colors.basic[1500]};
	padding: 16px 16px 0 16px;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
	display: flex;
	flex-flow: column;
	font-weight: 600;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.6;
	${({ offWhiteBg }) =>
		offWhiteBg &&
		css`
			background: #dbdee4;
		`}
`;

export const QuizQuestionText = styled.div`
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(13)};
	font-weight: 600;
	line-height: 1.85;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
	letter-spacing: normal;
	padding: 0 14px 14px;
`;

export const QuizQuestionTitle = styled.h1`
	padding: 14px 14px 8px;
	margin: 0;
	flex: 0;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
`;

export const QuizResultItemQuestion = styled.div`
	flex-grow: 1;
	border-bottom: 1px solid #b1b6c3;
	padding-bottom: 80px;
`;
export const QuizResultAnswer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	font-family: var(--poppins);
`;
export const QuizResultItem = styled.div`
	display: flex;
	width: 100%;
	&:not(:first-child) {
		margin-top: 26px;
	}
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
`;
export const QuizQuestionContainer = styled.div<{ minHeight: boolean }>`
	display: flex;
	flex-flow: row;
	width: 100%;
	${props => props.theme.breakpoints.down("sm")} {
		flex-direction: column;
	}
	${({ minHeight }) =>
		minHeight &&
		css`
			min-height: 400px;
		`}
`;

export const QuizResultItemRationale = styled.div`
	width: 436px;
	flex-grow: 0;
	flex-shrink: 0;
	background: ${({ theme }) => theme.palette.colors.basic[100]};
	border-radius: 4px;
	margin-left: 40px;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	overflow: hidden;

	> h1 {
		display: flex;
		align-items: center;
		font-size: ${props => props.theme.typography.pxToRem(15)};
		font-weight: 600;
		line-height: 1.6;
		letter-spacing: normal;
		color: ${({ theme }) => theme.palette.colors.basic[1450]};
	}

	${props => props.theme.breakpoints.down("sm")} {
		width: auto;
		margin-left: 0;
		margin-top: 10px;
	}
`;

export const QuizResultLabel = styled.div`
	flex: 0.3;
	font-weight: bold;
	font-family: var(--poppins);
`;

export const QuizResultPreviewNotes = styled.span`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
`;

export const QuizResultRationaleGrid = styled.div`
	margin: 25px 17px;
	display: flex;
	flex-flow: row nowrap;
`;

export const QuizResultRationaleImg = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	width: 200px;
	margin-left: 32px;
	position: relative;
	border-radius: 8px;
	overflow: hidden;

	> img {
		display: block;
		max-width: 100%;
	}

	> .overlay {
		display: none;
		background: rgba(0, 0, 0, 0.7);
		font-family: var(--openSans);
		font-size: ${props => props.theme.typography.pxToRem(12)};
		font-weight: 600;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		padding-top: 40%;
		flex-flow: column;
		color: ${({ theme }) => theme.palette.colors.basic[100]};
		cursor: pointer;

		> svg {
			display: block;
			margin: 0 auto;
		}
	}

	&:hover {
		> .overlay {
			display: block;
		}
	}
`;

export const QuizResultRationaleResult = styled.div`
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	line-height: 1.6;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
`;

export const QuizResultRationale = styled.div`
	margin-left: 5px;
`;
export const QuizResultRationaleText = styled.div`
	flex: 1 1 0;
	max-width: 100%;
	overflow: hidden;
`;
export const QuizResultTimeSpent = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const QuizResultTimeSpentText = styled.span`
	margin-left: 8px;
	font-family: var(--openSans);
	font-weight: normal;
`;
export const Zoom = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 100;
	padding: 5vh 5vw;

	> img {
		display: block;
		margin: 0 auto;
		max-height: 90vh;
		max-width: 90vw;
	}
`;

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center"
		},
		openSansFontFamily: {
			fontFamily: "var(--poppins)"
		},
		leftCol: {
			padding: "10px 8px 10px 0"
		},
		rightCol: {
			padding: "10px 0 10px 8px",
			[theme.breakpoints.down("sm")]: {
				padding: "10px 0px"
			}
		},
		notesHeader: {
			backgroundColor: theme.palette.colors.primary[600],
			color: theme.palette.colors.basic[100],
			backdropFilter: "blur(16px)",
			padding: 8,
			borderRadius: "4px 4px 0 0"
		},
		notesBody: {
			backdropFilter: "blur(16px)",
			border: "solid 1px rgba(77, 80, 88, 0.16)",
			backgroundColor: theme.palette.background.default,
			padding: 10,
			borderRadius: "0 0 4px 4px"
		},
		cancelButton: {
			position: "fixed",
			right: "16vw",
			top: "8vh"
		},
		scroll: {
			overflowY: "auto",
			maxHeight: "100vh",
			width: "100%",
			padding: "20px 0",
			display: "flex",
			flexFlow: "row nowrap",
			alignItems: "start",
			justifyContent: "center"
		},
		content: {
			maxWidth: "1140px",
			width: "100%",
			margin: "0 5%",
			background: theme.palette.colors.basic[1500]
		},
		header: {
			display: "flex",
			flexFlow: "row nowrap",
			alignItems: "center",
			justifyContent: "space-between",
			background: colors.primary["600"],
			color: theme.palette.colors.basic[100]
		},
		title: {
			lineHeight: "56px",
			padding: "0 16px",
			opacity: 0.87,
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(18)}"`,
			fontWeight: "bold"
		},
		row: {
			display: "flex",
			flexFlow: props => `${props["isMobile"] ? "column" : "row nowrap"}`,
			width: "100%",
			gap: "16px"
		},
		questionNumber: {
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			fontStretch: "normal",
			fontStyle: "normal",
			letterSpacing: "normal",
			color: "#4d5058",
			fontFamily: "var(--openSans)"
		},
		col: {
			flexBasis: "50%",
			margin: props => `${props["isMobile"] ? "0 24px" : 0}`
		},
		halfCol: {
			flexBasis: "50%",
			margin: props => `${props["isMobile"] ? "0 24px" : 0}`
		},
		fullCol: {
			flexBasis: "100%",
			margin: props => `${props["isMobile"] ? "0 24px" : 0}`
		},
		tutoredTestResultCol: {
			flexBasis: "50%",
			padding: props => `${props["isMobile"] ? "16px 16px 0 16px" : 0}`
		},
		resultInfo: {
			flexBasis: "50%"
		},
		text: {
			fontSize: `${theme.typography.pxToRem(13)}`,
			fontWeight: 600,
			lineHeight: 1.85
		},
		questionText: {
			fontFamily: "var(--openSans)",
			fontSize: `"${theme.typography.pxToRem(13)}"`,
			fontWeight: 600,
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: 1.85,
			letterSpacing: "normal",
			color: theme.palette.colors.basic[1450]
		},
		questionContainer: {
			gap: "16px",
			display: "block",
			padding: "24px 0",
			margin: props => `${props["isMobile"] ? 0 : "0 24px"}`,
			color: theme.palette.colors.basic[1450],
			"&:not(:last-of-type)": {
				borderBottom: "solid 1px #b1b6c3"
			}
		},
		correct: {
			"& $radioLabel, & svg": {
				color: theme.palette.colors.primary[500]
			},
			"&$your $radioLabel, &$your svg": {
				color: theme.palette.colors.success[600]
			}
		},
		correctBg: {
			backgroundColor: theme.palette.colors.green[300],
			borderRadius: "4px",
			height: "60px",
			[theme.breakpoints.down("sm")]: {
				height: "auto"
			}
		},
		yourBg: {
			backgroundColor: theme.palette.colors.danger[100],
			borderRadius: "4px",
			height: "60px",
			[theme.breakpoints.down("sm")]: {
				height: "auto"
			}
		},
		gap5: {
			gap: "5px"
		},
		correctOptBg: {
			borderRadius: "4px",
			height: "60px",
			backgroundColor: theme.palette.colors.primary[100],
			[theme.breakpoints.down("sm")]: {
				height: "auto"
			}
		},
		correctAnsText: {
			paddingLeft: "7px",
			color: theme.palette.colors.success[600],
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6
		},
		correctOptText: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			color: theme.palette.colors.primary[500],
			paddingLeft: "7px"
		},
		yourAnsText: {
			paddingLeft: "7px",
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			color: theme.palette.colors.red[650]
		},
		optText: {
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			paddingLeft: "7px",
			fontWeight: 600,
			lineHeight: 1.6,
			color: theme.palette.colors.basic[1450],
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			maxWidth: "calc(100% - 10px)"
		},
		your: {
			"& svg, $radioLabel": {
				color: theme.palette.colors.red[650]
			}
		},
		radioLabel: {
			flexBasis: "100%",
			textAlign: "center",
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			paddingBottom: "8px",
			fontFamily: "var(--poppins)",
			color: theme.palette.colors.primary[500],
			[theme.breakpoints.down("sm")]: {
				paddingRight: "2px",
				paddingLeft: "2px",
				height: "100%"
			}
		},
		rationaleContainer: {
			borderRadius: "3px",
			background: theme.palette.colors.basic[100],
			padding: "16px"
		},
		rationaleContainerTutoredTest: {
			borderRadius: "3px",
			background: theme.palette.colors.basic[100],
			padding: "16px",
			height: "100%"
		},
		rationaleHeader: {
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			lineHeight: 1.6,
			display: "flex",
			alignItems: "center",
			"&:first-child": {
				marginBottom: "16px"
			}
		},
		rationaleImage: {
			borderRadius: "5px",
			height: props => `${props["isMobile"] ? "150px" : "120px"}`
		},
		icon: {
			marginRight: "8px"
		},
		timer: {
			fontSize: `"${theme.typography.pxToRem(13)}"`,
			lineHeight: 1.23,
			color: theme.palette.colors.basic[1450],
			display: "flex",
			alignItems: "center"
		},
		textArea: {
			width: "100%",
			border: "solid 1px #edf1f7",
			fontFamily: "var(--poppins)",
			fontSize: `"${theme.typography.pxToRem(13)}"`
		},
		notesTypography: {
			fontSize: `"${theme.typography.pxToRem(15)}"`,
			fontWeight: 600,
			flexGrow: 1
		},
		purple: {
			backgroundColor: theme.palette.colors.primary[100]
		},
		green: {
			backgroundColor: theme.palette.colors.green[300]
		},
		grey: {
			backgroundColor: theme.palette.colors.danger[100]
		},
		transparent: {
			backgroundColor: "transparent"
		},
		card: {
			borderRadius: "5px"
		}
	})
);

export const OKButton = styled(Button)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	color: ${props => props.theme.palette.text.primary};
`;

export const AnswerOption = styled(Box)`
	display: flex;
	min-height: 48px;
	border-radius: 4px;
	margin: 10px 10px 10px 0;
	font-family: var(--openSans);
`;

export const NotesContainer = styled.div`
	display: flex;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	align-items: center;
	margin-bottom: 10px;
	cursor: pointer;
`;

export const CorrectAnswer = styled.img`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const WrongAnswer = styled.img`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const PercentageContainer = styled.div`
	display: flex;
	font-size: ${props => props.theme.typography.pxToRem(13)};
	align-items: center;
	margin-bottom: 10px;
	margin-left: 3px;
	> span {
		padding-left: 2px;
	}
`;

export const NotAttempted = styled.img`
	margin-right: 10px;
	width: 24px;
	height: 24px;
`;

export const AnswerOptionRow = styled(Box)<{ color: string; bgColor: string; opacity: number }>`
	padding: 15px 0 15px 0;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: bold;
	font-stretch: normal;
	width: 100%;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	border-radius: 4px;
	opacity: ${({ opacity }) => opacity};
	color: ${({ color }) => color};
	background-color: ${({ bgColor }) => bgColor};
	display: flex;
	${({ $highlightTable, isMobile }) =>
		$highlightTable
			? css`
					margin: 10px 1px 0;
			  `
			: css`
					margin: ${isMobile ? "10px 0px 0" : "10px 5px 0"};
			  `}
`;

export const HighlightedContent = styled.div`
	> .highlighted {
		background: #4c69ba;
		color: ${colors.basic[100]};
		padding: 3px;
		border-radius: 3px;

		&.selected {
			background: #189646;
		}

		> i {
			background: ${({ theme }) => theme.palette.colors.primary[2000]};
			margin: 0 -3px;
			color: ${colors.basic[100]};
		}
	}
	> .selected:not(.highlighted) {
		background: ${({ theme }) => theme.palette.colors.danger[100]};
		color: ${({ theme }) => theme.palette.colors.red[650]};
	}

	i {
		font-style: normal;
		padding: 3px;
		border-radius: 3px;

		> .highlighted {
			background: ${({ theme }) => theme.palette.colors.primary[2000]};
			margin: 0 -3px;
			color: ${colors.basic[100]};
			padding: 3px;
			border-radius: 3px;
		}
	}
`;

export const AnswerOptionsIndication = styled(Box)<{ color: string }>`
	color: ${({ color }) => color};
	width: ${({ isMobile, theCorrectAnswer, yourAnswer }) =>
		isMobile && !theCorrectAnswer && !yourAnswer ? "0%" : "50%"};
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	font-family: var(--poppins);
	padding-left: ${({ isMobile }) => (isMobile ? 0 : "8px")};
`;

export const AnswerOptionsText = styled(Box)`
	color: ${({ theme }) => theme.palette.colors.basic[1450]};
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	width: 50%;
	padding-right: 8px;
	font-family: var(--poppins);
`;

export const CancelButton = styled(Button)`
	font-size: ${props => props.theme.typography.pxToRem(16)};
	color: ${props => props.theme.palette.text.primary};
	font-weight: bold;
	margin-right: 15px;
`;
