import React from "react";

import { AnswerOptionRow, AnswerOptionsIndication, AnswerOptionsText, HighlightedContent } from "../styles";
import { checkHtmlIndex } from "../utils/highlightsUtils";

const html2text = html => html.replace(/<[^>]+>/g, "").replace(/\s{2,}/g, " ");

const HotspotHighlightResult = ({
	result,
	question: {
		data: { answerOptions }
	}
}) => {
	let content = answerOptions
		.map(({ text, isCorrect }) => (isCorrect ? `<span class="highlighted">${text}</span>` : text))
		.join("");

	const correctAnswers = answerOptions.filter(({ isCorrect }) => isCorrect);

	result?.selectedAnswers.forEach(({ text }) => {
		let i = 0;
		let opened = false;
		const answerText = text.replace(/\s{2,}/g, " ").trim();

		while (i <= content.length - 1) {
			if (
				!opened &&
				html2text(content.substr(i)).startsWith(answerText) &&
				content.charAt(i) !== "<" &&
				checkHtmlIndex(content, i)
			) {
				const newChunk = '<i class="selected">';
				content = content.substr(0, i) + newChunk + content.substr(i);
				i += newChunk.length;
				opened = true;
			} else if (html2text(content.substr(0, i)).endsWith(answerText) && checkHtmlIndex(content, i)) {
				const newChunk = "</i>";
				content = content.substr(0, i) + newChunk + content.substr(i);
				i += newChunk.length;

				return;
			}

			i++;
		}
	});

	content = content
		.replace(
			/<span class="highlighted"><i class="selected">([^<]+)<\/i><\/span>/g,
			'<span class="highlighted selected">$1</span>'
		)
		.replace(/<i class="selected">([^<]+)<\/span>/g, '<i class="selected">$1</i></span><i class="selected">')
		.replace(
			/<span class="highlighted">([^<]+)<\/i>/g,
			'<span class="highlighted">$1</span></i><span class="highlighted">'
		);

	return (
		<>
			<HighlightedContent dangerouslySetInnerHTML={{ __html: content }} />
			{result?.percentageGrade === 1 ? (
				result?.selectedAnswers.map(({ text }, index) => (
					<AnswerOptionRow key={`correct-${index}`} bgColor="#C4E3D2">
						<AnswerOptionsIndication color="#23b34a">Correct Answer</AnswerOptionsIndication>
						<AnswerOptionsText>{text}</AnswerOptionsText>
					</AnswerOptionRow>
				))
			) : (
				<>
					{correctAnswers.map(({ text }, index) => {
						const isCorrect = result?.selectedAnswers.some(answer => answer.text.trim() === text.trim());

						return (
							<AnswerOptionRow key={`correct-${index}`} bgColor={isCorrect ? "#C4E3D2" : "#c0c9e5"}>
								<AnswerOptionsIndication color={isCorrect ? "#23b34a" : "#264fae"}>
									{isCorrect ? "Correct Answer" : "Unmarked correct answer"}
								</AnswerOptionsIndication>
								<AnswerOptionsText>{text}</AnswerOptionsText>
							</AnswerOptionRow>
						);
					})}
					{result?.selectedAnswers
						.filter(({ text }) => !correctAnswers.some(answer => text.trim() === answer.text.trim()))
						.map(({ text }, index) => (
							<AnswerOptionRow key={`incorrect-${index}`} bgColor="#faccd0">
								<AnswerOptionsIndication color="#772a34">Incorrect Answer</AnswerOptionsIndication>
								<AnswerOptionsText>{text}</AnswerOptionsText>
							</AnswerOptionRow>
						))}
				</>
			)}
		</>
	);
};

export default HotspotHighlightResult;
