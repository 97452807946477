import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import ReactPixel from "react-facebook-pixel";

import { useDispatch } from "react-redux";

import { resetCoupon } from "store/features/Auth/authSlice";

import { PixelEvent, setTiktokEvent } from "modules/utils";

import { useStyles } from "./styles";

interface SignUpSuccessLeftProps {
	trial?: boolean;
	signupSuccessUserData?: SignupSuccessUserDto;
}

export interface SignupSuccessUserDto {
	courseName?: string;
	price?: string;
	fullName?: string;
	email?: string;
	shipping?: string;
	discount?: string | null;
	shippingApplicable?: boolean;
	addOnsSub?: {
		name?: string;
		price?: string;
	}[];
}

const SignUpSuccessLeft: React.FC<SignUpSuccessLeftProps> = ({ signupSuccessUserData: data, trial }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	useEffect(() => {
		if (!trial) {
			ReactPixel.track(PixelEvent.Purchase, { ...data, value: data!.price?.replace("$", ""), currency: "USD" });
			setTiktokEvent(PixelEvent.CompletePayment, {
				...data,
				content_id: data?.email,
				value: data!.price?.replace("$", ""),
				currency: "USD"
			});
		} else if (trial) {
			ReactPixel.track(PixelEvent.StartTrial, { ...data, value: 0.0, currency: "USD" });
		}
	}, [data, trial]);

	useEffect(() => {
		return () => {
			dispatch(resetCoupon());
		};
	}, [dispatch]);

	return (
		<Box display="flex" justifyContent="center">
			<Box display="flex" justifyContent="center" flexDirection="column" minHeight="40em">
				<Box display="flex" justifyContent="center">
					<CheckCircle style={{ fill: "#30d14d", width: "64px", height: "64px" }} />
				</Box>
				{trial ? (
					<>
						<Typography className={classes.errorTitle}>{"Your Free Trial is Ready"}</Typography>
						<Box mt={2} width="100%" textAlign="center" className={classes.containerGap}>
							<Typography className={classes.successDesc}>
								Please open the confirmation Email to enjoy your course!
							</Typography>
						</Box>
					</>
				) : (
					<>
						<Typography className={classes.errorTitle}>{"Payment Successful"}</Typography>

						<Box mt={4} width="100%" textAlign="center" className={classes.containerGap}>
							<Typography className={classes.successDesc}>Thanks for your purchase!</Typography>
						</Box>

						<Box display="flex" mt={4} width="100%" className={classes.containerGap}>
							<Typography className={classes.successLeftContainerText}>{data?.courseName}</Typography>
							<Typography className={classes.successRightContainerText}>{data?.price}</Typography>
						</Box>

						{data?.addOnsSub?.length && (
							<>
								{data?.addOnsSub.map((x, i) => (
									<Box key={i} display="flex" mt={2} width="100%" className={classes.containerGap}>
										<Typography className={classes.successLeftContainerText}>{x.name}</Typography>
										<Typography className={classes.successRightContainerText}>{x.price}</Typography>
									</Box>
								))}
							</>
						)}

						{data?.shippingApplicable && data?.shipping && (
							<Box display="flex" mt={2} width="100%" className={classes.containerGap}>
								<Typography className={classes.successLeftContainerText}>{"Shipping"}</Typography>
								<Typography className={classes.successRightContainerText}>{data.shipping}</Typography>
							</Box>
						)}
						{data?.discount && (
							<Box display="flex" mt={2} width="100%" className={classes.containerGap}>
								<Typography className={classes.successLeftContainerText}>{"Discount"}</Typography>
								<Typography className={classes.successRightContainerText}>-{data.discount}</Typography>
							</Box>
						)}

						<Box display="flex" mt={2} width="100%" className={classes.containerGap}>
							<Typography className={classes.successLeftContainerText}>{data?.fullName}</Typography>
							<Typography className={classes.successRightContainerText}>{data?.email}</Typography>
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default SignUpSuccessLeft;
