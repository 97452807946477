import { GLOBAL_CONSTANTS } from "../../constants";

const isSupported = (): boolean => {
	try {
		const key = "test_ls_key";
		localStorage.setItem(key, "test value");
		localStorage.removeItem(key);
		return true;
	} catch {
		console.warn("Local storage not supported!");
		return false;
	}
};

class LocalStorage {
	private isLocalStorageAvaliable: boolean;

	constructor() {
		this.isLocalStorageAvaliable = isSupported();
	}

	set = (key: string, value: string): void => {
		if (!this.isLocalStorageAvaliable) return;

		localStorage.setItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`, value);
	};

	private safelyParseJSON(json: string): Record<string, unknown> | undefined {
		let parsed;

		try {
			parsed = JSON.parse(json);
		} catch {
			// values like token is not json string. for such cases we need silent catch here
		}

		return parsed;
	}

	get = (key: string, defaultValue: unknown): unknown => {
		if (!this.isLocalStorageAvaliable) return defaultValue;

		const data = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`);

		try {
			return data ? this.safelyParseJSON(data) || data : defaultValue;
		} catch (e) {
			console.error(
				`Error while parsing local storage data for ${key}. Message: ${(e as Error).message}. Stack: ${
					(e as Error).stack
				}`
			);
			return data || defaultValue;
		}
	};

	remove = (key: string): void => {
		if (!this.isLocalStorageAvaliable) return;

		localStorage.removeItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`);
	};
}

const storage = new LocalStorage();

export { LocalStorage };
export default storage;
