// noinspection HtmlUnknownBooleanAttribute

import React, { FC, useEffect, useMemo, useRef } from "react";

import { Box } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { ReactComponent as DiscountIcon } from "@remar/shared/dist/assets/icons/icon-coupon-multicolour-light.svg";
import Button from "@remar/shared/dist/components/Button";
import { CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";
import cn from "classnames";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { applyValidatedCoupon, selectInactiveSubscription, setUpgradeModal } from "store/features/Auth/authSlice";
import { selectSubscriptionInfo } from "store/features/MyAccount/myAccountSlice";
import { getFullState as getFullNotificationsState } from "store/features/notifications/notifications.slice";
import videojs from "video.js";

import { routes } from "core/constants";

import { TrailerContainer, TrailerHeading, TrailerText, useStyles } from "./style";

interface Props {
	url: string;
	isTrial: boolean;
	sectionLesson: CourseChapterSectionLesson;
	allLessons: CourseChapterSectionLesson[];
}

export const Trailer: FC<Props> = ({
	url,
	sectionLesson: { isLockedByPayment, isLockedByProgress },
	allLessons,
	isTrial = false
}) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const videoRef = useRef(null);
	const player = useRef<videojs.Player>();
	const { bannerData } = useAppSelector(getFullNotificationsState);
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);
	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);

	useEffect(() => {
		player.current = player.current || getVideoPlayer(videoRef!.current!, url, { controls: false });
		return () => {
			player.current = undefined;
		};
	}, [url]);

	const getLatestLesson = () => {
		const lockedLessonIndex = allLessons.findIndex(({ isLockedForCurrentUser }) => isLockedForCurrentUser);
		const latestLessonIndex =
			lockedLessonIndex == -1
				? allLessons.length - 1
				: lockedLessonIndex === 0
				? lockedLessonIndex
				: lockedLessonIndex - 1;
		return allLessons[latestLessonIndex];
	};

	const handleRoute = () => {
		if (isLockedByPayment) {
			if (bannerData?.coupon) {
				dispatch(applyValidatedCoupon(bannerData?.coupon));
			}
			dispatch(setUpgradeModal(true));
		} else {
			const currentUnlockedLesson = getLatestLesson();
			history.push(`${routes.lesson.getPath()}/${currentUnlockedLesson.lessonId}/${currentUnlockedLesson.sectionId}`);
		}
	};

	const upgradeRenewText = useMemo(
		() =>
			subscriptionInfo?.subscription.isCancelled || isRenewEnabled ? "Renew Subscription" : "Upgrade Subscription",
		[subscriptionInfo?.subscription.isCancelled, isRenewEnabled]
	);

	return (
		<div data-vjs-player className={classes.trailerVideoDiv}>
			<TrailerContainer>
				<TrailerHeading>Lesson Locked</TrailerHeading>
				<TrailerText>
					{`To unlock this lesson, ${
						isLockedByPayment
							? !isTrial
								? "you need to start your subscription"
								: "you need to upgrade your subscription"
							: "you need to complete the previous unlocked lesson"
					} `}
				</TrailerText>
				<div>
					<Button onClick={handleRoute} variant={"ghost"}>
						{isLockedByProgress && !isLockedByPayment && (
							<span>
								<ChevronLeft />
							</span>
						)}
						<span
							className={cn({
								[classes.trailerProgress]: isLockedByProgress && !isLockedByPayment,
								[classes.trailerSubscription]: isLockedByPayment
							})}
						>
							{isLockedByPayment ? (
								isTrial ? (
									<>
										{!!bannerData?.coupon && (
											<Box display={"flex"} mr={1}>
												<DiscountIcon />
											</Box>
										)}
										{upgradeRenewText}
									</>
								) : null
							) : (
								"Complete Previous Lesson"
							)}
						</span>
					</Button>
				</div>
				<div>
					<Button color="basic" onClick={() => player.current?.play()}>
						Watch Again
					</Button>
				</div>
			</TrailerContainer>
			<video autoPlay ref={videoRef} className="video-js vjs-big-play-centered" />
		</div>
	);
};
