import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import {
	createForm,
	getPopulateInputsAction,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { pendingReducer, rejectReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { AppThunk, RootState } from "store";

import { NoteState, noteService } from "store/services";

import { NoteFormInputs, NoteFormRawData } from "./models";

import { emit } from "../notifications/notifications.slice";

export const initialState: NoteState = {
	isLoading: false,
	error: "",
	path: [],
	notes: [],
	page: 1,
	perPage: 10,
	totalItems: 0,
	totalCount: 0,
	noteForm: createForm<NoteFormInputs, NoteFormRawData, {}>({
		defaultValueGetters: {},
		statePath: "noteForm",
		inputs: {
			id: { type: CustomInputType.Number },
			name: {
				label: "Enter Note Name",
				placeholder: "Enter Note Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 140 }
			},
			text: {
				label: "Enter Note Text",
				placeholder: "Enter Note Text",
				type: CustomInputType.Editor,
				validations: { required: true, maxLength: 5000 }
			}
		}
	})
};

const utilsResetState = getResetState<NoteState>(initialState);
const utilsPopulateInputs = getPopulateInputsAction<NoteState>({});

export const fetchNotes = createAsyncThunk(
	"notes/fetchNotes",
	async (
		{ page: optPage, perPage: optPerPage, searchText }: { page?: number; perPage?: number; searchText?: string },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			const { page, perPage } = (getState() as RootState).notes;
			return await noteService.find({
				page: optPage || page,
				perPage: optPerPage || perPage,
				orderBy: { id: "DESC" },
				...(searchText && {
					filters: {
						name: {
							$ilike: `%${searchText}%`
						}
					}
				})
			});
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
			return rejectWithValue(e.message);
		}
	}
);

export const createNote = createAsyncThunk(
	"note/createNote",
	async (options: { sideEffect: () => void }, { dispatch, getState, rejectWithValue }) => {
		await dispatch(setStateValue({ key: "isLoading", value: true }));
		try {
			const data = (getState() as RootState).notes.noteForm.rawData;
			if (data.id) {
				const { id, ...rest } = data;
				await noteService.update({ filters: { id }, data: { ...rest } });
			} else await noteService.create({ ...data });
			options.sideEffect();
		} catch (e) {
			dispatch(emit({ message: "An error has occured.", color: "error" }));
			return rejectWithValue(e.message);
		} finally {
			await dispatch(setStateValue({ key: "noteForm", value: initialState.noteForm }));
			dispatch(fetchNotes({}));
		}
	}
);

export const deleteNote =
	(id: number): AppThunk =>
	async dispatch => {
		try {
			await dispatch(setStateValue({ key: "isLoading", value: true }));
			await noteService.delete({ filters: { id } });
			await dispatch(resetState());
			dispatch(fetchNotes({}));
		} catch (e) {
			console.log(e);
		}
	};

export const noteSlice = createSlice({
	name: "note",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction,
		populateInputs: utilsPopulateInputs,
		resetState: utilsResetState
	},
	extraReducers: {
		[fetchNotes.pending.type]: state => pendingReducer(state, "isLoading"),
		[fetchNotes.fulfilled.type]: (state, { payload }) => {
			const { page: newPage, perPage: newPerPage, items, totalItems } = payload;
			state.page = newPage;
			state.perPage = newPerPage;
			state.notes = items;
			state.totalItems = totalItems;
			if (!state.totalCount) {
				state.totalCount = totalItems;
			}
			state.isLoading = false;
		},
		[fetchNotes.rejected.type]: rejectReducer
	}
});

export const getFullState = ({ notes }: RootState): NoteState => notes;

export const { validateForm, setStateValue, populateInputs, resetState } = noteSlice.actions;

export default noteSlice.reducer;
