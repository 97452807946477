import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import { ReactComponent as CheckMarkActive } from "@remar/shared/dist/assets/icons/icon-checkmark-filled-active.svg";
import Button from "@remar/shared/dist/components/Button";

import { useAppSelector } from "store";
import { getFullState } from "store/features/Auth/authSlice";

import { ReactComponent as GiftIllustration } from "assets/images/content-illustrations-gift-blue.svg";

import { ActNowWrapper } from "./styles";

const ActNow = ({ claimDeal, ignoreDeal }) => {
	const { paymentDiscount } = useAppSelector(getFullState);
	const discountAmount = useMemo(() => {
		if (paymentDiscount) {
			const amount = paymentDiscount.data.amount;
			return paymentDiscount.data.isPercentage ? amount + "%" : "$" + amount;
		}
		return "";
	}, [paymentDiscount]);

	return (
		<ActNowWrapper>
			<Box my={2} display={"flex"} justifyContent={"center"}>
				<GiftIllustration />
			</Box>
			<Typography align={"center"} className={"text"}>
				{discountAmount} Off <br />
				Upgrade today only!
			</Typography>
			<Typography align={"center"} className={"sub-text"}>
				Upgrade now to get {discountAmount} off, 3 months full access, two essential nursing books, and insights from
				the top NCLEX expert – an exclusive offer just for our trial users!
			</Typography>
			<Box my={2} className={"deal-features"}>
				<Typography>
					<CheckMarkActive />3 Month Full Access
				</Typography>
				<Typography>
					<CheckMarkActive /> 2 Physical Books
				</Typography>
				<Typography>
					<CheckMarkActive /> 1 Experienced NCLEX Expert
				</Typography>
			</Box>
			<Box mt={2}>
				<Button variant={"filled"} color={"primary"} fullWidth onClick={claimDeal}>
					Claim deal now
				</Button>
				<Box mt={1}>
					<Button variant={"ghost"} color={"secondary"} fullWidth onClick={ignoreDeal}>
						Skip the deal
					</Button>
				</Box>
			</Box>
		</ActNowWrapper>
	);
};

export default ActNow;
