const warning = {
	100: "hsl(51,100%,93%)",
	200: "hsl(50,100%,85%)",
	300: "hsl(48,100%,78%)",
	400: "hsl(46,100%,72%)",
	500: "hsl(45,100%,63%)",
	600: "hsl(43,70%,53%)",
	700: "hsl(42,69%,43%)",
	800: "hsl(40,75%,33%)",
	900: "hsl(39,81%,26%)",
	1000: "hsl(42,100%,49%)",
	1200: "hsl(21,100%,50%)",
	1300: "hsl(31,83%,86%)"
};

export default warning;
