import React from "react";

export const Jpeg = () => (
	<svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M1 0h24v24H1z" />
			<path
				d="M22 6h-5.25a.75.75 0 0 1-.75-.75V0H5.125C4.505-.002 4.002.5 4 1.12v21.755c-.002.62.5 1.123 1.12 1.125h15.755c.62.002 1.123-.5 1.125-1.12V6z"
				fill="#EDF1F7"
			/>
			<path
				d="M21.672 4.922 17.082.328A1.125 1.125 0 0 0 16.287 0H16v5.25c0 .414.336.75.75.75H22v-.286a1.12 1.12 0 0 0-.328-.792z"
				fill="#F7F9FC"
			/>
			<rect width="14" height="7.2" rx=".5" transform="translate(.6 2.4)" fill="#0091FF" />
			<text
				fontFamily="OpenSans-SemiBold, Open Sans"
				fontSize="5"
				fontWeight="500"
				letterSpacing="-.1"
				fill="#FFF"
				transform="translate(.6)"
			>
				<tspan x="1.4" y="8">
					JPEG
				</tspan>
			</text>
			<g fill="#C5CEE0">
				<path d="M8.667 12h7.666c.917 0 1.667.707 1.667 1.569v4.862c0 .862-.75 1.569-1.667 1.569H8.667C7.75 20 7 19.293 7 18.431V13.57C7 12.707 7.75 12 8.667 12zm6.191 1.151c.472 0 .853.36.853.803 0 .444-.381.804-.853.804-.471 0-.854-.36-.854-.804 0-.444.383-.803.854-.803zm-5.497 4.392 1.958-2.992c.164-.178.536-.178.7 0l1.271 1.941.504-.77c.117-.127.38-.127.497 0l1.391 2.125a.568.568 0 0 1-.48.894h-5.168c-.661 0-1.044-.633-.673-1.198z" />
			</g>
		</g>
	</svg>
);

export const Pdf = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M0 0h24v24H0z" />
			<path
				d="M21 6h-5.25a.75.75 0 0 1-.75-.75V0H4.125C3.505-.002 3.002.5 3 1.12v21.755c-.002.62.5 1.123 1.12 1.125h15.755c.62.002 1.123-.5 1.125-1.12V6z"
				fill="#EDF1F7"
			/>
			<path
				d="M17.285 18.047c-.315-.362-.774-.612-1.293-.706-.821-.149-1.6-.203-2.333-.19-.667-.678-1.311-1.479-1.751-2.359.385-1.279.588-2.385.703-3.008.059-.32-.03-.661-.236-.912a1.006 1.006 0 0 0-.775-.372h-.001c-.29 0-.535.12-.71.344-.588.755-.187 2.58-.1 2.94.089.363.215.718.37 1.061-.305.97-.716 2.022-1.273 3.018-1.86.683-2.967 1.622-3.058 1.701-.308.303-.46.606-.453.903.005.168.065.417.325.645.199.175.454.263.713.263.262 0 .53-.09.746-.27.897-.744 1.616-1.668 2.193-2.648.864-.297 1.886-.531 3.037-.587a17.129 17.129 0 0 0 2.17 1.782c.55.38 1.284.331 1.747-.116.433-.419.425-.976-.021-1.489zm-9.49 2.46c-.174.148-.43.158-.58.023-.059-.053-.089-.1-.09-.143 0-.034.015-.166.236-.388.016-.014.744-.642 2.014-1.249a9.15 9.15 0 0 1-1.58 1.758zm3.642-9.178c.042-.049.087-.079.185-.079.14 0 .23.078.272.125a.41.41 0 0 1 .099.35c-.096.481-.249 1.244-.5 2.15a8.893 8.893 0 0 1-.021-.079c-.209-.795-.357-2.086-.035-2.467zm-.562 6.296c.3-.627.551-1.261.761-1.875.326.546.71 1.055 1.114 1.518-.676.069-1.302.196-1.875.357zm5.89 1.302c-.234.244-.616.265-.907.05-.399-.297-.983-.76-1.608-1.352.488.025.995.084 1.518.185.38.074.711.265.932.537.285.352.158.484.065.58z"
				fill="#E56353"
				fillRule="nonzero"
			/>
			<path
				d="M20.672 4.922 16.082.328A1.125 1.125 0 0 0 15.287 0H15v5.25c0 .414.336.75.75.75H21v-.286a1.12 1.12 0 0 0-.328-.792z"
				fill="#F7F9FC"
			/>
			<g transform="translate(.6 2.4)">
				<rect fill="#1998D5" width="12" height="7.2" rx=".5" />
				<path
					d="M2.43 2.1c.512 0 .884.098 1.118.295.235.196.352.499.352.907 0 .41-.12.708-.359.898-.24.19-.606.285-1.1.285h-.598v.99h-.568V2.1h1.156zm3.363 0c.597 0 1.056.147 1.376.442.321.294.481.704.481 1.229 0 .524-.155.94-.467 1.245-.31.306-.787.459-1.428.459H4.65V2.1h1.143zm4.857 0-.005.531H8.97v.927h1.496v.526H8.97v1.391H8.4V2.1h2.25zm-4.876.526h-.562V4.94h.624c.403 0 .712-.097.926-.292.214-.195.321-.482.321-.862 0-.772-.436-1.159-1.31-1.159zm-3.353-.01h-.578V3.97h.66c.315 0 .53-.06.643-.181.114-.121.171-.298.171-.532 0-.233-.072-.398-.217-.495-.144-.096-.37-.144-.679-.144z"
					fill="#FFF"
				/>
			</g>
		</g>
	</svg>
);

export const Ppt = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<g>
				<path d="M0 0h24v24H0z" />
				<path
					d="M21 6h-5.25a.75.75 0 0 1-.75-.75V0H4.125C3.505-.002 3.002.5 3 1.12v21.755c-.002.62.5 1.123 1.12 1.125h15.755c.62.002 1.123-.5 1.125-1.12V6z"
					fill="#EDF1F7"
				/>
				<path
					d="M20.672 4.922 16.082.328A1.125 1.125 0 0 0 15.287 0H15v5.25c0 .414.336.75.75.75H21v-.286a1.12 1.12 0 0 0-.328-.792z"
					fill="#F7F9FC"
				/>
				<rect width="12" height="7.2" rx=".5" transform="translate(.6 2.4)" fill="#FA8B6F" />
				<rect fill="#C5CEE0" x="7" y="12" width="10" height="9" rx="1.5" />
				<rect fill="#FFF" x="10" y="14" width="4" height="1" rx=".5" />
				<rect fill="#FFF" x="10" y="16" width="4" height="1" rx=".5" />
				<rect fill="#FFF" x="10" y="18" width="4" height="1" rx=".5" />
			</g>
			<text fontFamily="OpenSans-SemiBold, Open Sans" fontSize="5" fontWeight="500" letterSpacing=".2" fill="#FFF">
				<tspan x="2" y="8">
					PPT
				</tspan>
			</text>
		</g>
	</svg>
);
