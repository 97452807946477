import { alpha } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

export const ChapterImgContainer = styled.div`
	overflow: visible;
	margin: 0 16px;
`;

export const ChaptersRow = styled.div`
	margin-top: 0;
	padding-top: 35px;
	position: relative;
	background: ${({ theme }) => `linear-gradient(
		to bottom,
		transparent 0%,
		${alpha(theme.palette.background.default, 0.25)} 10%,
		${alpha(theme.palette.background.default, 0.35)} 20%,
		${theme.palette.background.default} 90%
	)`};

	${props => props.theme.breakpoints.down("sm")} {
		margin-top: 50px;
	}
`;

export const LessonSwiperWrapper = styled.div`
	&:last-child {
		margin-bottom: 120px;
	}

	.swiper-container {
		position: relative;
		z-index: 0;

		&:hover {
			z-index: 5;
		}
	}

	.swiper-slide {
		flex-shrink: 1;
		width: auto;
		align-self: center;
	}

	.swiper-button-disabled {
		display: none !important;
	}

	.swiper-button-next {
		right: 0;

		background: ${({ theme }) => `linear-gradient(
			to right,
			transparent 0%,
			${alpha(theme.palette.background.default, 0.25)} 10%,
			${alpha(theme.palette.background.default, 0.35)} 15%,
			${theme.palette.background.default} 100%
		)`};

		&::after {
			right: 22px;
		}
	}

	.swiper-button-prev {
		left: 0;
		padding-left: 32px;

		background: ${({ theme }) => ` linear-gradient(
			to left,
			transparent 0%,
			${alpha(theme.palette.background.default, 0.25)} 10%,
			${alpha(theme.palette.background.default, 0.35)} 15%,
			${theme.palette.background.default} 100%
		)`};

		&::after {
			left: 22px;
		}
	}

	.popout {
		.swiper-button-next,
		.swiper-button-prev {
			z-index: 5 !important;
		}

		.swiper-wrapper {
			position: static !important;
			transition-property: margin-left;
			transform: none !important;
		}

		.swiper-slide-prev {
			visibility: hidden;
		}

		.swiper-button-next-overlay {
			right: 0;
			position: absolute;
			top: 0;
			height: 100%;

			background: ${({ theme }) => `linear-gradient(
				to right,
				transparent 0%,
				${alpha(theme.palette.background.default, 0.25)} 10%,
				${alpha(theme.palette.background.default, 0.35)} 15%,
				${theme.palette.background.default} 100%
			)`};
		}

		.swiper-button-prev-overlay {
			left: -64px;
			width: 96px;

			position: absolute;
			top: 0;
			height: 100%;

			background: ${({ theme }) => `linear-gradient(
				to left,
				transparent 0%,
				${alpha(theme.palette.background.default, 0.25)} 7%,
				${alpha(theme.palette.background.default, 0.35)} 12%,
				${theme.palette.background.default} 30%
			)`};
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none;
		top: 0;
		margin-top: 0;
		height: 100%;
		z-index: 10;
		width: 68px;

		&::after {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
		}
	}

	&:hover {
		.swiper-button-next,
		.swiper-button-prev {
			display: block;
		}
	}

	.popout:hover {
		overflow: visible;

		.swiper-button-next,
		.swiper-button-prev {
			background: none;
			z-index: 10;
		}
	}
`;

export const ChapterImg = styled.img`
	border-radius: 50%;
	width: 163px;
	height: 163px;
	cursor: pointer;
	transition: all 0.3s;
	object-fit: cover;
	border: ${props => `solid 2px ${props.theme.palette.colors.basic[100]}`};

	&:hover {
		filter: none;
	}

	${({ locked }) =>
		locked
			? css`
					opacity: 0.3;
			  `
			: css`
					opacity: 1;
			  `};

	${({ selected }) =>
		selected
			? css`
					height: 188px;
					filter: none;
					width: 188px;

					${props => props.theme.breakpoints.down("sm")} {
						height: 168px;
						width: 168px;
					}
			  `
			: css`
					${props => props.theme.breakpoints.down("sm")} {
						height: 118px;
						width: 118px;
					}
			  `}
`;

export const SectionTitle = styled.div`
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	font-family: var(--poppins);
	line-height: 1.33;
	color: ${props => props.theme.palette.text.primary};
	margin: 40px 15px 24px;
`;
