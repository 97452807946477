import React from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import cn from "classnames";

import { AnswerOption, useStyles } from "../styles";

const SingleChoiceOrMultipleChoiceResult = ({ result, question }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();
	const {
		data: { answerOptions }
	} = question;
	const selectedAnswers = result?.selectedAnswers || [];

	const getAnswer = (id, exist = false) =>
		exist
			? selectedAnswers.find(answer => answer.questionAnswerOptionId === id)
			: !selectedAnswers.find(answer => answer.questionAnswerOptionId === id);

	return (
		<>
			<Box>
				{answerOptions.map(({ id, text, isCorrect }) => {
					let presentCorrectAnswer;
					let presentInCorrectAnswer;
					let notPresentCorrectAnswer;
					let notPresentInCorrectAnswer;

					if (isCorrect) {
						presentCorrectAnswer = result?.isCorrect || getAnswer(id, true);
						notPresentCorrectAnswer = getAnswer(id);
					}
					if (!isCorrect) {
						presentInCorrectAnswer = getAnswer(id, true);
						notPresentInCorrectAnswer = getAnswer(id);
					}
					return (
						<Box key={id}>
							<AnswerOption
								className={cn({
									[classes.green]: presentCorrectAnswer,
									[classes.grey]: presentInCorrectAnswer,
									[classes.purple]: notPresentCorrectAnswer,
									[classes.transparent]: notPresentInCorrectAnswer
								})}
							>
								<Box
									p={isMobile && notPresentInCorrectAnswer ? 0 : 1}
									alignSelf="center"
									fontWeight="bolder"
									flex={isMobile && notPresentInCorrectAnswer ? 0 : 1}
									className={classes.openSansFontFamily}
									color={
										presentCorrectAnswer
											? "#23b34a"
											: notPresentCorrectAnswer
											? "#264fae"
											: presentInCorrectAnswer
											? "#4d5058"
											: "transparent"
									}
								>
									{presentCorrectAnswer
										? "Correct Answer"
										: notPresentCorrectAnswer
										? "Unmarked correct answer"
										: presentInCorrectAnswer
										? "Incorrect answer"
										: notPresentInCorrectAnswer
										? ""
										: null}
								</Box>
								<Box
									flex={2}
									p={isMobile && notPresentInCorrectAnswer ? 0 : 1}
									alignSelf="center"
									className={classes.openSansFontFamily}
									fontWeight="bolder"
									ml={isMobile && notPresentInCorrectAnswer ? 0 : 5}
								>
									{text}
								</Box>
							</AnswerOption>
						</Box>
					);
				})}
			</Box>
		</>
	);
};

export default SingleChoiceOrMultipleChoiceResult;
