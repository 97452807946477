import { IValidateCouponResponse } from "../models";

export const getDiscount = (validatedCoupon: IValidateCouponResponse, totalPrice: number): number => {
	if (!validatedCoupon) {
		return 0;
	}
	const discount = validatedCoupon.coupon.data.isPercentage
		? (validatedCoupon.coupon.data.amount / 100) * totalPrice || 0
		: validatedCoupon.coupon.data.amount || 0;
	if (discount >= totalPrice) {
		return totalPrice;
	}
	return discount;
};
