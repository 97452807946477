import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, CircularProgress, Modal, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { FullScreenLayout } from "@remar/shared/dist/layouts";
import { usePostAffiliateProTrack } from "hooks/usePostAffiliateProTrack";
import * as qs from "qs";
import { useHistory } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "store";

import {
	getCourses,
	guestSignUpVerification,
	setPapCookie,
	setSubscriptionTypesForSignup
} from "store/features/Auth/authSlice";

import { routes } from "core/constants";

import { getCallbackUrl } from "modules/utils";

import ChangeOrSelectCourse from "../components/ChangeOrSelectCourse";

interface SignInProps {}

const CourseSelect: React.FC<SignInProps> = () => {
	const [showSelectCourse, setShowSelectCourse] = useState(false);
	const theme = useTheme<Theme>();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { courses } = useAppSelector((state: RootState) => state.auth);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const searchParams = new URLSearchParams(history.location.search); // todo: use useSearchParams
	const accountClaimCode = searchParams.get("accountClaimCode") as string;
	const isAuthLogin = (searchParams.get("authLogin") as string) == "true";
	const modalStyle = isMobile ? { height: "90vh", overflow: "scroll" } : {};
	const { course } = useMemo(
		() => qs.parse(history.location.search, { ignoreQueryPrefix: true }),
		[history.location.search]
	);
	const callbackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);

	useEffect(() => {
		dispatch(getCourses());
	}, [dispatch]);

	const sideEffect = useCallback(() => {
		history.push(`${routes.signUp.getPath()}/course`);
	}, [history]);

	useEffect(() => {
		if (accountClaimCode) {
			dispatch(guestSignUpVerification({ accountClaimCode, sideEffect }));
		}
	}, [accountClaimCode, dispatch, sideEffect]);

	useEffect(() => {
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: course as unknown as number
			})
		);
	}, [course, dispatch]);

	useEffect(() => {
		if (!history.location.search || accountClaimCode || isAuthLogin || callbackUrl) {
			setShowSelectCourse(true);
		}
	}, [history.location.search, accountClaimCode, isAuthLogin]);

	usePostAffiliateProTrack(v => dispatch(setPapCookie(v)));

	if (showSelectCourse && !courses.length) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	return (
		<FullScreenLayout>
			<Modal open={showSelectCourse} style={modalStyle}>
				<ChangeOrSelectCourse
					selectCourse={showSelectCourse}
					onClose={() => setShowSelectCourse(false)}
					courses={courses}
					accountClaimCode={accountClaimCode}
				/>
			</Modal>
		</FullScreenLayout>
	);
};

export default CourseSelect;
