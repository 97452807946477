import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ActNowWrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 30px 100px;

	.text {
		font-size: 26px;
		font-weight: 700;
		font-family: var(--poppins);
		margin: 20px 0;
	}
	.sub-text {
		font-size: 13px;
		font-weight: 400;
		font-family: var(--openSans);
		margin-bottom: 20px;
	}
	.deal-features {
		p {
			display: flex;
			align-items: center;
			margin: 5px 0;
			svg {
				width: 15px;
				margin-right: 10px;
			}
		}
	}
`;
