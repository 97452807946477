// noinspection HtmlUnknownBooleanAttribute

import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Checkbox, IconButton, Radio, useMediaQuery } from "@material-ui/core";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Pause from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import MuteIcon from "@material-ui/icons/VolumeMute";
import VolumeIcon from "@material-ui/icons/VolumeUp";
import { InteractiveBlockMap, Lesson, Question, QuestionAnswerOption, QuestionGroup, SavedAnswer } from "models";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import videojs from "video.js";

import {
	AlertContainer,
	AnswerOptionContainer,
	AnswerOptionDrag,
	AnswerOptionText,
	CheckBoxWrapper,
	ForceBreak,
	FullScreenAlert,
	GroupContainer,
	GroupTitle,
	RadioWrapper,
	RotationContainer,
	StyledText,
	VITContainer,
	VITNavigationBar,
	VITProgress,
	VITProgressLabel,
	VITQuestion,
	VITQuestionAnswerOption,
	VITQuestionContainer,
	VITStatusRow,
	VITSubmitButton,
	VITVideoContainer,
	VITYourAnswerLabel,
	VolumeButton,
	VolumeContainer,
	VolumeSlider,
	WarningIcon,
	useStyles
} from "./styles";

import { LessonVideoSegmentTypes, MAX_VIT_ANSWER_OPTIONS, QuestionTypes } from "../../constants";
import { IExtendedTheme } from "../../theme/default";
import { getVideoPlayer } from "../../utils/serviceUtils";

interface UserAnswers {
	id: string;
	groupId?: string;
	order?: number;
	text?: string;
	answerOptions?: QuestionAnswerOption[];
}

interface SubmitEvent {
	questionId: number | string;
	userAnswers: UserAnswers[];
}
interface VITLessonAppearanceProps {
	theme?: IExtendedTheme;
	// eslint-disable-next-line
	lesson: Lesson;
	isFinished: boolean;
	interactiveBlocks: { [questionId: number]: InteractiveBlockMap };
	// eslint-disable-next-line
	questions: Question[];
	savedUserAnswers?: { [key: string]: SavedAnswer };
	// eslint-disable-next-line
	onSubmit?: (e: SubmitEvent) => void;
	// eslint-disable-next-line no-unused-vars
	onComplete?: () => void;
	handleFullscreen?: () => void;
}

const AnswerOptionDropZone: FC<{
	children: ReactNode | ReactNode[];
	dropZoneIndex?: number;
	canDrop?: boolean;
	index: number;
	// eslint-disable-next-line no-unused-vars
	onDrop: (_options: { newDropZoneIndex?: number; oldDropZoneIndex?: number; order: number; id: number }) => unknown;
	style?: { [fieldName: string]: unknown };
}> = ({ children, canDrop: forcedCanDrop, dropZoneIndex, onDrop, style }) => {
	const [dragObjectData, drop] = useDrop({
		accept: "box",
		drop: item =>
			onDrop({
				newDropZoneIndex: dropZoneIndex,
				oldDropZoneIndex: (item as unknown as Record<string, unknown>).dropZoneIndex as number,
				order: (item as unknown as Record<string, unknown>).index as number,
				id: (item as unknown as Record<string, unknown>).id as number
			}),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
		canDrop: () => !!forcedCanDrop
	});
	const { isOver, canDrop } = dragObjectData as Record<string, boolean>;
	const overStyles = {
		border: "1px dashed #fff",
		height: 48,
		margin: "8px 8px 0 0"
	};

	return (
		<div ref={drop} style={style || {}}>
			{children}
			<div className="dashed-placeholder" style={isOver && canDrop ? overStyles : {}} />
		</div>
	);
};

const AnswerOption: FC<{
	text: string;
	isVITDisabled?: boolean;
	id: string;
	canDrop?: boolean;
	isCorrect?: boolean;
	// eslint-disable-next-line no-unused-vars
	handleDrop?: (options: {
		newGroupIndex: number;
		oldGroupIndex: number;
		oldOptionIndex: number;
		touchType: string;
		newOptionIndex: number;
		id: number;
	}) => void;
	index: number;
	dropZoneIndex?: number;
	typeId: number;
}> = ({ text, canDrop, id, index, dropZoneIndex, isVITDisabled, isCorrect, handleDrop }) => {
	const [canDrag, setCanDrag] = useState(false);

	const [, drag] = useDrag({
		item: { id, index, dropZoneIndex },
		type: "box",
		canDrag: () => canDrag,
		end: () => setCanDrag(false)
	});
	return (
		<AnswerOptionDropZone
			index={index + 1}
			canDrop={canDrop}
			onDrop={({ oldDropZoneIndex, order, id }) => {
				const payload = {
					newGroupIndex: dropZoneIndex!,
					oldGroupIndex: oldDropZoneIndex!,
					oldOptionIndex: order,
					touchType: dropZoneIndex === oldDropZoneIndex ? "dragger" : "reorder",
					newOptionIndex: index + 1,
					id
				};
				handleDrop!(payload);
			}}
		>
			<AnswerOptionContainer ref={drag} isVITDisabled={isVITDisabled}>
				<AnswerOptionText isCorrect={isCorrect} isVITDisabled={isVITDisabled}>
					{text}
				</AnswerOptionText>
				<AnswerOptionDrag
					isVITDisabled={isVITDisabled}
					isCorrect={isCorrect}
					onTouchStart={() => setCanDrag(true)}
					onMouseDown={() => setCanDrag(true)}
				>
					=
				</AnswerOptionDrag>
			</AnswerOptionContainer>
		</AnswerOptionDropZone>
	);
};

export const VITLessonAppearance = ({
	lesson,
	theme,
	isFinished,
	interactiveBlocks,
	questions,
	savedUserAnswers = {},
	onSubmit = () => 1,
	onComplete = () => 1,
	handleFullscreen
}: VITLessonAppearanceProps) => {
	const [isPlaying, setIsPlaying] = useState(false);

	const isTouchDevice = document.documentElement && "ontouchstart" in document.documentElement;
	/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
	/* @ts-ignore */
	const isFullscreenEnabled = document.fullscreenEnabled || document.webkitFullscreenEnabled;

	const showVITOnlyInFullscreen =
		isFullscreenEnabled && (isTouchDevice || (theme && useMediaQuery(theme?.breakpoints.down("md") ?? "")));
	const DragAndDropBackend = isTouchDevice ? TouchBackend : HTML5Backend;

	const video =
		(lesson.interactiveBlocks && lesson.interactiveBlocks.length > 0 && lesson.interactiveBlocks[0].video) || undefined;

	const videoUrl = video?.videoUrl;

	const isVITDisabled = isTouchDevice && !window.document.fullscreenEnabled;

	const [sourceGroup, setSourceGroup] = useState<string | undefined>();

	const videoRef = useRef<HTMLVideoElement>(null);
	const videoPlayer = useRef<typeof videojs.players>();
	const videoState = useRef<string>("start");

	const [showQuestion, setShowQuestion] = useState(false);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [userAnswers, setUserAnswers] = useState<UserAnswers[]>([]);
	const [isReviewing, setIsReviewing] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [groupingResult, setGroupingResult] = useState(false);
	const [volume, setVolume] = useState(100);
	const [muted, setMuted] = useState(false);

	useEffect(() => {
		if (completed && !isFinished) {
			onComplete();
		}
	}, [completed, isFinished]);

	const classes = useStyles();

	const question = questions[questionIndex];
	const typeId = question.typeId;
	const questionId: number | string = question?.id || (question?.questionDraftId as string);
	const rightAnswers = useMemo(
		() => (question.data.answerOptions || []).filter(({ isCorrect }) => isCorrect).map(({ id }) => id),
		[question]
	);

	const selectedInteractiveBlock = interactiveBlocks[questionId];
	const {
		[LessonVideoSegmentTypes.Start]: startSegment,
		[LessonVideoSegmentTypes.Question]: questionSegment,
		[LessonVideoSegmentTypes.Loop]: loopSegment,
		[LessonVideoSegmentTypes.Correct]: correctSegment,
		[LessonVideoSegmentTypes.Incorrect]: incorrectSegment
	} = selectedInteractiveBlock || {};

	const handleChangeAnswer = (checked, answerId) => {
		if (question.typeId === QuestionTypes.SingleChoice || question.typeId === QuestionTypes.Neutral) {
			setUserAnswers([{ id: answerId }]);
		} else {
			setUserAnswers(
				checked ? [...userAnswers, { id: answerId }] : userAnswers.filter(answer => answer.id !== answerId)
			);
		}
	};

	const handleSubmit = () => {
		const formattedUserAnswers =
			question.typeId === QuestionTypes.Grouping
				? ((userAnswers as QuestionGroup[]).reduce((acc, cur) => {
						acc.push(...cur.answerOptions.map(a => ({ ...a, groupId: cur.id } as UserAnswers)));
						return acc;
				  }, [] as UserAnswers[]) as unknown as UserAnswers[])
				: userAnswers;

		if (formattedUserAnswers?.length === 0) return;
		setIsReviewing(true);
		onSubmit({
			questionId,
			userAnswers: formattedUserAnswers
		});

		let allAnswersAreCorrect =
			userAnswers
				.map(item => {
					return item.id;
				})
				.sort()
				.join("-") === rightAnswers.sort().join("-");
		if (question.typeId === QuestionTypes.Grouping) {
			userAnswers.forEach(item => {
				const groupItem = question.correctGroups!.find(f => (!f.id && f.text === item.text) || f.id === item.id);
				allAnswersAreCorrect = item.answerOptions?.every(f =>
					groupItem?.answerOptions.some(c => c.id && c.id === f.id)
				) as boolean;
				setGroupingResult(allAnswersAreCorrect);
				if (!allAnswersAreCorrect) {
					return;
				}
			});
		}
		const config = allAnswersAreCorrect ? correctSegment : incorrectSegment;
		const segmentStartTime = Math.floor(config.startsAtMilliseconds / 1000);
		const segmentFinishTime = Math.floor(config.endsAtMilliseconds / 1000);
		videoPlayer.current?.currentTime(segmentStartTime);
		if (segmentFinishTime - segmentStartTime > 15) setTimeout(() => setShowQuestion(false), 15000);
		videoState.current = "review";
	};

	const handleSequenceDrop = ({ index, newIndex }) => {
		const newUserAnswers = [...userAnswers];
		const option = userAnswers[index - 1];

		newUserAnswers.splice(index - 1, 1);

		newUserAnswers.splice(index - 1 < newIndex ? newIndex : newIndex - 1, 0, option);

		setUserAnswers(newUserAnswers);
	};

	useEffect(() => {
		if (videoUrl && !isFinished) {
			videoPlayer.current = getVideoPlayer(videoRef!.current!, videoUrl, {
				muted: false,
				loop: true,
				autoplay: false,
				controls: false
			});
		}
	}, [videoUrl, selectedInteractiveBlock]);

	useEffect(() => {
		const userAnswerOption = savedUserAnswers![questionId]?.answers ?? [];
		setUserAnswers(userAnswerOption);

		const index = questions.findIndex(({ id }) => !savedUserAnswers![id]);
		setQuestionIndex(index === -1 ? 0 : index);
	}, [lesson.id]);

	useEffect(() => {
		setUserAnswers(
			typeId === QuestionTypes.Grouping
				? question.data.groups
				: question.typeId === QuestionTypes.Sequencing
				? question.data.answerOptions ?? []
				: []
		);
	}, [question]);

	useEffect(() => {
		if (videoPlayer && question && selectedInteractiveBlock) {
			const startFromTime = Math.floor(startSegment.startsAtMilliseconds / 1000);
			const loopFromTime = Math.floor(loopSegment.startsAtMilliseconds / 1000);
			const questionFromTime = Math.floor(questionSegment.startsAtMilliseconds / 1000);
			const questionToTime = Math.floor(questionSegment.endsAtMilliseconds / 1000);
			const loopToTime = Math.floor(loopSegment.endsAtMilliseconds / 1000);
			const correctResToTime = Math.floor(correctSegment.endsAtMilliseconds / 1000);
			const incorrectResToTime = Math.floor(incorrectSegment.endsAtMilliseconds / 1000);
			videoPlayer.current?.currentTime(startFromTime);
			if ((!showVITOnlyInFullscreen && !isTouchDevice) || isVITDisabled) {
				videoPlayer.current?.play();
				setIsPlaying(true);
			}
			setShowQuestion(false);
			const interval = setInterval(() => {
				const time = Math.round(videoPlayer.current?.currentTime() as number);

				if (videoState.current === "start" && time === questionFromTime) {
					//display question at question start time
					setShowQuestion(true);
				} else if (
					(videoState.current === "start" && time === questionToTime) ||
					(videoState.current === "loop" && time == loopToTime)
				) {
					//loop video if question or loop time ends
					videoPlayer.current?.currentTime(loopFromTime);
					videoState.current = "loop";
				} else if (videoState.current === "review" && (time === correctResToTime || time === incorrectResToTime)) {
					if (questionIndex < questions?.length - 1) {
						setQuestionIndex(questionIndex + 1);
						setIsReviewing(false);
						videoPlayer.current?.play();
						videoState.current = "start";
					} else {
						videoPlayer.current?.pause();
						setIsPlaying(false);
						setCompleted(true);
					}

					if (
						Math.round(videoPlayer.current?.currentTime() as number) ===
						Math.round(videoPlayer.current?.duration() as number)
					) {
						setCompleted(true);
					}
				}
			}, 200);
			return () => {
				clearInterval(interval);
			};
		}

		return () => {};
	}, [question, videoPlayer]);

	const reorder = (startIndex: number, endIndex: number, list?: QuestionAnswerOption[]): QuestionAnswerOption[] => {
		const result = Array.from(list!);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const handleDrop = payload => {
		const { newGroupIndex, oldGroupIndex, oldOptionIndex, newOptionIndex, touchType, id } = payload;
		const groups = userAnswers as QuestionGroup[];
		if (newGroupIndex === oldGroupIndex && touchType === "dragger") {
			const splicedOption = reorder(oldOptionIndex, newOptionIndex, groups[oldGroupIndex]?.answerOptions);

			setUserAnswers(
				groups?.map((x, i) => {
					if (i === oldGroupIndex) {
						return {
							...x,
							answerOptions: splicedOption as QuestionAnswerOption[]
						};
					} else return { ...x };
				})
			);
			return;
		}

		if (newGroupIndex !== oldGroupIndex && touchType === "reorder") {
			const removed = groups[oldGroupIndex]?.answerOptions?.slice(
				oldOptionIndex,
				oldOptionIndex + 1
			) as QuestionAnswerOption[];

			setUserAnswers(
				groups?.map((x: QuestionGroup, i) => {
					if (i === oldGroupIndex) {
						return {
							...x,
							answerOptions: x?.answerOptions?.filter(x => x.id !== id)
						};
					} else if (i === newGroupIndex) {
						const opts = x?.answerOptions;

						return {
							...x,
							answerOptions: [...opts.slice(0, newOptionIndex), removed[0], ...opts.slice(newOptionIndex)]
						};
					} else return { ...x };
				})
			);
		}
	};

	const [fullscreen, setFullscreen] = useState(false);
	const [volumeControlVisible, setVolumeControlVisible] = useState(false);

	const preHandleFullscreen = () => {
		setFullscreen(!fullscreen);

		if (!isVITDisabled) {
			if (fullscreen) {
				document.exitFullscreen && document.exitFullscreen();
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				/* @ts-ignore */
				document.webkitExitFullscreen && document.webkitExitFullscreen();
			} else {
				handleFullscreen && handleFullscreen();
				const interval = setInterval(() => {
					if (!document.fullscreenElement) {
						setFullscreen(false);
						clearInterval(interval);
					}
				}, 500);
			}
		}
	};

	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		if (showVITOnlyInFullscreen && !isVITDisabled) {
			if (!fullscreen) {
				videoPlayer?.current?.pause();
				setIsPlaying(false);
			} else {
				videoPlayer.current?.play();
				setIsPlaying(true);
			}
		}
	}, [fullscreen, showVITOnlyInFullscreen, videoPlayer, isVITDisabled]);

	useEffect(() => {
		if (videoPlayer) {
			if (!muted && volume === 0) {
				setVolume(100);
			}
			videoPlayer.current?.volume(volume / 100);
			videoPlayer.current?.muted(muted);
		}
	}, [volume, muted, videoPlayer]);

	const renderQuestion = useCallback(
		(question: Question) => {
			switch (question?.typeId) {
				case QuestionTypes.Neutral: {
					return question?.data.answerOptions!.map(({ id, text }) => {
						const checked = userAnswers.some(item => item.id == id);
						return (
							<VITQuestionAnswerOption key={id} checked={checked} isReviewing={isReviewing}>
								{!isReviewing && (
									<RadioWrapper>
										<Radio checked={checked} onChange={() => handleChangeAnswer(true, id)} />
									</RadioWrapper>
								)}
								<StyledText>{text}</StyledText>
								{isReviewing && checked && <VITYourAnswerLabel>YourAnswer</VITYourAnswerLabel>}
							</VITQuestionAnswerOption>
						);
					});
				}
				case QuestionTypes.SingleChoice: {
					return question?.data.answerOptions!.map(({ id, text, isCorrect }) => {
						const checked = userAnswers.some(item => item.id == id);
						return (
							<VITQuestionAnswerOption key={id} checked={checked} isReviewing={isReviewing} isCorrect={isCorrect}>
								{!isReviewing && (
									<RadioWrapper>
										<Radio checked={checked} onChange={() => handleChangeAnswer(true, id)} />
									</RadioWrapper>
								)}
								<StyledText>{text}</StyledText>
								{isReviewing && checked && <VITYourAnswerLabel>YourAnswer</VITYourAnswerLabel>}
							</VITQuestionAnswerOption>
						);
					});
				}
				case QuestionTypes.Sequencing: {
					return (
						<>
							<AnswerOptionDropZone
								index={0}
								dropZoneIndex={1}
								canDrop
								onDrop={({ order: index, newDropZoneIndex: newIndex }) => handleSequenceDrop({ index, newIndex })}
							>
								<div className="drop-space" />
							</AnswerOptionDropZone>
							{userAnswers?.map(({ id, text, order }, answerOptionIndex) => (
								<AnswerOption
									index={answerOptionIndex + 1}
									id={id}
									canDrop
									key={id}
									isCorrect={isReviewing ? answerOptionIndex + 1 === order : undefined}
									text={text ?? ""}
									typeId={typeId}
									handleDrop={({ oldOptionIndex: index, newOptionIndex: newIndex }) =>
										handleSequenceDrop({ index, newIndex })
									}
								/>
							))}
						</>
					);
				}
				case QuestionTypes.Grouping: {
					return (
						<Box display="flex" flexDirection="row" width="100%" mt="1" style={{ gap: "20px" }}>
							{userAnswers
								.map(({ id, text }) => question.data.groups.find(f => (!f.id && f.text === text) || f.id === id))
								.map((group, groupIndex) => {
									const answerOptions = (userAnswers[groupIndex] as unknown as Record<string, unknown>)
										.answerOptions as QuestionAnswerOption[];

									const canDrop =
										(sourceGroup !== group?.id && answerOptions?.length < MAX_VIT_ANSWER_OPTIONS) ||
										(sourceGroup == group?.id && answerOptions?.length < MAX_VIT_ANSWER_OPTIONS + 1);

									const handleAlert = () => sourceGroup !== group?.id && !canDrop && setShowAlert(true);

									const handleHideAlert = () => setShowAlert(false);

									return (
										<GroupContainer
											showVITOnlyInFullscreen={showVITOnlyInFullscreen}
											key={`createLesson-qestionItem-${questionIndex}-answerGroup-${groupIndex}`}
											onDragOver={handleAlert}
											onTouchMove={handleAlert}
											onTouchEnd={handleHideAlert}
											onMouseOut={handleHideAlert}
											onMouseDown={() => setSourceGroup(group?.id)}
											onDragStart={() => setSourceGroup(group?.id)}
										>
											{sourceGroup !== group?.id && !canDrop && showAlert && (
												<AlertContainer>
													The max capacity is reached. Please shuffle the options around to make space
												</AlertContainer>
											)}
											<GroupTitle>{group?.text}</GroupTitle>
											<AnswerOptionDropZone
												index={0}
												canDrop={canDrop}
												onDrop={({ oldDropZoneIndex, order, id }) => {
													const payload = {
														newGroupIndex: groupIndex!,
														oldGroupIndex: oldDropZoneIndex!,
														oldOptionIndex: order,
														touchType: groupIndex === oldDropZoneIndex ? "dragger" : "reorder",
														newOptionIndex: 0,
														id
													};
													handleDrop(payload);
												}}
											>
												<Box style={{ minHeight: 10 }} />
											</AnswerOptionDropZone>
											{answerOptions?.map((answerOption, answerOptionIndex) => {
												const { text } = answerOption;
												return (
													<AnswerOption
														index={answerOptionIndex}
														handleDrop={payload => handleDrop!(payload)}
														canDrop={canDrop}
														id={answerOption.id}
														key={`createLesson-qestionItem-${questionIndex}-answerGroup-${groupIndex}-answerOption-${answerOptionIndex}`}
														isCorrect={isReviewing ? groupingResult : undefined}
														text={text ?? ""}
														dropZoneIndex={groupIndex}
														isVITDisabled={isVITDisabled}
														typeId={question.typeId!}
													/>
												);
											})}
										</GroupContainer>
									);
								})}
						</Box>
					);
				}
				default: {
					return question?.data.answerOptions!.map(({ id, text, isCorrect }) => {
						return (
							<VITQuestionAnswerOption
								key={id}
								checked={userAnswers.some(item => item.id == id)}
								isReviewing={isReviewing}
								isCorrect={isCorrect}
								showVITOnlyInFullscreen={showVITOnlyInFullscreen}
							>
								{!isReviewing && (
									<CheckBoxWrapper>
										<Checkbox
											checked={userAnswers.some(item => item.id == id)}
											onChange={({ target: { checked } }) => handleChangeAnswer(checked, id)}
										/>
									</CheckBoxWrapper>
								)}
								<StyledText>{text}</StyledText>
								{isReviewing && userAnswers.some(item => item.id == id) && (
									<VITYourAnswerLabel>YourAnswer</VITYourAnswerLabel>
								)}
							</VITQuestionAnswerOption>
						);
					});
				}
			}
		},
		[question, userAnswers, isReviewing, groupingResult, sourceGroup, showAlert, isVITDisabled, showVITOnlyInFullscreen]
	);

	return (
		<DndProvider backend={DragAndDropBackend}>
			<RotationContainer isVITDisabled={isVITDisabled} fullscreen={fullscreen}>
				<VITContainer
					flex={Boolean(fullscreen)}
					showVITOnlyInFullscreen={showVITOnlyInFullscreen}
					isVITDisabled={isVITDisabled}
				>
					<VITVideoContainer fullscreen={fullscreen} isVITDisabled={isVITDisabled}>
						<div data-vjs-player>
							<video playsInline ref={videoRef} className={classes.video}></video>
						</div>
					</VITVideoContainer>
					<Box position="absolute" top="0" left="0" width="100%" height="100%"></Box>
					{showQuestion && (fullscreen || (!fullscreen && (!showVITOnlyInFullscreen || isVITDisabled))) && (
						<VITQuestionContainer typeId={question?.typeId} showVITOnlyInFullscreen={showVITOnlyInFullscreen}>
							<VITQuestion>
								{questionIndex + 1}. {question?.text}
							</VITQuestion>
							{renderQuestion(question)}
							<VITStatusRow typeId={question?.typeId} showVITOnlyInFullscreen={showVITOnlyInFullscreen}>
								<VITProgress>
									{questionIndex + 1}/{questions?.length}
								</VITProgress>
								<VITProgressLabel>
									<ForceBreak>Questions Answered</ForceBreak>
								</VITProgressLabel>
								{!isReviewing && (
									<VITSubmitButton
										onClick={handleSubmit}
										showVITOnlyInFullscreen={showVITOnlyInFullscreen}
										isVITDisabled={isVITDisabled}
									>
										Submit
									</VITSubmitButton>
								)}
							</VITStatusRow>
						</VITQuestionContainer>
					)}
					{(isVITDisabled || !(!fullscreen && showVITOnlyInFullscreen)) && (
						<VITNavigationBar>
							<div>
								<IconButton
									onClick={() => {
										if (isPlaying) {
											videoPlayer.current?.pause();
											setIsPlaying(false);
										} else {
											videoPlayer.current?.play();
											setIsPlaying(true);
										}
									}}
								>
									{isPlaying ? <Pause fontSize="small" /> : <PlayIcon fontSize="small" />}
								</IconButton>
								<VolumeButton
									onMouseEnter={() => setVolumeControlVisible(true)}
									onMouseLeave={() => setVolumeControlVisible(false)}
									onClick={() => setMuted(prevMuted => !prevMuted)}
								>
									{!muted ? <VolumeIcon /> : <MuteIcon />}
									{volumeControlVisible && (
										<VolumeContainer>
											<VolumeSlider
												onClick={e => e.stopPropagation()}
												size="small"
												orientation="horizontal"
												max={100}
												value={muted ? 0 : volume}
												onChange={(_, volume) => {
													setVolume(volume);
													setMuted(volume === 0);
												}}
												min={0}
												step={5}
											/>
										</VolumeContainer>
									)}
								</VolumeButton>
							</div>
							{!isVITDisabled && (
								<IconButton onClick={preHandleFullscreen}>
									<FullscreenIcon fontSize="small" />
								</IconButton>
							)}
						</VITNavigationBar>
					)}
				</VITContainer>
			</RotationContainer>
			{isTouchDevice && !isVITDisabled && !fullscreen ? (
				<FullScreenAlert onClick={preHandleFullscreen}>
					<WarningIcon />
					Please watch the video in full screen video mode to interact with the video
				</FullScreenAlert>
			) : null}
		</DndProvider>
	);
};
