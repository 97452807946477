import React from "react";

import { Typography } from "@material-ui/core";

import styled from "styled-components";

const StyledReCaptcha = styled(Typography)`
	opacity: 0.87;
	font-size: 12px;
	line-height: 1.23;
	letter-spacing: normal;
	color: ${({ theme }) => theme.palette.text.primary};
	cursor: pointer;
	a {
		text-decoration: underline;
		color: ${({ theme }) => theme.palette.text.primary};
	}
`;

const RecaptchaTerms = () => (
	<StyledReCaptcha>
		This site is protected by reCAPTCHA and the Google <br />
		<a href="https://policies.google.com/privacy" target={"__blank"}>
			Privacy Policy
		</a>{" "}
		and{" "}
		<a href="https://policies.google.com/terms" target={"__blank"}>
			Terms of Service
		</a>{" "}
		apply.
	</StyledReCaptcha>
);

export default RecaptchaTerms;
