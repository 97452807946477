import React, { useMemo, useRef, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { useTheme } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import cn from "classnames";

import { CustomMenuList } from "./styles";

import { IExtendedTheme } from "../../theme/default";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			display: "flex"
		},
		paper: {
			marginRight: theme.spacing(2)
		},
		menu: {
			color: theme.palette.text.secondary,
			backgroundColor: theme.palette.background.paper,
			width: "auto",
			height: "auto"
		},
		icon: {
			"&:hover": {
				backgroundColor: "hsla(0,0%,100%,0.08)" // todo add into colorset
			}
		},
		noPadding: {
			padding: 0
		}
	})
);
export interface CustomMenuItem {
	label: string;
	onClick: () => void;
	disabled: boolean;
	visible: boolean;
}
export interface ActionMenuProps {
	noPadding?;
	customMenuItems: CustomMenuItem[];
}

export const ActionMenu = ({ noPadding, customMenuItems }: ActionMenuProps) => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);
	const filteredMenuItems = useMemo(() => customMenuItems.filter(item => item.visible), [customMenuItems]);
	return (
		<div className={classes.root}>
			{filteredMenuItems.length !== 0 && (
				<IconButton
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={() => setOpen(p => !p)}
					className={cn(classes.icon, {
						[classes.noPadding]: noPadding
					})}
				>
					<MoreVert style={{ fill: theme.palette.colors.basic[1400] }} />
				</IconButton>
			)}
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				style={{ zIndex: 3 }}
				role={undefined}
				transition
				placement="bottom-end"
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper classes={{ root: classes.menu }}>
							<ClickAwayListener onClickAway={() => setOpen(false)}>
								<CustomMenuList id="menu-list-grow">
									{filteredMenuItems.map(item => (
										<MenuItem key={item.label} onClick={item.onClick} disabled={item.disabled}>
											{item.label}
										</MenuItem>
									))}
								</CustomMenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};
