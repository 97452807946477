import React, { useMemo, useState } from "react";

import { useTheme } from "@material-ui/core";

import { QuestionTypes } from "@remar/shared/dist/constants";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils";

import {
	CaseStudyColumn,
	CaseStudyRow,
	CaseStudyTab,
	CaseStudyTabContent,
	CaseStudyTabs,
	Page,
	Pages
} from "modules/Lesson/style";

import { TestQuizQuestionText } from "modules/QuestionBank/styles";

import ClozeDropDownResult from "./ClozeDropDownResult";
import DragAndDropResult from "./DragAndDropResult";
import DropDownTableResult from "./DropDownTableResult";
import HighlightTableResult from "./HighlightTableResult";
import HotspotHighlightResult from "./HotspotHighlightResult";
import MatrixMultipleChoiceResult from "./MatrixMultipleChoiceResult";
import MatrixSingleChoiceResult from "./MatrixSingleChoiceResult";
import MultipleResponseGroupResult from "./MultipleResponseGroupResult";
import SingleChoiceOrMultipleChoiceResult from "./SingleChoiceOrMultipleChoiceResult";
import TutoredTestResultInfo from "./TutoredResultInfo";

const CaseStudyQuestionTutoredResult = ({
	results,
	question: {
		caseStudyQuestions: unorderedQuestions,
		data: { tabs: parentTabs }
	}
}) => {
	const theme = useTheme<IExtendedTheme>();
	const [activeTab, setActiveTab] = useState(0);
	const [activeQuestion, setActiveQuestion] = useState(0);
	const questions = useMemo(() => [...unorderedQuestions].sort((a, b) => a.order - b.order), [unorderedQuestions]);
	const currentQuestion = questions[activeQuestion];
	const {
		data: { tabs: currentTabs }
	} = currentQuestion;
	const tabs = currentTabs || parentTabs;
	const result = useMemo(
		() => results.find(({ snapshot }) => snapshot.id === currentQuestion?.id),
		[currentQuestion?.id, results]
	);
	const setActiveQuestionIndex = (index: number) => {
		setActiveTab(0);
		setActiveQuestion(index);
	};

	const handleChangeQuestion = questionIndex => {
		if (activeQuestion !== questionIndex) {
			setActiveQuestionIndex(questionIndex);
		}
	};

	const getQuestionType = (id, result, question) => {
		switch (id) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceResult question={question} result={result} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDropDown:
			case QuestionTypes.ClozeDropDown:
				return <ClozeDropDownResult question={question} result={result} />;
			case QuestionTypes.DropDownTable:
				return <DropDownTableResult question={question} result={result} />;
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
			case QuestionTypes.SingleChoice:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.RationalScoringDragAndDrop:
			case QuestionTypes.DragAndDrop:
				return <DragAndDropResult question={question} result={result} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupResult question={question} result={result} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightResult question={question} result={result} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableResult question={question} result={result} />;
			default:
				return null;
		}
	};

	return (
		<>
			<CaseStudyRow>
				<CaseStudyColumn>
					<CaseStudyTabs>
						{tabs.map(({ title, id }, tabIndex) => (
							<CaseStudyTab key={`tab-${id}`} active={tabIndex === activeTab} onClick={() => setActiveTab(tabIndex)}>
								{title}
							</CaseStudyTab>
						))}
					</CaseStudyTabs>
					{activeTab !== undefined && (
						<CaseStudyTabContent dangerouslySetInnerHTML={{ __html: tabs[activeTab]?.text }}></CaseStudyTabContent>
					)}
				</CaseStudyColumn>
				<CaseStudyColumn>
					<TestQuizQuestionText
						pl={"14px"}
						color={theme.palette.colors.basic[600]}
						dangerouslySetInnerHTML={{ __html: currentQuestion?.description }}
					/>
					<TestQuizQuestionText pl={"14px"}>
						Question: {getSanitizedHtmlText(currentQuestion?.text)}
					</TestQuizQuestionText>
					{currentQuestion && getQuestionType(currentQuestion.typeId, result, currentQuestion)}
					<TutoredTestResultInfo result={result} />
				</CaseStudyColumn>
			</CaseStudyRow>
			<Pages>
				<Page onClick={() => handleChangeQuestion(Math.max(activeQuestion - 1, 0))}>&lt;</Page>
				{questions?.map((_, questionIndex) => (
					<Page
						active={activeQuestion === questionIndex}
						key={`question-${questionIndex}`}
						onClick={() => handleChangeQuestion(questionIndex)}
					>
						{questionIndex + 1}
					</Page>
				))}
				<Page onClick={() => handleChangeQuestion(Math.min(activeQuestion + 1, questions.length - 1))}>&gt;</Page>
			</Pages>
		</>
	);
};

export default CaseStudyQuestionTutoredResult;
