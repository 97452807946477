import { useEffect, useRef } from "react";

export const useInterval = (callback: () => null, delay: number | null): void => {
	const savedCallback = useRef(() => null);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			savedCallback.current();
		};

		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
		return;
	}, [delay]);
};
