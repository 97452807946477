export const FIREBASE_CONFIG = {
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	authDomain: process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_GOOGLE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_GOOGLE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_GOOGLE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_GOOGLE_APP_ID,
	measurementId: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
	vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
};
