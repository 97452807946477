import React from "react";

import { Box, Card, Container, Grid, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { CheckCircle, CloseRounded } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { TextField } from "@remar/shared/dist/components/TextField";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { Field, Form, Formik } from "formik";

import { hasAtleast8Chars, hasLowerCase, hasSpecial, hasUpperCase } from "./PwdStregthUtils";
import { ChangePwdSchema } from "./schemas";
import { ModalSubTitleText, ModalTitleText, useChangePasswordStyles } from "./styles";

const changePwdInitialValues = {
	currentPassword: "",
	newPassword: "",
	confirmPassword: ""
};

const ValidationIcon = ({ valid }) => {
	const theme = useTheme<IExtendedTheme>();
	return (
		<CheckCircle
			style={{
				width: "15px",
				height: "15px",
				fill: valid ? theme.palette.checkMark.validColor : theme.palette.checkMark.color,
				opacity: valid ? 1 : 0.18
			}}
		/>
	);
};

const ChangePassword = ({ onClose, handleSubmit }) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useChangePasswordStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card>
					<Formik
						initialValues={changePwdInitialValues}
						validationSchema={ChangePwdSchema}
						validateOnChange
						onSubmit={values => {
							handleSubmit(values);
						}}
					>
						{({ isValid, values, dirty }) => {
							return (
								<>
									<Box display="flex" justifyContent="space-between">
										<ModalTitleText>Edit Profile</ModalTitleText>
										<IconButton onClick={onClose}>
											<CloseRounded style={{ width: "20px", height: "20px", fill: "#c5cee0" }} />
										</IconButton>
									</Box>
									<Form>
										<Box className={classes.root}>
											<ModalSubTitleText>Change Password</ModalSubTitleText>

											<Grid container spacing={2}>
												<Grid item xs={12} sm={6}>
													<span className={classes.passContain}>Password must contain:</span>
													<ul className={classes.listStyle}>
														<li className={classes.listItem}>
															<ValidationIcon valid={hasAtleast8Chars(values.newPassword)} />
															<span> At least 8 characters </span>
														</li>
														<li className={classes.listItem}>
															<ValidationIcon valid={hasUpperCase(values.newPassword)} />
															<span> At least 1 upper case letter (A…Z) </span>
														</li>

														<li className={classes.listItem}>
															<ValidationIcon valid={hasLowerCase(values.newPassword)} />
															<span> At least 1 lower case letter (a…z) </span>
														</li>

														<li className={classes.listItem}>
															<ValidationIcon valid={hasSpecial(values.newPassword)} />
															<span> At least 1 special character (!$@-) </span>
														</li>
													</ul>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<Field
																label="Current Password"
																component={TextField}
																name="currentPassword"
																type="password"
																placeholder="Current Password"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12}>
															<Field
																label="Password"
																component={TextField}
																name="newPassword"
																type="password"
																placeholder="Password"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12}>
															<Field
																label="Confirm Password"
																component={TextField}
																name="confirmPassword"
																type="password"
																placeholder="Confirm Password"
																fullWidth
															/>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Box>
									</Form>

									<Box display="flex" justifyContent="flex-end" mt={3}>
										<Button
											variant={"filled"}
											color="primary"
											disabled={!isValid || !dirty}
											fullWidth={isMobile}
											onClick={() => handleSubmit(values)}
										>
											Save
										</Button>
									</Box>
								</>
							);
						}}
					</Formik>
				</Card>
			</Box>
		</Container>
	);
};

export default ChangePassword;
