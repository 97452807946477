import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { Note } from "@remar/shared/dist/models";

import { NoteCreateDto, NoteUpdateDto } from "./dto";

import { URLS } from "../services.constants";

export class NoteService extends BaseApiService<
	Note,
	NoteCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	NoteUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const noteService = new NoteService(URLS.NOTES);
