import React from "react";

import { Box, Typography, createStyles, makeStyles } from "@material-ui/core";

import { IExtendedTheme } from "theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		callbackImg: {
			justifyContent: "center",
			display: "flex",
			alignItems: "center",
			height: "96px",
			width: "96px",
			borderRadius: "4px",
			backgroundColor: theme.palette.colors.primary[1500],
			color: theme.palette.colors.primary[1400]
		},
		initials: {
			color: theme.palette.colors.primary[1400],
			fontSize: `"${theme.typography.pxToRem(30)}"`,
			textTransform: "uppercase"
		},
		img: {
			display: "block"
		}
	})
);

interface IProps {
	profilePictureUrl?: string;
	firstName: string;
	lastName: string;
}

export const ProfileAvatar = ({ profilePictureUrl, firstName, lastName }: IProps) => {
	const classes = useStyles();

	return profilePictureUrl ? (
		<img alt="Profile avatar" src={profilePictureUrl} width="96" height="96" className={classes.img} />
	) : (
		<Box className={classes.callbackImg}>
			<Typography className={classes.initials}>{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</Typography>
		</Box>
	);
};
