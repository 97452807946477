import { ICourseWithCompletion } from "../../models";
import { coursesService } from "../../services/courses/courses.service";

export const getCoursesLessons = async (
	ids: number[],
	isTrial: boolean,
	includeLessonDetails: boolean
): Promise<ICourseWithCompletion[]> => {
	const filters: { [fieldName: string]: unknown } = {
		"chapters.sections.sectionLessons.lesson.isActive": true,
		"chapters.isActive": true,
		isActive: "true"
	};
	if (ids) {
		filters.id = ids;
	}
	if (typeof isTrial !== "undefined") {
		filters["chapters.sections.sectionLessons.isAvailableForTrial"] = isTrial;
	}
	const include = includeLessonDetails
		? [
				"chapters.sections.sectionLessons.lesson.attachments",
				"chapters.sections.sectionLessons.lesson.questions",
				"chapters.sections.sectionLessons.lesson.questions.caseStudyQuestions",
				"chapters.sections.sectionLessons.lesson.interactiveBlocks.video",
				"chapters.sections.sectionLessons.lesson.interactiveBlocks.segments.type",
				"chapters.sections.sectionLessons.lesson.interactiveBlocks.question.type",
				"chapters.sections.sectionLessons.lesson.video",
				"chapters.sections.sectionLessons.lesson.trailer",
				"chapters.courseChapterDataItems"
		  ]
		: ["chapters.sections.sectionLessons.lesson.trailer"];
	const { items: courses } = await coursesService.find({
		filters,
		include,
		orderBy: {
			"chapters.order": "ASC",
			"chapters.sections.order": "ASC",
			"chapters.sections.sectionLessons.order": "ASC"
		}
	});
	return Promise.resolve(courses as ICourseWithCompletion[]);
};
