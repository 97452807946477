export const ERROR_MESSAGES = {
	EMAIL_VERIFY_BUTTON_DISPLAY: "Resend the email verfication"
};

export enum UserSubscriptionTypeCategories {
	Course = 1,
	LocationPackage = 2,
	LocationPerSeat = 3,
	QuestionBankOnly = 4
}

export const USA_COUNTRY_ID = 1;

export const LAST_DAYS_IN_SUBSCRIPTION = 3;
