import React from "react";

import { BoxProps } from "@material-ui/core";

import ContentLoader from "./ContentLoader";

import HeaderContainer, { HeaderWrapperProps } from "../../../components/HeaderContainer/HeaderContainer";
import { Container, RowWrapper } from "../styles";

export type WrapperProps = HeaderWrapperProps &
	BoxProps & {
		infoBlock?: React.ReactNode;
		showLoader?: boolean;
		hideTable?: boolean;
		restContent?: React.ReactNode;
	};
const Wrapper: React.FC<WrapperProps> = ({
	children,
	heading,
	breadcrumb,
	actions,
	infoBlock,
	showLoader = false,
	hideTable = false,
	restContent,
	customActionStyle,
	...rest
}) => (
	<Container {...rest}>
		<HeaderContainer
			heading={heading}
			breadcrumb={breadcrumb}
			actions={actions}
			customActionStyle={customActionStyle}
		/>
		{infoBlock && <RowWrapper $isFlex>{infoBlock}</RowWrapper>}
		{!hideTable && (
			<RowWrapper mr={1.3} mt={1}>
				{showLoader ? <ContentLoader /> : children}
			</RowWrapper>
		)}
		{restContent}
	</Container>
);

export default Wrapper;
