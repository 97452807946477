import { Box, alpha } from "@material-ui/core";

import styled from "styled-components";

const RandomBackground =
	"https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80";

export const SignupContainer = styled(Box)<{ customBgImg: string }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-flow: column;
	background: ${({ customBgImg, theme }) =>
		`${theme.palette.background.default} url("${customBgImg || RandomBackground}") center center no-repeat`};
	background-size: cover;
`;

export const SignUpOverlay = styled(Box)`
	position: absolute;
	left: 0;
	right: 0;
	height: 100%;
	background: linear-gradient(
		180deg,
		hsla(219, 11%, 37%, 0) 0%,
		hsla(221, 11%, 27%, 0.54) 45%,
		hsl(224, 18%, 12%) 100%
	);
`;

export const SignupContent = styled(Box)<{ gradient: boolean }>`
	display: flex;
	flex-flow: row nowrap;
	flex-grow: 1;

	&.gradient {
		background: ${({ theme }) =>
			`radial-gradient(circle at -100% 50%, ${theme.palette.background.paper}, ${
				theme.palette.background.paper
			} 75%, ${alpha(theme.palette.background.paper, 0.6)} 100%, ${alpha(theme.palette.background.paper, 0.2)} 100%)`};
	}
`;

export const Form = styled(Box)`
	width: ${({ $isPasswordReset }) => ($isPasswordReset ? "600px" : "auto")};
	display: flex;
	flex-flow: column;
	margin-bottom: 50px;
	${({ theme }) => theme.breakpoints.down("sm")} {
		padding: 0 16px;
	}
`;

export const FormContainer = styled(Box)<{ transparent: boolean }>`
	${({ theme }) => theme.breakpoints.down("sm")} {
		flex: auto;
	}
	${({ theme }) => theme.breakpoints.up("sm")} {
		flex: 650px 0 0;
		padding: 40px;
	}
	background: ${({ transparent, theme }) => (transparent ? "transparent" : theme.palette.background.paper)};
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
`;

export const CenterBlockWrapper = styled(Box)`
	margin: auto;
	max-width: 998px;
	z-index: 1;

	${({ theme }) => theme.breakpoints.down("sm")} {
		max-width: 100%;
	}
`;
