import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const useCaptchaToken = (): { handleGenerateCaptchaToken: () => Promise<string> } => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleGenerateCaptchaToken: () => Promise<string> = () => {
		return new Promise(async (resolve, reject) => {
			if (!executeRecaptcha) {
				return reject("Execute recaptcha not yet available");
			}

			const token = await executeRecaptcha("verify_captcha");
			return resolve(token);
		});
	};

	return { handleGenerateCaptchaToken };
};

export default useCaptchaToken;
