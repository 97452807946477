import React, { memo } from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { FooterContainer, LeftContainer, RightContainer, Text } from "./styles";

import { GLOBAL_CONSTANTS } from "../../../../constants";

const FooterNav = ({ showSidebar = false }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const year = new Date().getFullYear();

	return (
		<FooterContainer component="footer" isMobile={isMobile} showSidebar={showSidebar}>
			<RightContainer isMobile={isMobile}>
				<Box>
					<Text showSidebar={showSidebar} noCursor>
						© {year} ReMar. All rights reserved.
					</Text>
				</Box>
			</RightContainer>
			<LeftContainer isMobile={isMobile}>
				<Box>
					<Text
						showSidebar={showSidebar}
						onClick={() => {
							window.open(`${GLOBAL_CONSTANTS.MARKETING_WEBSITE}/vit/faq`);
						}}
					>
						FAQ
					</Text>
					<Text
						showSidebar={showSidebar}
						onClick={() => {
							window.open(`${GLOBAL_CONSTANTS.MARKETING_WEBSITE}/vit/terms-conditions`);
						}}
					>
						Terms of service
					</Text>
					<Text
						showSidebar={showSidebar}
						onClick={() => {
							window.open(`${GLOBAL_CONSTANTS.MARKETING_WEBSITE}/vit/privacy-policy`);
						}}
					>
						Privacy policy
					</Text>
				</Box>
			</LeftContainer>
		</FooterContainer>
	);
};

export default memo(FooterNav);
