const cyanBlue = {
	100: "hsl(212,100%,94%)",
	200: "hsl(211,100%,89%)",
	250: "hsl(211, 83%, 77%)",
	300: "hsl(210,91%,61%)",
	400: "hsl(210,84%,52%)",
	500: "hsl(210,84%,41%)",
	600: "hsl(210,84%,41%)",
	700: "hsl(211,77%,24%)",
	800: "hsl(211,55%,24%)"
};

export default cyanBlue;
