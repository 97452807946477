import { Color } from "@material-ui/lab";
import { Action, ThunkAction } from "@reduxjs/toolkit";

import { SetImageUrlGenericApiService, setImageUrlFromFile } from "./setImageUrlFromFile";
import { SetStateValue } from "./setStateValue";

import { performFileUpload } from "../serviceUtils";

function _emitSignature<
	ReturnType,
	RootState,
	AppThunk extends ThunkAction<ReturnType, RootState, unknown, Action<string>>
>(message: string, color: Color): AppThunk {
	console.log(message, color);
	return undefined as unknown as AppThunk;
}
function uploadFileSignature(data: { file: Partial<File> }, options: UploadFileOptions): void {
	console.log(data, options);
}

function setValueSignature(name: string): void {
	console.log(name);
}

export interface UploadFileOptions {
	_emit: unknown;
	// eslint-disable-next-line no-unused-vars
	genericApiService?: SetImageUrlGenericApiService;
	imageUrlStatePath?: string;
	loaderStatePath?: string;
	onErr?: () => void;
	uploadCb?: () => void;
	onProgress?: ({}: { loaded: number; total: number }) => void;
	setValue?: typeof setValueSignature;
	s3KeyStatePath?: string;
	fileNameStatePath?: string;
	s3UrlStatePath?: string;
	setStateValue: SetStateValue;
}

export type UploadFile = typeof uploadFileSignature;

export function uploadFile<
	ReturnType,
	RootState,
	AppThunk extends ThunkAction<ReturnType, RootState, unknown, Action<string>>
>(data: { file: Partial<File> }, options: UploadFileOptions): AppThunk {
	const {
		_emit,
		genericApiService,
		imageUrlStatePath,
		loaderStatePath,
		onErr,
		onProgress,
		s3KeyStatePath,
		setValue,
		fileNameStatePath,
		setStateValue,
		uploadCb
	} = options;
	return (async dispatch => {
		if (loaderStatePath) {
			dispatch(setStateValue({ key: loaderStatePath, value: true }));
		}
		try {
			const { key: s3Key } = await performFileUpload(data, { onProgress });
			if (setValue) {
				setValue(s3Key);
			} else if (s3KeyStatePath) {
				dispatch(setStateValue({ key: s3KeyStatePath, value: s3Key }));
			}
			if (fileNameStatePath) {
				dispatch(setStateValue({ key: fileNameStatePath, value: data.file.name }));
			}
			if (imageUrlStatePath && genericApiService) {
				dispatch(
					setImageUrlFromFile(
						{
							imageKey: s3Key,
							imageUrlStatePath: imageUrlStatePath
						},
						{ _emit, genericApiService, setStateValue }
					)
				);
			}
		} catch (e) {
			console.error(e);
			dispatch((_emit as typeof _emitSignature)("Error during upload", "error"));
			if (onErr) {
				onErr();
			}
		}
		uploadCb && uploadCb();
		if (loaderStatePath) {
			dispatch(setStateValue({ key: loaderStatePath, value: false }));
		}
	}) as unknown as AppThunk;
}
