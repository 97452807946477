import { useCallback, useEffect } from "react";

export const useDraggedScroll = (): void => {
	const windowScrollCallback = useCallback(e => {
		const y = e.clientY;

		if (y < window.innerHeight * 0.1) {
			window.scrollTo(0, window.scrollY - 0.01 * window.innerHeight);
		} else if (y > window.innerHeight * 0.9) {
			window.scrollTo(0, window.scrollY + 0.01 * window.innerHeight);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("dragover", windowScrollCallback);

		return () => {
			window.removeEventListener("dragover", windowScrollCallback);
		};
	}, []);
};
