import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	CourseChapterSectionLesson,
	ICourseWithCompletion,
	Lesson,
	School,
	UserCertificate
} from "@remar/shared/dist/models";
import { handleStripePaymentConfirmation } from "@remar/shared/dist/utils/auth";
import { getCoursesLessons } from "@remar/shared/dist/utils/sliceUtils";

import { RootState } from "store";

import { UnlockChapterDtoResult, lessonsService, schoolsService, usersService } from "store/services";

interface CourseCompletionState {
	isCourseComplete: boolean;
	isCourseCompleteLoading: boolean;
}
interface CourseState {
	trialCourses: ICourseWithCompletion[];
	selectedCourseIndex: number | null;
	selectedLessonId: number | null;
	selectedIntroLessonIndex: number | null;
	selectedSectionLesson: CourseChapterSectionLesson | null;
	introLessons: Lesson[];
	introLessonsLoading: boolean;
	isLoading: boolean;
	courseCompletion: CourseCompletionState;
	errorMessage: string;
	schoolList: School[];
	muted: boolean;
	certificate?: UserCertificate | null;
	unlockLoading: boolean;
	isChapterSuccessfullyUnlocked: boolean | null;
	unlockChapterPrice: number | null;
}
const initialState: CourseState = {
	introLessons: [],
	introLessonsLoading: false,
	isLoading: true,
	courseCompletion: {
		isCourseComplete: false,
		isCourseCompleteLoading: false
	},
	selectedCourseIndex: null,
	selectedLessonId: null,
	selectedIntroLessonIndex: null,
	selectedSectionLesson: null,
	errorMessage: "",
	trialCourses: [],
	schoolList: [],
	muted: true,
	certificate: null,
	unlockLoading: false,
	isChapterSuccessfullyUnlocked: null,
	unlockChapterPrice: null
};

export const loadTrialCourse = createAsyncThunk(
	"course/trialCourse",
	async (
		{ ids, isTrial, includeLessonDetails = false }: { ids: number[]; isTrial: boolean; includeLessonDetails?: boolean },
		{ rejectWithValue }
	) => {
		try {
			const courses = await getCoursesLessons(ids, isTrial, includeLessonDetails);
			return { courses };
		} catch (e) {
			const error = e as { message: string };
			return rejectWithValue((error && error.message) || "An error has occurred.");
		}
	}
);

export const fetchIntroLessons = createAsyncThunk("course/fetchIntroLessons", async (_, { rejectWithValue }) => {
	return await lessonsService
		.find({
			filters: { isIntro: true, isActive: true },
			findAll: true,
			include: ["interactiveBlocks.video"]
		})
		.catch(err => rejectWithValue(err.message));
});

export const getCourseCompletion = createAsyncThunk(
	"course/courseCompletion",
	async (_, { rejectWithValue }) => {
		return await usersService.getUserCertificate().catch(({ message }) => rejectWithValue(message));
	},
	{
		condition: (_, { getState }) => {
			const { courses } = getState() as RootState;
			return !courses.courseCompletion.isCourseCompleteLoading;
		}
	}
);

export const getSchools = createAsyncThunk("course/getschools", async (_, { rejectWithValue, dispatch }) => {
	return await schoolsService
		.find({ findAll: true, orderBy: { name: "ASC" } })
		.then(res => dispatch(setSchools(res)))
		.catch(() => rejectWithValue("Error in getting schools"));
});

export const setSelectedLesson = createAsyncThunk("course/selectedLesson", async (lessonId: number, {}) => lessonId);

export const unlockChapter = createAsyncThunk(
	"course/unlockChapter",
	async (
		{
			isPaid,
			chapterId,
			paymentProviderId,
			paymentProviderPaymentMethodIdentifier,
			stripe
		}: {
			isPaid: boolean;
			chapterId: number;
			paymentProviderId?: number;
			paymentProviderPaymentMethodIdentifier?: string;
			stripe;
		},
		{ rejectWithValue }
	) => {
		if (isPaid) {
			const res = await usersService
				.unlockPayedChapter({ chapterId, paymentProviderId, paymentProviderPaymentMethodIdentifier })
				.catch(rejectWithValue);
			if (res.paymentNeedsConfirmation) {
				const { success, error } = await handleStripePaymentConfirmation(
					stripe!,
					res!.subscriptionPaymentIntentClientSecret as string
				);
				if (!success && error) {
					throw new Error("Your payment did not succeed. Please try again");
				}
			}
			res.chapterId = chapterId;
			return res;
		} else {
			return await usersService.unlockChapter(chapterId).catch(rejectWithValue);
		}
	}
);

export const courseSlice = createSlice({
	name: "course",
	initialState,
	reducers: {
		unlockLesson: (state, action: PayloadAction<{ lessonId: number }>) => {
			const lesson = state.trialCourses // todo: place to refactor to optimize work with array
				.flatMap(({ chapters }) => chapters)
				.flatMap(item => item!.sections!)
				.flatMap(item => item.sectionLessons!)
				.find(({ lessonId }) => lessonId === action.payload.lessonId);

			if (lesson) {
				lesson.isLockedForCurrentUser = false;
			}
		},
		setSchools: (state, { payload: { items } }: PayloadAction<{ items: School[] }>) => {
			state.schoolList = items;
		},
		setMuted: (state, { payload }: PayloadAction<boolean>) => {
			state.muted = payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload;
		},
		resetSelectedLesson: state => {
			state.selectedSectionLesson = null;
		},
		resetChapterUnlock: state => {
			state.isChapterSuccessfullyUnlocked = null;
		},
		setUnlockChapterPrice: (state, { payload }: PayloadAction<number>) => {
			state.unlockChapterPrice = payload;
		}
	},
	extraReducers: {
		[getCourseCompletion.pending.type]: state => {
			state.courseCompletion.isCourseCompleteLoading = true;
		},
		[getCourseCompletion.fulfilled.type]: (state, { payload }: PayloadAction<UserCertificate>) => {
			if (payload) {
				state.certificate = payload;
				state.courseCompletion.isCourseComplete = true;
			}
			state.courseCompletion.isCourseCompleteLoading = false;
		},
		[getCourseCompletion.rejected.type]: state => {
			state.courseCompletion.isCourseComplete = false;
			state.courseCompletion.isCourseCompleteLoading = false;
		},
		[loadTrialCourse.pending.type]: state => {
			state.isLoading = true;
		},
		[loadTrialCourse.fulfilled.type]: (
			state,
			{ payload: { courses } }: PayloadAction<{ courses: ICourseWithCompletion[] }>
		) => {
			state.trialCourses = courses;
			state.isLoading = false;
		},
		[loadTrialCourse.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[fetchIntroLessons.pending.type]: state => {
			state.introLessonsLoading = true;
		},
		[fetchIntroLessons.fulfilled.type]: (state, { payload }) => {
			state.introLessonsLoading = false;
			state.introLessons = payload.items;
		},
		[fetchIntroLessons.rejected.type]: state => {
			state.introLessonsLoading = true;
		},
		[setSelectedLesson.fulfilled.type]: (state, { payload: selectedLessonId }) => {
			const { trialCourses } = state;
			state.selectedLessonId = selectedLessonId;
			state.selectedSectionLesson = null;
			state.selectedCourseIndex = null;
			for (const i in trialCourses) {
				const courseItem = trialCourses[i];
				if (
					// todo: refactor stairs below
					typeof courseItem.chapters!.find(
						chapterItem =>
							typeof chapterItem.sections!.find(
								({ sectionLessons }) =>
									typeof sectionLessons!.find(slItem => {
										if (slItem.lessonId === selectedLessonId) {
											state.selectedSectionLesson = slItem;
											return true;
										}
										return false;
									}) !== "undefined"
							) !== "undefined"
					) !== "undefined"
				) {
					state.selectedCourseIndex = parseInt(i, 10);
					break;
				}
			}
			const introLessonIndex = state.introLessons.findIndex(({ id }) => id === selectedLessonId);
			state.selectedIntroLessonIndex = introLessonIndex !== -1 ? introLessonIndex : null;
		},
		[unlockChapter.pending.type]: state => {
			state.unlockLoading = true;
			state.isChapterSuccessfullyUnlocked = null;
		},
		[unlockChapter.fulfilled.type]: (state, { payload }: PayloadAction<UnlockChapterDtoResult>) => {
			const chapter = state.trialCourses
				.flatMap(({ chapters }) => chapters)
				.find(item => item!.id === payload.chapterId);

			if (chapter) {
				chapter.lockedChapter = false;
			}
			state.unlockLoading = false;
			state.isChapterSuccessfullyUnlocked = true;
		},
		[unlockChapter.rejected.type]: state => {
			state.unlockLoading = false;
			state.isChapterSuccessfullyUnlocked = false;
		}
	}
});

export const {
	unlockLesson,
	setSchools,
	setMuted,
	setLoading,
	resetSelectedLesson,
	resetChapterUnlock,
	setUnlockChapterPrice
} = courseSlice.actions;

export const selectCourseInfoForSelectedLesson = ({
	courses: { trialCourses, selectedCourseIndex, selectedSectionLesson }
}: RootState): { course: ICourseWithCompletion | null; sectionLesson: CourseChapterSectionLesson | null } => {
	return !trialCourses.length || selectedCourseIndex === null || selectedSectionLesson === null
		? {
				course: null,
				sectionLesson: null
		  }
		: { course: trialCourses[selectedCourseIndex], sectionLesson: selectedSectionLesson };
};

export const selectFullState = (state: RootState): CourseState => state.courses;
export const selectIsCourseComplete = ({ courses: { courseCompletion } }: RootState): CourseCompletionState =>
	courseCompletion;
export const selectMuted = ({ courses: { muted } }: RootState): boolean => !!muted;
export const getCertificate = (state: RootState): UserCertificate | undefined => state.courses.certificate;

// export const selectSchoolList = ({ courses: { schoolList } }: RootState): School[] | null => schoolList; // not used

export const selectTrialCoursesInfo = ({
	courses: { trialCourses, isLoading, errorMessage }
}: RootState): { trialCourses: ICourseWithCompletion[]; isLoading: boolean; errorMessage: string } => ({
	trialCourses,
	isLoading,
	errorMessage
});

export default courseSlice.reducer;
