import { useContext } from "react";

import { Analytics, logEvent } from "firebase/analytics";
import { GoogleAnalytics } from "index";

type ContextProps = {
	eventName: string;
	eventIdentifier?: string;
	contentIdentifier?: string;
};

const useAnalyticsEventTracker = (contentType: string) => {
	const context = useContext(GoogleAnalytics);
	return ({ eventName, eventIdentifier = undefined, contentIdentifier = undefined }: ContextProps) => {
		if (context) {
			const id = `${contentIdentifier ?? localStorage.getItem("analyticsId")}`;
			logEvent(context as Analytics, eventName, {
				content_type: contentType,
				content_id: `${id}`,
				["event_identifier"]: `${!eventIdentifier ? "" : eventIdentifier}`
			});
		}
	};
};

export default useAnalyticsEventTracker;
