import { useEffect, useRef, useState } from "react";

type NewValCallbackType<T> = (prevVal: T) => void;
type setStateWithCallbackType<T> = (newVal: T | NewValCallbackType<T>, callback?: () => void) => void;
const useStateWithCallback = <T>(initialVal: T): [T, setStateWithCallbackType<T>] => {
	const [value, setValue] = useState(initialVal);
	const callbackRef = useRef<() => void>();

	useEffect(() => {
		if (callbackRef.current) {
			callbackRef.current();
		}
	}, [value]);

	const setStateCallback = (newVal, callback) => {
		if (typeof newVal === "function") {
			setValue(newVal(value));
		} else {
			setValue(newVal);
		}

		if (callback) {
			callbackRef.current = callback;
		}
	};
	return [value, setStateCallback];
};

export default useStateWithCallback;
