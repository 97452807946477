import React, { useState } from "react";

import { Box, IconButton, Modal, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Button from "@remar/shared/dist/components/Button";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { useSimpleModalStyles } from "@remar/shared/dist/modals/SimpleModal/styles";

import { useAppSelector } from "store";

import { selectUser } from "store/features/Auth/authSlice";

import PreventCancelSubscription from "./PreventCancelSubsription";
import SubscriptionCancellation from "./SubscriptionCancellation";
import { CancelSubModalWrapper, useStyles } from "./styles";

const CancelSubscriptionButton = ({ id, refetchData }: { id: number; refetchData: () => void }) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const simpleModalClasses = useSimpleModalStyles();
	const { allowedLocations } = useAppSelector(selectUser);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [openPreventCancelWindow, setOpenPreventCancelWindow] = useState(false);
	const [cancellationOptionsModalOpen, setCancellationOptionsModalOpen] = useState(false);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const onClose = () => {
		setCancelModalOpen(false);
	};

	const onConfirm = () => {
		setCancelModalOpen(false);
		setCancellationOptionsModalOpen(true);
	};
	return (
		<Box display="flex" justifyContent={"center"}>
			<Button
				variant={"ghost"}
				color={"secondary"}
				onClick={() => {
					allowedLocations?.some(l => l.coverStudentCharges)
						? setOpenPreventCancelWindow(true)
						: setCancelModalOpen(true);
				}}
			>
				Cancel Subscription
			</Button>
			<SimpleModal
				theme={theme}
				title="Cancel Subscription"
				open={cancelModalOpen}
				onClose={onClose}
				text=""
				extraContent={
					<Box mb={1}>
						<Typography color={"textSecondary"}>Are you sure you want to cancel your subscription?</Typography>
					</Box>
				}
				footer={
					<Box className={classes.buttonContainer}>
						<Button variant={"outlined"} color={"basic"} size="medium" onClick={onClose}>
							{`No, Keep ${isMobile ? "it" : "Subscription"}`}
						</Button>
						<Button variant={"filled"} color={"danger"} size="medium" onClick={() => onConfirm()}>
							Yes, Cancel
						</Button>
					</Box>
				}
			/>

			<Modal
				open={cancellationOptionsModalOpen}
				className={simpleModalClasses.modal}
				onClose={() => setCancellationOptionsModalOpen(false)}
			>
				<CancelSubModalWrapper width={800}>
					<Box className={"header"}>
						<Typography className={simpleModalClasses.title}>Cancel Subscription</Typography>
						<IconButton
							onClick={() => {
								setCancellationOptionsModalOpen(false);
								refetchData();
							}}
						>
							<CloseIcon color="action" />
						</IconButton>
					</Box>
					<SubscriptionCancellation subscriptionId={id} onClose={() => setCancellationOptionsModalOpen(false)} />
				</CancelSubModalWrapper>
			</Modal>
			<Modal open={openPreventCancelWindow} onClose={() => setOpenPreventCancelWindow(false)}>
				<PreventCancelSubscription
					onClose={() => setOpenPreventCancelWindow(false)}
					locationName={allowedLocations?.find(l => l.coverStudentCharges)?.name}
				/>
			</Modal>
		</Box>
	);
};

export default CancelSubscriptionButton;
