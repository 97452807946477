import React from "react";

import { CircularProgress } from "@material-ui/core";

import { LoadingWrapper } from "../styles";

const ContentLoader = ({ height, size }: { height?: number | string; size?: string }) => (
	<LoadingWrapper height={height}>
		<CircularProgress size={size ? size : "7rem"} color="primary" thickness={5} variant="indeterminate" />
	</LoadingWrapper>
);

export default ContentLoader;
