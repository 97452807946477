import { makeStyles } from "@material-ui/core";
import { alpha, createGenerateClassName } from "@material-ui/core/styles";

import * as defaultColors from "../../colors";

import { IExtendedTheme } from "../../theme/default";

export const generateClassName = createGenerateClassName({
	disableGlobal: true,
	seed: "ReMarCustomInputComponent"
});

export const useCustomInputStyles = makeStyles((theme: IExtendedTheme) => {
	const colors = theme.palette.colors || defaultColors;
	return {
		bottomLabel: {
			color: colors.primary[1400],
			fontSize: "1rem",
			fontWeight: 400
		},
		cancelContainer: {
			position: "relative",
			"& .MuiIconButton-root:hover": {
				backgroundColor: colors.danger[100]
			}
		},
		imgContainer: {
			display: "flex",
			width: "264px",
			height: "175px",
			justifyContent: "center",
			margin: "auto",
			position: "relative"
		},
		imgContainerBorder: {
			border: `1px dashed ${"hsl(219,35%,92%)"}` // todo add into colorset
		},
		cancelIcon: {
			width: "0.6em",
			height: "0.6em",
			fill: colors.danger[500]
		},
		cancelThumbnail: {
			position: "absolute",
			zIndex: 1,
			right: -theme.spacing(1),
			top: -theme.spacing(1),
			borderRadius: "4px",
			backgroundColor: colors.danger[100]
		},
		chips: {
			display: "flex",
			flexWrap: "wrap"
		},
		chip: {
			margin: 2,
			maxWidth: "100%",
			"& > span": {
				textTransform: "none"
			}
		},
		errorMessage: {
			color: "red",
			fontSize: "12px",
			marginLeft: "12px",
			position: "absolute"
		},
		image: {
			maxWidth: "100%",
			maxHeight: "100%"
		},
		optionCheckbox: {
			border: `1px solid ${colors.basic[750]}`,
			borderRadius: theme.shape.borderRadius,
			width: 32,
			height: 32,
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		topLevelContainerWithError: {
			"& div[role=button]": {
				color: "red"
			}
		},
		uploader: {
			"& .explorer-uploader": {
				backgroundColor: theme.palette.background.default,
				"& .uploader-content": {
					color: alpha(colors.basic[100], 0.63),
					textTransform: "none"
				}
			}
		},
		switchBase: {
			"&$checked": {
				color: colors.primary[400]
			},
			"&$checked + $track": {
				backgroundColor: colors.primary[200]
			}
		},
		checked: {},
		track: {},
		radioDisabled: {
			color: colors.basic[100],
			opacity: 0.5,
			fontSize: "1rem !important"
		},
		selectRoot: {
			backgroundColor: theme.palette.background.default
		}
	};
});
