import { v4 as uuid } from "uuid";

export const getTracingId = (): string => {
	const key = "tracingId";
	let value = localStorage.getItem(key);
	if (!value) {
		value = uuid();
		localStorage.setItem(key, value);
	}
	return value;
};
