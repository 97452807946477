import { Button } from "@material-ui/core";
import styled from "styled-components";

export const CalcContainer = styled.div`
	padding: 40px 16px 0 16px;
	background: ${props => props.backgroundColor};
`;

export const CalcBody = styled.div`
	display: flex;
	flex-flow: column;
	width: 232px;
	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;

export const CalcKeypad = styled.div`
	display: flex;
	flex-flow: row wrap;
`;

export const CalcButton = styled(Button).withConfig({
	shouldForwardProp: (prop: string) => prop !== "double"
})`
	flex: 0 0 ${({ double }) => (double ? 50 : 25)}%;
	min-width: 0;
	overflow: hidden;
	line-height: 46px;
	font-family: HelveticaNeue;
	font-size: 18px;
	font-weight: 300;
	text-align: center;
	color: hsl(0, 0%, 0%);
	padding: 0;
	border-radius: 0;
	background${props => props.theme.palette.colors.basic[100]};
	border-bottom: 1px solid rgba(151, 151, 151, 0.4);
	border-right: 1px solid rgba(151, 151, 151, 0.4);

	&:nth-child(4n),
	&:last-child {
		border-right: none;
		background: hsl(224, 44%, 51%);
		color${props => props.theme.palette.colors.basic[100]};
	}

	&:nth-child(16n + 1),
	&:nth-child(16n + 2),
	&:nth-child(16n + 3) {
		border-bottom: none;
	}
`;

export const CalcDisplay = styled.div`
	font-family: HelveticaNeue;
	font-size: ${({ error }) => (error ? 25 : 36)}px;
	font-weight: 100;
	color${props => props.theme.palette.colors.basic[100]};
	background: hsl(222, 64%, 42%);
	padding: 0 8px;
	text-align: right;
	position: relative;
	height: 82px;
	display: flex;
	align-items: flex-end;
	justify-content: right;
	flex-flow: row wrap;
`;

export const Close = styled.div`
	position: absolute;
	top: 6px;
	right: 6px;
	font-weight: bold;
	font-size: 20px;
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
