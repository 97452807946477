import { Button, Checkbox } from "@material-ui/core";

import styled from "styled-components";

export const TransparentButton = styled(Button)`
	font-weight: bold;
	color: ${props => props.textColor || props.theme.palette.colors.basic[100]};
	margin: 0 5px;
`;

export const StyledCheckbox = styled(Checkbox)`
	margin-right: 0;
	margin-top: -2px;
	color: hsl(222, 64%, 42%);

	& svg {
		font-size: 24px;
		color: ${props =>
			props.checked ? props.theme.palette.colors.primary[500] : props.theme.palette.colors.basic[600]};
	}
`;
