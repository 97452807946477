import React, { useState } from "react";

import { Hidden, IconButton, useMediaQuery, useTheme } from "@material-ui/core";

import { StyledDrawer as Drawer, DrawerCloseIconContainer, HamburgerIcon } from "./styles";

import CloseCircleSvg from "../../../../assets/icons/icon-close-circle.svg";
import HamburgerSvg from "../../../../assets/icons/icon-hamburger.svg";

import SideBar from "../SideBar";
import { SideNavItem, SideNavItemNested } from "../types";

type Props = {
	navItemList: (SideNavItem | SideNavItemNested)[];
	logo: React.ReactNode;
	isTrial?: boolean;
	isCustomized?: boolean;
	clickSideEffect?: (identifier: string) => void;
};
const SideNav = ({ navItemList, logo, clickSideEffect, isTrial = false, isCustomized = false }: Props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [open, setOpen] = useState(false);

	return (
		<>
			<Hidden smDown>
				<SideBar
					isMobile={isMobile}
					navItemList={navItemList}
					isTrial={isTrial}
					logo={logo}
					isCustomized={isCustomized}
				/>
			</Hidden>
			<Hidden mdUp>
				<IconButton onClick={() => setOpen(true)}>
					{!open && <HamburgerIcon src={HamburgerSvg} alt={"Hamburger icon"} />}
				</IconButton>
			</Hidden>
			<Drawer open={open} onClose={() => setOpen(false)}>
				<DrawerCloseIconContainer>
					<img alt="Close Icon" src={CloseCircleSvg} onClick={() => setOpen(false)} />
				</DrawerCloseIconContainer>
				<SideBar
					clickSideEffect={clickSideEffect}
					isMobile={isMobile}
					navItemList={navItemList}
					isTrial={isTrial}
					logo={logo}
					isCustomized={isCustomized}
				/>
			</Drawer>
		</>
	);
};

export default SideNav;
