import React, { RefObject } from "react";

import { Box, Card, Container, Grid, IconButton, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@remar/shared/dist/components/Button";

import { Field, FieldInputProps, FieldMetaProps, Form, Formik, FormikProps } from "formik";
import { TextField as MuiTextField } from "formik-material-ui";
import { RootState, useAppSelector } from "store";

import { ModalTitleText } from "modules/MyAccount/styles";

import BillingAddress from "./BillingAddress";
import { useChangePasswordStyles } from "./styles";

const initialFormValues = {
	nameOnCard: ""
};

const TextField = ({
	inputRef,
	...props
}: {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	[fieldName: string]: unknown;
}) => (
	<MuiTextField
		InputProps={{ disableUnderline: true, ref: inputRef as RefObject<unknown> }}
		variant="filled"
		{...props}
	/>
);

const EditAddPaymentMethod = ({ handleSubmit, CardElement, closeModal }) => {
	const theme = useTheme<Theme>();
	const classes = useChangePasswordStyles();
	const { error, isLoading } = useAppSelector((state: RootState) => state.billing);
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Container maxWidth="md">
			<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
				<Card>
					<Formik
						initialValues={initialFormValues}
						onSubmit={values => {
							handleSubmit(values);
						}}
					>
						{({ isValid, values, dirty }) => {
							return (
								<>
									<Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
										<ModalTitleText>Payment Method</ModalTitleText>
										<IconButton onClick={closeModal}>
											<CloseIcon color="disabled" />
										</IconButton>
									</Box>
									<Form>
										<Box className={classes.root}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={6}>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<Box mb={-1} mt={-1}>
																<Typography className={classes.formSubtitle}>Cardholder’s Name</Typography>
															</Box>
														</Grid>
														<Grid item xs={12}>
															<Field
																hiddenLabel
																component={TextField}
																name="nameOnCard"
																placeholder="Full Name"
																fullWidth
															/>
														</Grid>

														<Grid item xs={12}>
															<Box mb={-1} mt={1}>
																<Typography className={classes.formSubtitle}>Billing Details</Typography>
															</Box>
														</Grid>
														<Grid item xs={12}>
															<Box bgcolor={theme.palette.background.default} py={2} px={2} borderRadius={5}>
																<CardElement
																	options={{
																		style: {
																			base: {
																				fontSize: `"${theme.typography.pxToRem(18)}"`,
																				color: "#c2c2c2",
																				"::placeholder": {
																					color: "#aab7c4"
																				}
																			},
																			invalid: {
																				color: "#9e2146"
																			}
																		}
																	}}
																/>
															</Box>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={1}></Grid>
												<Grid item xs={12} sm={5}>
													<BillingAddress closeModal={closeModal} />
												</Grid>
											</Grid>
										</Box>
									</Form>

									{error && (
										<Box mt={3}>
											<Typography variant="caption" style={{ color: "red" }}>
												{error}
											</Typography>
										</Box>
									)}
									<Box
										display="flex"
										flexDirection={isMobile ? "column-reverse" : "row"}
										justifyContent="flex-end"
										gridGap={10}
										mt={3}
									>
										<Button fullWidth={isMobile} color="secondary" variant="filled" onClick={closeModal}>
											Cancel
										</Button>
										{isMobile && <Box width="100%" mt={1} />}
										<Button
											loading={isLoading}
											color="primary"
											disabled={!isValid || !dirty || isLoading}
											variant="filled"
											fullWidth={isMobile}
											onClick={() => handleSubmit(values)}
										>
											Save
										</Button>
									</Box>
								</>
							);
						}}
					</Formik>
				</Card>
			</Box>
		</Container>
	);
};
export default EditAddPaymentMethod;
