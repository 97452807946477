import React from "react";

import { Grid } from "@material-ui/core";

import { ReactComponent as IconArrowUp } from "assets/icons/icon-arrow-up.svg";
import { ReactComponent as IconStudentHat } from "assets/icons/icon-student-hat.svg";

import {
	CreateTestBannerContainer,
	CreateTestBannerGridContainer,
	CreateTestBannerHeading,
	CreateTestBannerText,
	CreateTestBannerTextContainer
} from "./styles";

const CreateTestBanner = () => (
	<CreateTestBannerGridContainer container>
		<Grid item xs={12} md={7}>
			<CreateTestBannerContainer>
				<IconStudentHat />
				<CreateTestBannerTextContainer>
					<CreateTestBannerHeading>Create Test</CreateTestBannerHeading>
					<CreateTestBannerText>
						Start by creating your own test now! Take advantage of the ReMar question bank and test experience.
					</CreateTestBannerText>
				</CreateTestBannerTextContainer>
				<IconArrowUp />
			</CreateTestBannerContainer>
		</Grid>
	</CreateTestBannerGridContainer>
);

export default CreateTestBanner;
