import React from "react";

import { TableWrapper, TableWrapperProps, Wrapper, WrapperProps } from "./components";

import InfoBlock from "../../components/InfoBlock";
import { InfoBlockProps } from "../../components/InfoBlock/InfoBlock";

type TableContentLayoutProps<TDto> = Omit<WrapperProps, "infoBlock"> &
	TableWrapperProps<TDto> & {
		infoBlockItems?: Array<InfoBlockProps>;
		trialBlock?: React.ReactNode;
	};
const WithTableContentLayout = <T extends Record<string, unknown>>({
	heading,
	breadcrumb,
	actions,
	infoBlockItems,
	trialBlock,
	children,
	hideTable,
	customActionStyle,
	...rest
}: TableContentLayoutProps<T>) => (
	<Wrapper
		heading={heading}
		hideTable={hideTable}
		breadcrumb={breadcrumb}
		actions={actions}
		customActionStyle={customActionStyle}
		infoBlock={infoBlockItems?.map(({ title, ...r }) => (
			<InfoBlock key={title} title={title} {...r} />
		))}
		restContent={children}
	>
		{trialBlock}
		<TableWrapper {...rest} />
	</Wrapper>
);

export default WithTableContentLayout;
