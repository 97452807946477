import React from "react";

import { Box, Typography, useTheme } from "@material-ui/core";

import { NavigateNext } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { Header, HeaderContainer, StyledBreadcrumb } from "./styles";

import { IExtendedTheme } from "../../theme/default";
import Text from "../Text";

export interface IBreadCrumb {
	title: string;
	key: number;
	link?: string | undefined;
	onClick?: () => void;
}
export interface HeaderWrapperProps {
	heading: string;
	breadcrumb?: Array<IBreadCrumb>;
	breadcrumbSeparator?: React.ReactNode | null;
	actions?: React.ReactNode;
	customHeaderStyle?: React.CSSProperties;
	customActionStyle?: React.CSSProperties;
}

const HeaderWrapper = ({
	heading,
	breadcrumb,
	breadcrumbSeparator = null,
	actions,
	customHeaderStyle,
	customActionStyle
}: HeaderWrapperProps) => {
	const theme = useTheme<IExtendedTheme>();

	const activeBreadCrumbStyle = {
		color: theme.palette.text.primary,
		fontSize: "13px",
		margin: 0
	};

	return (
		<HeaderContainer style={customHeaderStyle}>
			<Header>
				<Text type="h6" color="primary">
					{heading}
				</Text>
				{!!breadcrumb?.length && (
					<StyledBreadcrumb
						style={{ marginTop: "13px", paddingRight: "4px" }}
						className="breadcrumb-container"
						separator={breadcrumbSeparator ?? <NavigateNext fontSize="small" />}
					>
						{breadcrumb.map(({ link, title, onClick }, index) => {
							const isLastBreadCrumb = breadcrumb.length > 1 && index === breadcrumb.length - 1;

							const text = (
								<Typography
									color="secondary"
									style={
										isLastBreadCrumb
											? activeBreadCrumbStyle
											: {
													margin: 0,
													color: theme.palette.text.secondary
											  }
									}
									onClick={onClick}
									key={`breadcrumb_${index}`}
								>
									{title}
								</Typography>
							);

							return link ? (
								<Link key={`breadcrumb_${index}`} style={{ textDecoration: "none" }} to={link}>
									{text}
								</Link>
							) : (
								text
							);
						})}
					</StyledBreadcrumb>
				)}
			</Header>
			{actions && <Box style={customActionStyle}>{actions}</Box>}
		</HeaderContainer>
	);
};

export default HeaderWrapper;
