import React from "react";

import { Integrations } from "@remar/shared/dist/models";

import { ReactComponent as Discover } from "assets/images/cards/discover.svg";
import { ReactComponent as Express } from "assets/images/cards/express.svg";
import { ReactComponent as Master } from "assets/images/cards/master.svg";
import { ReactComponent as Visa } from "assets/images/cards/visa.svg";

const accountDetailsKeys = ["firstName", "lastName", "email", "password", "confirmPassword"];
const BookAccountDetailsKeys = [
	"firstName",
	"lastName",
	"email",
	"countryId",
	"address1",
	"city",
	"state",
	"zipCode",
	"phoneNumber"
];
const invitationAccountDetailsKeys = ["firstName", "lastName", "email", "password"];
const addressDetailsKeys = ["address1", "countryId", "city", "phoneNumber", "state", "zip"];
const paymentDetailsKeys = ["nameOnCard", "validCardDetails", "terms"];
export const signUpFormKeys = [accountDetailsKeys, addressDetailsKeys, paymentDetailsKeys];
export const invitationSignUpFormKeys = [invitationAccountDetailsKeys, addressDetailsKeys, paymentDetailsKeys];
export const upgradeFormKeys = [addressDetailsKeys, paymentDetailsKeys];
export const socialSignUpFormKeys = [addressDetailsKeys, paymentDetailsKeys];
export const signUpBookFormKeys = [BookAccountDetailsKeys, paymentDetailsKeys];

export const validateFormValues = (keysToBeChecked, values) => {
	const formKeys = Object.keys(values);
	return keysToBeChecked.every(val => formKeys.includes(val) && values[val]);
};

export const getCardIcon = type => {
	switch (type) {
		case "visa":
			return <Visa />;
		case "discover":
			return <Discover />;
		case "mastercard":
			return <Master />;
		case "amex":
			return <Express />;
		default:
			return <Visa />;
	}
};
export const getBookType = integrationId => {
	return integrationId !== Integrations.DigitalAssetIntegrationId ? "Physical Book" : "Digital book";
};
