import { useMemo } from "react";

import { getApp, initializeApp } from "firebase/app";

import { FIREBASE_CONFIG } from "./config";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFirebase = () => {
	return useMemo(() => {
		// check if firebase app is already initialized
		try {
			const app = getApp();

			return app ?? initializeApp(FIREBASE_CONFIG);
		} catch (error) {
			console.error("Firebase getApp()", { error });
			return initializeApp(FIREBASE_CONFIG);
		}
	}, []);
};
