import React from "react";

import { Box } from "@material-ui/core";

import { Form, FormContainer, SignupContainer, SignupContent } from "./styles";

import LogoComponent, { LogoComponentProps } from "../../components/LogoComponent";
import { FooterNav } from "../index";

type FullScreenLayoutProps = {
	customBgImage?: string;
	gradient?: boolean;
};
export const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({ children, customBgImage, gradient }) => (
	<SignupContainer customBgImg={customBgImage}>
		<SignupContent className={!!gradient ? "gradient" : undefined}>{children}</SignupContent>
		<FooterNav />
	</SignupContainer>
);

export const FullScreenWithLogoLayout: React.FC<FullScreenLayoutProps & LogoComponentProps> = ({
	children,
	logo,
	title,
	...rest
}) => (
	<FullScreenLayout {...rest}>
		<Box margin="auto" display="flex" flexDirection="column" justifyContent="space-between" gridGap={40} py={5}>
			<LogoComponent logo={logo} title={title} />
			{children}
		</Box>
	</FullScreenLayout>
);

type Props = FullScreenLayoutProps &
	LogoComponentProps & {
		$isPasswordReset?: boolean;
		isLeftBlockTransparent?: boolean;
	};
const AuthFormLayout: React.FC<Props> = ({
	logo,
	children,
	$isPasswordReset,
	title,
	customBgImage,
	gradient,
	isLeftBlockTransparent
}) => (
	<FullScreenLayout customBgImage={customBgImage} gradient={gradient}>
		<FormContainer transparent={isLeftBlockTransparent !== undefined ? isLeftBlockTransparent : !!gradient}>
			<Form $isPasswordReset={$isPasswordReset}>
				<LogoComponent logo={logo} title={title} />
				{children}
			</Form>
		</FormContainer>
	</FullScreenLayout>
);

export default AuthFormLayout;
