import React, { useEffect, useState } from "react";

import HotspotHighlightInput, {
	HighlightedNode
} from "@remar/shared/dist/components/HotspotHighlightInput/HotspotHighlightInput";

import { HotspotHighlightQuestionBox, TestQuizQuestionText } from "./styles";

const HotspotHighlightQuestion = ({ question, onChange, key: uniqueIdentifier, userAnswers }) => {
	const { data } = question;
	const [value, setValue] = useState<
		{
			highlighted: boolean;
			text: string;
		}[]
	>([{ highlighted: false, text: data.text }]);

	const onChangeHandler = newValue => {
		const ansArr = newValue
			.map(v => {
				if (v.highlighted && v.text.trim().length !== 0) {
					return { text: v.text };
				}
			})
			.filter(x => x);

		onChange([...ansArr]);
		setValue(newValue);
	};

	useEffect(() => {
		let content = data.text;

		const answerOptions: HighlightedNode[] = [];

		userAnswers.forEach(answerOption => {
			const startIndex = content.indexOf(answerOption.text);

			if (startIndex === -1) {
				return false;
			}

			const start = content.substr(0, startIndex);

			if (start) {
				answerOptions.push({ text: start, highlighted: false });
			}

			answerOptions.push({ text: answerOption.text, highlighted: true });

			content = content.substr(startIndex + answerOption.text.length);
		});

		if (content.length > 0) answerOptions.push({ text: content, highlighted: false });

		setValue(answerOptions);
	}, [data.text, userAnswers]);

	const disableInput = event => {
		event.preventDefault();
	};

	return (
		<>
			<TestQuizQuestionText key={uniqueIdentifier} />
			<HotspotHighlightQuestionBox
				onKeyPress={disableInput}
				onKeyDown={disableInput}
				onPaste={disableInput}
				fullwidth
				studentSide
			>
				<HotspotHighlightInput value={value} onChange={newValue => onChangeHandler(newValue)} student={true} />
			</HotspotHighlightQuestionBox>
		</>
	);
};
export default HotspotHighlightQuestion;
