const DOMAIN_REGEX = /(.*)\.vt\.remarnurse\.com/gi;
const RESERVED_DOMAINS = ["admin", "institution", "client"];

export const getSubDomainName = (origin?: string): string => {
	if (!origin) return "";

	const matched = DOMAIN_REGEX.exec(origin);

	const subs = (matched ? matched[1] : "").split(".");

	if (!subs?.length) return "";

	const first = subs[0].toLowerCase();

	return RESERVED_DOMAINS.includes(first) ? "" : first;
};
