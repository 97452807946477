import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";
import { BaseModel } from "@remar/shared/dist/models";

import { TestSummary, Tests } from "./dto";

import { URLS } from "../services.constants";

export class TestsService extends BaseApiService<
	Tests,
	BaseModel,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	summary(): Promise<TestSummary> {
		return request(`${this.baseUrl}/summary?filters[isPublished]=true`, "GET") as Promise<TestSummary>;
	}
}

export const testsService = new TestsService(URLS.QUIZZES);
