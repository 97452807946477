import { CaseReducer } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export function getResetState<State>(initialState: State): CaseReducer<State> {
	return ((state: State): void => {
		const newInitialState = cloneDeep(initialState);
		for (const key in state) {
			state[key] = newInitialState[key];
		}
	}) as CaseReducer<State>;
}
