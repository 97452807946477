import React, { useEffect, useMemo, useState } from "react";

import { FormControl, MenuItem, Select, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "store";
import { fetchSubjectLessonStats, getFullState } from "store/features/QuestionBank/questionBank.slice";
import { LessonQuestionStats, SubjectQuestionStats } from "store/services/subjectsLessons";

import { IconInfo } from "assets/icons";
import { ReactComponent as IconRedCross } from "assets/icons/icon-circle-cross.svg";
import { ReactComponent as IconGreenTick } from "assets/icons/icon-green-tick.svg";
import { ReactComponent as IconYellowCircle } from "assets/icons/icon-yellow-partial-circle.svg";

import {
	AnswerLabel,
	AnswerNumber,
	AnswerPercentage,
	AnswersInfoContainer,
	CorrectAnswerBar,
	IncorrectAnswerBar,
	LessonTableHeader,
	PartiallyCorrectAnswerBar,
	QuestionsRemaining,
	StackedBarGraph,
	StatsContainer,
	StatsTableBody,
	StatsTableBodyContent,
	StyledAnswersBox,
	StyledPartialAnswersBox,
	SubjectTableHeader,
	TableLabel,
	TableLabelContainer
} from "./styles";

export interface Lesson {
	id: number;
	name: string;
}

export interface AnswerPercentages {
	correctAnswers: number;
	incorrectAnswers: number;
	partiallyCorrectAnswers: number;
}

interface Props {
	stats: SubjectQuestionStats | LessonQuestionStats;
	percentages?: AnswerPercentages;
	title: string;
	totalCorrectQuestionCount: string;
	totalIncorrectQuestionCount: string;
	totalPartialCorrectQuestionCount: string;
}

const StatsLayout: React.FC<Props> = ({
	children,
	stats,
	percentages,
	title,
	totalCorrectQuestionCount,
	totalIncorrectQuestionCount,
	totalPartialCorrectQuestionCount
}) => (
	<StatsContainer>
		{children}
		<StackedBarGraph
			hasbgcolor={
				stats[totalCorrectQuestionCount] +
					stats[totalIncorrectQuestionCount] +
					stats[totalPartialCorrectQuestionCount] ===
				0
			}
		>
			<CorrectAnswerBar width={percentages?.correctAnswers} />
			<PartiallyCorrectAnswerBar width={percentages?.partiallyCorrectAnswers} />
			<IncorrectAnswerBar width={percentages?.incorrectAnswers} />
		</StackedBarGraph>
		<StyledAnswersBox>
			<AnswersInfoContainer>
				<IconGreenTick />
				<AnswerLabel>Correct Answers</AnswerLabel>
				<AnswerNumber>{stats[totalCorrectQuestionCount]}</AnswerNumber>
				<AnswerPercentage>{percentages?.correctAnswers}%</AnswerPercentage>
			</AnswersInfoContainer>
			<AnswersInfoContainer>
				<IconRedCross />
				<AnswerLabel>Incorrect Answers</AnswerLabel>
				<AnswerNumber>{stats[totalIncorrectQuestionCount]}</AnswerNumber>
				<AnswerPercentage>{percentages?.incorrectAnswers}%</AnswerPercentage>
			</AnswersInfoContainer>
		</StyledAnswersBox>
		<StyledPartialAnswersBox margin>
			<IconYellowCircle />
			<AnswerLabel>Partially Correct Answers</AnswerLabel>
			<AnswerNumber>{stats[totalPartialCorrectQuestionCount]}</AnswerNumber>
			<AnswerPercentage>{percentages?.partiallyCorrectAnswers}%</AnswerPercentage>
		</StyledPartialAnswersBox>
		<StyledPartialAnswersBox>
			<IconInfo />
			<QuestionsRemaining>
				{stats.remainingQuestionCount} Questions Remaining for this {title}
			</QuestionsRemaining>
		</StyledPartialAnswersBox>
	</StatsContainer>
);

const SubjectLessonStats = () => {
	const theme = useTheme<Theme>();
	const dispatch = useAppDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { subjects, lessonQuestionStats, subjectQuestionStats } = useAppSelector(getFullState);
	const [subjectId, setSubjectId] = useState("");
	const [lessonId, setLessonId] = useState("");
	const [lessonDropdown, setLessonDropdown] = useState<Lesson[] | undefined>([]);
	const [subjectAnswerPercentages, setSubjectAnswerPercentages] = useState<AnswerPercentages>();
	const [lessonAnswerPercentages, setLessonAnswerPercentages] = useState<AnswerPercentages>();

	const getLessons = useMemo(
		() =>
			subjects
				?.find(({ id }) => +subjectId === id)
				?.lessons?.map(({ id, name }) => ({
					id,
					name
				})),
		[subjectId, subjects]
	);

	useEffect(() => {
		setLessonDropdown(getLessons);
	}, [getLessons]);

	useEffect(() => {
		const totalAttempted =
			subjectQuestionStats.totalSubjectCorrectQuestionCount +
			subjectQuestionStats.totalSubjectIncorrectQuestionCount +
			subjectQuestionStats.totalSubjectPartialCorrectQuestionCount;

		setSubjectAnswerPercentages({
			correctAnswers: Math.floor((subjectQuestionStats.totalSubjectCorrectQuestionCount / totalAttempted) * 100) || 0,
			incorrectAnswers:
				Math.floor((subjectQuestionStats.totalSubjectIncorrectQuestionCount / totalAttempted) * 100) || 0,
			partiallyCorrectAnswers:
				Math.floor((subjectQuestionStats.totalSubjectPartialCorrectQuestionCount / totalAttempted) * 100) || 0
		});
	}, [subjectQuestionStats]);
	useEffect(() => {
		const totalAttempted =
			lessonQuestionStats.totalLessonCorrectQuestionCount +
			lessonQuestionStats.totalLessonIncorrectQuestionCount +
			lessonQuestionStats.totalLessonPartialCorrectQuestionCount;
		setLessonAnswerPercentages({
			correctAnswers: Math.floor((lessonQuestionStats.totalLessonCorrectQuestionCount / totalAttempted) * 100) || 0,
			incorrectAnswers: Math.floor((lessonQuestionStats.totalLessonIncorrectQuestionCount / totalAttempted) * 100) || 0,
			partiallyCorrectAnswers:
				Math.floor((lessonQuestionStats.totalLessonPartialCorrectQuestionCount / totalAttempted) * 100) || 0
		});
	}, [lessonQuestionStats]);

	const handleSubjectDropdownChange = val => {
		setLessonId("");
		setSubjectId(val);
		dispatch(fetchSubjectLessonStats({ subjectId: val }));
	};
	const handleLessonDropdownChange = val => {
		setLessonId(val);
		dispatch(fetchSubjectLessonStats({ subjectId: +subjectId, lessonId: val }));
	};
	return (
		<>
			<StatsTableBody>
				<StatsTableBodyContent>
					<SubjectTableHeader>
						<TableLabelContainer>
							<TableLabel>Subject</TableLabel>
						</TableLabelContainer>
					</SubjectTableHeader>
					<StatsLayout
						title="Subject"
						stats={subjectQuestionStats}
						percentages={subjectAnswerPercentages}
						totalCorrectQuestionCount={"totalSubjectCorrectQuestionCount"}
						totalIncorrectQuestionCount={"totalSubjectIncorrectQuestionCount"}
						totalPartialCorrectQuestionCount={"totalSubjectPartialCorrectQuestionCount"}
					>
						<FormControl size="small" variant="filled" fullWidth hiddenLabel>
							<Select
								value={subjectId}
								disableUnderline
								disabled={subjects.length === 0}
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
								onChange={event => {
									handleSubjectDropdownChange(event.target.value);
								}}
							>
								<MenuItem value="" disabled>
									Select Subject
								</MenuItem>
								{subjects.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</StatsLayout>
				</StatsTableBodyContent>
				<StatsTableBodyContent isMobile={isMobile} borderLeft>
					<LessonTableHeader>
						<TableLabelContainer>
							<TableLabel>Lesson</TableLabel>
						</TableLabelContainer>
					</LessonTableHeader>
					<StatsLayout
						title="Lesson"
						stats={lessonQuestionStats}
						percentages={lessonAnswerPercentages}
						totalCorrectQuestionCount={"totalLessonCorrectQuestionCount"}
						totalIncorrectQuestionCount={"totalLessonIncorrectQuestionCount"}
						totalPartialCorrectQuestionCount={"totalLessonPartialCorrectQuestionCount"}
					>
						<FormControl
							disabled={lessonDropdown?.length === 0 || lessonDropdown === undefined}
							size="small"
							variant="filled"
							fullWidth
							hiddenLabel
						>
							<Select
								value={lessonId}
								disableUnderline
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
								onChange={event => {
									handleLessonDropdownChange(event.target.value);
								}}
							>
								<MenuItem value="" disabled>
									Select Lesson
								</MenuItem>
								{lessonDropdown?.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</StatsLayout>
				</StatsTableBodyContent>
			</StatsTableBody>
		</>
	);
};

export default SubjectLessonStats;
