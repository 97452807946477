const danger = {
	100: "hsl(355,82%,89%)",
	200: "hsl(354,81%,82%)",
	300: "hsl(353,81%,74%)",
	400: "hsl(354,80%,67%)",
	500: "hsl(353,81%,63%)",
	600: "hsl(353,62%,56%)",
	700: "hsl(353,48%,38%)",
	800: "hsl(352,48%,32%)",
	900: "hsl(353,47%,19%)",
	1000: "hsla(354,82%,22%,0.27)",
	1100: "hsl(354,76%,48%)",
	1200: "hsl(350,100%,63%)",
	1300: "hsl(0,100%,43%)",
	1400: "hsl(353, 81%, 63%)",
	1500: "hsl(353, 47%, 19%)"
};

export default danger;
