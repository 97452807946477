import { Themes } from "../models/theme.types";

export type ThemeNames =
	| "Dark Blue"
	| "Dark Red"
	| "Dark Green"
	| "Dark Cyan Blue"
	| "Dark Purple"
	| "Dark Yellow"
	| "Dark Orange"
	| "Light Blue"
	| "Light Orange"
	| "Light Purple"
	| "Light Cyan Blue"
	| "Light Green"
	| "Light Yellow"
	| "Light Red";

export const convertThemeName = (name: ThemeNames): Themes => {
	switch (name) {
		case "Dark Cyan Blue":
			return Themes.DARK_CYAN_BLUE;
		case "Dark Red":
			return Themes.DARK_RED;
		case "Dark Yellow":
			return Themes.DARK_YELLOW;
		case "Dark Orange":
			return Themes.DARK_ORANGE;
		case "Dark Green":
			return Themes.DARK_GREEN;
		case "Dark Blue":
			return Themes.DARK_BLUE;
		case "Dark Purple":
			return Themes.DARK_PURPLE;
		case "Light Blue":
			return Themes.LIGHT_BLUE;
		case "Light Orange":
			return Themes.LIGHT_ORANGE;
		case "Light Purple":
			return Themes.LIGHT_PURPLE;
		case "Light Cyan Blue":
			return Themes.LIGHT_CYAN_BLUE;
		case "Light Green":
			return Themes.LIGHT_GREEN;
		case "Light Yellow":
			return Themes.LIGHT_YELLOW;
		case "Light Red":
			return Themes.LIGHT_RED;
	}
};
