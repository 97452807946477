import React from "react";

import { Box, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { useHistory } from "react-router-dom";

import { ReactComponent as IllustrationSvg } from "assets/icons/icon-illustration.svg";
import { routes } from "core/constants";

import { useStyles } from "./styles";

const SignUpSuccessRight = () => {
	const classes = useStyles();
	const history = useHistory();
	return (
		<Box display="flex" justifyContent="center" className={classes.successRightBgColor}>
			<Box display="flex" justifyContent="center" flexDirection="column" minHeight="40em">
				<Box display="flex" justifyContent="center">
					<IllustrationSvg />
				</Box>
				<Typography className={classes.errorTitle}>{"Verify Your Email"}</Typography>

				<Box display="flex" mt={4} width="100%" textAlign="center" className={classes.containerGap}>
					<Typography>Please check your mail inbox for email verification</Typography>
				</Box>

				<Box display="flex" justifyContent="center" mt={8}>
					<Button
						color="primary"
						variant="filled"
						onClick={() => {
							history.push(routes.signIn.getPath());
						}}
					>
						{"Go to Login"}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default SignUpSuccessRight;
