import { UserLessonAttemptCreateDto, UserLessonAttemptUpdateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";
import { UserLessonAttempt } from "../../models";

import { URLS } from "../services.constants";

export class UserLessonAttemptsService extends BaseApiService<
	UserLessonAttempt,
	UserLessonAttemptCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	UserLessonAttemptUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userLessonAttemptsService = new UserLessonAttemptsService(URLS.USER_LESSON_ATTEMPTS);
