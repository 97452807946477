import { GLOBAL_CONSTANTS } from "../constants";

export const setTiktokEvent = (event: string, options?: {}): void => {
	const { ttq: tiktok } = window;
	if (tiktok) {
		tiktok.track(event, options);
	}
};

export const getCallbackUrl = (search: string): string => {
	const callbackUrl = new URLSearchParams(search).get(GLOBAL_CONSTANTS.CALLBACK_URL_PARAM) as string;
	return callbackUrl;
};

export enum PixelEvent {
	"Purchase" = "Purchase",
	"AddToCart" = "AddToCart",
	"InitiateCheckout" = "InitiateCheckout",
	"CompletePayment" = "CompletePayment",
	"AddPaymentInfo" = "AddPaymentInfo",
	"StartTrial" = "StartTrial"
}
