import React, { memo, useMemo } from "react";

import { Box } from "@material-ui/core";

import { daysToWeeks } from "date-fns";

import { RemainingText } from "../../style";

const RemainingPeriod: React.FC = ({ children }) => {
	const { days, period } = useMemo(() => {
		const days = parseInt(children as string, 10);
		const period = days < 7 ? `${days} days` : `${daysToWeeks(days)} weeks`;
		return { days, period };
	}, [children]);

	if (isNaN(days) || !days) {
		return null;
	}

	return (
		<Box display="flex" justifyContent="center">
			<RemainingText>{period} left</RemainingText>
		</Box>
	);
};

export default memo(RemainingPeriod);
