import { UserQuestionNoteCreateDto, UserQuestionNoteUpdateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";
import { UserQuestionNote } from "../../models";

import { URLS } from "../services.constants";

export class UserQuestionNotesService extends BaseApiService<
	UserQuestionNote,
	UserQuestionNoteCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	UserQuestionNoteUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userQuestionNotesService = new UserQuestionNotesService(URLS.USER_QUESTION_NOTES);
