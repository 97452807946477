import { PayloadAction } from "@reduxjs/toolkit";

import { IBaseState } from "../models";

export const setLoadingReducer = <T extends Pick<IBaseState, "isLoading">>(
	state: T,
	isLoading: boolean,
	key = "isLoading"
): void => {
	state[key] = isLoading;
};
export const pendingReducer = <T extends Pick<IBaseState, "isLoading">>(state: T, key = "isLoading"): void =>
	setLoadingReducer(state, true, key);
export const fulfilledReducer = <T extends Pick<IBaseState, "isLoading">>(state: T, key = "isLoading"): void =>
	setLoadingReducer(state, false, key);
export const rejectReducer = <T extends Partial<IBaseState>>(
	state: T,
	{ payload }: PayloadAction<string | Error>,
	errorKey = "error"
): void => {
	state[errorKey] = typeof payload === "string" ? payload : payload.message;
	state.isLoading = false;
};
