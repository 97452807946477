import { Button, IconButton, Slider, alpha, lighten, makeStyles } from "@material-ui/core";
import ReportIcon from "@material-ui/icons/Report";
import styled, { css } from "styled-components";

import { QuestionTypes } from "../../constants";

export const useStyles = makeStyles(() => ({
	video: {
		width: "100% !important",
		display: "block",
		height: "auto !important",
		paddingTop: "0 !important",
		wordBreak: "break-all",
		fontSize: "13px",
		aspectRatio: "16/9",
		"& > video": {
			margin: "0 auto",
			display: "block"
		}
	}
}));

export const GroupTitle = styled.div`
	border-radius: 4px;
	padding: 0 17px 0 13px;
	margin-bottom: 4px;
	color${props => props.theme.palette.text.primary};
	font-size: 15px;
	font-weight: 600;
	display: flex;
	align-items: center;
	min-height: ${({ showVITOnlyInFullscreen }) => (showVITOnlyInFullscreen ? "30px" : "40px")};

	${({ theme, showVITOnlyInFullscreen }) => `
	${theme.breakpoints.down("sm")} {
		${
			!showVITOnlyInFullscreen
				? `
			min-height: 22px;
			font-size: 13px;
			margin-bottom: -5px;
			line-height: 15px;
			max-height: 30px;
			overflow: hidden;
			align-items: flex-start;
		`
				: ""
		}
	}
`}
`;

export const GroupContainer = styled.div`
	width: 100%;
	border-radius: 5px;
	border: ${({ theme }) => `solid 1px ${theme.palette.background.default}`};

	position: relative;
	${({ theme, showVITOnlyInFullscreen }) => `
padding: ${showVITOnlyInFullscreen ? "4px" : "10px"};

${theme.breakpoints.down("sm")} {
		${
			!showVITOnlyInFullscreen
				? `
				padding: 2px;
				max-width: 50%;
				${AnswerOptionText}, ${AnswerOptionDrag} {
					height: 22px !important;
					font-size: 12px !important;
				}

				${AnswerOptionDrag} {
					width: 22px !important;
				}

				${VITStatusRow} {
					border: 1px solid red;
				}

				${AnswerOptionContainer} {
					margin: 2px 0;
				}`
				: ""
		}


}
	`}
`;

export const RadioWrapper = styled.div`
	margin-left: 0;

	.MuiRadio-root {
		color: hsl(224, 16%, 41%) !important;
	}

	.MuiRadio-colorSecondary.Mui-checked {
		color: #fff !important;
	}
`;

export const CheckBoxWrapper = styled.div`
	.MuiCheckbox-root {
		color: ${({ theme }) => theme.palette.background.paper} !important;
	}

	.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${({ theme }) => theme.palette.text.primary} !important;
	}
`;

export const AlertContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	background: ${({ theme }) => alpha(theme.palette.background.paper, 0.8)};
	border-radius: 5px;
`;

export const VolumeContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 100%;
	right: 0;
	background: rgba(34, 37, 45, 0.8);
	transform: translate(0, -50%);
	padding: 0 10px;
	height: 28px;
	width: 120px;
`;

export const VolumeSlider = styled(Slider)`
	width: 100px !important;
`;

export const WarningIcon = styled(ReportIcon)`
	color: hsl(45, 100%, 63%);
	position: relative;
	left: -8px;
`;

export const FullScreenAlert = styled.div`
	font-family: var(--openSans);
	font-size: 13px;
	font-weight: 600;
	font-stretch: normal;
	line-height: 1.54;
	color: hsl(45, 100%, 63%);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 10px;
`;

export const RotationContainer = styled.div`
	width: 100%;

	@media (orientation: portrait) {
		${({ isVITDisabled, fullscreen }) =>
			isVITDisabled &&
			css`
				aspect-ratio: 9/16;
				${fullscreen
					? css`
							position: fixed;
							top: 0;
							left: 0;
							height: 100%;
							width: 100%;
							z-index: 9999;
					  `
					: css`
							min-height: 640px;
					  `}
			`}
	}
`;

export const VITNavigationBar = styled.div`
	display: flex;
	justify-content: space-between;
	background: hsl(224, 14%, 15%);
	width: 100%;
	flex-shrink: 0;

	button {
		color: hsl(223, 10%, 58%) !important;
	}
`;

export const VITVideoContainer = styled.div`
	${({ fullscreen }) =>
		fullscreen
			? css`
					width: 100%;
					aspect-ratio: 16/9;
					overflow: hidden;
			  `
			: ""}

	> img {
		max-width: 100%;
	}
`;

export const VolumeButton = styled(IconButton)`
	position: relative;
	display: none;

	${({ theme }) => css`
		${theme.breakpoints.up("md")} {
			display: inline;
		}
	`}
`;

export const VITContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-flow: row wrap;

	${({ isVITDisabled }) =>
		isVITDisabled &&
		css`
			background: ${({ theme }) => theme.palette.background.paper};

			@media (orientation: landscape) {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: 9999;
			}
		`}

	${({ theme, flex, showVITOnlyInFullscreen }) =>
		`${flex && !showVITOnlyInFullscreen ? "height: 100vh;" : ""}
		${
			flex || showVITOnlyInFullscreen
				? ""
				: `
		${theme.breakpoints.up("md")} {
			min-height: 450px;
		}
	`
		}`}

	> div:first-child {
		width: 100%;
		${({ theme, showVITOnlyInFullscreen, isVITDisabled }) => `
		${
			isVITDisabled &&
			css`
				height: calc(100% - 48px);
				> div {
					height: 100% !important;
				}
			`
		}

		${theme.breakpoints.up("sm")} {
			${!showVITOnlyInFullscreen ? "flex-grow: 1; display: flex; aspect-ratio: 16 / 9;" : ""}
		}
		`}
	}

	video {
		height: 100%;
		max-width: 100%;
	}

	${({ flex, showVITOnlyInFullscreen, theme, isVITDisabled }) =>
		isVITDisabled
			? css`
					@media (orientation: portrait) {
						${flex
							? css`
									width: 100vh;
									height: 100vw;
							  `
							: css`
									height: calc((100vw - 16px) * 0.97);
									width: calc(((100vw - 16px) * 0.97 - 48px) * 16 / 9);
							  `}

						transform-origin: top left;
						transform: rotate(-90deg) translateX(-100%);
					}
			  `
			: flex
			? `

			${
				flex &&
				showVITOnlyInFullscreen &&
				`${theme.breakpoints.down("sm")} and (orientation: portrait) {
			transform: rotate(-90deg) translateX(-100%);
			transform-origin: top left;
			width: 100vh;
			height: 100vw;

			> div:first-child {
				aspect-ratio: 16/9 !important;
				width: 100% !important;
			}
				}`
			}
			> div:first-child {
				flex-grow: 1;
				display: flex;
				width: 100%;

				> div {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
	`
			: ""}
`;

export const StyledText = styled.span`
	word-break: break-all;
	color: rgba(255, 255, 255, 0.87);
	font-family: var(--poppins);
	font-size: 13px;
	font-weight: 600;

	${({ theme }) => `
	${theme.breakpoints.down("sm")} {
		font-size: 13px;
	}`}
`;

export const ForceBreak = styled.div`
	flex: 0;
	${({ theme }) => `
	${theme.breakpoints.down("sm")} {
		flex-grow: 1;
	}`}
`;

export const VITProgressLabel = styled.div`
	flex-grow: 1;
	opacity: 0.35;
	font-family: var(--openSans);
	font-size: 12px;
	font-weight: 600;
	line-height: 1.33;
	color${props => props.theme.palette.text.primary};
	margin-left: 8px;
	display: flex;
	flex-flow: row wrap;
`;

export const VITProgress = styled.div`
	opacity: 0.35;
	padding-left: 12px;
	font-family: var(--poppins);
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	color${props => props.theme.palette.text.primary};
	flex-grow: 0;

	${({ theme }) => `
	${theme.breakpoints.down("sm")} {
		font-size: 13px;
	}`}
`;

export const VITQuestion = styled.div`
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: 15px;
	font-style: normal;
	line-height: 1.33;
	color${props => props.theme.palette.text.primary};
	margin-bottom: 8px;

	${({ theme }) => `
	${theme.breakpoints.down("sm")} { margin-bottom: 5px; font-size: 13px; }`}
`;

export const VITQuestionAnswerOption = styled.div`
	width: 75%;
	margin-top: 16px;
	min-height: 56px;
	background-color: rgba(34, 37, 45, 0.4);
	position: relative;
	font-family: var(--openSans);
	font-size: 15px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	font-weight: 600;
	line-height: 1.6;
	color${props => props.theme.palette.text.primary};
	${({ isReviewing }) =>
		isReviewing &&
		css`
			padding: 16px;
		`}
	${({ checked, isCorrect, isReviewing }) =>
		(checked && !isReviewing) || (checked && isReviewing && isCorrect === undefined)
			? css`
					background-color: hsl(213, 54%, 44%);
			  `
			: checked && isReviewing
			? css`
					background-color: ${isCorrect ? "hsl(136,67%,42%)" : "hsl(353,81%,63%)"};
			  `
			: !checked && isReviewing && isCorrect
			? css`
					background-color: hsl(148, 78%, 27%);
			  `
			: ""}


			${({ theme, showVITOnlyInFullscreen }) => `
			${theme.breakpoints.down("sm")} {
				margin-top: ${!showVITOnlyInFullscreen ? "3px" : "9px"};
				width: ${!showVITOnlyInFullscreen ? "100%" : "79%"};
				height: auto;
				min-height: ${!showVITOnlyInFullscreen ? "30px" : "40px"};

				${
					!showVITOnlyInFullscreen
						? `
							[class*="PrivateSwitchBase"] {
								padding: 3px;
							} `
						: ""
				}
			}`}


	svg {
		color: #fff !important;
	}
`;

export const VITQuestionContainer = styled.div`
	position: absolute;
	cursor: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: ${({ typeId }) => (typeId === QuestionTypes.Grouping ? "28px 32px" : "24px 50% 25px 40px")};
	background-image: ${({ theme }) =>
		`linear-gradient(to right, ${theme.palette.background.paper} 0%, ${alpha(
			lighten(theme.palette.background.paper, 0.05),
			0.7
		)} 74%, transparent 100%)`};

	.drop-space {
		min-height: 10px;
	}

	${({ theme, typeId, showVITOnlyInFullscreen }) => `
		${theme.breakpoints.down("sm")} {
			padding: ${!showVITOnlyInFullscreen ? "5px" : typeId === QuestionTypes.Grouping ? "32px 29px" : "32px 47% 32px 29px"};
			display: flex;
			flex-direction: column;

			${
				!showVITOnlyInFullscreen
					? `
				${AnswerOptionContainer} {
					margin: 2px 0;
				}

				${AnswerOptionDrag}, ${AnswerOptionText} {
					height: 28px;
					width: 28px;
				}

				.drop-space {
					min-height: 6px;
				}

				.dashed-placeholder {
					max-height: 28px;
				}

				${VITQuestion} {
					margin-bottom: 2px;
				}
				`
					: ""
			}
		}
	`}
`;

export const VITStatusRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-top: 16px;
	width: ${({ typeId }) => ([QuestionTypes.Grouping, QuestionTypes.Sequencing].includes(typeId) ? "100%" : "75%")};

	${({ theme, typeId, showVITOnlyInFullscreen }) => `
		${theme.breakpoints.down("sm")} {
			width: ${
				[QuestionTypes.Grouping, QuestionTypes.Sequencing].includes(typeId) || !showVITOnlyInFullscreen ? "100%" : "79%"
			};
			flex-flow: row wrap;


			${
				!showVITOnlyInFullscreen
					? `
				margin-top: 2px;

				${VITProgressLabel}, ${VITProgress} {
					display: none;
				}
			`
					: ""
			}
	`}
`;

export const VITSubmitButton = styled(Button)`
	font-family: var(--poppins);
	flex-grow: 0;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.14;
	text-align: center;
	color: ${props => props.theme.palette.common.white};
	${({ disabled }) =>
		!disabled
			? css`
					background-color: hsl(222, 64%, 42%);
			  `
			: css`
					opacity: 0.38;
					background-color: rgba(143, 155, 179, 0.16);
			  `}
	border-radius: 4px;
	padding: 12px 20px;

	${({ theme, showVITOnlyInFullscreen, isVITDisabled }) => `
	${
		isVITDisabled &&
		css`
			padding: 6px 20px;
		`
	}
	${theme.breakpoints.down("sm")} {
		order: -1;
		flex: 100% 0 0;
		margin-bottom: 11px;
		${!showVITOnlyInFullscreen ? "padding: 5px; font-size: 13px; margin-bottom: 2px;" : ""}
	}
`}
`;

export const VITYourAnswerLabel = styled.div`
	position: absolute;
	bottom: 4px;
	left: 16px;
	opacity: 0.87;
	font-family: var(--openSans);
	font-size: 10px;
	font-weight: 600;
	color${props => props.theme.palette.text.primary};
`;

export const AnswerOptionContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	margin: 8px 0;

	${({ isVITDisabled }) =>
		isVITDisabled &&
		css`
			margin: 4px 0;
		`}
`;

export const AnswerOptionIndex = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: 15px;
	font-weight: 600;
	color${props => props.theme.palette.text.primary};
	background: hsl(224, 7%, 32%);
	margin-right: 8px;
	border-radius: 4px;

	${({ theme }) => `
	${theme.breakpoints.down("sm")} {
		height: 38px;
		width: 38px;
	} `}
`;

export const AnswerOptionText = styled.div`
	height: 48px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: var(--openSans);
	display: flex;
	flex-grow: 1;
	padding-left: 16px;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	color${props => props.theme.palette.text.primary};
	border-radius: 4px 0 0 4px;
	background: ${({ isCorrect }) =>
		isCorrect === undefined ? "rgba(42, 46, 55, 0.5)" : isCorrect ? "hsl(142,72%,34%)" : "hsl(353,62%,56%)"};

	${({ theme, isVITDisabled }) => `
		${theme.breakpoints.down("sm")} {
			height: ${isVITDisabled ? "32" : "38"}px;
		} `}
`;

export const AnswerOptionDrag = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--openSans);
	font-size: 34px;
	font-weight: 600;
	color${props => props.theme.palette.text.primary};
	border-radius: 0 4px 4px 0;
	background: ${({ isCorrect }) =>
		isCorrect === undefined ? "rgba(42, 46, 55, 0.5)" : isCorrect ? "#189646" : "#d54b5b"};
	cursor: grabbing;

	${({ theme, isVITDisabled }) => `
	${theme.breakpoints.down("sm")} {
		${
			isVITDisabled
				? css`
						height: 32px;
						width: 32px;
				  `
				: css`
						height: 38px;
						width: 38px;
				  `
		}

	} `}
`;
