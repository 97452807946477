import React from "react";

import { Box, Checkbox } from "@material-ui/core";

import { CheckBoxWrapper, StyledOptText } from "modules/Lesson/style";

import { MatrixTableBody, MatrixTableHeader } from "modules/QuestionBank/styles";

import { AnswerContainer, RowLabelContainer, RowOptionsContainer } from "./styles";

import { useStyles } from "../styles";

const MatrixMultipleChoiceResult = ({
	result,
	question: {
		data: { tableLabel, answerOptions, groups }
	}
}) => {
	const classes = useStyles();

	const selectedAnswers = {};
	result?.selectedAnswers?.forEach(({ questionAnswerOptionId, questionGroupId }) => {
		selectedAnswers[questionGroupId] = selectedAnswers[questionGroupId] ?? [];
		selectedAnswers[questionGroupId] = [...selectedAnswers[questionGroupId], questionAnswerOptionId];
	});

	return (
		<>
			<MatrixTableHeader display="flex" maxWidth="595px" flexDirection="row">
				<Box display="flex" flexDirection="row" alignItems="center" width="100%">
					<RowLabelContainer>
						<StyledOptText>{tableLabel}</StyledOptText>
					</RowLabelContainer>

					<RowOptionsContainer>
						{groups?.map(({ text }, groupOptionIndex) => (
							<Box
								key={groupOptionIndex}
								justifyContent="center"
								display="flex"
								alignItems="center"
								height="100%"
								flexDirection="row"
								flex={1}
								{...(groupOptionIndex !== 2 && {
									borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
								})}
							>
								<StyledOptText>{text}</StyledOptText>
							</Box>
						))}
					</RowOptionsContainer>
				</Box>
			</MatrixTableHeader>
			<MatrixTableBody isinresults>
				{answerOptions?.map(({ text, id: answerOptionId }, gIndex) => (
					<Box
						key={gIndex}
						borderBottom={gIndex < groups.length - 1 ? "solid 1px rgba(151, 151, 151, 0.3)" : ""}
						display="flex"
						flexDirection="row"
						width="100%"
					>
						<Box display="flex" flexDirection="row" alignItems="center" width="100%">
							<RowLabelContainer>
								<StyledOptText>{text}</StyledOptText>
							</RowLabelContainer>
							<RowOptionsContainer>
								<CheckBoxWrapper>
									{groups?.map(({ id: gId, selectedAnswerOptions }, _index) => {
										const correctAnswer = selectedAnswerOptions?.includes(answerOptionId);
										const yourAnswer = selectedAnswers[gId]?.includes(answerOptionId);

										return (
											<Box
												className={`${correctAnswer && yourAnswer ? classes.correct : ""} ${
													yourAnswer ? classes.your : ""
												}`}
												key={_index}
												display="flex"
												flexDirection="row"
												justifyContent="center"
												alignItems="center"
												flexWrap="wrap"
												flex={1}
												{...(_index !== 2 && {
													borderRight: "solid 1px rgba(151, 151, 151, 0.3)"
												})}
											>
												<Checkbox color="default" value={gId} readOnly checked={yourAnswer || correctAnswer} />

												<div className={classes.radioLabel}>
													{correctAnswer && yourAnswer ? (
														<AnswerContainer>Correct Answer</AnswerContainer>
													) : correctAnswer && !yourAnswer ? (
														<AnswerContainer>Unmarked correct answer</AnswerContainer>
													) : yourAnswer ? (
														<AnswerContainer>Incorrect answer</AnswerContainer>
													) : null}
												</div>
											</Box>
										);
									})}
								</CheckBoxWrapper>
							</RowOptionsContainer>
						</Box>
					</Box>
				))}
			</MatrixTableBody>
		</>
	);
};

export default MatrixMultipleChoiceResult;
