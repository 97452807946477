import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";

import { request } from "@remar/shared/dist/api/request";
import { SubjectLesson } from "@remar/shared/dist/models";

import { SubjectLessonStatsResponse } from "./dto";

import { URLS } from "../services.constants";

export class SubjectLessonService extends BaseApiService<
	SubjectLesson,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getSubjectLessonStats(data: { lessonId?: number; subjectId?: number }): Promise<SubjectLessonStatsResponse> {
		return request(
			`${this.baseUrl}/subject-lesson-stats${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<SubjectLessonStatsResponse>;
	}
}

export const subjectLessonService = new SubjectLessonService(URLS.SUBJECT_LESSONS);
