import React, { useEffect, useMemo, useState } from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";

import {
	CancellationWrapper,
	GiftIllustrationImage,
	RecommendedBlock,
	SadIllustrationImage,
	StudentIllustrationImage
} from "../styles";
import { CancellationOptionsEnum, ICancellationProps } from "../subscriptionCancellation.dto";

const CancelSelectedOptionComponent = ({ action, selectedOption, isCancellingSubscription }: ICancellationProps) => {
	const [selectedBtnId, setSelectedBtnId] = useState(0);
	useEffect(() => {
		return () => {
			setSelectedBtnId(0);
		};
	}, []);
	const Illustration = useMemo(() => {
		switch (selectedOption!.id) {
			case CancellationOptionsEnum.CancelNClEXSoonOption:
				return StudentIllustrationImage;
			case CancellationOptionsEnum.CancelProblemOption:
				return SadIllustrationImage;
			default:
				return GiftIllustrationImage;
		}
	}, [selectedOption]);

	return (
		<CancellationWrapper container>
			<Grid item xs={12} md={6} className={"left-Grid"}>
				<Box my={3}>
					<Illustration />
				</Box>
				{selectedOption?.data.leftInfo && <Typography className={"title"}>{selectedOption?.data.leftInfo}</Typography>}
			</Grid>
			<Grid item xs={12} md={6} className={"right-Grid"}>
				<Typography className={"title"} align={"center"}>
					{selectedOption?.data.heading}
				</Typography>
				{selectedOption?.data.body && (
					<Box my={3}>
						<Typography className={"description"} align={"center"}>
							{selectedOption?.data.body}
						</Typography>{" "}
					</Box>
				)}
				{selectedOption?.data.subtext && (
					<Box my={2}>
						<Typography className={"description"} align={"center"}>
							{selectedOption?.data.subtext}
						</Typography>
					</Box>
				)}
				{selectedOption?.data.info && (
					<RecommendedBlock>
						<Typography className={"recommendedTitle"}>Recommended:</Typography>
						<Typography className={"recommendedDescription"}>{selectedOption?.data.info}</Typography>
					</RecommendedBlock>
				)}

				{selectedOption?.options.map(
					({ id, text, data: { action: actionType, link }, primary, paymentAndDiscountId }) => (
						<Box mt={2} width={"100%"} key={id}>
							<Button
								variant={primary ? "filled" : "outlined"}
								color={primary ? "primary" : "basic"}
								fullWidth
								onClick={() => {
									setSelectedBtnId(id);
									action!({ type: actionType, link, cancellationReasonId: id, paymentAndDiscountId });
								}}
								loading={isCancellingSubscription && selectedBtnId === id}
								disabled={isCancellingSubscription && selectedBtnId === id}
							>
								{text}
							</Button>
						</Box>
					)
				)}
			</Grid>
		</CancellationWrapper>
	);
};

export default CancelSelectedOptionComponent;
