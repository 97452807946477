import React from "react";

import { Box, Container, Paper, Typography } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { Link } from "react-router-dom";

const NoAccess = () => (
	<Container maxWidth="md">
		<Box
			textAlign="center"
			minHeight="100vh"
			height="100%"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			<Paper elevation={3}>
				<Box p={8}>
					<Typography variant="h1">Sorry, you can`t access this page</Typography>
					<Box mt={2}>
						<Link to="/">
							<Button color="primary" variant="filled" size="large">
								Go home
							</Button>
						</Link>
					</Box>
				</Box>
			</Paper>
		</Box>
	</Container>
);

export default NoAccess;
