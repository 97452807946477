import React, { useState } from "react";

import { Box, Checkbox, Modal, Radio, TextareaAutosize, Typography, useTheme } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";

import { Close, Notes } from "@material-ui/icons";

import {
	BowTieResult,
	CaseStudyQuestionResult,
	ClozeDropDownResult,
	DragAndDropResult,
	DropDownTableResult,
	HighlightTableResult,
	HotspotHighlightResult,
	MatrixMultipleChoiceResult,
	MatrixSingleChoiceResult,
	MultipleResponseGroupResult,
	SingleChoiceOrMultipleChoiceResult
} from "./Components";

import {
	CorrectAnswersIcon,
	IncorrectAnswersIcon,
	NotesContainer,
	QuizButtonsRow,
	QuizLessonTestTitle,
	QuizQuestionAnswerOption,
	QuizQuestionContainer,
	QuizQuestionContent,
	QuizQuestionText,
	QuizQuestionTitle,
	QuizResultAnswer,
	QuizResultItem,
	QuizResultItemQuestion,
	QuizResultItemRationale,
	QuizResultLabel,
	QuizResultPreviewNotes,
	QuizResultRationale,
	QuizResultRationaleGrid,
	QuizResultRationaleImg,
	QuizResultRationaleResult,
	QuizResultRationaleText,
	QuizResultTimeSpent,
	QuizResultTimeSpentText,
	QuizeSequenceInCorrectContainer,
	Zoom,
	useStyles
} from "./styles";

import Timer from "../../assets/icons/icon-clock.svg";
import DownloadIconSvg from "../../assets/icons/icon-download.svg";
import IconZoom from "../../assets/icons/icon-search-plus.svg";

import { QuestionTypes } from "../../constants";

import ContentLoader from "../../layouts/TableContentLayout/components/ContentLoader";
import { IExtendedTheme } from "../../theme/default";
import { downloadURI, getSanitizedHtmlText } from "../../utils/serviceUtils";
import Button from "../Button";
import { FilesUploaded } from "../FilesUploaded";

const TestResults = ({
	results,
	loadingResults,
	onClose,
	onNextLesson,
	showNextLesson,
	userAttemptedQuestions,
	handleShowNotes,
	QuestionResultInfoComponent,
	notes,
	showNotes,
	setShowNotes
}) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles();
	const [zoomedUrl, setZoomedUrl] = useState("");

	const getQuestionType = (id, result, question, results) => {
		switch (id) {
			case QuestionTypes.MatrixSingleChoice:
				return <MatrixSingleChoiceResult question={question} result={result} />;
			case QuestionTypes.MatrixMultipleChoice:
				return <MatrixMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return <ClozeDropDownResult question={question} result={result} />;
			case QuestionTypes.MultipleChoice:
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return <DragAndDropResult question={question} result={result} />;
			case QuestionTypes.SingleChoice:
				return <SingleChoiceOrMultipleChoiceResult question={question} result={result} />;
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestionResult
						question={question}
						results={results}
						QuestionResultInfoComponent={QuestionResultInfoComponent}
					/>
				);
			case QuestionTypes.DropDownTable:
				return <DropDownTableResult question={question} result={result} />;
			case QuestionTypes.BowTie:
				return <BowTieResult result={result} />;
			case QuestionTypes.MultipleResponseGroup:
				return <MultipleResponseGroupResult question={question} result={result} />;
			case QuestionTypes.HotspotHighlight:
				return <HotspotHighlightResult question={question} result={result} />;
			case QuestionTypes.HighlightTable:
				return <HighlightTableResult question={question} result={result} />;
			default:
				return null;
		}
	};

	if (loadingResults) {
		return <ContentLoader />;
	}

	let questionId = 0;
	let index = 0;
	return (
		<>
			{zoomedUrl !== "" && (
				<Zoom>
					<IconButton className={classes.cancelButton} size="small" onClick={() => setZoomedUrl("")}>
						<Close />
					</IconButton>
					<img alt="zoom" src={zoomedUrl} />
				</Zoom>
			)}
			{showNotes && (
				<Modal
					onClose={() => setShowNotes(false)}
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexFlow: "column"
					}}
					disableEnforceFocus
					disableAutoFocus
					open={showNotes}
				>
					<>
						<Box width={310} display="flex" alignItems="center" className={classes.notesHeader}>
							<Typography style={{ fontSize: `"${theme.typography.pxToRem(15)}"`, fontWeight: 600, flexGrow: 1 }}>
								Notes
							</Typography>
							<IconButton size="small" onClick={() => setShowNotes(false)}>
								<Close />
							</IconButton>
						</Box>
						<Box width={310} className={classes.notesBody}>
							<TextareaAutosize
								style={{
									width: "100%",
									border: "solid 1px #edf1f7",
									fontFamily: "var(--poppins)",
									fontSize: `${theme.typography.pxToRem(13)}`
								}}
								value={notes}
								readOnly
								minRows={9}
								maxRows={9}
								maxLength={500}
							/>
						</Box>
					</>
				</Modal>
			)}
			<QuizLessonTestTitle>Test Results</QuizLessonTestTitle>
			<QuizQuestionContainer>
				<QuizQuestionContent>
					{results.map(result => {
						if (result.questionId === questionId) return;
						questionId = result.questionId;
						index++;
						const answers = result.selectedAnswers.map(({ questionAnswerOptionId }) => questionAnswerOptionId);
						const question = userAttemptedQuestions.find(
							({ id, typeId }) => id === (QuestionTypes.CaseStudy === typeId ? result.questionId : result.snapshot.id)
						);
						const answerOptions =
							result.snapshot?.typeId !== QuestionTypes.Sequencing
								? result.snapshot?.data?.answerOptions
								: result.selectedAnswers?.map(f =>
										result.snapshot.data?.answerOptions?.find(q => q.id == f.questionAnswerOptionId)
								  );
						return (
							<QuizResultItem key={result.id}>
								<QuizResultItemQuestion>
									<QuizQuestionTitle>Question {index}</QuizQuestionTitle>
									{![QuestionTypes.Sequencing, QuestionTypes.Grouping].includes(result.snapshot.typeId) ? (
										<>
											<Typography className={classes.questionText}>{getSanitizedHtmlText(question?.text)}</Typography>
											<Box
												style={{ color: theme.palette.colors.basic[600] }}
												dangerouslySetInnerHTML={{ __html: question?.description }}
											/>
											{getQuestionType(question?.typeId, result, question, results)}
										</>
									) : (
										<>
											<QuizQuestionText>{result.snapshot.text}</QuizQuestionText>
											{answerOptions.map(({ id, text, isCorrect, questionGroupId = "" }) => {
												const isOptionCorrect =
													(result.snapshot.typeId === QuestionTypes.Sequencing && result.isCorrect) ||
													(result.snapshot.typeId === QuestionTypes.Grouping &&
														questionGroupId ===
															result.selectedAnswers.find(({ questionAnswerOptionId }) => questionAnswerOptionId === id)
																?.questionGroupId) ||
													(![QuestionTypes.Sequencing, QuestionTypes.Grouping].includes(result.snapshot.typeId) &&
														isCorrect);
												return (
													<QuizQuestionAnswerOption
														key={id}
														isChecked={answers.includes(id)}
														isCorrect={isOptionCorrect}
													>
														<QuizResultLabel>
															{isOptionCorrect ? "Correct answer" : answers.includes(id) ? "Your answer" : ""}
														</QuizResultLabel>
														<QuizResultAnswer>
															{result.snapshot.typeId === QuestionTypes.SingleChoice ? (
																<Radio checked={false} color="default" />
															) : result.snapshot.typeId === QuestionTypes.MultipleChoice ? (
																<Checkbox checked={false} color="default" />
															) : null}
															{text}
														</QuizResultAnswer>
													</QuizQuestionAnswerOption>
												);
											})}
											{result.snapshot.typeId === QuestionTypes.Sequencing && !result.isCorrect && (
												<QuizeSequenceInCorrectContainer>
													{result.snapshot.data.answerOptions.map(({ id, text }) => (
														<QuizQuestionAnswerOption key={id} isChecked={false} isCorrect={true}>
															<QuizResultLabel>Correct answer</QuizResultLabel>
															<QuizResultAnswer>{text}</QuizResultAnswer>
														</QuizQuestionAnswerOption>
													))}
												</QuizeSequenceInCorrectContainer>
											)}
										</>
									)}
								</QuizResultItemQuestion>
								{question?.typeId !== QuestionTypes.CaseStudy && (
									<QuizResultItemRationale>
										<QuizResultRationaleGrid>
											<QuizResultRationaleText>
												<div>
													{result.isCorrect ? (
														<QuizResultRationaleResult>
															<CorrectAnswersIcon /> Correct
														</QuizResultRationaleResult>
													) : (
														<QuizResultRationaleResult>
															<IncorrectAnswersIcon /> Incorrect
														</QuizResultRationaleResult>
													)}
												</div>
												<QuizResultRationale
													dangerouslySetInnerHTML={{
														__html: result?.snapshot.endOfQuestionSummary ?? ""
													}}
												/>
											</QuizResultRationaleText>
											{result.snapshot.mainImageKey && (
												<QuizResultRationaleImg>
													<img alt="rationale" src={result.snapshot.mainImageUrl} />
													<div className="overlay" onClick={() => setZoomedUrl(result.snapshot.mainImageUrl)}>
														<img src={IconZoom} alt="Zoom" />
														Click to zoom
													</div>
												</QuizResultRationaleImg>
											)}
										</QuizResultRationaleGrid>
										<Box style={{ margin: "0 17px 25px" }}>
											<QuizResultTimeSpent>
												<img src={Timer} alt="Timer" />
												<QuizResultTimeSpentText>Time spent - {result.timeSpent} seconds</QuizResultTimeSpentText>
											</QuizResultTimeSpent>
											{result.snapshot.attachments.length !== 0 && (
												<Box>
													<FilesUploaded
														attachments={result.snapshot.attachments}
														IconSvg={() => <img src={DownloadIconSvg} alt="Download" />}
														showTitle={false}
														isDownload
														borderColor="#edf1f7"
														filenameColor="#4d5058"
														onClick={attachment => downloadURI(attachment.fileUrl, attachment.name)}
													/>
												</Box>
											)}
											{showNotes && (
												<NotesContainer onClick={() => handleShowNotes(result.snapshot.id)}>
													<Notes style={{ width: "20px", height: "20px", fill: "#d3d6e0" }} />
													<QuizResultPreviewNotes>Preview notes</QuizResultPreviewNotes>
												</NotesContainer>
											)}
										</Box>
									</QuizResultItemRationale>
								)}
							</QuizResultItem>
						);
					})}
					<QuizButtonsRow>
						<Button variant="filled" color="secondary" onClick={onClose}>
							Close
						</Button>
						{showNextLesson && (
							<Button variant="filled" color="primary" onClick={onNextLesson}>
								Next lesson
							</Button>
						)}
					</QuizButtonsRow>
				</QuizQuestionContent>
			</QuizQuestionContainer>
		</>
	);
};

export default TestResults;
