import { createStyles, makeStyles } from "@material-ui/core/styles";

import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import styled from "styled-components";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			display: "flex"
		},
		userInfoContainer: {
			width: 750,
			marginRight: 24
		},
		subscriptionInfoContainer: {
			width: 350,
			marginRight: 70
		},
		header: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom: 57,
			marginTop: 20
		},
		headerTitle: {
			fontSize: 25,
			fontWeight: 900,
			fontFamily: "var(--poppins)",
			fontStretch: "normal",
			fontStyle: "normal",
			opacity: 0.87
		},
		title: {
			fontWeight: 900,
			fontSize: `"${theme.typography.pxToRem(22)}"`,
			fontFamily: "var(--poppins)",
			letterSpacing: 1.45
		},
		searchField: {
			borderRadius: "4px",
			marginBlock: "24px",
			width: 350
		},
		topicTitle: {
			fontWeight: 900,
			fontSize: `"${theme.typography.pxToRem(22)}"`,
			fontFamily: "var(--poppins)",
			letterSpacing: 1.45,
			marginBlock: "26px"
		},
		topicCard: {
			backgroundColor: theme.palette.background.paper,
			color: "#fff"
		},
		topicName: {
			fontSize: `"${theme.typography.pxToRem(20)}"`,
			fontWeight: 600,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			width: "100%",
			cursor: "pointer"
		},
		questionTitle: {
			fontWeight: 900,
			fontSize: `"${theme.typography.pxToRem(22)}"`,
			fontFamily: "var(--poppins)",
			letterSpacing: 1.45,
			marginBlock: "26px"
		},
		questionCard: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			marginBlock: "16px",
			borderRadius: "6px",
			padding: "10px",
			"&:before": {
				height: 0
			}
		},
		answerDetail: {
			"& a": {
				textDecoration: "underline"
			}
		},
		videoContainer: {
			position: "relative",
			height: "22.5%",
			marginBottom: "25px",
			padding: "0px",
			"& .questions-overlay": {
				zIndex: 1500,
				width: "100%",
				height: "100%",
				marginTop: "-50%",
				top: 0
			},
			"& .vjs-fluid": {
				height: "100%"
			},
			"& .vjs-fullscreen-control": {
				marginLeft: "auto"
			},
			"& .video-js": {
				padding: 0,
				height: "100%",
				width: "100%",
				"& .vjs-control-bar": {
					display: "none"
				},
				"& .vjs-tech": {
					width: "100%",
					height: "100%"
				}
			}
		},
		videoQuestionContainer: {
			padding: "10%",
			height: "100%",
			background:
				"linear-gradient(90deg, rgba(47, 50, 59, 1) 0%, rgba(47, 50, 59, 0.7) 75%, rgba(47, 50, 59, 0.2) 100%)",
			"& .question-head": {
				margin: "0 0 16px",
				opacity: "0.87",
				fontSize: `"${theme.typography.pxToRem(15)}"`,
				fontWeight: 600,
				lineHeight: 1.33,
				color: "#ffffff"
			}
		}
	})
);

export const ExplainerVideo = styled.div`
	margin: 0 0 16px;
	opacity: 0.87;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.33;
	color: ${props => props.theme.palette.text.primary};
	${props => props.theme.breakpoints.down("sm")} {
		margin: 16px 0 16px;
`;
