import { Box, Button, Card, Modal, Typography } from "@material-ui/core";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CardWrapper = styled(Card)`
	max-width: 480px;
	min-height: 273px;
	width: 100%;
	background: ${({ theme }) => theme.palette.colors.basic[900]};
	border-radius: 4px;
	padding: 0;

	span.MuiIconButton-colorPrimary:not(.Mui-checked) {
		color: ${({ theme }) => theme.palette.colors.basic[100]};
	}
`;

export const HeaderText = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	font-weight: bold;
	font-family: var(--poppins);
	line-height: 24px;
	opacity: 0.87;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	margin: 15px 16px;
`;

export const DescriptionText = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(15)};
	font-family: var(--openSans);
	/* TODO: replace by ${({ theme }) => theme.palette.colors.basic[1250]}; after merge to develop */
	color: hsl(223, 13%, 73%);
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 24px;

	a {
		color: ${({ theme }) => theme.palette.colors.info[500]};
		text-decoration: underline;
	}
`;

export const LabelText = styled(Typography)`
	opacity: 0.87;
	font-weight: 600;
	font-size: ${({ theme }) => theme.typography.pxToRem(13)};
	color: ${({ theme }) => theme.palette.colors.basic[100]};
`;

export const SubmitButton = styled(Button)`
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	font-family: var(--poppins);
	font-weight: 600;
	color: ${({ theme }) => theme.palette.colors.basic[100]};
	background-color: ${({ theme }) => theme.palette.colors.primary[500]};
`;

export const DescriptionWrapper = styled(Box)`
	padding: 16px;
	/* TODO: replace by ${({ theme }) => theme.palette.colors.basic[1250]}; after merge to develop */
	color: hsl(223, 13%, 73%);
`;

export const ButtonWrapper = styled(Box)`
	display: flex;
	font-family: var(--poppins);
	justify-content: flex-end;
	border-top: 1px solid ${({ theme }) => theme.palette.colors.basic[800]};
	padding: 16px 22px;

	button {
		padding: 11px 36px;
	}
`;
