import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QuestionBankTestModes, QuestionTypes } from "@remar/shared/dist/constants";
import {
	ContentArea,
	Question,
	QuestionAttemptsCount,
	UserCustomTest,
	UserCustomTestHistoryResult,
	UserLessonAttempt,
	UserQuestionAnswerAttempt,
	UserQuestionAttempt,
	UserQuestionNote
} from "@remar/shared/dist/models";
import { getResetState } from "@remar/shared/dist/utils/stateUtils";
import pick from "lodash/fp/pick";
import shuffle from "lodash/shuffle";
import { RootState } from "store";
import {
	UserAnswerQuestionIdDto,
	UserQuestionAnswerDto,
	UserQuestionAttemptCreateDto,
	UserQuestionFeedbackItemCreateDto,
	UserQuestionMarkingCreateDto,
	UserQuestionNoteCreateDto,
	UserQuestionNoteUpdateDto,
	questionsAttemptsCountService,
	userCustomTestsService,
	userQuestionAttemptsService,
	userQuestionFeedbackItemsService,
	userQuestionMarkingService,
	userQuestionNotesService
} from "store/services";

interface SavedAnswer {
	id: number;
	answers: { questionAnswerOptionId: string; questionGroupId?: string }[];
	timeSpent: number;
}

interface CSQuestions {
	questionIndex: number;
	id: number;
}

interface TestState {
	currentTest: UserCustomTest;
	isLoading: boolean;
	errorMessage: string;
	optionsError: string;
	quizPercentage: number;
	quizResult: (UserQuestionAttempt | ContentArea)[];
	userAttemptedQuestions: Question[];
	quizError: string;
	isTestFinished: boolean;
	questionNotes: UserQuestionNote;
	handleNextLoading: boolean;
	feedback: string;
	feedbackAllowed: boolean;
	quizTestAnswers: {
		[key: string]: SavedAnswer;
	};
	quizTestAnswersAlreadyAttempted: UserAnswerQuestionIdDto;
	quizTestAnswersAlreadyAttemptedCaseStudy: UserAnswerQuestionIdDto;
	questionResult: UserQuestionAttempt;
	caseStudyResult: UserQuestionAttempt[];
	isMarked: boolean;
	questionAttemptsCount: QuestionAttemptsCount[];
	attemptedCSQuestions: CSQuestions[];
	customTestAttemptsHistory: UserCustomTestHistoryResult;
	isLoadingCustomTestAttempts: boolean;
}

const initialState: TestState = {
	currentTest: {
		id: 0,
		userId: 0,
		typeId: 0,
		data: {
			questions: [],
			isTimed: false
		},
		name: "",
		isLoading: false
	},
	quizError: "",
	isLoading: false,
	errorMessage: "",
	optionsError: "",
	handleNextLoading: false,
	isTestFinished: false,
	quizPercentage: 0,
	quizTestAnswers: {},
	quizTestAnswersAlreadyAttempted: {},
	quizTestAnswersAlreadyAttemptedCaseStudy: {},
	quizResult: [],
	userAttemptedQuestions: [],
	questionResult: {
		id: 0,
		userId: 0,
		questionId: 0,
		isCorrect: false,
		timeSpent: 0,
		snapshot: {},
		percentageGrade: 0
	},
	questionNotes: { text: "", questionId: 0, id: 0, userId: 0 },
	feedback: "",
	feedbackAllowed: false,
	isMarked: false,
	caseStudyResult: [],
	questionAttemptsCount: [],
	attemptedCSQuestions: [],
	customTestAttemptsHistory: {
		items: []
	},
	isLoadingCustomTestAttempts: false
};

const utilsResetState = getResetState<TestState>(initialState);

export const getQuestionNotes = createAsyncThunk(
	"test/getQuestionNotes",
	async (data: { id: number; customTestId: number }, { rejectWithValue, dispatch }) => {
		return await userQuestionNotesService
			.find({ filters: { questionId: data.id, customTestId: data.customTestId as unknown as number } })
			.then(res => {
				dispatch(setNotesQuestionId(data.id));
				return res;
			})
			.catch(error => rejectWithValue(error.message));
	}
);

export const setQuestionNotes = createAsyncThunk(
	"test/setQuestionNotes",
	async (data: UserQuestionNoteCreateDto, { rejectWithValue }) => {
		return await userQuestionNotesService.create(data).catch(e => rejectWithValue(e.message));
	}
);

export const updateQuestionNotes = createAsyncThunk(
	"test/updateQuestionNotes",
	async (data: UserQuestionNoteUpdateDto, { rejectWithValue }) => {
		await userQuestionNotesService.update(data).catch(e => rejectWithValue(e.message));
	}
);

export const fetchTests = createAsyncThunk(
	"test/fetchTests",
	async (data: { id: number; sideEffect?: (customTest: UserCustomTest | null) => void }, { rejectWithValue }) => {
		try {
			const test = await userCustomTestsService.findOne(data.id);

			if (test) {
				test.data.questions?.forEach(question => {
					if (question.typeId === QuestionTypes.MultipleResponseGroup) {
						question.data.groups.forEach(group => (group.answerOptions = shuffle(group.answerOptions)));
					} else if (question.typeId === QuestionTypes.CaseStudy) {
						question.caseStudyQuestions?.forEach(csQuestion => {
							if (csQuestion.typeId === QuestionTypes.MultipleResponseGroup) {
								csQuestion.data.groups.forEach(group => (group.answerOptions = shuffle(group.answerOptions)));
							}
						});
					}
				});
			}
			data.sideEffect && data.sideEffect(test);
			return test;
		} catch {
			return rejectWithValue("Error in getting tests");
		}
	}
);

export const createUserQuestionAttempt = createAsyncThunk(
	"test/createUserQuestionAttempt",
	async (data: UserQuestionAttemptCreateDto, { rejectWithValue, dispatch, getState }) => {
		dispatch(setHandleNextLoading(true));
		dispatch(setOptionsError(""));

		const res = await userQuestionAttemptsService
			.create(data)
			.catch(error => {
				if (error.name == "422") {
					dispatch(setOptionsError(error.message));
				}
				rejectWithValue(error.message);
				data?.caseStudySideEffect && data.caseStudySideEffect(true);
				throw new Error(error);
			})
			.finally(() => {
				dispatch(setHandleNextLoading(false));
			});
		dispatch(setHandleNextLoading(false));
		data.sideEffect && data.sideEffect();
		await dispatch(setCreatePayload(res));
		const { test } = getState() as { test: TestState };

		data.caseStudySideEffect && data?.caseStudySideEffect(false, test.currentTest?.data?.questions);

		return res;
	}
);

export const completeUserTestAttempt = createAsyncThunk(
	"test/completeUserTestAttempt",
	async (filters: { id: number }, { rejectWithValue }) => {
		return await userCustomTestsService
			.update({ data: { inProgress: false }, filters: { ...filters } })
			.catch(error => rejectWithValue(error.message));
	}
);

export const updateUserQuestionAttempt = createAsyncThunk(
	"test/updateUserQuestionAttempt",
	async (
		data: {
			questionUserAttemptId: number;
			userAnswers: UserQuestionAnswerDto[];
			timeSpent: number;
			sideEffect?: () => void;
			caseStudySideEffect?: () => void;
		},
		{ rejectWithValue, dispatch }
	) => {
		dispatch(setOptionsError(""));
		dispatch(setHandleNextLoading(true));
		const res = await userQuestionAttemptsService
			.update({
				data: { userAnswers: data.userAnswers, timeSpent: data.timeSpent },
				filters: { id: data.questionUserAttemptId }
			})
			.catch(error => {
				if (error.name == "422") {
					dispatch(setOptionsError(error.message));
				}
				rejectWithValue(error.message);
				data.caseStudySideEffect && data?.caseStudySideEffect(true);
				throw new Error(error.message);
			})
			.finally(() => {
				dispatch(setHandleNextLoading(false));
			});
		dispatch(setCreatePayload(res.raw[0]));
		data.caseStudySideEffect && data?.caseStudySideEffect();
		data.sideEffect && data.sideEffect();
		return res;
	}
);

export const getFeedback = createAsyncThunk("test/getFeedback", async (id: number, { rejectWithValue, dispatch }) => {
	return await userQuestionFeedbackItemsService
		.find({ filters: { questionId: id } })
		.then(res => {
			if (!res?.items[0]?.text || res.items.length === 0) {
				dispatch(setFeedbackAllowed(true));
			} else {
				dispatch(setFeedbackAllowed(false));
			}
		})
		.catch(e => rejectWithValue(e.message));
});

export const setQuestionFeedback = createAsyncThunk(
	"test/setQuestionFeedback",
	async (data: UserQuestionFeedbackItemCreateDto, { rejectWithValue, dispatch }) => {
		return await userQuestionFeedbackItemsService
			.create(data)
			.then(res => {
				dispatch(setFeedbackAllowed(false));
				return res;
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const getMarkedQuestion = createAsyncThunk(
	"test/getMarkedQuestion",
	async (id: number, { rejectWithValue, dispatch }) => {
		return await userQuestionMarkingService
			.getMarkedQuestion({ filters: { id } })
			.then(res => {
				if (res.items[0]?.markedByUsers.length === 1) {
					dispatch(setIsQuestionMarked(true));
				} else {
					dispatch(setIsQuestionMarked(false));
				}
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const markQuestion = createAsyncThunk(
	"test/markQuestion",
	async (data: UserQuestionMarkingCreateDto, { rejectWithValue, dispatch, getState }) => {
		const { test } = getState() as { test: TestState };
		return await userQuestionMarkingService
			.markQuestion(data)
			.then(() => {
				dispatch(setIsQuestionMarked(!test.isMarked));
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const fetchQuestionAttemptsCount = createAsyncThunk(
	"test/fetchQuestionAttemptsCount",
	async (questions: Question[], { rejectWithValue }) => {
		const questionIds = questions.map(question => question.id);
		return await questionsAttemptsCountService
			.find({ findAll: true, filters: { questionId: questionIds } })
			.catch(() => rejectWithValue("Error in getting questionAttemptsCount"));
	}
);

export const retakeTest = createAsyncThunk(
	"test/retake",
	async (
		{ sideEffect, userCustomTestId }: { userCustomTestId: number; sideEffect: () => void },
		{ rejectWithValue, dispatch }
	) => {
		dispatch(setLoading(true));
		userCustomTestsService
			.retakeTest({ userCustomTestId })
			.then(() => sideEffect())
			.catch(() => rejectWithValue("Error while retaking the test"))
			.finally(() => {
				dispatch(setLoading(false));
			});
	}
);

export const fetchTestHistory = createAsyncThunk(
	"test/fetchTestHistory",
	async ({ id, page, perPage }: { id: number; page: number; perPage: number }, { rejectWithValue }) => {
		return await userCustomTestsService.getTestHistory({ id, page, perPage }).catch(e => rejectWithValue(e.message));
	}
);

export const testSlice = createSlice({
	name: "test",
	initialState,
	reducers: {
		setLoading: (state, { payload }) => {
			state.isLoading = payload;
		},
		failed: (state, { payload: { errorMessage } }) => {
			state.errorMessage = errorMessage;
		},
		setNotesQuestionId: (state, { payload }) => {
			state.questionNotes.questionId = payload;
		},
		emptyQuestionNotes: state => {
			state.questionNotes.text = "";
		},
		clearNotes: state => {
			state.questionNotes = { text: "", questionId: 0, id: 0, userId: 0 };
		},
		setFeedbackAllowed: (state, { payload }) => {
			state.feedbackAllowed = payload;
		},
		setOptionsError: (state, { payload }) => {
			state.optionsError = payload;
		},
		setHandleNextLoading: (state, { payload }) => {
			state.handleNextLoading = payload;
		},
		setIsQuestionMarked: (state, { payload }) => {
			state.isMarked = payload;
		},
		setCreatePayload: (state, { payload: attempt }) => {
			if (attempt.snapshot.forCaseStudy) {
				state.caseStudyResult = [...state.caseStudyResult, attempt];
			}
			state.questionResult = attempt;
			state.quizTestAnswers[attempt.subQuestionId ?? attempt.questionId] = {
				id: attempt.id,
				answers: attempt.selectedAnswers!.map(pick(["questionAnswerOptionId", "questionGroupId", "text"])),
				timeSpent: attempt.timeSpent
			} as SavedAnswer;

			if (attempt.CATResponse) {
				const { nextQuestion, refetchTest } = attempt.CATResponse;

				state.currentTest.refetchTest = refetchTest;

				if (!refetchTest && nextQuestion) {
					state.currentTest.data.questions = [...state.currentTest.data.questions, nextQuestion];
				}
			}
			state.isLoading = false;
		},
		setCSQuestions: (state, { payload: { id, questionIndex } }) => {
			state.attemptedCSQuestions.push({
				questionIndex: questionIndex,
				id: id
			});
			state.attemptedCSQuestions = [...new Map(state.attemptedCSQuestions.map(item => [item["id"], item])).values()];
		},
		setCurrentTest: (state, { payload }) => {
			state.currentTest = payload;
		},
		resetState: utilsResetState,
		completeCaseStudyQuestion: (state, { payload: { questionId } }: PayloadAction<{ questionId: number }>) => {
			state.quizTestAnswers![questionId] = {
				id: questionId,
				answers: [],
				timeSpent: 0
			};
		}
	},
	extraReducers: {
		[fetchTests.pending.type]: state => {
			state.currentTest.isLoading = true;
		},
		[fetchTests.fulfilled.type]: (state, { payload }) => {
			const {
				userQuestionAttempts,
				inProgress,
				timeSpent,
				data: { questions }
			} = payload;
			state.currentTest = payload;
			state.currentTest.timeSpent = timeSpent;
			state.isTestFinished = !inProgress;

			if (!state.currentTest.inProgress) {
				state.quizPercentage = Math.round((state.currentTest.percentageGrade ?? 0) * 100);
				state.quizResult = userQuestionAttempts;
				state.userAttemptedQuestions = questions;
				if (state.currentTest.typeId === QuestionBankTestModes.CAT) {
					state.quizResult = state.currentTest.userQuestionAttempts as UserQuestionAttempt[];
				}
			}

			(state.currentTest.userQuestionAttempts as UserQuestionAttempt[]).forEach(attempt => {
				if (attempt.snapshot?.typeId === QuestionTypes.MatrixSingleChoice) {
					let currentQuestion = questions.find(q => q.id === attempt.questionId);
					if (currentQuestion?.typeId === QuestionTypes.CaseStudy) {
						currentQuestion = currentQuestion.caseStudyQuestions?.find(q => q.id === attempt.subQuestionId);
					}
					const selectAnswers: UserQuestionAnswerAttempt[] = [];
					currentQuestion.data!.groups.forEach(option => {
						const singleAnswerByGroup = attempt.selectedAnswers?.find(f => f.questionGroupId == option.id);
						if (singleAnswerByGroup) {
							selectAnswers.push(singleAnswerByGroup);
						}
					});
					attempt.selectedAnswers = [...selectAnswers];
				}
				state.quizTestAnswers[attempt.subQuestionId ?? attempt.questionId] = {
					id: attempt.id,
					answers: attempt.selectedAnswers!.map(pick(["questionAnswerOptionId", "questionGroupId"])),
					timeSpent: attempt.timeSpent
				} as SavedAnswer;
				attempt.question;
				const mapF = (userQuestionAnswerAttempt: UserQuestionAnswerAttempt): UserQuestionAnswerDto =>
					({
						id: userQuestionAnswerAttempt.questionAnswerOptionId,
						text: userQuestionAnswerAttempt.text,
						groupId: userQuestionAnswerAttempt.questionGroupId,
						order: userQuestionAnswerAttempt.questionAnswerOptionOrder
					} as UserQuestionAnswerDto);
				state.quizTestAnswersAlreadyAttempted[attempt.questionId] = attempt.selectedAnswers!.map(mapF);
				attempt.subQuestionId &&
					(state.quizTestAnswersAlreadyAttemptedCaseStudy[attempt.subQuestionId] = attempt.selectedAnswers!.map(mapF));
			});
			state.currentTest.isLoading = false;
		},
		[fetchTests.rejected.type]: (state, { payload }) => {
			state.currentTest.isLoading = false;
			state.errorMessage = payload;
		},
		[createUserQuestionAttempt.pending.type]: state => {
			state.isLoading = true;
		},
		[completeUserTestAttempt.fulfilled.type]: (
			state,
			{ payload: { raw } }: PayloadAction<{ raw: UserLessonAttempt[] }>
		) => {
			const {
				percentageGrade,
				userQuestionAttempts,
				inProgress,
				timeSpent,
				data: { questions }
			} = raw[0];
			if (state.currentTest.typeId === QuestionBankTestModes.CAT) {
				state.currentTest.data.result = raw[0].data?.result;
			}
			state.currentTest.timeSpent = timeSpent;
			state.isTestFinished = !inProgress;
			state.quizPercentage = Math.round(percentageGrade * 100);
			state.quizResult = userQuestionAttempts;
			state.userAttemptedQuestions = questions;
		},
		[updateUserQuestionAttempt.fulfilled.type]: (state, { payload }: PayloadAction<{ raw: UserQuestionAttempt[] }>) => {
			const attempt = payload.raw[0];
			const results = state.caseStudyResult;
			results[results.findIndex(el => el.id === attempt.id)] = attempt;

			state.quizTestAnswers[attempt.subQuestionId ?? attempt.questionId] = {
				id: attempt.id,
				answers: attempt.selectedAnswers!.map(pick(["questionAnswerOptionId", "questionGroupId"])),
				timeSpent: attempt.timeSpent
			} as SavedAnswer;
		},
		[setQuestionNotes.fulfilled.type]: (
			state,
			{ payload: { customTestId, id, text } }: PayloadAction<UserQuestionNote>
		) => {
			state.isLoading = false;
			state.questionNotes.text = text || "";
			state.questionNotes.id = id || (null as unknown as number);
			state.questionNotes.customTestId = customTestId || (null as unknown as number);
		},
		[getQuestionNotes.pending.type]: state => {
			state.isLoading = true;
		},
		[getQuestionNotes.fulfilled.type]: (
			state,
			{ payload: { items } }: PayloadAction<{ items: UserQuestionNote[] }>
		) => {
			state.isLoading = false;
			state.questionNotes.text = items[0]?.text || "";
			state.questionNotes.id = items[0]?.id || (null as unknown as number);
			state.questionNotes.customTestId = items[0]?.customTestId || (null as unknown as number);
		},
		[getQuestionNotes.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[getFeedback.pending.type]: state => {
			state.isLoading = true;
		},
		[getFeedback.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[getFeedback.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[setQuestionFeedback.pending.type]: state => {
			state.isLoading = true;
		},
		[setQuestionFeedback.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[setQuestionFeedback.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[getMarkedQuestion.pending.type]: state => {
			state.isLoading = true;
		},
		[getMarkedQuestion.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[getMarkedQuestion.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[markQuestion.pending.type]: state => {
			state.isLoading = true;
		},
		[markQuestion.fulfilled.type]: state => {
			state.isLoading = false;
		},
		[markQuestion.rejected.type]: (state, { payload }) => {
			state.errorMessage = payload;
			state.isLoading = false;
		},
		[fetchQuestionAttemptsCount.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchQuestionAttemptsCount.fulfilled.type]: (state, { payload: { items } }) => {
			state.questionAttemptsCount = items;
			state.isLoading = false;
		},
		[fetchQuestionAttemptsCount.rejected.type]: (state, { payload }) => {
			state.isLoading = false;
			state.errorMessage = payload;
		},
		[fetchTestHistory.pending.type]: state => {
			state.isLoadingCustomTestAttempts = true;
		},
		[fetchTestHistory.fulfilled.type]: (state, { payload }) => {
			state.customTestAttemptsHistory = payload;
			state.isLoadingCustomTestAttempts = false;
		},
		[fetchTestHistory.rejected.type]: state => {
			state.isLoadingCustomTestAttempts = false;
		}
	}
});

export const {
	setLoading,
	failed,
	setNotesQuestionId,
	emptyQuestionNotes,
	clearNotes,
	resetState,
	setFeedbackAllowed,
	setIsQuestionMarked,
	completeCaseStudyQuestion,
	setOptionsError,
	setHandleNextLoading,
	setCreatePayload,
	setCSQuestions,
	setCurrentTest
} = testSlice.actions;

export function getFullState(state: RootState): TestState {
	return state.test;
}
export const getIsTestFinished = ({ test }: RootState): boolean => test.isTestFinished;

export const getQuizPercentage = ({ test }: RootState): number => test.quizPercentage;
export const getQuestionResult = ({ test }: RootState): UserQuestionAttempt => test.questionResult;
export const getCaseStudyResult = ({ test }: RootState): UserQuestionAttempt[] => test.caseStudyResult;
export const getQuizResult = ({ test }: RootState): UserQuestionAttempt[] => test.quizResult;
export const getIsLoading = ({ test }: RootState): boolean => test.isLoading;

export const getQuizAttemptedQuestions = ({ test }: RootState): Question[] => test.userAttemptedQuestions;
export const getQuizQuestionAnswers = ({ test }: RootState): { [key: string]: SavedAnswer } | undefined =>
	test.quizTestAnswers;

export const getQuizTestAnswersAlreadyAttempted = ({ test }: RootState): UserAnswerQuestionIdDto =>
	test.quizTestAnswersAlreadyAttempted;

export const getquizTestAnswersAlreadyAttemptedCaseStudy = ({ test }: RootState): UserAnswerQuestionIdDto =>
	test.quizTestAnswersAlreadyAttemptedCaseStudy;

export const selectQuestionNotes = ({ test }: RootState): UserQuestionNote => test.questionNotes;

export const selectFeedback = ({ test }: RootState): string => test.feedback;

export const optionsErrorState = ({ test }: RootState): string => test.optionsError;
export const handleNextLoadingState = ({ test }: RootState): boolean => test.handleNextLoading;

export const selectFeedbackAllowed = ({ test }: RootState): boolean => test.feedbackAllowed;

export const selectIsQuestionMarked = ({ test }: RootState): boolean => test.isMarked;

export default testSlice.reducer;
