import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FAQItem, FAQTopic, IBaseState, UserHelpRequest } from "@remar/shared/dist/models";
import { fulfilledReducer, pendingReducer, rejectReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { RootState } from "store";

import { faqItemsService, usersService } from "store/services";

import { emit } from "../notifications/notifications.slice";

interface HelpState extends IBaseState {
	topics: FAQTopic[];
	questionAndAnswers: FAQItem[];
}
const initialState: HelpState = {
	isLoading: false,
	error: "",
	topics: [],
	questionAndAnswers: []
};

export const getTopics = createAsyncThunk("help/getTopics", async (_, { rejectWithValue }) => {
	return await faqItemsService.findTopics({ findAll: true }).catch(error => rejectWithValue(error.message));
});

export const getFaqs = createAsyncThunk(
	"help/getFaqs",
	async ({ id, searchText }: { id?: number; searchText?: string }, { rejectWithValue }) => {
		const processedFilters: { [fieldName: string]: unknown } = {};
		if (id) {
			processedFilters.fAQTopicId = id;
		}
		if (searchText) {
			processedFilters.searchKeyword = searchText;
		}
		return await faqItemsService.find({ filters: processedFilters }).catch(error => rejectWithValue(error.message));
	}
);

export const sendHelpRequest = createAsyncThunk(
	"users/help",
	async (payload: UserHelpRequest, { dispatch, rejectWithValue }) => {
		return await usersService
			.sendHelpRequest(payload)
			.then(() => {
				dispatch(
					emit({
						message: "Form submitted successfully.",
						color: "success"
					})
				);
			})
			.catch(error => rejectWithValue(error.message));
	}
);

export const helpSlice = createSlice({
	name: "help",
	initialState,
	reducers: {},
	extraReducers: {
		[getTopics.pending.type]: pendingReducer,
		[getTopics.fulfilled.type]: (state, action: PayloadAction<{ items: FAQTopic[] }>) => {
			state.topics = action.payload.items;
			state.isLoading = false;
		},
		[getTopics.rejected.type]: rejectReducer,
		[getFaqs.pending.type]: pendingReducer,
		[getFaqs.fulfilled.type]: (state, action: PayloadAction<{ items: FAQItem[] }>) => {
			state.questionAndAnswers = action.payload.items;
			state.isLoading = false;
		},
		[getFaqs.rejected.type]: rejectReducer,
		[sendHelpRequest.pending.type]: pendingReducer,
		[sendHelpRequest.fulfilled.type]: fulfilledReducer,
		[sendHelpRequest.rejected.type]: rejectReducer
	}
});

export const {} = helpSlice.actions;

export const selectHelpIsLoading = ({ help }: RootState): boolean => help.isLoading;
export const selectFaqTopics = ({ help }: RootState): FAQTopic[] => help.topics;
export const selectFaqs = ({ help }: RootState): FAQItem[] => help.questionAndAnswers;

export default helpSlice.reducer;
