import { Box, makeStyles } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

export const useStyles = makeStyles(theme => ({
	header: {
		backgroundColor: "hsl(221,69%,38%)",
		color: theme.palette.common.white,
		backdropFilter: "blur(16px)",
		padding: 8,
		borderRadius: "4px 4px 0 0"
	},
	body: {
		backdropFilter: "blur(16px)",
		border: "solid 1px rgba(77, 80, 88, 0.16)",
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: "0 0 4px 4px"
	},
	container: {
		padding: "40px 16px 0 16px",
		background: props => `${props["backgroundColor"]}`
	},
	title: {
		color: theme.palette.common.white,
		fontSize: "15px",
		fontWeight: 600,
		flexGrow: 1
	},
	sendButton: {
		padding: "4px 10px",
		fontSize: "0.8125rem",
		color: alpha(theme.palette.text.primary, 0.87),
		backgroundColor: "hsl(0,0%,88%)",
		borderRadius: "4px"
	}
}));

export const BoxContainer = styled(Box)<{ disabled: boolean }>`
	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.65;
		`}

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;
