import React from "react";

import { Box, Card, IconButton, Typography } from "@material-ui/core";
import { EditSharp, Email, Home, Language, Phone } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { RemarBadge } from "@remar/shared/dist/components/RemarBadge";
import { getAccountAddress } from "@remar/shared/dist/utils/getAccountAddress";
import { useHistory } from "react-router-dom";

import { routes } from "core/constants";

import { useStyles } from "./styles";

const MyAccountCard = ({
	profilePictureUrl,
	firstName,
	lastName,
	email,
	schoolName,
	phoneNumber,
	address,
	counties,
	setEditProfilePictureModal,
	showBadge
}) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Box className={classes.userInfoContainer}>
			<Card className={classes.userInfoCard}>
				<Box className={classes.cardHeader}>
					<Box className={classes.imgAndIconContainer}>
						{profilePictureUrl ? (
							<img
								src={profilePictureUrl}
								width="96"
								height="96"
								className={classes.img}
								alt={`${firstName} ${lastName} profile picture`}
							/>
						) : (
							<Box className={classes.callbackImg}>
								<Typography className={classes.initials}>{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</Typography>
							</Box>
						)}
						<IconButton className={classes.editIcon} onClick={() => setEditProfilePictureModal(true)}>
							<EditSharp style={{ width: "12px", height: "12px", fill: "#151a30" }} />
						</IconButton>
					</Box>
					<Box className={classes.nameContainer}>
						<Typography className={classes.name}>{firstName || "-"}</Typography>
						<Typography className={classes.name}>
							{lastName || "-"} {showBadge && <RemarBadge />}
						</Typography>
					</Box>
				</Box>
				<Box className={classes.cardBody}>
					<Box className={classes.bodyRow}>
						<Box>
							<Box className={classes.infoContainer}>
								<Email style={{ width: "27px", height: "27px", fill: "#777a82" }} />
								<Box>
									<Typography className={classes.infoText}>{email || "-"}</Typography>
									<Typography className={classes.infoName}>Email address</Typography>
								</Box>
							</Box>
							<Box className={classes.infoContainer}>
								<Phone style={{ width: "27px", height: "27px", fill: "#777a82" }} />
								<Box>
									<Typography className={classes.infoText}>{phoneNumber || "-"}</Typography>
									<Typography className={classes.infoName}>Phone Number</Typography>
								</Box>
							</Box>
						</Box>

						<Box>
							<Box className={classes.infoContainer}>
								<Language style={{ width: "27px", height: "27px", fill: "#777a82" }} />
								<Box>
									<Typography className={classes.infoText}>{schoolName || "-"}</Typography>
									<Typography className={classes.infoName}>School</Typography>
								</Box>
							</Box>
							{address && counties && (
								<Box className={classes.infoContainer}>
									<Home style={{ width: "27px", height: "27px", fill: "#777a82" }} />
									<Box>
										<Typography className={classes.infoText}>{getAccountAddress(address, counties) || "-"}</Typography>
										<Typography className={classes.infoName}>Address</Typography>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
					{/*<Box className={classes.bodyRow}></Box>*/}
				</Box>
				<Box className={classes.cancelSubscriptionContainer}>
					<Button
						variant="ghost"
						color="secondary"
						onClick={() => history.push(`${routes.myAccount.getPath()}/manageAccount`)}
					>
						Manage Account
					</Button>
				</Box>
			</Card>
		</Box>
	);
};

export default MyAccountCard;
