let cashedToken = "";
let cachedWorkspace = "";

const TOKEN_KEY = "token";
const USER_KEY = "user";
const WORKSPACE_KEY = "workspace";
const REFRESH_TOKEN_KEY = "refreshToken";

const setJwt = (token: string, workspace: string, refreshToken?: string): void => {
	cashedToken = token;
	cachedWorkspace = workspace;
	localStorage.setItem(TOKEN_KEY, token);
	localStorage.setItem(WORKSPACE_KEY, workspace);
	if (refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	}
};

const getJwt = (includeRefreshToken?: boolean): string | null => {
	const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
	const sessionToken = cashedToken || localStorage.getItem(TOKEN_KEY);
	if (!sessionToken) {
		return null;
	}
	return (
		`${sessionToken}` +
		`${refreshToken && refreshToken.length && includeRefreshToken !== false ? ` ${refreshToken}` : ""}`
	);
};

const getWorkspace = (): string | null => cachedWorkspace || localStorage.getItem(WORKSPACE_KEY);

const injectJwt = (config: Record<string, unknown> = {}): Record<string, unknown> => {
	const token = getJwt();
	const workspace = getWorkspace();
	const header = token ? { Authorization: token } : {};
	const workspaceHeader = !!workspace ? { workspace } : {};
	const configHeaders = (config && (config.headers as Record<string, unknown>)) || {};
	return {
		...config,
		headers: {
			"Content-Type": "application/json",
			...configHeaders,
			...header,
			...workspaceHeader
		}
	};
};

const clearJwt = (): void => {
	cashedToken = "";
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(USER_KEY);
	localStorage.removeItem(WORKSPACE_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export { setJwt, getJwt, injectJwt, clearJwt, getWorkspace };
