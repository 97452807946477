import React, { useEffect, useState } from "react";

import { Box, Theme, Typography, createStyles, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { request } from "@remar/shared/dist/api/request";
import Button from "@remar/shared/dist/components/Button";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { Link, useParams } from "react-router-dom";

import Logo from "./Logo";

import Welcome from "./Welcome";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		titleWelcome: {
			fontSize: theme.typography.pxToRem(36),
			fontFamily: "var(--poppins)",
			fontWeight: "bold",
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.typography.pxToRem(20)
			}
		},
		title: {
			marginTop: theme.spacing(5),
			fontSize: theme.typography.pxToRem(42),
			fontWeight: "bold",
			fontFamily: "var(--poppins)"
		},
		subTitle: {
			marginTop: theme.spacing(2),
			fontSize: theme.typography.pxToRem(18),
			color: "#73727b",
			textAlign: "center",
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.spacing(1),
				fontSize: theme.typography.pxToRem(15),
				padding: "0px 5px"
			}
		}
	})
);

const ConfirmEmail = () => {
	const theme = useTheme<Theme>();
	const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const classes = useStyles();

	const [confirmed, setConfirmed] = useState(false);
	const [error, setError] = useState("");
	const { code } = useParams<{ code: string }>();

	const onConfirm = () => {
		request("/users/verify-email", "POST", { verificationCode: code })
			.then(() => setConfirmed(true))
			.catch(e => setError(e.message));
	};

	useEffect(() => {
		if (!confirmed) {
			onConfirm();
		}
	}, [code]);

	return (
		<Box display="flex" justifyContent="center" alignItems="center" height="100vh">
			{confirmed && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Logo width={isMobile ? 100 : 126} height={64} />

					<Box my={isLaptop ? 0 : 8}>
						<Welcome width={isMobile ? 250 : 300} height={isMobile ? 320 : 405} />
					</Box>
					<Typography className={classes.titleWelcome}>Welcome Aboard!</Typography>
					<Typography className={classes.subTitle}>
						You have successfully completed registration and can now start using the app.
					</Typography>
					<Box mt={5}>
						<Link to="/">
							<Button variant="filled" color="primary">
								Login to ReMar V2
							</Button>
						</Link>
					</Box>
				</Box>
			)}
			{!confirmed && !error && (
				<Box>
					<Typography className={classes.title}>Confirming email...</Typography>
					<ContentLoader size="100px" />
				</Box>
			)}
			{!confirmed && error && (
				<Box>
					<Typography className={classes.title}>Error</Typography>
					<Box display="flex" justifyContent="center" mt={0}>
						<Typography className={classes.subTitle}>{error}</Typography>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default ConfirmEmail;
