const orange = {
	100: "hsl(14,100%,94%)",
	200: "hsl(17,96%,89%)",
	300: "hsl(22,100%,62%)",
	400: "hsl(21,100%,50%)",
	500: "hsl(21,100%,42%)",
	600: "hsl(20,100%,36%)",
	700: "hsl(20,87%,21%)",
	800: "hsl(20,70%,17%)"
};

export default orange;
