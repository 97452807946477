import React, { FC } from "react";

const Logo: FC<{ width?: number; height?: number }> = ({ width = 48, height = 24 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 24">
		<defs>
			<linearGradient id="prtijl730a" x1="22.86%" x2="76.004%" y1="39.05%" y2="60.292%">
				<stop offset="0%" stopColor="#3F43EB" />
				<stop offset="100%" stopColor="#000691" />
			</linearGradient>
		</defs>
		<g fill="none" fillRule="evenodd">
			<g>
				<g>
					<g>
						<path
							fill="url(#prtijl730a)"
							d="M24.63 24c-5.515-.045-10.239-.665-14.802-2.328-2.702-.984-5.257-2.273-7.36-4.385C1.382 16.193.513 14.941.16 13.371c-.456-2.043.083-3.855 1.277-5.468 1.34-1.81 3.105-3.045 5.024-4.06C10.08 1.925 13.95.951 17.94.423c2.73-.36 5.469-.513 8.218-.372 4.964.255 9.813 1.1 14.39 3.264 2.235 1.057 4.316 2.367 5.879 4.42 1.26 1.66 1.913 3.51 1.394 5.668-.347 1.444-1.118 2.62-2.095 3.658-1.557 1.655-3.432 2.8-5.44 3.723-3.842 1.762-7.885 2.636-12.036 3.001-1.439.129-2.883.172-3.62.214z"
							transform="translate(-52 -64) translate(40 40) translate(12 24)"
						/>
						<path
							fill="#FFF"
							d="M20.29 3.804c-.019-.047-.035-.095-.047-.144-.074-.793-.223-1.016-.703-1.05-.497-.037-.623.169-.838.889-.033.11-.043.273-.165.291-.328.05-.876-.028-1.207.01-.386.042-.671.222-.655.695.016.44.251.656.72.678l1 .046c-.024.234-.033.383-.054.529-.564 4.03-1.132 8.06-1.706 12.09-.047.343.008.484.349.531.691.096 1.378.235 2.067.35.662.113 1.327.22 2.045.34L20.515 5.37h2.193c.158.004.316.017.473.039.536.066 1.054.245 1.523.527.727.37.73 1.423.084 1.95-.451.37-.905.736-1.361 1.101-.78.625-1.561 1.25-2.397 1.916L32.057 22c.8-1.382 1.564-2.7 2.338-4.04l-9.48-6.914c.266-.064.447-.1.623-.15 1.023-.3 2.067-.54 3.06-.926.68-.274 1.318-.654 1.892-1.127.947-.772.919-1.87-.004-2.686-.253-.23-.532-.425-.831-.579-2.147-1.06-4.452-1.416-6.78-1.64-.863-.086-1.737-.093-2.584-.134z"
							transform="translate(-52 -64) translate(40 40) translate(12 24)"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default Logo;
