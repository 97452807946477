import { Box, Typography, alpha } from "@material-ui/core";

import styled from "styled-components";

export const ColumnHeader = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	font-weight: 500;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.99)};
`;

export const StyledCellWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const StyledCellText = styled(Typography)`
	word-break: break-all;
	padding: 5px 0 5px 10px;
	align-self: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${({ theme }) => theme.palette.text.primary};
	font-size: ${({ theme }) => theme.typography.pxToRem(13)};
`;

export const DownloadIcon = styled(Box)`
	> svg {
		height: 100% !important;
		cursor: pointer;
	}
`;
