import React from "react";

import ClockIcon from "../../assets/icons/icon-clock-yellow.svg";
import SmallBadge from "../../assets/images/ReMar-Badge.svg";
import { UserBadgeTypeEnum } from "../../models";

type RemarBadgeProps = {
	size?: "small" | "medium" | "large";
	type?: UserBadgeTypeEnum;
};

const getSize = (size: RemarBadgeProps["size"]): number => {
	switch (size) {
		case "small":
			return 16;
		case "medium":
			return 18;
		case "large":
			return 30;
		default:
			return 18;
	}
};

const getBadge = (type: UserBadgeTypeEnum, size: RemarBadgeProps["size"], restProps) => {
	switch (type) {
		case UserBadgeTypeEnum.ReMarReadyBadge:
			return (
				<img
					width={getSize(size)}
					src={SmallBadge}
					alt="Remar Badge"
					{...restProps}
					style={{ verticalAlign: "middle" }}
				/>
			);
		case UserBadgeTypeEnum.InactivityBadge:
			return (
				<img
					width={getSize(size)}
					src={ClockIcon}
					alt="Inactive Badge"
					{...restProps}
					style={{ verticalAlign: "middle", fill: "red" }}
				/>
			);
		default:
			return (
				<img
					width={getSize(size)}
					src={SmallBadge}
					alt="Remar Badge"
					{...restProps}
					style={{ verticalAlign: "middle" }}
				/>
			);
	}
};

const RemarBadge = ({ size, type = UserBadgeTypeEnum.ReMarReadyBadge, ...rest }: RemarBadgeProps) =>
	getBadge(type, size, rest);

export default RemarBadge;
