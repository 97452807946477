import React from "react";

import { Checkbox, FormControlLabel, Link } from "@material-ui/core";

import {
	ButtonWrapper,
	CardWrapper,
	DescriptionText,
	DescriptionWrapper,
	HeaderText,
	LabelText,
	StyledModal,
	SubmitButton
} from "./styles";

interface Props {
	open: boolean;
	// eslint-disable-next-line no-unused-vars
	onClose: (isAgreed: boolean) => void;
}

const TermsAndConditions = ({ open, onClose }: Props) => {
	const [isAgreed, setIsAgreed] = React.useState<boolean | undefined>();

	const handleClose = () => {
		onClose(!!isAgreed);
	};

	return (
		<StyledModal disableEnforceFocus disableAutoFocus open={open} onClose={handleClose} st>
			<CardWrapper>
				<HeaderText>Updates to our Terms and Conditions</HeaderText>
				<DescriptionWrapper>
					<DescriptionText>
						We’re making updates to ReMar’s Terms and Conditions. You can review the updated Terms and Conditions{" "}
						<Link target="_blank" href="https://study.remarnurse.com/vit/terms-conditions/">
							here
						</Link>
						.
					</DescriptionText>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={isAgreed}
								onChange={v => setIsAgreed(v.target.checked)}
								name="isAgreed"
							/>
						}
						label={<LabelText>I have read and agree to the Terms & Conditions</LabelText>}
					/>
				</DescriptionWrapper>
				<ButtonWrapper>
					<SubmitButton onClick={handleClose} disabled={!isAgreed}>
						Continue
					</SubmitButton>
				</ButtonWrapper>
			</CardWrapper>
		</StyledModal>
	);
};

export default TermsAndConditions;
