import React from "react";

import { SemiCircleContainer } from "./style";

const SemiRadialBar = ({ data }) => (
	<SemiCircleContainer>
		<div className="semi-circle" data-value={data}>
			<div className="arc"></div>
		</div>
	</SemiCircleContainer>
);

export default SemiRadialBar;
