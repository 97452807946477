import React from "react";

import { Box, Typography } from "@material-ui/core";

import CheckMarkSVG from "../../../assets/icons/icon-checkmark.svg";

import Button from "../../Button";

import { DetailedNextCourse } from "../styles";

const NextCourseCard = ({
	courseCompleted,
	nextLesson,
	ContinueCourse
}: {
	courseCompleted: boolean;
	nextLesson: any;
	ContinueCourse?: () => void;
}) => {
	const { lesson, sectionMainThumbnailUrl, sectionMainImageUrl } = nextLesson || {};
	const { mainImageUrl, mainImageKey, mainImageThumbnailUrl } = lesson || {};
	const isStudent = !!ContinueCourse;
	return courseCompleted ? (
		<DetailedNextCourse courseCompleted>
			<img style={{ marginBottom: "20px" }} src={CheckMarkSVG} alt="CheckMarkSVG" />
			<Typography className={"unlock-feature"}>New feature unlocked</Typography>
			<Typography className={"retake-lesson detailed"}>
				{isStudent ? "You" : "Student"} can now go back and retake lessons and exams
			</Typography>
		</DetailedNextCourse>
	) : (
		<DetailedNextCourse detailed>
			<Box width={"50%"} pr={3}>
				<Typography className={"next-course-label"}>Next</Typography>
				<Typography className={"course-name"}>{lesson?.name}</Typography>
				<Typography className={"next-course-description"}>{lesson?.description}</Typography>
			</Box>
			<Box width={"50%"}>
				<img
					className={"lesson-thumbnail"}
					src={
						(mainImageKey
							? mainImageThumbnailUrl?.small250 || mainImageUrl
							: sectionMainThumbnailUrl?.small250 || sectionMainImageUrl) || "https://picsum.photos/200"
					}
					alt={"Course Thumbnail"}
				/>
				{ContinueCourse && (
					<Button variant={"outlined"} color={"basic"} fullWidth onClick={ContinueCourse}>
						Continue Course
					</Button>
				)}
			</Box>
		</DetailedNextCourse>
	);
};

export default NextCourseCard;
