import React from "react";

import PasswordMatchIndicator from "@remar/shared/dist/components/PasswordMatchIndicator";
import { PasswordMatchBoxWrapper } from "@remar/shared/dist/styles/auth.styles";

const PasswordMatchBox = ({ passwordErrorBox }) => (
	<PasswordMatchBoxWrapper display={"flex"} flexDirection="column">
		<PasswordMatchIndicator passwordErrorBox={passwordErrorBox} />
	</PasswordMatchBoxWrapper>
);

export default PasswordMatchBox;
