import React, { RefObject } from "react";

import { FieldInputProps, FieldMetaProps, FormikProps } from "formik";
import { TextField as MuiTextField, TextFieldProps } from "formik-material-ui"; //todo: migrate to formik-mui npm package or get rid of this

interface Props extends TextFieldProps {
	field: FieldInputProps<unknown>;
	form: FormikProps<unknown>;
	meta: FieldMetaProps<unknown>;
	inputRef: RefObject<unknown>;
}

const TextField = ({ inputRef, ...props }: Props) => {
	const handleBlur = e => {
		if (typeof e.target.value === "string") {
			e.target.value = e.target.value.trim();
		}
		props.field.onBlur(e);
	};
	return (
		<MuiTextField
			InputProps={{ disableUnderline: true, ref: inputRef }}
			{...props}
			onBlur={handleBlur}
			variant="filled"
		/>
	);
};

export default TextField;
