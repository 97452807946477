import { CircularProgress as Progress, alpha } from "@material-ui/core";
import Icon from "@material-ui/core/SvgIcon";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const RoundedIcon = styled(Icon)`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: ${({ theme }) => alpha(theme.palette.InfoCard.backgroundColor, 0.8)};
	padding: 13px;
	margin-right: 5px;
	flex-grow: 0;

	* {
		filter: grayscale(1);
	}
`;

export const FoldersContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: start;
	justify-content: start;
	margin: 0 -10px;
	margin-top: 21px;
`;

export const FolderTitle = styled(RouterLink)`
	flex-grow: 1;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Folder = styled.div`
	background: ${({ theme }) => alpha(theme.palette.InfoCard.backgroundColor, 0.4)};
	width: 302px;
	margin: 10px;
	padding: 25px 10px 20px 25px;
	font-family: var(--openSans);
	font-size: ${props => props.theme.typography.pxToRem(15)};
	line-height: 1.33;
	display: flex;
	flex-flow: row nowrap;
	gap: 16px;
	align-items: center;

	${props => props.theme.breakpoints.down("sm")} {
		width: 100%;
	}
`;
export const SectionHeader = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(18)};
	font-weight: bold;
	line-height: 1.33;
	color: ${props => props.theme.palette.colors.basic[100]};
	margin: 0 0 13px;
`;

export const SvgIcon = styled(Icon)`
	width: 70px;
	height: 70px;
`;

export const SearchPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 64px;
	padding: 12px 8px;
	background: ${props => props.theme.palette.background.paper};
	color: ${props => props.theme.palette.text.primary};
	margin: 28px 0 0;
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	line-height: 1.6;

	> div:first-child {
		flex-grow: 1;
	}

	> div:nth-child(2) {
		> div:first-child {
			width: 100%;
		}
	}

	.search-icon {
		margin-left: 0;
		padding-left: 10px;
		background-color: ${({ theme }) => theme.palette.colors.basic[1000]};
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
`;

export const CircularProgress = styled(Progress)`
	margin-left: 50%;
	transform: translate(-50%);
`;
