import React, { useState } from "react";

import { Box, CircularProgress, IconButton, Modal, Theme, Typography, useTheme } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import Button from "@remar/shared/dist/components/Button";
import { Wrapper } from "@remar/shared/dist/layouts";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { useSimpleModalStyles } from "@remar/shared/dist/modals/SimpleModal/styles";
import isEmpty from "lodash/isEmpty";
import { RootState, useAppSelector } from "store";

import { routes } from "core/constants";

import DeleteAccountConfirmation from "./DeleteAccountConfirmation";

import SubscriptionCancellation from "./SubscriptionCancellation";

import {
	BodyText,
	CancelSubModalWrapper,
	CardWrapper,
	CircularBox,
	ContentWrapper,
	RowWrapper,
	useStyles
} from "./styles";

const breadcrumbs = [
	{ title: "My Account", key: 0, link: routes.myAccount.getPath() },
	{ title: "Manage Account", key: 1 }
];
const ManageAccount = () => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const { subscriptionInfo, isLoading: myAccLoading } = useAppSelector((store: RootState) => store.myAccount);

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [cancellationOptionsModalOpen, setCancellationOptionsModalOpen] = useState(false);
	const simpleModalClasses = useSimpleModalStyles();
	const disabledCancelButton = subscriptionInfo?.subscription.isCancelled;
	const isTrial = subscriptionInfo?.subscription.type?.isTrial;

	const onCancelModalClose = () => {
		if (!isLoading) {
			setCancelModalOpen(false);
		}
	};
	const onCancelConfirm = () => {
		setCancellationOptionsModalOpen(true);
	};
	const onClose = () => {
		if (!isLoading) {
			setDeleteModalOpen(false);
		}
	};

	const onConfirm = () => {
		setShowDeleteModal(true);
		setDeleteModalOpen(false);
		setIsLoading(false);
	};

	if (myAccLoading) {
		return (
			<CircularBox>
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</CircularBox>
		);
	}

	return (
		<Wrapper heading="Manage Account" breadcrumb={breadcrumbs}>
			<CardWrapper>
				{!isTrial && !isEmpty(subscriptionInfo?.subscription) && (
					<RowWrapper>
						<ContentWrapper>
							<Typography className={classes.infoText}>Cancel Subscription</Typography>
							<Typography className={classes.infoName}>
								Your subscription will be cancelled. If you cancel a paid subscription, you can keep using the
								subscription until the next billing date.
							</Typography>
						</ContentWrapper>
						<Button
							variant={"outlined"}
							color={"basic"}
							size="medium"
							disabled={disabledCancelButton}
							onClick={() => setCancelModalOpen(true)}
						>
							Cancel Subscription
						</Button>
					</RowWrapper>
				)}
				<RowWrapper>
					<ContentWrapper>
						<Typography className={classes.infoText}>Delete Account</Typography>
						<Typography className={classes.infoName}>
							When you delete your ReMar account, you won&#39;t be able to retrieve the content of your account. All
							your account data, subscription, and courses progress will also be deleted.
						</Typography>
					</ContentWrapper>
					<Button variant="outlined" color="basic" size="medium" onClick={() => setDeleteModalOpen(true)}>
						Delete Account
					</Button>
				</RowWrapper>
			</CardWrapper>
			<SimpleModal
				theme={theme}
				title="Delete Account"
				open={deleteModalOpen}
				onClose={onClose}
				text={""}
				extraContent={
					<BodyText>
						Are you sure you want to delete this account? When you delete your ReMar account, you wont be able to
						retrieve the content of your account. All your account data, subscription and courses progress will also be
						deleted.
					</BodyText>
				}
				footer={
					<Box className={classes.buttonContainer}>
						<Button
							loading={isLoading}
							variant={"outlined"}
							color={"basic"}
							size="medium"
							disabled={isLoading}
							onClick={onClose}
						>
							No, Cancel
						</Button>
						<Button
							loading={isLoading}
							variant="filled"
							color="danger"
							disabled={isLoading}
							size="medium"
							onClick={() => onConfirm()}
						>
							Yes, Delete Account
						</Button>
					</Box>
				}
			/>
			{showDeleteModal && (
				<DeleteAccountConfirmation showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
			)}
			<SimpleModal
				theme={theme}
				title="Cancel Subscription"
				open={cancelModalOpen}
				onClose={onCancelModalClose}
				text="Are you sure you want to cancel your subscription?"
				footer={
					<Box className={classes.buttonContainer}>
						<Button
							loading={isLoading}
							variant={"outlined"}
							color={"basic"}
							size="medium"
							disabled={isLoading}
							onClick={onCancelModalClose}
						>
							No, Keep Subscription
						</Button>
						<Button
							loading={isLoading}
							variant="filled"
							color="danger"
							disabled={isLoading}
							size="medium"
							onClick={() => onCancelConfirm()}
						>
							Yes, Cancel
						</Button>
					</Box>
				}
			/>
			<Modal
				open={cancellationOptionsModalOpen}
				className={simpleModalClasses.modal}
				onClose={() => setCancellationOptionsModalOpen(false)}
			>
				<CancelSubModalWrapper width={800}>
					<Box className={"header"}>
						<Typography className={simpleModalClasses.title}>Cancel Subscription</Typography>
						<IconButton onClick={() => setCancellationOptionsModalOpen(false)}>
							<CloseIcon color="action" />
						</IconButton>
					</Box>
					<SubscriptionCancellation
						subscriptionId={subscriptionInfo?.subscription.id}
						onClose={() => setCancellationOptionsModalOpen(false)}
					/>
				</CancelSubModalWrapper>
			</Modal>
		</Wrapper>
	);
};

export default ManageAccount;
