import { thumbnailUrl } from "utils/thumbnailUrl";

import { BaseModel } from "./base.model";
import { Course } from "./course.model";
import { CourseChapterSection } from "./courseChapterSection.model";

export interface ICourseChapterData {
	price: number;
	name: string;
	planId: string;
	currency: string;
	planName: string;
}

export interface LockChapterData {
	buttonText: string;
	disclaimer: string;
	lifetimeAccess: boolean;
	numberOfDays: number;
	isPaid: boolean;
	planId: string;
}

export interface ICourseChapterDataItem extends BaseModel {
	courseChapterId: number;
	integrationId: number;
	data: ICourseChapterData;
}

export interface CourseChapter extends BaseModel {
	calculateTimeLengthAutomatically: boolean;
	contentAccessTypeId: ContentAccessTypes;
	course?: Course;
	courseId: number;
	isActive: boolean;
	isLockedForCurrentUser?: boolean;
	mainImageKey?: string;
	mainImageThumbnailUrl?: thumbnailUrl;
	mainImageUrl?: string;
	manualTimeLengthComponent: number;
	name: string;
	order: number;
	progressTrackingEnabled?: boolean;
	sections?: CourseChapterSection[];
	totalLessons: number;
	totalSections: number;
	totalTimeLengthInMinutes: number;
	allowedLocations: Location[];
	lockedChapter: boolean;
	data: LockChapterData;
	courseChapterDataItems: ICourseChapterDataItem[];
}

export enum ContentAccessTypes {
	Paid = 1,
	Mixed = 2,
	Trial = 3
}
