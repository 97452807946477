import React, { useState } from "react";

import { Box } from "@material-ui/core";
import { Wrapper } from "@remar/shared/dist/layouts";
import { useAppDispatch } from "store";
import { setError } from "store/features/Billing/BillingSlice";

import NextBilling from "./NextBilling";
import PastPayments from "./PastPayments";
import PaymentMethods from "./PaymentMethods";

import { BillingsContainer, PastPaymentsContainer } from "./styles";

const Billings = () => {
	const [modal, setModal] = useState(false);
	const dispatch = useAppDispatch();

	return (
		<Wrapper heading="My Payments and Billings">
			<BillingsContainer>
				<PastPaymentsContainer>
					<Box>
						<PastPayments />
					</Box>
				</PastPaymentsContainer>
				<Box display="flex" flexDirection="column" flex={1 / 4}>
					<NextBilling />
					<PaymentMethods
						showRecurringInfo
						onEdit={() => {
							dispatch(setError(""));
							setModal(true);
						}}
						modal={modal}
						setModal={setModal}
					/>
				</Box>
			</BillingsContainer>
		</Wrapper>
	);
};

export default Billings;
