import { alpha } from "@material-ui/core/styles";
import styled, { css } from "styled-components";

function createCSS() {
	let styles = "";

	for (let i = 0; i <= 90; i += 1) {
		styles += `
		.semi-circle[data-value="${i}"] .arc{
      transform: rotate(${i * 1.8}deg);
      animation: rotate 1s linear;
    }
     `;
	}

	return css`
		${styles}
	`;
}

export const SemiCircleContainer = styled.div`
	justify-content: center;
	display: flex;
	padding-top: 15px;

	.semi-circle {
		position: relative;
		width: 200px;
		height: 100px;

		&::after {
			border: 12px solid;
			border-bottom: none;
			top: 0;
			z-index: 1;
			border-radius: 300px 300px 0 0;
			width: 100%;
			height: 100%;
			content: "";
			position: absolute;
			transform-origin: 50% 0;
			left: 0;
			box-sizing: border-box;
			border-image-source: linear-gradient(to bottom, hsl(55, 100%, 63%), hsl(1, 100%, 66%));
			background-image: ${({
				theme
			}) => `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.paper}),
				linear-gradient(to right, #d54b5b -17%, #f06d06, rgb(255, 255, 0), green 86%)`};
			background-origin: border-box;
			background-clip: content-box, border-box;
			border-image-slice: 0;
		}
	}

	.arc {
		border: 28px solid;
		border-top: none;
		content: " ";
		display: block;
		border-radius: 0 0 300px 300px;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 100px;
		left: 0;
		transform-origin: 50% 0;
		box-sizing: border-box;
		z-index: 2;
		border-color: transparent;

		&::after {
			width: 18px;
			content: " ";
			transform: rotate(90deg);
			height: 18px;
			background: transparent;
			display: block;
			top: -7px;
			position: absolute;
			left: -30.6px;
			border: solid 4px #fff;
			border-radius: 50%;
		}
	}

	${createCSS()};

	.semi-circle[data-value="0"] .arc {
		transform: rotate(1deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="91"] .arc {
		transform: rotate(165.2deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="92"] .arc {
		transform: rotate(166.4deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="93"] .arc {
		transform: rotate(167.6deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="94"] .arc {
		transform: rotate(168.8deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="95"] .arc {
		transform: rotate(170deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="96"] .arc {
		transform: rotate(171.2deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="97"] .arc {
		transform: rotate(172.4deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="98"] .arc {
		transform: rotate(173.6deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="99"] .arc {
		transform: rotate(174.8deg);
		animation: rotate 1s linear;
	}

	.semi-circle[data-value="100"] .arc {
		transform: rotate(176deg);
		animation: rotate 1s linear;
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
	}
`;

export const ProgressCount = styled.div`
	opacity: 0.87;
	font-family: var(--poppins);
	font-size: ${props => props.theme.typography.pxToRem(26)};
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: center;
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const ProgressPercentage = styled.div`
	font-size: ${props => props.theme.typography.pxToRem(15)};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: ${({ theme }) => alpha(theme.palette.text.primary, 0.6)};
`;

export const StyledSvg = styled.svg`
	overflow: visible;
`;

export const GraphContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding-top: 25px;
	justify-content: center;
`;

export const Graph = styled.div`
	display: flex;
	max-width: 200px;
	flex-direction: column;
`;
