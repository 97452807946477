import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";

import { Lesson } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class LessonsService extends BaseApiService<
	Lesson,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getLastViewedSectionId(courseChapterId: number): Promise<{ sectionLessonId: string }> {
		return request(`${this.baseUrl}/last-viewed-section-lesson?courseChapterId=${courseChapterId}`, "GET") as Promise<{
			sectionLessonId: string;
		}>;
	}

	createLastViewedSectionId(data: { sectionLessonId: number; courseChapterId: number }): Promise<{ success: boolean }> {
		return request(
			`${this.baseUrl}/last-viewed-section-lesson`,
			"POST",
			this.emptyStringToNull({
				sectionLessonId: data.sectionLessonId,
				courseChapterId: data.courseChapterId
			}) as Record<string, unknown>
		) as Promise<{ success: boolean }>;
	}
}

export const lessonsService = new LessonsService(URLS.LESSONS);
