import React, { useEffect, useState } from "react";

import { Box, Modal, TextareaAutosize, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { ReactComponent as NotesSvg } from "@remar/shared/dist/assets/icons/icon-clipboard-list-check-filled.svg";
import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";
import { Spacer } from "@remar/shared/dist/components/Spacer";
import { File, QuestionAttemptsCount } from "@remar/shared/dist/models";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils/helpers";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useAppDispatch, useAppSelector } from "store";
import {
	emptyQuestionNotes,
	fetchQuestionAttemptsCount,
	getFullState,
	getQuestionNotes
} from "store/features/QuestionBank/Test/test.slice";

import { ReactComponent as ChartColumn } from "assets/icons/icon-chart-column.svg";

import { ReactComponent as Timer } from "assets/icons/icon-clock.svg";

import { ReactComponent as YellowHalf } from "assets/icons/icon-half.svg";

import { PreviewNotesText, QuestionRationalInfo, QuestionResult } from "./styles";

import { useStyles as LessonStyles } from "../../../Lesson/style";

import { CorrectAnswer, NotAttempted, NotesContainer, PercentageContainer, WrongAnswer, useStyles } from "../styles";

const GetPeerPercentage = ({ qId, questionAttemptsCount }) => {
	const attempt = questionAttemptsCount.find(attempt => attempt.questionId === qId) as QuestionAttemptsCount;
	const percentage = (attempt?.totalCorrectAttempts / attempt?.totalAttempts) * 100;
	return <PreviewNotesText>{percentage ? percentage.toFixed() : 0}% of peers have answered correctly</PreviewNotesText>;
};

const PeerPercentage = React.memo(GetPeerPercentage);

const GetRationaleImg = ({ url }) => {
	const classes = useStyles();
	return (
		<Box display={"flex"} justifyContent="center" flex={1}>
			<img alt="rationale" className={classes.rationaleImage} src={url} />
		</Box>
	);
};

const RationaleImage = React.memo(GetRationaleImg);

const QuestionResultInfo = ({ result }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const [showNotes, setShowNotes] = useState(false);
	const lessonClasses = LessonStyles();
	const currentTest = localStorage.getItem("currentTestID");
	const analytics = useAnalyticsEventTracker("Question Bank");

	const {
		questionNotes: { text, questionId: notesQuestionId },
		questionAttemptsCount
	} = useAppSelector(getFullState);

	const handleShowNotes = questionId => {
		if (notesQuestionId !== questionId) {
			dispatch(emptyQuestionNotes());
			dispatch(getQuestionNotes({ id: questionId, customTestId: currentTest as unknown as number }));
		}
		setShowNotes(true);
	};

	useEffect(() => {
		if (result?.questionId) {
			dispatch(fetchQuestionAttemptsCount(result.questionId));
		}
	}, [dispatch, result?.questionId]);
	const getResultInfo = result => {
		let resultStatusIcon = <NotAttempted className={classes.icon} />;
		let resultStatusText = "Not Attempted";
		if (result) {
			if (result?.percentageGrade === 1) {
				resultStatusText = "Correct";
				resultStatusIcon = <CorrectAnswer />;
			} else if (result?.percentageGrade === 0) {
				resultStatusText = "Incorrect";
				resultStatusIcon = <WrongAnswer className={classes.icon} />;
			} else {
				resultStatusText = "Partially Correct";
				resultStatusIcon = <YellowHalf className={classes.icon} />;
			}
		}
		return (
			<>
				{resultStatusIcon} <QuestionResult>{resultStatusText}</QuestionResult>
			</>
		);
	};

	return (
		<>
			<div className={classes.resultInfo}>
				<div className={classes.rationaleContainer}>
					<div className={classes.rationaleHeader}>
						<Box display={"flex"} alignItems="center" flex={1}>
							<Box display={"flex"} flex={1}>
								{getResultInfo(result)}
							</Box>
							{result?.snapshot.mainImageKey && <RationaleImage url={result.snapshot.mainImageUrl} />}
						</Box>
					</div>
					<div className={classes.rationaleHeader}>Rational answer</div>
					<QuestionRationalInfo
						dangerouslySetInnerHTML={{
							__html: result?.snapshot?.endOfQuestionSummary ?? ""
						}}
					></QuestionRationalInfo>
					{(result?.snapshot?.attachments as File[])?.length > 0 && (
						<Box width={isMobile ? "100%" : "60%"}>
							<FilesUploaded
								attachments={result?.snapshot?.attachments as File[]}
								IconSvg={DownloadIconSvg}
								showTitle={false}
								isDownload
								borderColor="#edf1f7"
								filenameColor="#4d5058"
								onClick={attachment => downloadURI(attachment.fileUrl, attachment.name)}
							/>
						</Box>
					)}
					<Spacer />
					<NotesContainer
						onClick={() => {
							analytics({ eventName: "preview_notes_extended_results" });
							handleShowNotes(!result?.snapshot?.forCaseStudy ? result?.snapshot?.id : result?.snapshot?.parentId);
						}}
					>
						<NotesSvg className={classes.icon} />
						<PreviewNotesText>Preview notes</PreviewNotesText>
					</NotesContainer>
					<PercentageContainer>
						<ChartColumn className={classes.icon} />
						<PeerPercentage qId={result?.questionId} questionAttemptsCount={questionAttemptsCount} />
					</PercentageContainer>
					{result && (
						<div className={classes.timer}>
							<Timer className={classes.icon} />
							<PreviewNotesText> Time spent - {result?.timeSpent} seconds </PreviewNotesText>
						</div>
					)}
				</div>
			</div>
			{showNotes && (
				<Modal
					onClose={() => setShowNotes(false)}
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexFlow: "column"
					}}
					disableEnforceFocus
					disableAutoFocus
					open={showNotes}
				>
					<>
						<Box width={310} display="flex" alignItems="center" className={lessonClasses.notesHeader}>
							<Typography className={classes.notesTypography}>Notes</Typography>
							<IconButton size="small" onClick={() => setShowNotes(false)}>
								<Close />
							</IconButton>
						</Box>
						<Box width={310} className={lessonClasses.notesBody}>
							<TextareaAutosize
								className={classes.textArea}
								value={text}
								readOnly
								maxRows={9}
								minRows={9}
								maxLength={500}
							/>
						</Box>
					</>
				</Modal>
			)}
		</>
	);
};

export default QuestionResultInfo;
