import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Themes } from "@remar/shared/dist/models/theme.types";

import localStorage from "@remar/shared/dist/services/localStorage/localStorage";

import { ThemeNames, convertThemeName } from "@remar/shared/dist/utils/convertThemeName";

import isEmpty from "lodash/isEmpty";
import { RootState } from "store";

import { ThemeDto, locationPackageService } from "store/services/locationPackage";

import { emit } from "../notifications/notifications.slice";

interface ThemeState {
	isLoading: boolean;
	errorMessage: string;
	mode: "dark" | "white";
	colorShade: Themes;
	logoImageUrl?: string;
}

const storageTheme = localStorage.get("theme", {} as ThemeDto) as ThemeDto;

const initialState: ThemeState = {
	isLoading: false,
	errorMessage: "",
	mode: "dark",
	colorShade: isEmpty(storageTheme) ? Themes.DARK : convertThemeName(<ThemeNames>storageTheme.name)
};

export const loadThemeByDomain = createAsyncThunk(
	"Theme/loadThemeByDomain",
	async ({ subDomain }: { subDomain: string }, { dispatch, rejectWithValue }) => {
		try {
			const theme = await locationPackageService.getLocationThemeByDomain(subDomain);

			if (theme) {
				localStorage.set("theme", JSON.stringify(theme));
			}

			return theme;
		} catch ({ message }) {
			dispatch(emit({ message: `Error while loading theme parameters: ${message}`, color: "error" }));
			return rejectWithValue(message);
		}
	}
);

export const loadTheme = createAsyncThunk("Theme/loadTheme", async (_, { dispatch, rejectWithValue }) => {
	try {
		const theme = await locationPackageService.getLocationTheme();
		if (theme) {
			localStorage.set("theme", JSON.stringify(theme));
		}
		return theme;
	} catch ({ message }) {
		dispatch(emit({ message: `Error while loading theme parameters: ${message}`, color: "error" }));
		return rejectWithValue(message);
	}
});

const themeSlice = createSlice({
	name: "Theme",
	initialState,
	reducers: {
		switchColorShade: (state, action) => {
			state.colorShade = action.payload;
		},
		setImageLogo: (state, action) => {
			state.logoImageUrl = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(loadThemeByDomain.pending, state => {
				state.isLoading = true;
			})
			.addCase(loadThemeByDomain.fulfilled, (state, { payload: { logoImageUrl, name } }) => {
				state.colorShade = convertThemeName(<ThemeNames>name);
				state.logoImageUrl = logoImageUrl;
				state.isLoading = false;
			})
			.addCase(loadThemeByDomain.rejected, state => {
				state.isLoading = false;
			})
			.addCase(loadTheme.pending, state => {
				state.isLoading = true;
			})
			.addCase(loadTheme.fulfilled, (state, action) => {
				state.colorShade = convertThemeName(<ThemeNames>action.payload.name);
				state.logoImageUrl = action.payload.logoImageUrl;
				state.isLoading = false;
			})
			.addCase(loadTheme.rejected, state => {
				state.isLoading = false;
			});
	}
});

export const { switchColorShade, setImageLogo } = themeSlice.actions;

export const getFullState = ({ theme }: RootState): ThemeState => theme;

export default themeSlice.reducer;
