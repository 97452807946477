import { Box, Breadcrumbs } from "@material-ui/core";

import styled from "styled-components";

export const Header = styled(Box)`
	flex: 1;
`;

export const HeaderContainer = styled(Box)`
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	margin-top: 24px;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-right: 20px;

	${({ theme }) => theme.breakpoints.down("sm")} {
		padding-right: 0;
	}

	.breadcrumb-container {
		margin-top: 5px;
	}
`;

export const StyledBreadcrumb = styled(Breadcrumbs)`
	margin-top: 13px;
	padding-right: 4px;
`;
