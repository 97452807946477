import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import { Grid, createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import {
	DatePickerProps,
	MuiPickersUtilsProvider,
	DatePicker as Picker,
	TimePicker,
	TimePickerProps
} from "@material-ui/pickers";

const defaultMaterialTheme = createTheme({
	palette: {
		primary: {
			main: "rgba(38, 79, 174, 1)"
		}
	},
	typography: {
		allVariants: {
			color: "hsl(224,18%,12%)"
		}
	}
});

type Props = (Partial<TimePickerProps> | Partial<DatePickerProps>) & { isTimePicker?: boolean };

export const DatePicker = ({ isTimePicker = false, ...props }: Props) => (
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<Grid container justifyContent="space-around">
			<ThemeProvider theme={defaultMaterialTheme}>
				{isTimePicker ? <TimePicker {...(props as TimePickerProps)} /> : <Picker {...(props as DatePickerProps)} />}
			</ThemeProvider>
		</Grid>
	</MuiPickersUtilsProvider>
);

export default DatePicker;
