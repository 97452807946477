import { UserQuestionFeedbackItemCreateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "../../api/baseApiService";
import { UserQuestionFeedbackItem } from "../../models";

import { URLS } from "../services.constants";

export class UserQuestionFeedbackItemsService extends BaseApiService<
	UserQuestionFeedbackItem,
	UserQuestionFeedbackItemCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userQuestionFeedbackItemsService = new UserQuestionFeedbackItemsService(URLS.USER_QUESTION_FEEDBACK_ITEMS);
