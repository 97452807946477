import React from "react";

import { FullScreenWithLogoLayout } from "@remar/shared/dist/layouts";

import { CenterBlockWrapper, SignUpOverlay } from "@remar/shared/dist/layouts/AuthFormLayout/styles";

import AuthBackground from "assets/images/signup-background.png";

type Props = {
	logo?: React.ReactNode;
	bgImage?: string;
};
const SignUpLayout: React.FC<Props> = ({ children, logo, bgImage }) => (
	<>
		<FullScreenWithLogoLayout customBgImg={bgImage || AuthBackground} logo={logo}>
			<CenterBlockWrapper>{children}</CenterBlockWrapper>
		</FullScreenWithLogoLayout>
		<SignUpOverlay />
	</>
);

export default SignUpLayout;
