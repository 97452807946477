import React from "react";

import { Box, Typography } from "@material-ui/core";

import { ReactComponent as TestCreationLoaderSVG } from "assets/icons/creation-loader.svg";
import { ReactComponent as RemarLogoSvg } from "assets/icons/icon-logo-remar.svg";

export const CreateTestLoader = () => (
	<Box display="flex" flexDirection="column">
		<Box display="flex" flex={1} justifyContent="center" mt={4}>
			<RemarLogoSvg style={{ zIndex: 1500 }} />
		</Box>
		<Box display="flex" flex={1} justifyContent="center" mt={10}>
			<TestCreationLoaderSVG style={{ zIndex: 1500 }} />
		</Box>
		<Box display="flex" flex={1} justifyContent="center" mt={4}>
			<Typography variant="h6">Your Test is Creating…</Typography>
		</Box>
	</Box>
);
