import React, { useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import ReactPixel from "react-facebook-pixel";

import { setTiktokEvent } from "modules/utils";

import { useStyles } from "./styles";

export const GuestSignUpSuccessLeft = ({ signupSuccessUserData: data }) => {
	useEffect(() => {
		ReactPixel.track("Purchase", { ...data, value: data!.price?.replace("$", ""), currency: "USD" });
		setTiktokEvent("CompletePayment", { ...data, value: data!.price?.replace("$", ""), currency: "USD" });
	}, []);
	const classes = useStyles();
	return (
		<Box display="flex" justifyContent="center" flexDirection="column" minHeight="400px">
			<Box display="flex" justifyContent="center">
				<CheckCircle style={{ fill: "#30d14d", width: "64px", height: "64px" }} />
			</Box>
			<Typography className={classes.errorTitle}>{"Payment Successful"}</Typography>
			<Box mt={2} mb={2} flexDirection="column" display="flex" justifyContent="center">
				<Typography className={classes.successDesc}>{"Congratulations on your purchase!"}</Typography>
			</Box>

			{data.books?.map((x, i) => (
				<Box display="flex" key={i} mt={2} width="100%" className={classes.containerGap}>
					<Typography className={classes.successLeftContainerText}>{x.name}</Typography>
					<Typography className={classes.successRightContainerText}>{`${x.price} x ${x.quantity}`}</Typography>
				</Box>
			))}

			{data?.shipping && (
				<Box display="flex" mt={2} width="100%" className={classes.containerGap}>
					<Typography className={classes.successLeftContainerText}>{"Shipping"}</Typography>
					<Typography className={classes.successRightContainerText}>{data.shipping}</Typography>
				</Box>
			)}

			{data?.discount && (
				<Box display="flex" mt={2} width="100%" className={classes.containerGap}>
					<Typography className={classes.successLeftContainerText}>{"Discount"}</Typography>
					<Typography className={classes.successRightContainerText}>-{data.discount}</Typography>
				</Box>
			)}

			<Box display="flex" mt={2} width="100%" className={classes.containerGap}>
				<Typography className={classes.successLeftContainerText}>{data.fullName}</Typography>
				<Typography className={classes.successRightContainerText}>{data.email}</Typography>
			</Box>
		</Box>
	);
};
