import { request } from "../../api/request";
import { GLOBAL_CONSTANTS } from "../../constants";

const progressFnSignature = function (ev: ProgressEvent<XMLHttpRequestEventTarget>) {
	console.log(ev);
};

export async function performFileUpload(
	data: { file: Partial<File> },
	options?: { onProgress?: ({}: { loaded: number; total: number }) => void }
): Promise<{ key: string; url: string }> {
	const { file } = data;
	const { onProgress } = options || {};
	const body = { contentType: file.type, name: file.name };
	const { key, url } = await uploadGetKeyUrl(body);
	await uploadToS3(url, file as File, onProgress || (() => true));
	return { key, url };
}

export function getVideoDuration(data: { file: Partial<File> }, callback: (arg0: number) => void): void {
	const video = document.createElement("video");
	video.preload = "metadata";
	video.onloadedmetadata = function () {
		if (callback) {
			window.URL.revokeObjectURL(video.src);
			callback(video.duration);
		}
	};
	video.src = URL.createObjectURL(data.file as File);
}

export const uploadGetKeyUrl = (body: unknown): Promise<{ key: string; url: string }> =>
	request<{ key: string; url: string }>(
		GLOBAL_CONSTANTS.UPLOAD_URL,
		"POST",
		body as Record<string, unknown>
	) as Promise<{
		key: string;
		url: string;
	}>;

export const uploadToS3 = (url: string, data: File, onProgress: unknown): Promise<void> => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open("PUT", url);
		xhr.setRequestHeader("Content-Type", data.type);
		if (onProgress) {
			xhr.upload.addEventListener("progress", onProgress as typeof progressFnSignature);
		}
		xhr.addEventListener("load", () => {
			resolve();
		});
		xhr.addEventListener("error", err => {
			reject(err);
		});
		xhr.send(data);
	});
};
