import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "../../api/baseApiService";
import { QuestionAttemptsCount } from "../../models";

import { URLS } from "../services.constants";

export class QuestionAttemptsCountService extends BaseApiService<
	QuestionAttemptsCount,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const questionsAttemptsCountService = new QuestionAttemptsCountService(URLS.QUESTIONS_ATTEMPTS_COUNT);
