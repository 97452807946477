import React from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";
import { File } from "@remar/shared/dist/models";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils/helpers";

import { ReactComponent as YellowHalf } from "assets/icons/icon-half.svg";

import { QuestionRationalInfo, QuestionResult } from "./styles";

import { CorrectAnswer, WrongAnswer, useStyles } from "../styles";

const TutoredTestResultInfo = ({ result }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();
	return (
		<>
			<div className={classes.col}>
				<div className={classes.rationaleContainerTutoredTest}>
					<div className={classes.rationaleHeader}>
						{result?.percentageGrade === 1 ? (
							<Box display={"flex"} alignItems="center">
								<CorrectAnswer />
								<QuestionResult>Correct</QuestionResult>
							</Box>
						) : result?.percentageGrade === 0 ? (
							<Box display={"flex"} alignItems="center">
								<WrongAnswer className={classes.icon} />
								<QuestionResult>Incorrect </QuestionResult>
							</Box>
						) : (
							<Box display={"flex"} alignItems="center">
								<YellowHalf className={classes.icon} />
								<QuestionResult>Partially Correct</QuestionResult>
							</Box>
						)}
					</div>
					<div className={classes.rationaleHeader}>Rational answer</div>
					<QuestionRationalInfo
						dangerouslySetInnerHTML={{
							__html: result?.snapshot.endOfQuestionSummary ?? ""
						}}
					></QuestionRationalInfo>
					{(result?.snapshot.attachments as File[])?.length > 0 && (
						<Box width={isMobile ? "100%" : "50%"}>
							<FilesUploaded
								attachments={result?.snapshot?.attachments as File[]}
								IconSvg={DownloadIconSvg}
								showTitle={false}
								isDownload
								borderColor="#edf1f7"
								filenameColor="#4d5058"
								onClick={attachment => downloadURI(attachment.fileUrl, attachment.name)}
							/>
						</Box>
					)}
				</div>
			</div>
		</>
	);
};

export default TutoredTestResultInfo;
