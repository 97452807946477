import { Box, Typography } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Wrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 1300;

	svg {
		transition: height, width, margin 0.2s;
		${({ $big }) =>
			$big
				? css`
						height: 80px;
						width: 80px;
				  `
				: css`
						//height: 24px;
						width: 80px;
				  `}
	}
`;

export const Logo = styled.img`
	height: 50px;
	align-self: center;
	cursor: pointer;
`;

export const Title = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(36)};
	font-weight: 900;
	margin: ${({ theme }) => theme.spacing(3, 0, 6)};
}`;
