import { Box, alpha } from "@material-ui/core";
import styled, { css } from "styled-components";

export const EmptyWrapper = styled.div`
	margin: 0 auto;
	max-width: 326px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 12px;

	h3 {
		margin-top: 20px;
		font-size: 18px;
		font-weight: 700;
		color: ${({ theme }) => theme.palette.text.primary};
	}

	p {
		text-align: center;
		font-size: 15px;
		line-height: 1.7;
		font-weight: 600;
		color: ${({ theme }) => alpha(theme.palette.text.primary, 0.38)};
		margin-bottom: 20px;
	}

	.manage-subject {
		margin-right: 0.5rem;
		color: hsl(222, 87%, 60%); // todo add into colors set
		background-color: ${alpha("hsl(222, 64%, 42%)", 0.25)}; // todo add into colors set

		&:hover {
			background-color: ${alpha("hsl(222, 64%, 42%)", 0.38)}; // todo add into colors set
		}
	}
`;

export const RowWrapper = styled(Box)`
	gap: 8px;
	flex-wrap: wrap;
	justify-content: flex-start;

	${({ theme }) => [theme.breakpoints.down("sm")]} {
		width: 100%;
		justify-content: center;
	}

	div:first-child {
		svg {
			g#user-nurse {
				fill: #4a99f9; // todo: add into colors set
			}
		}
	}

	div:nth-child(2) {
		svg {
			g#user-nurse {
				fill: #d5af34; // todo: add into colors set
			}
		}
	}
	div:last-child {
		svg {
			g#user-nurse {
				fill: #23b34a; // todo: add into colors set
			}
		}
	}

	${({ mr }) =>
		mr &&
		css`
			margin-right: ${mr}rem;
		`}

	${({ mt }) =>
		mt &&
		css`
			margin-top: ${mt}rem;
		`}

		${({ $isFlex }) =>
		$isFlex &&
		css`
			display: flex;
		`}

		${({ $alignItem }) =>
		$alignItem &&
		css`
			align-items: ${$alignItem};
		`}
`;

export const Container = styled(Box)`
	width: 100%;
	
	${({ theme }) => [theme.breakpoints.down("md")]} {
		padding-left: 16px;
		padding-right: 16px;
	}

	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: rgba(255, 255, 255, 0.6);
		}

		div:last-child {
			color: ${props => props.theme.palette.text.primary};
		}
	}
}
`;

export const THeaderWrapper = styled(Box)`
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${props => props.theme.palette.background.paper};
	padding: 8px 20px;
	border-radius: 4px 4px 0 0;

	${({ theme }) => [theme.breakpoints.down("sm")]} {
		height: auto;
		flex-direction: column;
	}
`;

export const LoadingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${({ height }) => (height ? `${height}px` : "500px")};
	width: 100%;
`;
