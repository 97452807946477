import { CancellationOption } from "store/services";

export enum CancellationOptionsEnum {
	CancelNClEXSoonOption = 2,
	CancelProblemOption = 7
}

export interface ICancellationProps {
	action?: (params: {
		type: string;
		cancellationReasonId: number;
		option?: CancellationOption;
		link?: string;
		paymentAndDiscountId?: number | null;
	}) => void;
	cancellationOptions?: CancellationOption[];
	selectedOption?: CancellationOption;
	isCancellingSubscription?: boolean;
}
