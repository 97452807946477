import { Box, Modal, Typography, createStyles, darken, makeStyles } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import styled from "styled-components";

import { LockSvg } from "assets/icons";
import { ReactComponent as CrownSvg } from "assets/icons/icon-crown.svg";
import { ReactComponent as LessonLockSvg } from "assets/icons/icon-lesson-lock.svg";

export const OverflowHide = styled.div`
	height: ${({ height }) => height}px;
	overflow: hidden;
`;

export const ScrollableModal = styled(Modal)`
	max-height: 100vh;
	overflow: auto;
`;

export const StyledLessonLockSvg = styled(LessonLockSvg)`
	g {
		transform: translate(3px, 3px);
	}
`;

export const StyledCrownSvg = styled(CrownSvg)`
	g {
		transform: translate(3px, 3px);
	}
`;

export const LessonThumbnailMuteIcon = styled.div`
	position: absolute;
	bottom: 4px;
	right: 4px;
	cursor: pointer;
	color: ${({ theme }) => theme.palette.text.primary};
`;

export const LockIcon = styled(LockSvg)`
	cursor: pointer;
	position: absolute;
	top: 36%;
	left: 41%;
	width: 40px;
	height: 40px;
	z-index: 1;
`;

export const RemainingText = styled(Typography)`
	font-weight: 600;
	font-size: 12px;
	line-height: 1.33;
	font-family: var(--openSans);
	color: ${({ theme }) => darken(theme.palette.text.secondary, 0.2)};
`;

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		previousLesson: {
			display: "none",
			margin: "-8px 8px 8px 8px",
			alignItems: "center",
			flexFlow: "row nowrap"
		},
		previousLessonLink: {
			fontFamily: "var(--poppins)",
			fontSize: 12,
			fontWeight: "bold",
			lineHeight: 1.33,
			color: theme.palette.text.primary,
			borderBottom: "2px solid white"
		},
		extraWrapper: {
			borderRadius: 4,
			transition: "all 0.3s",
			position: "absolute",
			width: 250,
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			background: theme.palette.background.paper,
			display: "flex",
			flexFlow: "column",
			height: 140
		},
		root: {
			margin: "0 16px",
			width: 250,
			height: 140,
			position: "relative",
			zIndex: 0,
			overflow: "hidden",

			"& .bottomPanel": { display: "none" },

			"&:hover": {
				"& $previousLesson": {
					display: "flex"
				},

				"& $videoThumbnail": {
					display: "block !important"
				},

				"& $extraWrapper": {
					width: 350,
					height: "auto",
					borderRadius: 8,
					overflow: "hidden"
				},

				overflow: "visible",
				zIndex: 4,

				"& $videoReadyToPlay": {
					display: "block"
				},

				"& $upgradeButton": {
					display: "block",
					margin: "0 16px 16px"
				},

				"& $hidden": { display: "none" },

				"& $expandable": {
					height: "auto",
					overflow: "hidden",

					"& .bottomPanel": {
						display: "block",
						height: "100%"
					},

					"& .bottomPanel p": {
						color: theme.palette.text.primary,
						opacity: 1,
						textOverflow: "ellipsis",
						overflow: "hidden",
						whiteSpace: "nowrap"
					}
				}
			}
		},
		upgradeButton: { display: "none", fontFamily: "var(--poppins)", fontSize: 12, fontWeight: "bold" },
		hidden: { display: "block" },
		videoReadyToPlay: { display: "none !important" },
		videoThumbnail: {
			padding: "0 !important",
			maxWidth: "100%",
			overflow: "hidden",
			margin: "0 auto",
			height: "183px !important",
			display: "none"
		},
		bottomTitle: {
			fontSize: theme.typography.pxToRem(15),
			opacity: 0.87,
			fontWeight: 600,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		bottomSubTitle: {
			fontSize: theme.typography.pxToRem(12),
			opacity: 0.6,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		},
		bottomLabel: {
			fontSize: theme.typography.pxToRem(12),
			marginLeft: 12,
			fontWeight: 600,
			opacity: 0.6
		},
		expandable: {
			width: "100%",
			overflow: "hidden"
		},
		bgImage: {
			display: "block",
			height: "auto",
			width: "100%",
			objectFit: "cover",
			objectPosition: "center center"
		},
		icon: {
			position: "absolute",
			top: 6,
			right: 6,
			zIndex: 1,
			background: "#13161e",
			borderRadius: "3px"
		}
	})
);

export const OrderSummaryWrapper = styled(Box)`
	.order-item {
		display: flex;
		justify-content: space-between;
		background-color: ${({ theme }) => theme.palette.background.paper};
		padding: 10px;
		width: 250px;
	}
`;
