import React from "react";

import PropTypes from "prop-types"; // todo: do we really need to integrate the package just for one component? What is purpose of it?

import { isMobile } from "react-device-detect";

import { DndProvider } from "react-dnd";

import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

export const GlobalDndContext: React.FC = ({ children }) => (
	<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>{children}</DndProvider>
);
GlobalDndContext.propTypes = { children: PropTypes.node };

export default GlobalDndContext;
