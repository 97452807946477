import React from "react";

import Logo from "../../assets/images/logo.svg";

const LogoIcon = ({ isLoading, logoImageUrl }) => {
	return !isLoading && logoImageUrl ? (
		<img alt="logo" src={logoImageUrl} height={40} width={40} />
	) : (
		<img alt="logo" src={Logo} height={40} width={40} />
	);
};

export default LogoIcon;
