import React from "react";

import { LinearProgress, Typography } from "@material-ui/core";

import IconConfetti from "../../../assets/icons/Confetti.svg";

import { UserTypes } from "../../../constants";
import { CourseProgress, StyledConfetti } from "../styles";

const CourseProgressCard = ({
	courseCompleted,
	percentage,
	courseName,
	userType = UserTypes.RegularStudent
}: {
	courseCompleted: boolean;
	percentage: number;
	courseName: string;
	userType?: UserTypes;
}) => {
	const isStudent = userType == UserTypes.RegularStudent;
	return (
		<CourseProgress detailed>
			{courseCompleted && <StyledConfetti src={IconConfetti} alt="StyledConfetti" />}
			<Typography className={"course-progress-label"}>Course</Typography>
			<Typography className={"course-name"}>{courseName}</Typography>
			<Typography className={"course-progress-label"}>{isStudent ? "My" : "Student"} Progress</Typography>
			<Typography className={"course-progress-value"}>{Math.round(percentage)}% completed</Typography>
			<LinearProgress className={"progress"} variant="determinate" value={percentage} />
		</CourseProgress>
	);
};

export default CourseProgressCard;
