import React from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import cn from "classnames";

import { BowTieContainer, BowTieGroupName, useStyles as useBowTieStyles } from "./styles";

import ConnectionsSmallSVG from "../../../assets/icons/connections-small.svg";
import { AnswerOption, useStyles } from "../styles";

const BowTieDiagram = () => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useBowTieStyles({ bgColor: "inherit", bg: "#fff", padding: "16px 0px", isBowTieResult: true });
	return (
		<Box width={isMobile ? "auto" : "605px"}>
			<BowTieContainer display={"flex"} flexDirection="column" maxWidth={"860px"} isBowTieResult>
				<div className={classes.wrapperSchemaContainer}>
					{isMobile && (
						<Box zIndex={1} mt={3.5}>
							<img src={ConnectionsSmallSVG} alt="connection" />
						</Box>
					)}
					<Box className={classes.schemaContainer}>
						<Box className={classes.shortSchemaColumn}>
							<Box className={classes.schemaBox}>
								<Box>Actions To Take</Box>
							</Box>
							<Box className={classes.schemaBox}>
								<Box>Actions To Take</Box>
							</Box>
						</Box>
						{!isMobile ? (
							<Box className={classes.schemaSeparator}>
								<Box className={classes.schemaTopDiag}>
									<i></i>
									<i></i>
								</Box>
								<Box className={classes.schemaBottomDiag}>
									<i></i>
									<i></i>
								</Box>
							</Box>
						) : null}
						<Box className={classes.longSchemaColumn}>
							<Box className={classes.schemaBox}>
								<Box>Conditions Most Likely Experiencing</Box>
							</Box>
						</Box>
						{!isMobile ? (
							<Box className={classes.schemaSeparator}>
								<Box className={classes.schemaBottomDiag}>
									<i></i>
									<i></i>
								</Box>
								<Box className={classes.schemaTopDiag}>
									<i></i>
									<i></i>
								</Box>
							</Box>
						) : null}
						<Box className={classes.shortSchemaColumn}>
							<Box className={classes.schemaBox}>
								{" "}
								<Box>Parameters To Monitor</Box>
							</Box>
							<Box className={classes.schemaBox}>
								{" "}
								<Box>Parameters To Monitor</Box>
							</Box>
						</Box>
					</Box>
				</div>
			</BowTieContainer>
		</Box>
	);
};

const BowTieResult = ({ result }) => {
	const theme = useTheme<Theme>();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const classes = useStyles();
	const { selectedAnswers = [], snapshot = {} } = result || {};
	const { groups = [] } = snapshot.data || {};
	const getAnswer = (id, exist = false) =>
		exist
			? selectedAnswers.find(answer => answer.questionAnswerOptionId === id)
			: !selectedAnswers.find(answer => answer.questionAnswerOptionId === id);

	return (
		<>
			<BowTieDiagram />
			<Box>
				{groups.map(({ id, answerOptions }, i) => (
					<Box key={id} mb={2}>
						<BowTieGroupName className={classes.openSansFontFamily}>
							{i === 0 ? "Actions To take" : i === 1 ? "Potential Conditions" : "Parameters to Monitor"}
						</BowTieGroupName>
						{answerOptions.map(({ id, text, isCorrect }) => {
							let presentCorrectAnswer;
							let presentInCorrectAnswer;
							let notPresentCorrectAnswer;
							let notPresentInCorrectAnswer;

							if (isCorrect) {
								presentCorrectAnswer = getAnswer(id, true);
								notPresentCorrectAnswer = getAnswer(id);
							}
							if (!isCorrect) {
								presentInCorrectAnswer = getAnswer(id, true);
								notPresentInCorrectAnswer = getAnswer(id);
							}
							return (
								<Box key={id}>
									<AnswerOption
										className={cn({
											[classes.green]: presentCorrectAnswer,
											[classes.grey]: presentInCorrectAnswer,
											[classes.purple]: notPresentCorrectAnswer,
											[classes.transparent]: notPresentInCorrectAnswer
										})}
									>
										<Box
											p={isMobile && notPresentInCorrectAnswer ? 0 : 1}
											alignSelf="center"
											fontWeight="bolder"
											flex={isMobile && notPresentInCorrectAnswer ? 0 : 1}
											className={classes.openSansFontFamily}
											color={
												presentCorrectAnswer
													? "#23b34a"
													: notPresentCorrectAnswer
													? "#264fae"
													: presentInCorrectAnswer
													? "#4d5058"
													: "transparent"
											}
										>
											{presentCorrectAnswer
												? "Correct Answer"
												: notPresentCorrectAnswer
												? "Unmarked correct answer"
												: presentInCorrectAnswer
												? "Incorrect answer"
												: notPresentInCorrectAnswer
												? ""
												: null}
										</Box>
										<Box
											flex={2}
											className={classes.openSansFontFamily}
											p={isMobile && notPresentInCorrectAnswer ? 0 : 1}
											alignSelf="center"
											fontWeight="bolder"
											ml={isMobile && notPresentInCorrectAnswer ? 0 : 5}
										>
											{text}
										</Box>
									</AnswerOption>
								</Box>
							);
						})}
					</Box>
				))}
			</Box>
		</>
	);
};

export default BowTieResult;
