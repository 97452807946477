import React, { useCallback } from "react";

import { IconButton, InputAdornment } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Search as SearchIcon } from "@material-ui/icons";

import debounce from "lodash/debounce";

import { IExtendedTheme } from "../../theme/default";
import { SearchBar } from "../Table/styles";

interface Props {
	inputValue: string;
	setInputValue: React.Dispatch<React.SetStateAction<string>>;
	placeHolder: string;
	onChange: (searchText: string) => void;
	isDebounced?: boolean;
	debounceTime?: number;
	width?: string;
	customStyles?: string;
	disabled?: boolean;
	searchInputCustomStyles?: React.CSSProperties;
	autoFocus?: boolean;
}

const SearchBarComponent = ({
	inputValue,
	setInputValue,
	placeHolder,
	isDebounced = true,
	debounceTime = 500,
	onChange,
	width,
	customStyles,
	disabled = false,
	searchInputCustomStyles,
	autoFocus = true
}: Props) => {
	const theme = useTheme<IExtendedTheme>();
	const debouncedSearch = useCallback(
		debounce((searchText: string) => {
			onChange(searchText);
		}, debounceTime),
		[]
	);
	return (
		<SearchBar
			$customStyles={customStyles}
			hiddenLabel
			InputProps={{
				autoFocus,
				style: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.background.paper,
					...searchInputCustomStyles
				},
				disableUnderline: true,
				startAdornment: (
					<InputAdornment position="start">
						<IconButton size="small" disabled={disabled}>
							<SearchIcon style={{ color: "hsl(223,10%,58%)" }} height={20} width={20} />
						</IconButton>
					</InputAdornment>
				)
			}}
			color="primary"
			onChange={({ target: { value } }) => {
				setInputValue(value);
				if (isDebounced) {
					debouncedSearch(value);
				} else {
					onChange(value);
				}
			}}
			variant="filled"
			size="small"
			placeholder={placeHolder}
			value={inputValue}
			width={width}
			disabled={disabled}
		/>
	);
};

export default SearchBarComponent;
