import React, { useEffect } from "react";

import { Box, CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@remar/shared/dist/components/Button";
import { downloadExternalFile } from "@remar/shared/dist/utils/serviceUtils/helpers";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import { useAppDispatch, useAppSelector } from "store";
import { getCourseCompletion, selectFullState } from "store/features/Course/courseSlice";
import styled from "styled-components";

import { RemarLogoSvg } from "assets/icons";

import { routes } from "core/constants";

import { CompletionCertificateLink, CompletionCertificateText, StyledCertificateSvg } from "./style";

const CompletionCertificateContainer = styled(Box)`
	margin-left: -64px;

	${props => props.theme.breakpoints.down("xs")} {
		margin-left: 0;
	}
`;

const CompletionCertificate = ({ course }: { course: string }) => {
	const {
		certificate,
		courseCompletion: { isCourseCompleteLoading }
	} = useAppSelector(selectFullState);
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	useEffect(() => {
		if (!certificate) {
			dispatch(getCourseCompletion());
		}
	}, [certificate, dispatch]);

	const handleDownload = () => {
		certificate && downloadExternalFile(certificate.certificatePdfUrl);
	};

	useEffect(() => {
		if (!certificate) {
			dispatch(getCourseCompletion());
		}
	}, [certificate, dispatch]);

	const { width, height } = useWindowSize();

	const Loader = (
		<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
			<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
		</Box>
	);

	return isCourseCompleteLoading ? (
		Loader
	) : (
		<CompletionCertificateContainer>
			<Confetti width={width} height={height} />
			<Box display="flex" justifyContent="center" mt={2} flexDirection={"column"} alignItems={"center"}>
				<RemarLogoSvg />
				<StyledCertificateSvg width={isMobile ? "100%" : "auto"} />
				<Box mt={4}>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.5rem" : "3rem"}>
						Congratulations
					</CompletionCertificateText>
				</Box>
				<Box mt={4}>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.3em" : "1.5em"}>
						You have successfully completed
					</CompletionCertificateText>
				</Box>
				<Box mt={4}>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.2em" : "1.4em"}>
						{course ?? "N/A"}
					</CompletionCertificateText>
				</Box>
				<Box mt={4} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.3em" : "1.5em"}>
						New feature unlocked:
					</CompletionCertificateText>
					<CompletionCertificateText variant="h1" fontSize={isMobile ? "1.3em" : "1.5em"}>
						You can now go back and retake lessons and exams
					</CompletionCertificateText>
				</Box>
				<Box display="flex" flexDirection={"column"} mt={8} width={isMobile ? "85%" : "auto"}>
					<Button variant="filled" color="primary" onClick={handleDownload}>
						Download Certificate
					</Button>
					<Link to={`${routes.course.getPath()}`}>
						<CompletionCertificateLink>Go to Homepage</CompletionCertificateLink>
					</Link>
				</Box>
			</Box>
		</CompletionCertificateContainer>
	);
};

export default CompletionCertificate;
