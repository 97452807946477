import { UserQuestionMarkingCreateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "../../api/baseApiService";
import { request } from "../../api/request";
import { UserMarkedQuestionModel } from "../../models";

import { URLS } from "../services.constants";

export class UserQuestionMarkingService extends BaseApiService<
	UserMarkedQuestionModel,
	UserQuestionMarkingCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getMarkedQuestion(data: BaseFindDto): Promise<UserMarkedQuestionModel> {
		return request(
			`${this.baseUrl}/marked-question${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<UserMarkedQuestionModel>;
	}

	markQuestion(data: UserQuestionMarkingCreateDto): Promise<void> {
		return request(
			`${this.baseUrl}/marked-question`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<void>;
	}
}

export const userQuestionMarkingService = new UserQuestionMarkingService(URLS.QUESTIONS);
