import React from "react";

import { Box, Typography } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";

import { useStyles } from "./styles";

export const GuestSignUpError = ({ errorMessage, tryAgainEnabled = true }) => {
	const classes = useStyles();
	return (
		<Box>
			<Box display="flex" justifyContent="center">
				<CancelRounded style={{ color: "#ed5465", width: "64px", height: "64px" }} />
			</Box>
			<Typography className={classes.errorTitle}>{"Something Went Wrong"}</Typography>
			<Box mt={2} flexDirection="column" display="flex" justifyContent="center">
				<Typography className={classes.errorDescription}>
					{errorMessage || "We are not able to process your payment"}
				</Typography>
				{tryAgainEnabled && <Typography className={classes.errorDescription}>{"Please try again."}</Typography>}
			</Box>
		</Box>
	);
};
