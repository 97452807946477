import React from "react";

import { ReactComponent as ChromeSvg } from "assets/icons/icon-chrome.svg";
import { ReactComponent as RemarLogoSvg } from "assets/icons/icon-logo-remar.svg";

import {
	Container,
	DownloadChromeButton,
	FirstSection,
	Heading,
	Layout,
	Message,
	SecondSection,
	Text,
	ThirdSection
} from "./styles";

const BrowserAlert = () => (
	<Layout>
		<Container>
			<FirstSection>
				<RemarLogoSvg />
			</FirstSection>
			<SecondSection>
				<Heading>Sorry, your browser doesn’t support ReMar’s Technology.</Heading>
				<Message>Please use this service on our recommended browser,</Message>
				<Message>Chrome for the best user experience.</Message>
			</SecondSection>
			<ThirdSection>
				<ChromeSvg />
				<Text>Chrome</Text>
				<DownloadChromeButton color="primary" variant="contained" href="https://www.google.com/intl/en_pk/chrome/">
					Download Google Chrome
				</DownloadChromeButton>
			</ThirdSection>
		</Container>
	</Layout>
);

export default BrowserAlert;
