const basic = {
	100: "hsl(0,0%,100%)",
	150: "hsl(0,0%,97%)",
	1000: "hsl(224,18%,12%)",
	1100: "hsl(224,22%,10%)",
	1150: "hsl(223, 10%, 58%)",
	1200: "hsl(223,13%,73%)",
	200: "hsl(220,13%,87%)",
	300: "hsl(224,10%,78%)",
	400: "hsl(224,7%,67%)",
	500: "hsl(224,5%,60%)",
	600: "hsl(224,4%,49%)",
	700: "hsl(226,7%,36%)",
	750: "hsl(223,14%,26%)",
	800: "hsl(222,13%,19%)",
	900: "hsl(224,14%,15%)",
	950: "hsl(220,15%,15%)",
	1250: "hsl(223,13%,73%)",
	1300: "hsl(224,16%,41%)",
	1400: "hsl(223,10%,58%)",
	1450: "hsl(245,7%,29%)",
	1500: "hsl(218,27%,94%)",
	1550: "hsl(213, 53%, 19%)"
};

export default basic;
