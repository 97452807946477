import { UserLessonFeedbackItemCreateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "../../api/baseApiService";
import { UserLessonFeedbackItem } from "../../models";

import { URLS } from "../services.constants";

export class UserLessonFeedbackItemsService extends BaseApiService<
	UserLessonFeedbackItem,
	UserLessonFeedbackItemCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const userLessonFeedbackItemsService = new UserLessonFeedbackItemsService(URLS.USER_LESSON_FEEDBACK_ITEMS);
