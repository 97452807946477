import React from "react";

import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { isFunction } from "lodash";
import { useHistory } from "react-router-dom";

import { Logo, Title, Wrapper } from "./styles";

import LogoImg from "../../assets/icons/icon-logo-remar.svg";

export type LogoComponentProps = {
	logo?: React.ReactNode | React.FC;
	title?: string;
	showLargeLogo?: boolean;
};

const LogoComponent = ({ logo, title, showLargeLogo = true }: LogoComponentProps) => {
	const theme = useTheme<Theme>();
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const getLogo = logo => {
		if (!logo) return <Logo src={LogoImg} alt="ReMar Logo" onClick={() => history.push("/")} />;
		if (isFunction(logo)) {
			return logo();
		}
		return logo;
	};

	return (
		<Wrapper marginTop={isMobile ? "25px" : "0px"} $big={showLargeLogo}>
			{getLogo(logo)}
			{title && <Title variant="h1">{title}</Title>}
		</Wrapper>
	);
};

export default LogoComponent;
