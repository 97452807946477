import React from "react";

import { Box, Card } from "@material-ui/core";
import { ErrorSharp } from "@material-ui/icons";
import { ReactComponent as DiscountIcon } from "@remar/shared/dist/assets/icons/icon-coupon-multicolour-light.svg";
import Button from "@remar/shared/dist/components/Button";

import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { applyValidatedCoupon, selectInactiveSubscription, setUpgradeModal } from "store/features/Auth/authSlice";
import { getFullState as getFullNotificationsState } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import { PaymentBlock, PaymentDescription, PaymentTitle } from "./styles";

const NextBilling = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const { subscriptionInfo } = useAppSelector((store: RootState) => store.myAccount);
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);
	const { bannerData } = useAppSelector(getFullNotificationsState);

	const getNextBillingDate = () => {
		if (subscriptionInfo) {
			const date = subscriptionInfo!.upcomingInvoice!.billingDate!;
			return date && format(new Date(date), "MM.dd.yyyy");
		}
		return "";
	};

	return (
		<Card>
			<PaymentTitle>Your Next Billing</PaymentTitle>
			<PaymentBlock>
				{subscriptionInfo?.subscription.type?.isTrial ? (
					<>
						<Box display="flex" mb={1}>
							<ErrorSharp style={{ fill: "#898f9e" }} />
							<PaymentDescription>Your current subscription is trial</PaymentDescription>
						</Box>
						{!isRenewEnabled && (
							<Button
								color="primary"
								onClick={() => {
									if (bannerData?.coupon) {
										dispatch(applyValidatedCoupon(bannerData?.coupon));
									}
									dispatch(setUpgradeModal(true));
								}}
							>
								{!!bannerData?.coupon && (
									<Box display={"flex"} mr={1}>
										<DiscountIcon />
									</Box>
								)}
								Upgrade
							</Button>
						)}
					</>
				) : (
					<>
						<Box display="flex" mb={1}>
							<ErrorSharp style={{ fill: "#898f9e" }} />
							<PaymentDescription>
								{`Your Next Payment will take place on ${getNextBillingDate()} and a total of ${
									subscriptionInfo?.upcomingInvoice.price
								}$.`}
							</PaymentDescription>
						</Box>
						<Button color="primary" onClick={() => history.push(routes.myAccount.getPath())}>
							Manage
						</Button>
					</>
				)}
			</PaymentBlock>
		</Card>
	);
};
export default NextBilling;
