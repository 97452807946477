import { LessonCommentsItemCreateDto, LessonCommentsItemUpdateDto } from "./dto";

import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "../../api/baseApiService";
import { request } from "../../api/request";
import { LessonComment } from "../../models";

import { URLS } from "../services.constants";

export class LessonCommentsService extends BaseApiService<
	LessonComment,
	LessonCommentsItemCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	LessonCommentsItemUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	commentVote(data: { commentId?: number; isLike: boolean }): Promise<LessonCommentsItemUpdateDto> {
		return request(
			`${this.baseUrl}/user-vote`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<LessonCommentsItemUpdateDto>;
	}

	deleteCommentVote(data: BaseDeleteDto): Promise<BaseDeleteDto> {
		return request(
			`${this.baseUrl}/user-vote`,
			"DELETE",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<BaseDeleteDto>;
	}
}

export const lessonCommentsService = new LessonCommentsService(URLS.LESSON_COMMENTS);
