import React, { useCallback, useState } from "react";

import {
	Box,
	Card,
	IconButton,
	LinearProgress,
	RootRef, //todo refactor deprecated ref
	Typography,
	alpha,
	createStyles,
	makeStyles
} from "@material-ui/core";
import { Delete, VideoLibrary } from "@material-ui/icons";

import { Color } from "@material-ui/lab/Alert/Alert";
import { AsyncThunk } from "@reduxjs/toolkit";

import cn from "classnames";

import { useConfirm } from "material-ui-confirm";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { Subject } from "rxjs";

import { IExtendedTheme } from "../../theme/default";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		root: {
			height: 250,
			background: theme.palette.background.paper,
			border: `3px dashed ${theme.palette.colors.info[750]}`,
			color: alpha(theme.palette.text.primary, 0.6),
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center"
		},
		dropZone: {
			background: theme.palette.background.default,
			width: "calc(100% + 50px)",
			height: "calc(100% + 50px)",
			margin: -50,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			padding: theme.spacing(3),
			transition: "all 0.2s",
			cursor: ({ loading, disabled }: { loading: boolean; disabled: boolean }) => {
				return loading || disabled ? "not-allowed" : "pointer";
			}
		},
		compact: {
			height: 80,
			margin: 0,
			padding: 0
		},
		dragActive: {
			background: theme.palette.colors.basic[700]
		},
		fileNameWrapper: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(1),
			margin: theme.spacing(1, 0),
			maxWidth: 150,
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis"
		},
		deleteButton: {
			color: theme.palette.colors.danger[500],
			backgroundColor: theme.palette.colors.danger[200],
			marginLeft: theme.spacing(2)
		},
		progress: {
			background: "hsl(0,0%,10%)" // todo add into coloerset
		},
		dialog: {
			padding: theme.spacing(2),
			background: theme.palette.colors.basic[900]
		},
		successButton: {
			height: "48px",
			background: theme.palette.colors.danger[900]
		},
		sucessButtonLabel: {
			fontSize: "16px",
			fontWeight: "bold",
			lineHeight: 1.25,
			color: theme.palette.colors.danger[500]
		},
		cancellButton: {
			height: "48px",
			borderRadius: "4px",
			border: `1px solid ${theme.palette.colors.basic[500]}`,
			background: "transparent"
		},
		cancelButtonLabel: {
			fontSize: "16px",
			fontWeight: "bold",
			lineHeight: 1.25,
			color: theme.palette.colors.basic[1250]
		}
	})
);

interface Props {
	accept?: string | string[];
	boxProps?: { [propName: string]: unknown };
	clearFileInputSubject?: Subject<void>;
	title?: React.ReactNode | string;
	confirmTitle?: string;
	confirmDescription?: string;
	cancellText?: string;
	confirmText?: string;
	error?: boolean | string;
	icon?: JSX.Element;
	onAdd?: (_files: File[]) => void;
	onRemove?: (_file?: File) => void;
	multiple?: boolean;
	loading?: boolean;
	header?: string;
	progress?: number;
	disabled?: boolean;
	dragFileError?: string;
	emit?: AsyncThunk<void, { message: string; color: Color; preventAutoDismiss?: boolean | undefined }, {}>;
}

const Uploader = ({
	accept,
	boxProps = {},
	clearFileInputSubject,
	title = "Drag and drop the file here, or click to select file",
	icon = <VideoLibrary style={{ width: "64px", height: "64px" }} />,
	error,
	onAdd,
	onRemove,
	progress,
	confirmDescription,
	multiple = false,
	loading = false,
	header = "",
	confirmTitle = "Are sure you want to delete uploaded file?",
	cancellText = "No, Continue Uploading",
	confirmText = "Yes, I’m Sure",
	dragFileError,
	disabled = false,
	emit
}: Props) => {
	const dispatch = useDispatch();
	const classes = useStyles({ loading, disabled });
	const [files, setFiles] = useState<File[]>([]);
	const [dragActive, setDragActive] = useState(false);

	const confirmPopUp = useConfirm();

	const onDelete = (name: string) => {
		confirmPopUp({
			title: confirmTitle,
			description: confirmDescription,
			dialogProps: { classes: { paper: classes.dialog } },
			cancellationButtonProps: {
				variant: "outlined",
				color: "secondary",
				classes: {
					root: classes.cancellButton,
					label: classes.cancelButtonLabel
				}
			},
			confirmationButtonProps: {
				variant: "contained",
				classes: {
					root: classes.successButton,
					label: classes.sucessButtonLabel
				}
			},
			cancellationText: cancellText,
			confirmationText: confirmText
		}).then(() => {
			const _files = files.filter(i => i.name !== name);
			const _file = files.find(i => i.name === name);
			setFiles(_files);

			onRemove && onRemove(_file);
		});
	};

	const onDragEnter = () => {
		setDragActive(true);
	};

	const onDragLeave = () => {
		setDragActive(false);
	};

	const onDrop = useCallback(
		acceptedFiles => {
			const _files = [...files, ...acceptedFiles];
			if (_files.length > 0) {
				setFiles(_files);
				onAdd && onAdd(_files);
			} else {
				emit &&
					dispatch(
						emit({
							message: dragFileError || "Sorry!, only video file formats are supported.",
							color: dragFileError ? "error" : "warning"
						})
					);
			}
			setDragActive(false);
		},
		[dispatch]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		onDragEnter,
		onDragLeave,
		onDropAccepted: onDragLeave,
		multiple,
		accept
	});
	const { ref, ...rootProps } = getRootProps();

	if (clearFileInputSubject) {
		clearFileInputSubject.subscribe(() => setFiles([]));
	}

	return (
		<Card className={classes.root} style={boxProps}>
			<RootRef rootRef={ref}>
				<Box
					{...rootProps}
					className={cn(classes.dropZone, {
						[classes.dragActive]: dragActive,
						[classes.compact]: files.length !== 0
					})}
				>
					<Box py={1}>{icon}</Box>
					<input {...getInputProps()} disabled={loading || disabled} />
					{!loading && header && <h3>{header}</h3>}
					{files.length === 0 &&
						(dragActive ? (
							<Typography variant="subtitle2">Drop files here</Typography>
						) : (
							<Typography variant="subtitle2">{title}</Typography>
						))}
				</Box>
			</RootRef>
			{files.length === 0 && loading && (
				<Box width="100%">
					<LinearProgress
						className={classes.progress}
						color="primary"
						variant={progress ? "determinate" : "indeterminate"}
						value={progress}
					/>
					{typeof progress === "number" ? <Box>{Math.floor(progress)}%</Box> : null}
				</Box>
			)}
			<Box display="flex" flexDirection="column" maxHeight={140}>
				{error && (
					<Box my={3}>
						<Typography color="error" variant="body2">
							{typeof error === "string" ? error : "Error happened, try refreshing the page or retry later"}
						</Typography>
					</Box>
				)}

				{!error &&
					files.map((file, idx) => (
						<Box key={`${file.name}_${idx}`}>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<Box className={classes.fileNameWrapper}>
									<Typography variant="caption">{file.name}</Typography>
								</Box>
								<IconButton
									disabled={loading || disabled}
									onClick={e => {
										e.stopPropagation();
										e.preventDefault();

										onDelete(file.name);
									}}
									size="small"
									className={classes.deleteButton}
								>
									<Delete />
								</IconButton>
							</Box>

							{loading && (
								<Box>
									<LinearProgress
										className={classes.progress}
										color="primary"
										variant={progress ? "determinate" : "indeterminate"}
										value={progress}
									/>
									{typeof progress === "number" ? <Box>{Math.floor(progress)}%</Box> : null}
								</Box>
							)}
						</Box>
					))}
			</Box>
		</Card>
	);
};

export default Uploader;
