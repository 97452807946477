import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { Country } from "@remar/shared/dist/models";

import { URLS } from "../services.constants";

export class CountriesService extends BaseApiService<
	Country,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const countriesService = new CountriesService(URLS.COUNTRIES);
