import {
	CheckInputValidityOptions,
	CustomInputModel,
	CustomInputType,
	ValidateInputOptions
} from "./customInput.model";

import { getSanitizedHtmlText } from "../../utils/serviceUtils";

const customInputValues = Object.values(CustomInputType);

const checkEmptySpaces = str => {
	const text = str.replace(/<[^>]+>/g, "");
	return /^(?:&nbsp;)+$/.test(text);
};

export function checkValueExistence(value: unknown, isArray: boolean): boolean {
	if (!isArray) {
		return typeof value !== "undefined" && value !== null && (typeof value !== "string" || value.length > 0);
	} else {
		return typeof value === "object" && !!value?.hasOwnProperty("length") && Object.entries(value).length > 0;
	}
}

export function checkInputValidity<ValueType>(
	inputData: CustomInputModel<ValueType>,
	options?: CheckInputValidityOptions
): CustomInputModel<ValueType> {
	const { markAsDirty } = options || {};
	const { validations, value } = inputData;
	let error = "";
	let finalValue: unknown = value;
	let isAnActualNumber = false;
	if (validations) {
		const { required, isInteger, isArray, isNumber, max, maxLength, min, minLength } = validations;
		const valueExists = checkValueExistence(value, !!isArray);
		if (!valueExists) {
			if (required) {
				error = "This field is required. ";
			}
		} else {
			if (isNumber) {
				finalValue = parseFloat(`${value}`);
				isAnActualNumber = true;
				if (isNaN(finalValue as number)) {
					error = "This field's value must be a number. ";
				}
			} else if (isInteger) {
				finalValue = parseFloat(`${value}`);
				const valueData = `${finalValue}`.split(".");
				isAnActualNumber = true;
				if (isNaN(finalValue as number) || valueData.length === 2) {
					error = "This field's value must be an integer. ";
				}
			} else {
				if (typeof maxLength !== "undefined") {
					finalValue = `${value}`;
					if ((finalValue as string).length > maxLength) {
						error = `This field's value cannot exceed ${maxLength} characters. `;
					}
				}
				if (!error.length && typeof minLength !== "undefined") {
					finalValue = `${value}`;
					if ((finalValue as string).length < minLength) {
						error = `This field's value cannot be less than ${minLength} characters. `;
					}
				}
			}
			if (isAnActualNumber) {
				if (typeof max !== "undefined") {
					if (typeof finalValue !== "number") {
						finalValue = parseFloat(`${finalValue}`);
					}
					isAnActualNumber = true;
					if ((finalValue as number) > max) {
						error = `This field's value cannot exceed ${max}. `;
					}
				}
				if (!error.length && typeof min !== "undefined") {
					if (typeof finalValue !== "number") {
						finalValue = parseFloat(`${finalValue}`);
					}
					isAnActualNumber = true;
					if ((finalValue as number) < min) {
						error = `This field's value cannot be less than ${min}. `;
					}
				}
			}
		}
	}
	return {
		...inputData,
		error: error.length ? error : null,
		pristine: markAsDirty === true ? false : inputData.pristine,
		value: (isAnActualNumber && isNaN(finalValue as number) ? "" : finalValue) as ValueType
	};
}

export function isCustomInputObject<ValueType>(
	inputData: CustomInputModel<ValueType> | Record<string, unknown>
): inputData is CustomInputModel<ValueType> {
	return (
		inputData &&
		typeof inputData.error !== "undefined" &&
		typeof inputData.pristine !== "undefined" &&
		typeof inputData.statePath !== "undefined" &&
		customInputValues.includes(inputData.type as CustomInputType)
	);
}

export function validateInput<ValueType>(value: ValueType, options: ValidateInputOptions): void {
	const { dispatch, setStateValue, inputData, markAsDirty, validateForm } = options;
	const newInputData = checkInputValidity<ValueType>({ ...inputData, value } as CustomInputModel<ValueType>, {
		markAsDirty
	});
	const { type } = inputData;
	let finalInputValue: unknown = value;
	if (type === CustomInputType.Number) {
		if (typeof value === "string") {
			finalInputValue = parseFloat(value);
		}
	} else if (type === CustomInputType.Checkbox) {
		if (typeof value === "string") {
			finalInputValue = value === "true" || value === "checked";
		}
	} else if (type === CustomInputType.Editor) {
		if (checkEmptySpaces(value)) {
			const emptyText = getSanitizedHtmlText(value as unknown as string);
			if (emptyText) {
				finalInputValue = emptyText.trim();
			}
		}
	} else if (typeof value === "string") {
		if (value === "true") {
			finalInputValue = true;
		} else if (value === "false") {
			finalInputValue = false;
		}
	}
	dispatch(
		setStateValue({
			key: `${inputData.statePath}.value`,
			value: finalInputValue
		})
	);
	dispatch(
		setStateValue({
			key: `${inputData.statePath}.error`,
			value: newInputData.error
		})
	);
	dispatch(setStateValue({ key: `${inputData.statePath}.pristine`, value: newInputData.pristine }));
	if (validateForm && inputData.formStatePath) {
		dispatch(validateForm({ formStatePath: inputData.formStatePath, markInputsAsDirty: markAsDirty }));
	}
}
