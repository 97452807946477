import { Box, Drawer, alpha } from "@material-ui/core";

import styled from "styled-components";

interface NavItemProps {
	highlight: boolean;
}

export const StyledDrawer = styled(Drawer)`
	> * {
		width: 100%;
		background-color: transparent !important;
		z-index: 9999;
	}
`;

export const Container = styled(Box)`
	top: 0;
	bottom: 0;
	padding-top: 12px;
	z-index: 4;
	position: fixed;
	background-color: transparent;
	width: 72px;
	transition: width 0.2s;
	background: ${props => props.theme.palette.background.paper};

	.title {
		display: none;
	}
	
	.icon-out {
		display: none;
	}

	.highlight {
		transition: all 0.1s;
	}

	.MuiCollapse-wrapper {
		display: none;
	}

	.expand-chevron {
		display: none;
		transition: all 0.2s;
		padding: 2px;
		width: 24px;
		height: 24px;
	}

	.powered-by {
		display: none;
	}

	${props => {
		if (props.isMobile) {
			return `
			.MuiCollapse-wrapper {
				display: inherit;
			}

			.expand-chevron {
				display: inherit;
			}

			.highlight {
				background: ${props => props.theme.palette.background.paper};
			}

			width: 280px;
			background: ${props =>
				`linear-gradient(90deg, ${props.theme.palette.background.paper} 0%, ${alpha(
					props.theme.palette.background.paper,
					0.89
				)} 50%, ${alpha(props.theme.palette.background.paper, 0.7)} 80%, ${alpha(
					props.theme.palette.background.paper,
					0.3
				)} 100%)`}
		
			.title {
				margin-top: 6px;
				display: block;
				margin-left: 16px;
			}

			img {
				margin-left: 10px;
				width: 47px;
				
				&.icon-out {
					display: block;
					width: 24px;
					height: 24px;
				}
			}
			
			.navItem {
				width: 280px;
			}
			`;
		}
		return null;
	}}
}

&:hover {
	.MuiCollapse-wrapper {
		display: inherit;
	}

	.expand-chevron {
		display: inherit;
	}

	.highlight {
		background: ${props => props.theme.palette.background.paper};
	}

	width: 280px;
	background: ${props =>
		`linear-gradient(90deg, ${props.theme.palette.background.paper} 0%, ${alpha(
			props.theme.palette.background.paper,
			0.89
		)} 60%, ${alpha(props.theme.palette.background.paper, 0.7)} 80%, ${alpha(
			props.theme.palette.background.paper,
			0.3
		)} 100%)`} ;

	.title {
		margin-top: 6px;
		display: block;
		margin-left: 16px;
	}

	img {
		margin-left: 10px;
		width: 47px;
		
		&.icon-out {
			display: block;
			width: 24px;
			height: 24px;
		}
	}

	.powered-by img{
		margin-left: 10px;
		width: 83px;
		height: 42px;
	}

	.navItem {
		width: 280px;
	}

	.powered-by {
		min-width: 280px;
		transition: all 0.2s;
		display: flex;
	}
`;

export const NavItemContainer = styled(Box)`
	width: inherit;
	position: absolute;
	top: 74px;
`;

export const NavItem = styled(Box)<NavItemProps>`
	cursor: pointer;
	font-size: 13px;
	line-height: 1.23;
	color: ${props => alpha(props.theme.palette.text.primary, 0.6)};
	transition: all 0.2s;

	.item-box {
		padding: 12px 20px;
		display: flex;

		.nested {
			padding-left: 36px;
		}
	}

	${({ theme, highlight, disabled }) => {
		if (highlight) {
			return `
				background: ${theme.palette.background.default};
			`;
		}

		if (disabled) {
			return `
				pointer-events: none;
			`;
		}

		return null;
	}}

	${props => {
		if (props.isMobile) {
			return `
			${({ route, theme }) =>
				route &&
				`background: linear-gradient(
			to left,
			${alpha(theme.palette.background.default, 0.12)},
			${alpha(theme.palette.background.default, 0.24)} 73%,
			${theme.palette.background.default} );`}
			`;
		}
		return null;
	}}
	
	&:hover {
		${({ route, theme }) =>
			route &&
			`background: linear-gradient(
			to left,
			${alpha(theme.palette.background.default, 0.12)},
			${alpha(theme.palette.background.default, 0.24)} 73%,
			${theme.palette.background.default} );`}
`;

export const DrawerCloseIconContainer = styled(Box)`
	z-index: 1300;
	top: 18px;
	right: 36px;
	position: fixed;
`;

export const HamburgerIcon = styled.img`
	z-index: 1300;
	position: fixed;
	top: 15px;
	left: 20px;
`;
