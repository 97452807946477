import React, { FC, useEffect, useMemo, useState } from "react";

import { Box, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { Add, Delete } from "@material-ui/icons";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@remar/shared/dist/components/Button";

import Coupon from "@remar/shared/dist/components/Coupon";
import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import SpecialOffer from "@remar/shared/dist/components/SpecialOffer";
import { AddonsIntegrationIds, ExternalIntegrationIds } from "@remar/shared/dist/constants";
import { Course } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { getDiscount } from "@remar/shared/dist/utils/getDiscount";

import { addMonths, format } from "date-fns";
import pluralize from "pluralize";
import { useHistory, useParams } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";
import {
	addUserSubscriptionTypeAddonIds,
	getFullState,
	removeUserSubscriptionTypeAddonIds,
	resetCoupon,
	selectSubscriptions,
	setInitUserSubscriptionTypeAddonIds,
	setShippingApplicable,
	validateCoupon
} from "store/features/Auth/authSlice";

import { ReactComponent as TodayIcon } from "assets/icons/icon-today.svg";

import ChangeOrSelectCourse from "./ChangeOrSelectCourse";

import {
	BookLabel,
	DelayStartDateBox,
	PaidShippingContainer,
	SummaryCloseButton,
	SummaryCloseButtonContainer,
	useStyles
} from "./styles";

import { getBookType } from "./utils";

interface Props {
	setFreeTrial?: (_) => void;
	closeModal?: (_) => void;
	courses?: Course[];
	startDate?: Date;
	showCrossButton?: boolean;
	setStartDate?: (date: Date) => void;
	isUpgrade?: boolean;
	specialOfferDiscount?: number;
}

const getDuration = duration => {
	return (
		duration &&
		Object.entries(duration)
			.filter(([, dur]) => !!dur)
			.map(([period, dur]) => `${dur} ${pluralize(period, period)}`)
	);
};

const Summary: FC<Props> = ({
	setFreeTrial,
	courses,
	startDate = new Date(),
	setStartDate,
	closeModal,
	showCrossButton = true,
	isUpgrade = false,
	specialOfferDiscount
}) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles();
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [changeCourseModal, setChangeCourseModal] = useState(false);
	const [openPicker, setOpenPicker] = useState(false);
	const { accountClaimCode } = useParams<{ accountClaimCode?: string }>();

	const {
		userSubscriptionTypeId,
		inactiveSubscription: { isRenewEnabled, nextUserSubscriptionType },
		subscription: { isTrial },
		subscriptionTypes,
		userSubscriptionTypeAddonIds,
		selectedShippingPlan,
		multipleShippingPlan,
		selectedCountryId,
		isValidatingCoupon,
		validatedCoupon,
		invalidCoupon,
		discountScope,
		paymentDiscount
	}: RootState["auth"] = useAppSelector(getFullState);
	const userSubscriptions = useAppSelector(selectSubscriptions);
	const dispatch = useAppDispatch();
	const activeSubscription = useMemo(
		() => (isRenewEnabled ? nextUserSubscriptionType : subscriptionTypes?.find(us => us.id === userSubscriptionTypeId)),
		[subscriptionTypes, userSubscriptionTypeId, isRenewEnabled, nextUserSubscriptionType]
	);
	const addOnsSub = activeSubscription?.subTypeEIDItems!.filter(
		item => item.parentId !== null && item.integrationId === ExternalIntegrationIds.Stripe
	);
	const filteredAddons = useMemo(
		() => (addOnsSub || [])?.filter(addon => userSubscriptionTypeAddonIds?.includes(addon.id)),
		[addOnsSub]
	);
	const totalAddonsPrice = (filteredAddons || []).reduce((acc, curr) => acc + curr.data.price, 0);

	const initialPhysicalBook = activeSubscription?.subTypeEIDItems!.find(
		item => item.parentId === null && item.integrationId === ExternalIntegrationIds.PBS
	);
	const initialDigitalBook = activeSubscription?.subTypeEIDItems!.find(
		item => item.parentId === null && item.integrationId === ExternalIntegrationIds.DigitalAsset
	);
	const initialShippingApplicable = !!initialPhysicalBook;

	const addonsShippingApplicable = (addOnsSub || [])
		?.filter(addon => userSubscriptionTypeAddonIds?.includes(addon.id))
		.reduce((acc, cur) => {
			const isDigital = !!cur.counterparts?.find(item => item.integrationId === ExternalIntegrationIds.DigitalAsset);
			return acc || !isDigital;
		}, false);

	const shippingApplicable = initialShippingApplicable || !!addonsShippingApplicable;

	useEffect(() => {
		dispatch(setShippingApplicable(shippingApplicable));
	}, [dispatch, shippingApplicable]);

	useEffect(() => {
		if (!!setFreeTrial) {
			setFreeTrial!(history.location.search.includes("trial=true"));
		}
	}, [history.location.search]);
	useEffect(() => {
		if (activeSubscription && addOnsSub && addOnsSub.length) {
			const ids = [];
			for (let index = 0; index < addOnsSub.length; index++) {
				const addonsSub = addOnsSub[index];
				if (addonsSub.data.isDefault === true && !activeSubscription.isRecurring) {
					ids.push(addonsSub.id as never);
				}
			}
			dispatch(setInitUserSubscriptionTypeAddonIds(ids));
		}
	}, [activeSubscription]);

	useEffect(() => {
		if (selectedCountryId && validatedCoupon) {
			//	 revalidate coupon upon country update
			handleApplyCoupon({ coupon: validatedCoupon.coupon.code, addons: filteredAddons });
		}
	}, [selectedCountryId]);

	const subscriptionString = useMemo(() => {
		const name = activeSubscription?.name;
		const duration = getDuration(activeSubscription?.duration);
		return (name && `${duration ?? ""} - ${name} ${!!initialDigitalBook ? "- (PDF workbook included)" : ""}`) || "N/A";
	}, [activeSubscription, initialDigitalBook]);

	const renewSubscription = useMemo(() => {
		let nextSubscription = nextUserSubscriptionType;
		if (!nextSubscription) {
			nextSubscription = activeSubscription;
		}
		const name = nextSubscription?.name;
		const duration = getDuration(nextSubscription?.duration);
		if (duration && duration.length !== 0) {
			duration[0] =
				duration.length !== 0 && duration[0].includes("1") ? duration[0].replace("months", "month") : duration[0];
		}
		const subscriptionName = (name && `${duration ?? ""} ${name}`) || "N/A";
		const price = nextSubscription?.subTypeEIDItems?.find(f => f.parentId === null)?.data["price"] || 0;
		const nextSubscriptionTypeId =
			nextSubscription?.subTypeEIDItems?.find(f => f.parentId === null)?.subscriptionTypeId || 0;
		return { subscriptionName, price, nextSubscriptionTypeId };
	}, [nextUserSubscriptionType, activeSubscription]);

	//Getting sum of addons
	const addOnsSum = addOnsSub?.reduce(function (lastSum, currentItem) {
		if (userSubscriptionTypeAddonIds?.includes(currentItem.id)) {
			return lastSum + (currentItem?.data["price"] || 0);
		}
		return lastSum;
	}, 0);

	const mainSubTypeEIDItems = activeSubscription?.subTypeEIDItems!.find(
		i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
	);
	const coursePrice = mainSubTypeEIDItems?.data["price"] || 0;

	const courseOrRenewalPrice = useMemo(
		() => (isRenewEnabled ? renewSubscription?.price : coursePrice || "N/A"),
		[isRenewEnabled, renewSubscription, coursePrice]
	);
	const isPercentageDiscount = useMemo(
		() => (validatedCoupon ? validatedCoupon.coupon.data.isPercentage : false),
		[validatedCoupon]
	);

	const discountedCoursePrice = useMemo(() => {
		const discount = getDiscount(validatedCoupon, courseOrRenewalPrice);
		const notificationDiscount = paymentDiscount?.data.isPercentage
			? (paymentDiscount?.data.amount / 100) * courseOrRenewalPrice || 0
			: paymentDiscount?.data.amount || 0;
		return courseOrRenewalPrice - discount - notificationDiscount;
	}, [courseOrRenewalPrice, validatedCoupon, paymentDiscount]);

	const freeShipping = useMemo(() => {
		const allowedCourse =
			userSubscriptions && userSubscriptions[0]?.type?.allowedCourses
				? userSubscriptions[0]?.type?.allowedCourses[0]
				: undefined;
		const activeSub =
			activeSubscription && activeSubscription.allowedCourses ? activeSubscription.allowedCourses[0] : undefined;
		const course = isTrial ? allowedCourse : activeSub;
		return !!(course && course.freeShipping);
	}, [activeSubscription, isTrial, userSubscriptions]);

	const shippingPlan = useMemo(() => {
		const addonPhysicalBooks = filteredAddons.filter(
			a => !a.counterparts?.some(c => c.integrationId === ExternalIntegrationIds.DigitalAsset)
		).length;
		const physicalBookCount = addonPhysicalBooks + !!initialPhysicalBook;
		return physicalBookCount > 1 ? multipleShippingPlan : selectedShippingPlan;
	}, [filteredAddons, initialPhysicalBook, multipleShippingPlan, selectedShippingPlan]);
	const shippingPrice = useMemo(() => shippingPlan?.data["price"], [shippingPlan?.data]);

	const notificationDiscount = useMemo(
		() =>
			paymentDiscount?.data.isPercentage
				? (paymentDiscount?.data.amount / 100) * courseOrRenewalPrice || 0
				: paymentDiscount?.data.amount || 0,
		[paymentDiscount, courseOrRenewalPrice]
	);

	const totalPrice =
		courseOrRenewalPrice +
		addOnsSum +
		((shippingApplicable && shippingPrice) || 0) -
		((freeShipping && shippingPrice) || 0) -
		(validatedCoupon?.discountedAmount || 0) -
		(notificationDiscount || 0);

	const showCourseDiscountedAmount = useMemo(
		() =>
			((discountScope?.signUp || discountScope?.renewals) &&
				isPercentageDiscount &&
				!!validatedCoupon?.discountedAmount) ||
			paymentDiscount,
		[discountScope, isPercentageDiscount, validatedCoupon, paymentDiscount]
	);

	const handleDateChange = date => {
		setStartDate && setStartDate!(date);
	};

	const handleApplyCoupon = ({ coupon, addons }: { coupon: string; addons: { id: number; quantity: number }[] }) => {
		const books = addons.map(addon => ({ id: addon.id, quantity: addon.quantity || 1 })) || [];
		if (!coupon) {
			return;
		}
		dispatch(
			validateCoupon({
				couponCode: coupon,
				userSubscriptionTypeId: isRenewEnabled ? renewSubscription.nextSubscriptionTypeId : userSubscriptionTypeId,
				books,
				countryId: selectedCountryId
			})
		);
	};
	const resetCouponState = () => {
		dispatch(resetCoupon());
	};

	return !openPicker ? (
		<>
			<Box display="flex" flexDirection="column">
				<Box className={classes.summaryRoot} pb={0} style={{ position: "relative" }}>
					{((showCrossButton && isMobile) || isUpgrade) && (
						<SummaryCloseButtonContainer>
							<SummaryCloseButton onClick={closeModal}>
								<CloseIcon color="disabled" />
							</SummaryCloseButton>
						</SummaryCloseButtonContainer>
					)}
					<Box>
						{
							<Box mb={4}>
								<Typography className={classes.title}>You Might Also Like</Typography>
								{addOnsSub
									?.filter(addon => !userSubscriptionTypeAddonIds?.includes(addon.id))
									.map(addon => {
										const digitalAsset = addon.counterparts.find(
											item => item.integrationId === AddonsIntegrationIds.DigitalAsset
										);
										return (
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												mt={2}
												bgcolor={theme.palette.background.default}
												p={1}
												key={`addon-${addon.id}`}
												borderRadius={5}
											>
												<Box display="flex" alignItems="center">
													<Box borderRadius={5} overflow="hidden" width={40} height={55}>
														<img className={classes.bookImg} src={addon?.mainImageUrl} alt={addon?.data["name"]} />
													</Box>
													<Box ml={1} display="flex" flexDirection={"column"} alignItems={"flex-start"}>
														<Typography className={classes.summaryText}>{addon?.data["name"] as string}</Typography>
														<Typography>{formatUSD(addon?.data["price"] || 0)}</Typography>
														<BookLabel $isPhysical={!Boolean(digitalAsset)}>
															{getBookType(
																digitalAsset ? AddonsIntegrationIds.DigitalAsset : AddonsIntegrationIds.PhysicalAsset
															)}
														</BookLabel>
													</Box>
												</Box>
												<Box>
													<Box
														bgcolor={theme.palette.colors.primary[500]}
														p={1}
														display="flex"
														borderRadius={5}
														justifyContent="center"
														alignItems="center"
														style={{ cursor: "pointer" }}
														onClick={() => {
															dispatch(addUserSubscriptionTypeAddonIds(addon.id));
															handleApplyCoupon({
																coupon: validatedCoupon?.coupon?.code,
																addons: (addOnsSub || [])?.filter(_addon =>
																	[...userSubscriptionTypeAddonIds, addon.id]?.includes(_addon.id)
																)
															});
														}}
													>
														<Add
															style={{
																backgroundColor: theme.palette.colors.primary[500],
																color: theme.palette.colors.basic[1500]
															}}
														/>
													</Box>
												</Box>
											</Box>
										);
									})}
							</Box>
						}

						<Box>
							<Typography className={classes.title}>Order Summary</Typography>
							<Box className={classes.orderSummary} mt={2}>
								<Typography className={classes.summaryText}>
									{isRenewEnabled ? renewSubscription.subscriptionName : subscriptionString}
									<span onClick={() => setChangeCourseModal(true)}>
										{subscriptionString !== "N/A" && !isRenewEnabled && !isTrial && "(Change)"}
									</span>
								</Typography>
								<Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
									<Typography
										className={
											showCourseDiscountedAmount ? classes.summaryCostSmallStrikeThrough : classes.summaryCostSmall
										}
									>
										{courseOrRenewalPrice !== "N/A" ? formatUSD(courseOrRenewalPrice) : courseOrRenewalPrice}{" "}
									</Typography>
									{showCourseDiscountedAmount && courseOrRenewalPrice !== "N/A" && (
										<Typography align="right" className={classes.summaryCostSmall}>
											{formatUSD(discountedCoursePrice)}
										</Typography>
									)}
								</Box>
							</Box>
							{specialOfferDiscount && <SpecialOffer discount={specialOfferDiscount} />}
							{filteredAddons.map(addon => {
								const digitalAsset = addon.counterparts.find(
									item => item.integrationId === AddonsIntegrationIds.DigitalAsset
								);

								return (
									<Box key={addon?.data["name"] as string} className={classes.addon} mt={2}>
										<Box display={"flex"} alignItems={"center"}>
											<img className={classes.addonImage} src={addon?.mainImageUrl} alt={addon?.data["name"]} />
											<Box display={"flex"} flexDirection={"column"}>
												<Typography className={classes.summaryText}>{addon?.data["name"] as string}</Typography>
												<BookLabel $isPhysical={!Boolean(digitalAsset)}>
													{getBookType(
														digitalAsset ? AddonsIntegrationIds.DigitalAsset : AddonsIntegrationIds.PhysicalAsset
													)}
												</BookLabel>
											</Box>
										</Box>
										<Box display={"flex"}>
											<Typography className={classes.summaryCostSmall}>
												{formatUSD(addon?.data["price"] || 0)}
											</Typography>
											<Delete
												className={classes.removeAddOnIcon}
												onClick={() => {
													dispatch(removeUserSubscriptionTypeAddonIds(addon.id));
													handleApplyCoupon({
														coupon: validatedCoupon?.coupon?.code,
														addons: (addOnsSub || [])?.filter(_addon =>
															userSubscriptionTypeAddonIds.filter(a => a !== addon.id)?.includes(_addon.id)
														)
													});
												}}
											/>
										</Box>
									</Box>
								);
							})}
							<Box mt={1}>
								{discountScope?.books &&
									isPercentageDiscount &&
									!!validatedCoupon?.discountedAmount &&
									filteredAddons.length !== 0 && (
										<Typography style={{ color: theme.palette.text.secondary, fontSize: "13px" }} align="right">
											Books Discount -{formatUSD(getDiscount(validatedCoupon, totalAddonsPrice))}
										</Typography>
									)}
							</Box>
							{!isPercentageDiscount && !!validatedCoupon?.discountedAmount && (
								<Box mt={2}>
									<Typography style={{ color: theme.palette.text.secondary, fontSize: "13px" }} align="right">
										{`(${discountScope?.signUp ? "Sign up" : ""}${
											discountScope?.signUp && discountScope?.books ? " & " : ""
										}${discountScope?.books ? "Books" : ""}) `}
										Discount -{formatUSD(validatedCoupon?.discountedAmount || 0)}
									</Typography>
								</Box>
							)}
						</Box>
					</Box>

					<Box>
						{shippingApplicable && shippingPlan && (
							<PaidShippingContainer>
								<Typography className={classes.summaryText} align="right">
									{shippingPlan?.name}
								</Typography>
								<Typography className={classes.summaryCostSmall} align="right">
									{formatUSD(shippingPrice || 0)}
								</Typography>
								{((discountScope?.shipping && !!validatedCoupon?.discountedAmount) || freeShipping) && (
									<Typography style={{ color: theme.palette.text.secondary, fontSize: "13px" }} align="right">
										Shipping Discount -{" "}
										{formatUSD(freeShipping ? shippingPrice : getDiscount(validatedCoupon, shippingPrice) || 0)}
									</Typography>
								)}
							</PaidShippingContainer>
						)}

						{!paymentDiscount && !specialOfferDiscount && (
							<Coupon
								onApply={coupon => handleApplyCoupon({ coupon, addons: filteredAddons })}
								invalidCoupon={invalidCoupon}
								isApplying={isValidatingCoupon}
								validatedCoupon={validatedCoupon}
								resetCoupon={resetCouponState}
							/>
						)}
						<Box mt={3}>
							<Typography className={classes.summaryTextLight} align="right">
								Your total
							</Typography>
							<Typography className={classes.summaryCostBig} align="right">
								{totalPrice && formatUSD(totalPrice)}
							</Typography>
							<Typography className={classes.formSubtitle} style={{ textAlign: "right" }}>
								Your subscription will start on {format(startDate as Date, "MMM dd, yyyy.")}
							</Typography>
						</Box>
					</Box>
				</Box>
				{!isRenewEnabled && !isMobile && (
					<DelayStartDateBox p={2} className={classes.changeDateText} onClick={() => setOpenPicker(true)}>
						<Box className="today-icon">
							<TodayIcon />
						</Box>
						Click here to delay your start date.
						<Box className="arrow-icon">
							<ArrowForwardRoundedIcon />
						</Box>
					</DelayStartDateBox>
				)}
			</Box>
			<Modal
				open={changeCourseModal}
				onClose={() => {
					setChangeCourseModal(false);
				}}
			>
				<ChangeOrSelectCourse
					onClose={() => setChangeCourseModal(false)}
					courses={courses}
					accountClaimCode={accountClaimCode}
				/>
			</Modal>
		</>
	) : (
		<Box className={classes.summaryRoot}>
			<Box>
				<Box mb={4} display="flex" justifyContent="space-between">
					<Typography className={classes.changeTheStartDate}>Change The Start Date</Typography>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						<IconButton onClick={() => setOpenPicker(false)} className={classes.closeIcon}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
				</Box>
				<div className={classes.datepickerInputContainer}>
					<DatePicker
						autoOk
						open={false}
						disablePast
						disableToolbar
						orientation="portrait"
						variant="static"
						openTo="date"
						maxDate={addMonths(new Date(), 3)}
						value={startDate}
						onChange={handleDateChange}
					/>
				</div>
				<Box display="flex" justifyContent="flex-end" mt={2}>
					<Button variant={"filled"} color={"primary"} onClick={() => setOpenPicker(false)}>
						Done
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default Summary;
