import React, { useState } from "react";

import { AccordionDetails, AccordionSummary, Box, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import CheckMarkSVG from "../../../assets/icons/icon-checkmark-filled-active.svg";
import CrownSvg from "../../../assets/icons/icon-crown.svg";
import LessonLockSvg from "../../../assets/icons/icon-lock.svg";

import Button from "../../Button";

import {
	AccordionLesson,
	AccordionSummaryWrapper,
	LessonProgressAccordion,
	SectionsWrapper,
	StyledAccordion,
	StyledCheckMarkSVG,
	StyledCrownSvg,
	StyledLessonLockSvg
} from "../styles";

const AccordionLessonsProgress = ({ sections, onLessonNavigation, nextLesson }) => {
	const [isExpanded, setIsExpanded] = useState<boolean[]>([]);

	return sections.map((section, index) => {
		const { mainImageUrl: sectionMainImageUrl, mainImageThumbnailUrl: sectionMainThumbnailUrl } = section;
		const lessonsCount = section.sectionLessons.length;
		const completedLessons = section.sectionLessons.filter(lesson => lesson.isCompleted).length;
		return (
			<LessonProgressAccordion key={section.id}>
				<StyledAccordion
					expanded={isExpanded[index] || section.id === nextLesson?.sectionId}
					onChange={() =>
						setIsExpanded(expanded => {
							const newExpanded = [...expanded];
							newExpanded[index] = !newExpanded[index];
							return newExpanded;
						})
					}
				>
					<AccordionSummary
						expandIcon={
							<ChevronLeft
								style={{
									width: "30px",
									height: "30px",
									transform: "rotate(-90deg)"
								}}
							/>
						}
					>
						<AccordionSummaryWrapper>
							<Typography>{section.name}</Typography>
							<Typography>
								{completedLessons}/{lessonsCount} Lessons Completed
							</Typography>
						</AccordionSummaryWrapper>
					</AccordionSummary>
					<AccordionDetails>
						<SectionsWrapper>
							{section.sectionLessons.map(sectionLesson => {
								const { lesson, isLockedByPayment, isLockedForCurrentUser, isCompleted } = sectionLesson;
								const { mainImageUrl, mainImageKey, mainImageThumbnailUrl } = lesson;
								const getIcon = () => {
									if (isCompleted) {
										return <StyledCheckMarkSVG src={CheckMarkSVG} alt={"checkmark"} width={20} height={20} />;
									}
									if (isLockedForCurrentUser) {
										if (isLockedByPayment) {
											return <StyledCrownSvg src={CrownSvg} alt={"crown"} width={20} height={20} />;
										}
										return <StyledLessonLockSvg src={LessonLockSvg} alt={"lessonLock"} width={20} height={20} />;
									}
									return;
								};
								return (
									<AccordionLesson key={sectionLesson.id}>
										{getIcon()}
										<img
											className={"lesson-thumbnail"}
											src={
												(mainImageKey
													? mainImageThumbnailUrl?.small250 || mainImageUrl
													: sectionMainThumbnailUrl?.small250 || sectionMainImageUrl) || "https://picsum.photos/200"
											}
											alt={`${lesson!.name} preview image`}
										/>
										<Box p={2}>
											<Typography className={"lesson-name"}>{lesson!.name}</Typography>
											<Typography className={"lesson-description"}>{lesson!.description}</Typography>
											{onLessonNavigation && (
												<Button
													variant={"outlined"}
													color={"basic"}
													fullWidth
													onClick={() => onLessonNavigation(sectionLesson.lessonId, sectionLesson.sectionId)}
												>
													Go to Lesson
												</Button>
											)}
										</Box>
									</AccordionLesson>
								);
							})}
						</SectionsWrapper>
					</AccordionDetails>
				</StyledAccordion>
			</LessonProgressAccordion>
		);
	});
};

export default AccordionLessonsProgress;
