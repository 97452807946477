import { useState } from "react";

import { IPasswordBoxValidation } from "../models";

import {
	charactersREGX,
	digitSpecialCharacterREGEX,
	lowerCaseREGX,
	passwordREGEX,
	upperCaseREGX
} from "../utils/serviceUtils";

const passwordBoxInit: IPasswordBoxValidation = {
	characters: false,
	lowerCase: false,
	upperCase: false,
	numbers: false,
	specialCharacter: false
};

interface IUseValidatePasswordProps {
	validatePassword: (val: string) => void;
	showPasswordErrorBox: boolean;
	passwordErrorBox: IPasswordBoxValidation;
}
export const usePasswordErrorBox = (): IUseValidatePasswordProps => {
	const [showPasswordErrorBox, setShowPasswordErrorBox] = useState<boolean>(false);
	const [passwordErrorBox, setPasswordErrorBox] = useState<IPasswordBoxValidation>(passwordBoxInit);

	const validatePassword = (value: string) => {
		if (value && !passwordREGEX.test(value)) {
			!showPasswordErrorBox && setShowPasswordErrorBox(true);
			setPasswordErrorBox({
				characters: charactersREGX.test(value),
				lowerCase: lowerCaseREGX.test(value),
				upperCase: upperCaseREGX.test(value),
				numbers: /\d/.test(value),
				specialCharacter: digitSpecialCharacterREGEX.test(value)
			});
		} else {
			showPasswordErrorBox && setShowPasswordErrorBox(false);
		}
	};
	return {
		validatePassword,
		showPasswordErrorBox,
		passwordErrorBox
	};
};
