import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { File, FileVaultFolder } from "@remar/shared/dist/models";
import { setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";
import { RootState } from "store";

import { fileVaultFolderService } from "store/services/fileVault";
import { fileVaultFilesService } from "store/services/fileVaultFiles";

interface FileVaultState {
	isLoading: boolean;
	errorMessage: string; // todo: pls unify the name name field to be able generic reducerHelpers to use
	folderList: FileVaultFolder[];
	selectedFolder: FileVaultFolder | null | undefined;
	selectedSubFolder: FileVaultFolder | null | undefined;
	selectedFolderId: number | null;
	selectedSubFolderId: number | null;
	files: File[] | undefined;
	page: number;
	perPage: number;
	totalItems: number;
	totalCount: number;
}
const initialState: FileVaultState = {
	isLoading: false,
	errorMessage: "",
	folderList: [],
	files: [],
	page: 1,
	perPage: 10,
	totalItems: 0,
	totalCount: 0,
	selectedFolder: null,
	selectedFolderId: null,
	selectedSubFolder: null,
	selectedSubFolderId: null
};

export const fetchFolders = createAsyncThunk(
	"fileVault/fetchFolders",
	async ({ id, searchText }: { id: number; searchText: string }, { rejectWithValue }) => {
		const filters = {
			"courses.id": id,
			isPrivate: false,
			isTopLevel: true,
			isVisible: true
		};
		if (searchText) {
			filters["name"] = {
				$ilike: `%${searchText}%`
			};
		}
		return await fileVaultFolderService
			.find({
				filters,
				include: ["subFolders.files.lessons"]
			})
			.catch(() => rejectWithValue("Error in getting folders"));
	}
);

export const fetchFiles = createAsyncThunk(
	"fileVault/fetchFiles",
	async (
		{ courseId, folderId, name, page }: { courseId: number; folderId: number; name?: string; page: number },
		{ rejectWithValue }
	) => {
		const filters = {
			"folders.parent.courses.id": courseId,
			"folders.id": folderId,
			"folders.isTopLevel": false
		};
		if (name) {
			filters["name"] = {
				$ilike: `%${name}%`
			};
		}
		return await fileVaultFilesService
			.find({
				filters,
				orderBy: { createdAt: "DESC" },
				page,
				include: ["lessons"]
			})
			.catch(() => rejectWithValue("Error in getting files"));
	}
);

export const setSelectedFolder = createAsyncThunk(
	"fileVault/setSelectedFolder",
	async (folderId: number, {}) => folderId
);

export const setSelectedSubFolder = createAsyncThunk(
	"fileVault/setSelectedSubFolder",
	async ({ folderId, subFolderId }: { folderId: number; subFolderId: number }, {}) => ({
		folderId,
		subFolderId
	})
);

export const fileVault = createSlice({
	name: "fileVault",
	initialState,
	reducers: {
		setStateValue: utilsSetStateValue
	},
	extraReducers: {
		[fetchFolders.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchFolders.fulfilled.type]: (state, { payload }) => {
			const { items } = payload;
			state.folderList = items;
			state.isLoading = false;
		},
		[fetchFiles.pending.type]: state => {
			state.isLoading = true;
		},
		[fetchFiles.fulfilled.type]: (state, { payload: { items, page: newPage, perPage: newPerPage, totalItems } }) => {
			state.files = items;
			state.totalItems = totalItems;
			if (!state.totalCount) {
				state.totalCount = totalItems;
			}
			state.perPage = newPerPage;
			state.page = newPage;
			state.isLoading = false;
		},
		[setSelectedFolder.fulfilled.type]: (state, { payload: selectedFolderId }) => {
			const topLevelFolders = state.folderList;
			state.selectedFolderId = selectedFolderId;
			state.selectedFolder = selectedFolderId && topLevelFolders.find(({ id }) => id === +selectedFolderId);
		},
		[setSelectedSubFolder.fulfilled.type]: (state, { payload }) => {
			const { folderId, subFolderId } = payload;
			const topLevelFolders = state.folderList;
			state.selectedFolder = folderId && topLevelFolders.find(({ id }) => id === +folderId);
			state.selectedSubFolderId = subFolderId;
			state.selectedSubFolder = subFolderId && state.selectedFolder?.subFolders.find(({ id }) => id === +subFolderId);
			state.totalItems = state!.selectedSubFolder! && state!.selectedSubFolder!.files.length;
		}
	}
});

export const { setStateValue } = fileVault.actions;

export const getFullState = ({ fileVault }: RootState): FileVaultState => fileVault;

export default fileVault.reducer;
