import { Button, FormControl, Typography, createStyles, makeStyles } from "@material-ui/core";
import { alpha, darken } from "@material-ui/core/styles";
import styled from "styled-components";

import * as colors from "../colors";
import { IExtendedTheme } from "../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		deleteIcon: {
			background: "transparent"
		},
		modal: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		card: {
			borderRadius: "5px"
		},
		title: {
			fontSize: theme.typography.pxToRem(25),
			fontWeight: 900
		},
		closeIcon: {
			marginTop: "-15px",
			marginRight: "-15px"
		},
		subTitle: {
			color: colors.basic[400],
			marginBottom: "15px"
		},
		modalFooter: {
			gap: "8px",
			paddingTop: "25px"
		},
		cancelBtn: {
			fontSize: theme.typography.pxToRem(16),
			backgroundColor: "transparent",
			color: colors.basic[400],
			borderColor: colors.basic[600],
			borderWidth: "1px",
			borderStyle: "solid",
			padding: "8px 20px",
			boxSizing: "border-box"
		},
		deleteBtn: {
			fontSize: theme.typography.pxToRem(16),
			backgroundColor: theme.palette.lightButtonColors.danger.backgroundColor,
			color: theme.palette.lightButtonColors.danger.color,
			padding: "8px 20px",
			"&:hover": {
				backgroundColor: theme.palette.lightButtonColors.danger.backgroundColorOnHover
			}
		},
		deleteAllBtn: {
			backgroundColor: theme.palette.lightButtonColors.danger.backgroundColor,
			color: theme.palette.lightButtonColors.danger.color,
			marginRight: "10px",
			fontSize: theme.typography.pxToRem(15),
			padding: "15px 6px 15px 6px",
			lineHeight: 1.2
		},
		deleteLessonOnlyBtn: {
			backgroundColor: theme.palette.lightButtonColors.danger.backgroundColor,
			color: theme.palette.lightButtonColors.danger.color,
			fontSize: theme.typography.pxToRem(15),
			padding: "15px 6px 15px 6px",
			lineHeight: 1.2
		},
		selectFilled: {
			backgroundColor: theme.palette.frame.contentBackgroundColor,
			borderRadius: "4px"
		},
		save: {
			marginLeft: "10px"
		},
		paper: {
			boxShadow: "none",
			margin: 0,
			color: theme.palette.text.primary,
			fontSize: 13,
			background: theme.palette.colors.basic[700],
			borderTop: `1px solid ${theme.palette.colors.basic[600]}`
		},
		modalMainText: {
			fontWeight: 700,
			fontFamily: "var(--openSans)",
			fontSize: theme.typography.pxToRem(26),
			lineHeight: "32px",
			opacity: 0.87
		},
		modalSubText: {
			fontSize: theme.typography.pxToRem(13),
			color: darken(theme.palette.text.secondary, 0.2),
			lineHeight: "20px",
			textAlign: "center"
		}
	})
);

export const LabelText = styled(Typography)`
	color: hsla(0, 0%, 100%, 0.38);
	margin-bottom: 10px;
`;

export const FormControlContainer = styled(FormControl)`
	width: 100%;
`;

export const Container = styled.div`
	margin-right: 1.3rem;

	.breadcrum-container {
		margin-top: 6px;

		div {
			font-size: 13px;
			line-height: 1.23;
			color: ${({ theme }) => theme.palette.colors.basic[400]};
		}

		div:last-child {
			color: ${({ theme }) => theme.palette.colors.basic[100]};
		}
	}
`;

export const BasicButton = styled(Button)`
	font-weight: 800;
	color: ${({ theme }) => theme.palette.lightButtonColors.basic.color};
	background-color: ${({ theme }) => theme.palette.lightButtonColors.basic.backgroundColor};

	&:hover {
		background-color: ${({ theme }) => alpha(theme.palette.colors.basic[400], 0.4)};
	}
`;

export const PrimaryAlphaButton = styled(Button)`
	color: ${({ theme }) => theme.palette.primary.main};
	background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.6)};
	&:hover {
		background-color: ${({ theme }) => theme.palette.colors.primary[500]}60;
	}
`;
