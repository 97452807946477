import React from "react";

import { Box, Card, IconButton, Modal, Typography, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Button from "../../components/Button";
import { IExtendedTheme } from "../../theme/default";

const useStyles = makeStyles((theme: IExtendedTheme) =>
	createStyles({
		modal: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		card: {
			borderRadius: "5px",
			padding: 0
		},
		cardHeader: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			padding: 16
		},
		title: {
			fontSize: theme.typography.pxToRem(25),
			fontWeight: 900
		},
		closeIcon: {
			marginTop: "-15px",
			marginRight: "-15px"
		},
		modalFooter: {
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
			gap: "8px",
			padding: 16,
			marginTop: 16,
			borderTop: `1px solid ${theme.palette.divider}`
		}
	})
);

type DialogModalProps = {
	open: boolean;
	title: string;
	cancelBtnText: string;
	successBtnText: string;
	onClose: () => void;
	onCancel?: () => void;
	onSuccess: () => void;
	successBtnDisabled?: boolean;
	loading?: boolean;
	maxWidth?: number;
};

/**
 * A modal dialog component with simple Yes|No action.
 *
 * @component
 * @example
 * <DialogModal
 *     title="Delete item"
 *     onClose={handleClose}
 *     open={isOpen}
 *     onSuccess={onDelete}
 *     successBtnText="Delete"
 *     cancelBtnText="Cancel"
 *     maxWidth={600}
 *     successBtnDisabled={isLoading}
 * >
 *     <DialogContent>
 *         <Typography>Are you sure you want to delete this item?</Typography>
 *     </DialogContent>
 * </DialogModal>
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the dialog.
 * @param {function} props.onClose - The callback function for closing the dialog.
 * @param {ReactNode} props.children - The content of the dialog.
 * @param {boolean} props.loading - A flag indicating if the success button is in the loading state.
 * @param {function} props.onSuccess - The callback function when the success button is clicked.
 * @param {string} props.successBtnText - The text for the success button.
 * @param {string} props.cancelBtnText - The text for the cancel button.
 * @param {boolean} props.open - A flag indicating if the dialog should be open.
 * @param {number} [props.maxWidth=500] - The maximum width of the dialog (in pixels).
 * @param {boolean} props.successBtnDisabled - A flag indicating if the success button is disabled.
 *
 * @returns {ReactElement} The rendered DialogModal component.
 */
const DialogModal: React.FC<DialogModalProps> = ({
	title,
	onClose,
	onCancel,
	children,
	loading,
	onSuccess,
	successBtnText,
	cancelBtnText,
	open,
	maxWidth = 500,
	successBtnDisabled
}) => {
	const classes = useStyles();

	const renderSuccessButton = () => (
		<Button
			onClick={onSuccess}
			disabled={loading || successBtnDisabled}
			variant={"filled"}
			color={"primary"}
			loading={loading}
		>
			{successBtnText}
		</Button>
	);

	return (
		/*todo: I believe we can reuse SimpleModal implementation */
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={open} onClose={onClose}>
			<Box width="100%" maxWidth={maxWidth} style={{ maxHeight: "90vh", overflowY: "auto" }}>
				<Card className={classes.card}>
					<Box className={classes.cardHeader}>
						<Typography className={classes.title}>{title}</Typography>
						<IconButton className={classes.closeIcon} onClick={onClose}>
							<CloseIcon color="disabled" />
						</IconButton>
					</Box>
					{children}
					<Box className={classes.modalFooter}>
						<Button color="basic" variant="outlined" onClick={onCancel ?? onClose}>
							{cancelBtnText}
						</Button>
						{renderSuccessButton()}
					</Box>
				</Card>
			</Box>
		</Modal>
	);
};

export default DialogModal;
