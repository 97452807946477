import React from "react";

import { Box, Typography } from "@material-ui/core";

import { LackOfFundsDiscountWrapper } from "./styles";

import DiscountIllustration from "../../assets/images/content-illustrations-discount-blue.svg";

const LackOfFundsDiscount = () => (
	<LackOfFundsDiscountWrapper>
		<Box className="discount-illustration">
			<img src={DiscountIllustration} alt="discount" />
		</Box>
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography className="discount-title">Get Discount For Your Purchase</Typography>
			<Typography className="discount-description">We are offering a special discount for you.</Typography>
		</Box>
	</LackOfFundsDiscountWrapper>
);

export default LackOfFundsDiscount;
