import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LessonTypes, QuestionTypes } from "@remar/shared/dist/constants";
import {
	DiscussionBoardProps,
	IBaseState,
	InteractiveBlockMap,
	LessonComment,
	Question,
	QuestionAnswerOption,
	QuestionGroup,
	SavedAnswer,
	UserLessonAttempt,
	UserLessonAttemptHistory,
	UserLessonFeedbackItem,
	UserLessonProgressItem,
	UserQuestionAnswerAttempt,
	UserQuestionAttempt,
	UserQuestionNote
} from "@remar/shared/dist/models";
import { UserAnswerQuestionIdDto, UserQuestionAnswerDto } from "@remar/shared/dist/services/userQuestionAnswers";
import { pendingReducer } from "@remar/shared/dist/utils/reducerHelpers";
import { getResetState } from "@remar/shared/dist/utils/stateUtils";
import { differenceInSeconds, parseISO } from "date-fns";
import { fromPairs, last, shuffle } from "lodash";

import { RootState } from "store";
import {
	IUserActivityTrackingPayload,
	LessonCommentsItemCreateDto,
	LessonCommentsItemUpdateDto,
	UserLessonAttemptCreateDto,
	UserLessonFeedbackItemCreateDto,
	UserLessonProgressItemCreateDto,
	UserLessonProgressItemUpdateDto,
	UserQuestionAttemptCreateDto,
	UserQuestionNoteCreateDto,
	UserQuestionNoteUpdateDto,
	lessonCommentsService,
	lessonsService,
	userLessonAttemptsService,
	userLessonFeedbackItemsService,
	userLessonProgressItemsService,
	userQuestionAttemptsService,
	userQuestionNotesService,
	usersService
} from "store/services";

import { apigClient } from "core/APIGateWay";

import { getQuestionCorrectGroups, getQuestionGroups, reorder } from "./LessonUtils";

import { GLOBAL_CONSTANTS } from "../../../constants";

const mapQuestions = question => {
	return question.typeId === QuestionTypes.Grouping
		? {
				...question,
				data: { groups: getQuestionGroups(question) },
				correctGroups: getQuestionCorrectGroups(question)
		  }
		: {
				...question,
				data: {
					...question.data,
					answerOptions:
						question.typeId === QuestionTypes.SingleChoice || question.typeId === QuestionTypes.MultipleChoice
							? question.data.answerOptions
							: shuffle(question.data.answerOptions)
				}
		  };
};

interface CSQuestions {
	questionIndex: number;
	id: number;
}

interface LessonState extends Omit<IBaseState, "error"> {
	interactiveBlocksByQuestionId: { [questionId: number]: InteractiveBlockMap };
	isSelectedLessonProgressFetched: boolean;
	isLoading: boolean;
	isLessonResultLoading: boolean;
	isNextLoading: boolean;
	optionsError: string;
	isAttemptLoading: boolean;
	selectedLessonProgress: UserLessonProgressItem;
	errorMessage: string;
	quizLessonQuestions?: Question[];
	quizLessonAttemptId?: number;
	quizLessonAttempt?: UserLessonAttempt;
	hasPracticeAttempt?: boolean;
	quizPassed: boolean;
	quizPercentage: number;
	quizResult: UserQuestionAttempt[];
	quizError: string;
	isLessonFinished: boolean;
	quizLessonAnswers: {
		[key: string]: SavedAnswer;
	};
	questionNotes: UserQuestionNote;
	feedback: string;
	feedbackAllowed: boolean;
	discussionBoard: DiscussionBoardProps;
	attemptedCSQuestions: CSQuestions[];
	quizTestAnswersAlreadyAttempted: UserAnswerQuestionIdDto;
	quizTestAnswersAlreadyAttemptedCaseStudy: UserAnswerQuestionIdDto;
	videoLessonProgressLoading: boolean;
	videoLessonProgress: number;
	lastViewedSectionId: number;
	userActivityTracking: IUserActivityTrackingPayload | null;
	selectedLessonAttemptsHistory: UserLessonAttemptHistory;
	loadingSelectedLessonAttemptsHistory: boolean;
}
const initialState: LessonState = {
	interactiveBlocksByQuestionId: {},
	isSelectedLessonProgressFetched: false,
	quizError: "",
	isLoading: false,
	isAttemptLoading: false,
	selectedLessonProgress: {} as UserLessonProgressItem,
	errorMessage: "",
	optionsError: "",
	isNextLoading: false,
	isLessonResultLoading: false,
	quizPassed: false,
	isLessonFinished: false,
	quizPercentage: 0,
	quizLessonAnswers: {},
	quizResult: [],
	questionNotes: { text: "", questionId: 0, id: 0, userId: 0 },
	feedback: "",
	feedbackAllowed: false,
	attemptedCSQuestions: [],
	discussionBoard: {
		comments: [],
		isLoading: false,
		errorMessage: "",
		totalItems: 0,
		order: "MostRelevant",
		page: 1,
		perPage: 10,
		more: false,
		scrollTo: 0
	},
	quizTestAnswersAlreadyAttempted: {},
	quizTestAnswersAlreadyAttemptedCaseStudy: {},
	videoLessonProgressLoading: false,
	videoLessonProgress: 0,
	lastViewedSectionId: 0,
	userActivityTracking: null,
	selectedLessonAttemptsHistory: {
		items: []
	},
	loadingSelectedLessonAttemptsHistory: false
};

const utilsResetState = getResetState<LessonState>(initialState);

// region Lesson & Question Attempts Actions
export const getUserLessonAttempt = createAsyncThunk(
	"lessonAttempt/getUserLessonAttempt",
	async (
		{
			isCourseComplete,
			...filters
		}: { introLessonId?: number; sectionLessonId?: number; inProgress?: boolean; isCourseComplete: boolean },
		{ rejectWithValue }
	) => {
		const r = await userLessonAttemptsService
			.find({
				filters: { ...filters },
				findAll: true
			})
			.catch((e?: Error) => rejectWithValue((e && e.message) || "An error has occurred."));

		return { ...r, isCourseComplete };
	}
);

export const getUserLessonAttemptHistory = createAsyncThunk(
	"lessonAttempt/getUserLessonAttemptHistory",
	async (
		{ page, perPage, sectionLessonId }: { sectionLessonId: number; page: number; perPage: number },
		{ rejectWithValue }
	) => {
		return await userLessonAttemptsService
			.find({
				page,
				perPage,
				filters: { sectionLessonId }
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const createUserLessonAttempt = createAsyncThunk(
	"lessonAttempt/createUserLessonAttempt",
	async (data: UserLessonAttemptCreateDto) => {
		return await userLessonAttemptsService.create({ ...data }).catch(({ message }) => ({ error: true, message }));
	}
);

export const completeUserLessonAttempt = createAsyncThunk(
	"lessonAttempt/completeUserLessonAttempt",
	async ({ id, sideEffect }: { id: number; sideEffect?: () => void }, { rejectWithValue }) => {
		const res = await userLessonAttemptsService
			.update({ data: { inProgress: false }, filters: { id } })
			.catch(rejectWithValue);
		sideEffect && sideEffect();
		return res;
	}
);

export const createUserQuestionAttempt = createAsyncThunk(
	"lessonAttempt/createUserQuestionAttempt",
	async (data: UserQuestionAttemptCreateDto, { rejectWithValue, dispatch }) => {
		dispatch(setHandleNextLoading(true)); // todo: move to extraReducer
		dispatch(setOptionsError("")); // todo: move to extraReducer
		const res = await userQuestionAttemptsService
			.create(data)
			.catch(error => {
				if (error.name == "422") {
					dispatch(setOptionsError(error.message));
				}
				rejectWithValue(error.message);
				data?.caseStudySideEffect && data.caseStudySideEffect(true);
				throw new Error(error);
			})
			.finally(() => {
				dispatch(setHandleNextLoading(false));
			});
		data.sideEffect && data.sideEffect();
		dispatch(setCreatePayload(res)); // todo: move to extraReducer
		data.caseStudySideEffect && data?.caseStudySideEffect();
		return res;
	}
);

export const updateUserQuestionAttempt = createAsyncThunk(
	"lessonAttempt/updateUserQuestionAttempt",
	async (
		data: { questionUserAttemptId: number } & Pick<
			UserQuestionAttemptCreateDto,
			"userAnswers" | "timeSpent" | "caseStudySideEffect" | "sideEffect"
		>,
		{ rejectWithValue, dispatch }
	) => {
		dispatch(setHandleNextLoading(true)); // todo: move to extraReducer
		dispatch(setOptionsError("")); // todo: move to extraReducer
		const res = await userQuestionAttemptsService
			.update({
				data: { userAnswers: data.userAnswers, timeSpent: data.timeSpent },
				filters: { id: data.questionUserAttemptId }
			})
			.catch(error => {
				if (error.name == "422") {
					dispatch(setOptionsError(error.message));
				}
				rejectWithValue(error.message);
				data.caseStudySideEffect && data?.caseStudySideEffect(true);
				throw new Error(error.message);
			})
			.finally(() => {
				dispatch(setHandleNextLoading(false));
			});
		dispatch(setCreatePayload(res!.raw[0])); // todo: move to extraReducer
		data.caseStudySideEffect && data.caseStudySideEffect();
		data.sideEffect && data.sideEffect();
		return res;
	}
);
// endregion

// region Lesson Progress Actions
export const getSelectedLessonProgress = createAsyncThunk(
	"lesson/getSelectedLessonProgress",
	async (sectionLessonId: number, { rejectWithValue }) => {
		return await userLessonProgressItemsService
			.find({ filters: { sectionLessonId }, findAll: true })
			.catch(rejectWithValue);
	}
);

export const setSelectedLessonProgress = createAsyncThunk(
	"lesson/setSelectedLessonProgress",
	async (
		{ sideEffect, ...requestData }: UserLessonProgressItemCreateDto & { sideEffect: () => void },
		{ rejectWithValue }
	) => {
		await userLessonProgressItemsService.create(requestData).catch(rejectWithValue);
		sideEffect && sideEffect();
	}
);

export const updateSelectedLessonProgress = createAsyncThunk(
	"lesson/updateSelectedLessonProgress",
	async (data: UserLessonProgressItemUpdateDto, { rejectWithValue }) => {
		return await userLessonProgressItemsService.update(data).catch(rejectWithValue);
	}
);

export const getLessonVideoProgress = createAsyncThunk(
	"lesson/getLessonVideoProgress",
	async (data: { sectionLessonId: number; userId: number }, { rejectWithValue }) => {
		return await apigClient // todo: wrap up by service
			.invokeApi(
				{},
				GLOBAL_CONSTANTS.AWS_API_GATEWAY_API_PATH,
				"GET",
				{
					queryParams: data
				},
				data
			)
			.catch(rejectWithValue);
	}
);

export const updateLessonVideoProgress = createAsyncThunk(
	"lesson/updateLessonVideoProgress",
	async (data: { userId: number; videoProgress: number; sectionLessonId: number }, { rejectWithValue }) => {
		return await apigClient // todo: wrap up by service
			.invokeApi({}, GLOBAL_CONSTANTS.AWS_API_GATEWAY_API_PATH, "POST", {}, data)
			.catch(rejectWithValue);
	}
);
// endregion

// region Last Viewed Section Actions
export const getLastViewedSectionId = createAsyncThunk(
	"lesson/getLastViewedSectionId",
	async (data: { courseChapterId: number }, { rejectWithValue }) => {
		return await lessonsService.getLastViewedSectionId(data.courseChapterId).catch(e => rejectWithValue(e.message));
	}
);

export const createLastViewedSectionId = createAsyncThunk(
	"lesson/createLastViewedSectionId",
	async (data: { sectionLessonId: number; courseChapterId: number }, { rejectWithValue }) => {
		return await lessonsService.createLastViewedSectionId(data).catch(e => rejectWithValue(e.message));
	}
);
// endregion

// region activity tracking
export const endUserActivityTracking = createAsyncThunk(
	"lesson/endTrackingLessonActivity",
	async (_, { getState, rejectWithValue }) => {
		const { userActivityTracking } = (getState() as RootState).lesson;
		if (!userActivityTracking) {
			return;
		}
		const { startTime, activityType, id } = userActivityTracking;
		await usersService
			.userActivityTracking({
				startTime,
				activityType,
				id,
				endTime: Date.now()
			})
			.catch(rejectWithValue);
	}
);
// endregion

// region Lesson Notes & Feedback Actions
export const getQuestionNotes = createAsyncThunk(
	"lessonAttempt/getQuestionNotes",
	async (id: number, { rejectWithValue, dispatch }) => {
		return await userQuestionNotesService
			.find({ filters: { questionId: id } })
			.then(res => {
				dispatch(setNotesQuestionId(id));
				return res;
			})
			.catch(rejectWithValue);
	}
);

export const setQuestionNotes = createAsyncThunk(
	"lessonAttempt/setQuestionNotes",
	async (data: UserQuestionNoteCreateDto, { rejectWithValue }) => {
		return await userQuestionNotesService.create(data).catch(error => rejectWithValue(error.message));
	}
);

export const updateQuestionNotes = createAsyncThunk(
	"lessonAttempt/updateQuestionNotes",
	async (data: UserQuestionNoteUpdateDto, { rejectWithValue }) => {
		await userQuestionNotesService.update(data).catch(e => rejectWithValue(e.message));
	}
);

export const getFeedback = createAsyncThunk(
	"lessonAttempt/getFeedback",
	async (id: number, { rejectWithValue, dispatch }) => {
		return await userLessonFeedbackItemsService
			.find({ filters: { userLessonAttemptId: id } })
			.then(res => {
				if (!res?.items[0]?.text) {
					dispatch(setFeedbackAllowed(true));
				}
				return res;
			})
			.catch(rejectWithValue);
	}
);

export const setLessonFeedback = createAsyncThunk(
	"lessonAttempt/setLessonFeedback",
	async (data: UserLessonFeedbackItemCreateDto, { rejectWithValue, dispatch }) => {
		return await userLessonFeedbackItemsService
			.create(data)
			.then(res => {
				dispatch(setFeedbackAllowed(false));
				return res;
			})
			.catch(e => rejectWithValue(e.message));
	}
);
// endregion

// region Lesson Comments Actions
export const createLessonComment = createAsyncThunk(
	"lesson-comments/createLessonComment",
	async (data: LessonCommentsItemCreateDto, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.create(data);
			return { res, parentId: data?.parentId };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "Cannot post comment.");
		}
	}
);

export const updateLessonComment = createAsyncThunk(
	"lesson-comments/updateLessonComment",
	async (data: LessonCommentsItemUpdateDto, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.update(data);
			return { res };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "Cannot update comment.");
		}
	}
);

export const deleteLessonComment = createAsyncThunk(
	"lesson-comments/deleteLessonComment",
	async (id: number, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.delete({ filters: { id } });
			return { res };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const getLessonComments = createAsyncThunk(
	"lesson-comments/getLessonComments",
	async (
		{ lessonId, order, page: optPage, viewMore }: { page?: number; lessonId: number; viewMore: boolean; order: string },
		{ getState, rejectWithValue }
	) => {
		try {
			const { discussionBoard } = (getState() as RootState).lesson as LessonState;
			const res = await lessonCommentsService.find({
				...(viewMore && { page: optPage || discussionBoard.page }),
				filters: { lessonId, isTopLevel: true },
				include: ["user", "user.badges", "user.assignedUserTypes", "replies.votes", "votes.user", "replies.user"],
				orderBy: {
					...(order === "MostRecent" && { createdAt: "DESC" }),
					...(order === "MostRelevant" && { totalLike: "DESC", createdAt: "DESC" })
				}
			});
			return { res, viewMore };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const lessonCommentsVoting = createAsyncThunk(
	"lesson-comments/lessonCommentsVoting",
	async ({ commentId, isLike }: { commentId?: number; isLike: boolean }, { rejectWithValue }) => {
		try {
			const res = await lessonCommentsService.commentVote({ commentId, isLike });
			return { ...res };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const deleteCommentVote = createAsyncThunk(
	"lesson-comment/deleteVote",
	async (
		{
			commentId,
			isLike,
			isTopLevel,
			voteId
		}: { commentId: number; voteId?: number; isLike: boolean | undefined; isTopLevel: boolean },
		{ rejectWithValue }
	) => {
		try {
			await lessonCommentsService.deleteCommentVote({ filters: { id: voteId } });
			return { commentId, voteId, isLike, isTopLevel };
		} catch (error) {
			return rejectWithValue((error && (error as { message: string }).message) || "An error has occurred.");
		}
	}
);

export const pinComment = createAsyncThunk(
	"lesson-comment/pinComment",
	async (
		{ commentId, isPinned, cb }: { commentId: number; isPinned: boolean; cb: () => void },
		{ rejectWithValue }
	) => {
		await lessonCommentsService.update({ filters: { id: commentId }, data: { isPinned } }).catch(rejectWithValue);
		cb();
	}
);
// endregion

export const lessonSlice = createSlice({
	name: "lesson",
	initialState,
	reducers: {
		clearLessonState: utilsResetState,
		retakeTest: state => {
			state.quizPassed = false;
			state.quizPercentage = 0;
			state.quizLessonAnswers = {};
			state.quizResult = [];
			state.quizLessonAttemptId = undefined;
			state.quizLessonAttempt = undefined;
			state.hasPracticeAttempt = undefined;
			state.isLessonFinished = false;
			state.feedback = "";
			state.feedbackAllowed = false;
			state.attemptedCSQuestions = [];
			state.optionsError = "";
			state.isNextLoading = false;
			state.quizTestAnswersAlreadyAttempted = [];
			state.quizTestAnswersAlreadyAttemptedCaseStudy = [];
		},
		setNotesQuestionId: (state, { payload }) => {
			state.questionNotes.questionId = payload;
		},
		setCommentsOrder: (state, { payload }) => {
			state.discussionBoard.order = payload;
			state.discussionBoard.page = 1;
		},
		setFeedbackAllowed: (state, { payload }) => {
			state.feedbackAllowed = payload;
		},
		resetLessonComments: state => {
			state.discussionBoard.comments = [];
		},
		moveGroupQuestionAnswerOption: (
			state,
			{
				payload: { id, newGroupIndex, newOptionIndex, oldGroupIndex, oldOptionIndex, questionIndex, touchType }
			}: PayloadAction<{
				questionIndex: number;
				newGroupIndex: number;
				oldGroupIndex: number;
				oldOptionIndex: number;
				id: string;
				newOptionIndex: number;
				touchType?: string;
			}>
		) => {
			if (newGroupIndex === oldGroupIndex && touchType === "dragger") {
				const stateQuizLessonQuestions = state?.quizLessonQuestions;
				state.quizLessonQuestions = stateQuizLessonQuestions?.map((question, i) => {
					if (i === questionIndex) {
						const groups = question?.data.groups as QuestionGroup[];
						const splicedOption = reorder(oldOptionIndex, newOptionIndex, groups[oldGroupIndex]?.answerOptions);
						return {
							...question,
							data: {
								groups: groups?.map((x, i) => {
									if (i === oldGroupIndex) {
										return { ...x, answerOptions: splicedOption as QuestionAnswerOption[] };
									} else return { ...x };
								})
							}
						};
					} else return question;
				});
			}
			if (newGroupIndex !== oldGroupIndex && touchType === "reorder") {
				const stateQuizLessonQuestions = state?.quizLessonQuestions;
				state.quizLessonQuestions = stateQuizLessonQuestions?.map((question, i) => {
					if (i === questionIndex) {
						const groups = question?.data.groups as QuestionGroup[];
						const removed = groups[oldGroupIndex]?.answerOptions[oldOptionIndex];
						return {
							...question,
							data: {
								groups: groups?.map((x: QuestionGroup, i) => {
									if (i === oldGroupIndex) {
										return { ...x, answerOptions: x?.answerOptions?.filter(x => x.id !== id) };
									} else if (i === newGroupIndex) {
										const opts = x?.answerOptions;
										opts?.splice(newOptionIndex, 0, removed);
										return {
											...x,
											answerOptions: opts
										};
									} else return { ...x };
								})
							}
						};
					} else return question;
				});
			}
		},

		clearNotes: state => {
			state.questionNotes = { text: "", questionId: 0, id: 0, userId: 0 };
		},
		mockUpdateLessonComment: (state, { payload: { commentId, parentId, text } }) => {
			if (parentId) {
				const updatedReplyParent = state.discussionBoard.comments.find(({ id }) => id === parentId);
				if (updatedReplyParent) {
					const updatedReply = updatedReplyParent.replies.find(({ id }) => id === commentId);
					updatedReply!.text = text;
				}
			} else {
				const updatedComment = state.discussionBoard.comments.find(({ id }) => id === commentId);
				if (updatedComment) {
					updatedComment.text = text;
				}
			}
		},
		clearLessonProgress: state => {
			state.selectedLessonProgress = {} as UserLessonProgressItem;
		},
		completeCaseStudyQuestion: (state, action: PayloadAction<{ questionId: number }>) => {
			state.quizLessonAnswers![action.payload.questionId] = {
				id: action.payload.questionId,
				answers: [],
				timeSpent: 0
			};
		},
		setCSQuestions: (state, action) => {
			state.attemptedCSQuestions.push({
				questionIndex: action.payload.questionIndex,
				id: action.payload.id
			});
			state.attemptedCSQuestions = [...new Map(state.attemptedCSQuestions.map(item => [item["id"], item])).values()];
		},
		setCreatePayload: (state, action) => {
			const attempt = action.payload;
			state.quizLessonAnswers[attempt.subQuestionId || attempt.questionId] = {
				id: attempt.id,
				answers: attempt.selectedAnswers!.map(
					({ questionAnswerOptionId, questionGroupId, text, questionAnswerOptionOrder, questionId }) =>
						({
							text,
							order: questionAnswerOptionOrder,
							groupId: questionGroupId,
							id: questionAnswerOptionId,
							questionId,
							canAttachFiles: false
						} as QuestionAnswerOption)
				),
				timeSpent: attempt.timeSpent
			};
		},
		setOptionsError: (state, action) => {
			state.optionsError = action.payload;
		},
		setHandleNextLoading: (state, action) => {
			state.isNextLoading = action.payload;
		},
		setIsLessonFinished: (state, action) => {
			state.isLessonFinished = action.payload;
		},
		startUserActivityTracking: (state, { payload: { id, activityType } }) => {
			state.userActivityTracking = { startTime: Date.now(), id, activityType, endTime: 0 };
		}
	},
	extraReducers: {
		[getUserLessonAttempt.pending.type]: state => {
			state.isLoading = true;
			state.isAttemptLoading = true;
			state.interactiveBlocksByQuestionId = {};
			state.quizLessonQuestions = [];
			state.quizLessonAttemptId = undefined;
			state.quizLessonAttempt = undefined;
			state.isLessonFinished = false;
			state.quizPassed = false;
			state.quizPercentage = 0;
			state.quizLessonAnswers = {};
		},
		[createUserLessonAttempt.fulfilled.type]: (state, { payload }: PayloadAction<UserLessonAttempt>) => {
			if (payload?.error) {
				state.quizError = (payload?.message as string) ?? "Unknown error";
			} else {
				state.quizError = "";
				state.quizLessonQuestions = payload?.snapshot.lesson.questions.map(mapQuestions);
				state.quizLessonAnswers = {};
				state.quizLessonAttemptId = payload?.id;
				state.quizLessonAttempt = payload;
				state.isLessonFinished = false;
				state.interactiveBlocksByQuestionId = payload?.snapshot.lesson.interactiveBlocksByQuestionId;
				state.isLoading = false;
			}
		},
		[completeUserLessonAttempt.pending.type]: state => {
			state.isLessonResultLoading = true;
		},
		[completeUserLessonAttempt.rejected.type]: state => {
			state.isLessonResultLoading = false;
		},
		[completeUserLessonAttempt.fulfilled.type]: (
			state,
			{ payload: { raw } }: PayloadAction<{ raw: UserLessonAttempt[] }>
		) => {
			const { passed, percentageGrade, userQuestionAttempts, inProgress } = raw[0];

			state.isLessonFinished = !inProgress;
			state.quizPassed = passed;
			state.quizPercentage = Math.round(percentageGrade * 100);
			state.quizResult = userQuestionAttempts;
			state.isLessonResultLoading = false;
		},
		[getUserLessonAttempt.rejected.type]: (state, { payload: { message } }: PayloadAction<Error>) => {
			state.quizError = message;
			state.isLoading = false;
		},
		[getUserLessonAttempt.fulfilled.type]: (
			state,
			{ payload }: PayloadAction<{ items: UserLessonAttempt[]; isCourseComplete: boolean }>
		) => {
			if (!payload.items) {
				return;
			}
			const userLessonAttempts = payload?.items.sort((a, b) => a.createdAt!.localeCompare(b.createdAt!));
			const bestQuizAttempt =
				userLessonAttempts?.length > 0
					? userLessonAttempts.reduce((prev, current) =>
							prev.percentageGrade > current.percentageGrade ? prev : current
					  )
					: undefined;
			// hack: if course is completed and last attempt is taken less 10 seconds ago - show last attempt otherwise best attempt.
			const lastAttempt = last(userLessonAttempts);
			const lastAttemptDateDiffToNowSeconds = lastAttempt
				? differenceInSeconds(Date.now(), parseISO(lastAttempt.createdAt!))
				: 0;
			const showBestAttempt = payload.isCourseComplete && lastAttemptDateDiffToNowSeconds > 10;
			const attempt = userLessonAttempts.find(a => a.inProgress) ?? (showBestAttempt ? bestQuizAttempt : lastAttempt);
			if (attempt && !(attempt.snapshot.lesson.typeId === LessonTypes.Interactive && !attempt.inProgress)) {
				state.isLessonFinished = !attempt.inProgress;
				state.interactiveBlocksByQuestionId = attempt?.snapshot.lesson.interactiveBlocksByQuestionId;
				state.quizLessonQuestions = attempt?.snapshot.lesson.questions.map(mapQuestions);
				state.quizLessonAttemptId = attempt?.id;
				state.quizLessonAttempt = attempt;
				state.quizPassed = attempt?.passed ?? false;
				state.quizPercentage = Math.round((attempt?.percentageGrade ?? 0) * 100);
				state.quizResult = attempt.userQuestionAttempts;

				state.quizLessonAnswers = fromPairs(
					attempt?.userQuestionAttempts.map(
						({ id, questionId, subQuestionId, selectedAnswers, timeSpent }: UserQuestionAttempt) => [
							subQuestionId ?? questionId,
							{
								id: id,
								answers: selectedAnswers!.map(
									({ questionAnswerOptionId, questionGroupId, text, questionAnswerOptionOrder, questionId }) =>
										({
											order: questionAnswerOptionOrder,
											text,
											groupId: questionGroupId,
											id: questionAnswerOptionId,
											questionId,
											canAttachFiles: false
										} as QuestionAnswerOption)
								),
								timeSpent: timeSpent
							}
						]
					)
				);

				const mapF = (userQuestionAnswerAttempt: UserQuestionAnswerAttempt): UserQuestionAnswerDto =>
					({
						id: userQuestionAnswerAttempt.questionAnswerOptionId,
						text: userQuestionAnswerAttempt.text,
						groupId: userQuestionAnswerAttempt.questionGroupId,
						order: userQuestionAnswerAttempt.questionAnswerOptionOrder
					} as UserQuestionAnswerDto);
				attempt.userQuestionAttempts.forEach(qAttempt => {
					!qAttempt.subQuestionId &&
						(state.quizTestAnswersAlreadyAttempted[qAttempt.questionId] = qAttempt.selectedAnswers!.map(mapF));
					qAttempt.subQuestionId &&
						(state.quizTestAnswersAlreadyAttemptedCaseStudy[qAttempt.subQuestionId] =
							qAttempt.selectedAnswers!.map(mapF));
				});
			}

			state.hasPracticeAttempt = userLessonAttempts?.some(({ practiceAttempt }) => practiceAttempt);
			state.isAttemptLoading = false;
			state.isLoading = false;
			state.isSelectedLessonProgressFetched = true;
		},
		[getSelectedLessonProgress.pending.type]: pendingReducer,
		[getSelectedLessonProgress.fulfilled.type]: (
			state,
			{ payload: { items } }: PayloadAction<{ items: UserLessonProgressItem[] }>
		) => {
			state.selectedLessonProgress = items.length === 0 ? ({} as UserLessonProgressItem) : items[0];
			state.isLoading = false;
			state.isSelectedLessonProgressFetched = true;
		},
		[getSelectedLessonProgress.rejected.type]: (state, { payload: { message } }: PayloadAction<Error>) => {
			state.errorMessage = message;
			state.isLoading = false;
		},

		[lessonCommentsVoting.fulfilled.type]: (
			state,
			{ payload: { id, isTopLevel, totalDislike, totalLike, votes } }: PayloadAction<LessonComment>
		) => {
			state.discussionBoard.comments.forEach((element: LessonComment) => {
				if (isTopLevel) {
					if (element.id === id) {
						element.totalDislike = totalDislike;
						element.totalLike = totalLike;
						element.votes = votes;
					}
				} else {
					const replyIndex = element.replies.findIndex(x => x.id === id);
					if (element.replies.length && replyIndex > -1) {
						element.replies[replyIndex].totalDislike = totalDislike;
						element.replies[replyIndex].totalLike = totalLike;
						element.replies[replyIndex].votes = votes;
					}
				}
			});
		},

		[deleteCommentVote.fulfilled.type]: (
			state,
			{
				payload: { commentId, isLike, isTopLevel, voteId }
			}: PayloadAction<{ commentId: number; voteId?: number; isLike: boolean; isTopLevel: number }>
		) => {
			state.discussionBoard.comments.forEach((element: LessonComment) => {
				if (isTopLevel) {
					if (element.id === commentId) {
						if (isLike) element.totalLike = element.totalLike - 1;
						if (!isLike) element.totalDislike = element.totalDislike - 1;
						element.votes = element.votes.filter(({ id }) => id !== voteId);
					}
				} else {
					const replyIndex = element.replies.findIndex(({ id }) => id === commentId);
					if (element.replies.length && replyIndex > -1) {
						if (isLike) element.replies[replyIndex].totalLike = element.replies[replyIndex].totalLike - 1;
						if (!isLike) element.replies[replyIndex].totalDislike = element.replies[replyIndex].totalDislike - 1;
						element.replies[replyIndex].votes = element.replies[replyIndex].votes.filter(x => x.id !== voteId);
					}
				}
			});
		},

		[setQuestionNotes.fulfilled.type]: (state, { payload: { id, text } }: PayloadAction<UserQuestionNote>) => {
			state.isLoading = false;
			state.questionNotes.text = text || "";
			state.questionNotes.id = id || (null as unknown as number);
		},
		[getQuestionNotes.pending.type]: pendingReducer,
		[getQuestionNotes.fulfilled.type]: (
			state,
			{ payload: { items } }: PayloadAction<{ items: UserQuestionNote[] }>
		) => {
			state.isLoading = false;
			state.questionNotes.text = items[0]?.text || "";
			state.questionNotes.id = items[0]?.id || (null as unknown as number);
		},
		[getQuestionNotes.rejected.type]: (state, { payload: { message } }: PayloadAction<Error>) => {
			state.errorMessage = message;
			state.isLoading = false;
		},
		[getFeedback.pending.type]: pendingReducer,
		[getFeedback.fulfilled.type]: (
			state,
			{ payload: { items } }: PayloadAction<{ items: UserLessonFeedbackItem[] }>
		) => {
			state.isLoading = false;
			state.feedback = items[0]?.text || "";
		},
		[getFeedback.rejected.type]: (state, { payload: { message } }: PayloadAction<Error>) => {
			state.errorMessage = message;
			state.isLoading = false;
		},
		[getLessonComments.pending.type]: state => {
			state.discussionBoard.isLoading = true;
		},
		[deleteLessonComment.fulfilled.type]: (
			state,
			{
				payload: { res }
			}: PayloadAction<{
				res: { raw: LessonComment[] };
			}>
		) => {
			const { parentId } = res.raw[0];
			if (!parentId) {
				state.discussionBoard.totalItems--;
			}
		},
		[getLessonComments.fulfilled.type]: (
			state,
			{
				payload: {
					res: { items, more, page, totalItems },
					viewMore
				}
			}: PayloadAction<{
				res: { items: LessonComment[]; totalItems: number; page: number; more: boolean };
				viewMore: boolean;
			}>
		) => {
			if (viewMore) {
				const prevCommentsLength = state.discussionBoard.comments.length;
				state.discussionBoard.comments.push(...items);
				state.discussionBoard.page = page;
				state.discussionBoard.scrollTo = prevCommentsLength;
			} else {
				state.discussionBoard.comments = items;
				state.discussionBoard.scrollTo = 0;
			}
			state.discussionBoard.more = more;
			state.discussionBoard.totalItems = totalItems;
			state.discussionBoard.isLoading = false;
		},
		[getLessonComments.rejected.type]: (state, { payload }: PayloadAction<string>) => {
			state.discussionBoard.errorMessage = payload;
			state.discussionBoard.isLoading = false;
		},
		[createLessonComment.fulfilled.type]: (state, { payload }) => {
			if (payload.parentId) {
				state.discussionBoard.comments?.find(comment => comment.id === payload.parentId)?.replies?.unshift(payload.res);
			} else {
				state.discussionBoard.comments.unshift(payload.res);
				state.discussionBoard.totalItems++;
			}
		},
		[createLessonComment.rejected.type]: (state, { payload }: PayloadAction<string>) => {
			state.discussionBoard.errorMessage = payload;
		},
		[updateLessonComment.fulfilled.type]: (
			state,
			{
				payload: {
					res: { raw }
				}
			}
		) => {
			if (raw[0].parentId) {
				const updatedReplyParent = state.discussionBoard.comments.find(({ id }) => id === raw[0].parentId);
				if (updatedReplyParent) {
					const updatedReply = updatedReplyParent.replies.find(({ id }) => id === raw[0].id);
					updatedReply!.text = raw[0].text;
				}
			} else {
				const updatedComment = state.discussionBoard.comments.find(({ id }) => id === raw[0].id);
				if (updatedComment) {
					updatedComment.text = raw[0].text;
				}
			}
		},
		[updateLessonComment.rejected.type]: (state, { payload }: PayloadAction<string>) => {
			state.discussionBoard.errorMessage = payload;
		},
		[getLessonVideoProgress.pending.type]: state => {
			state.videoLessonProgressLoading = true;
		},
		[getLessonVideoProgress.fulfilled.type]: (state, action) => {
			state.videoLessonProgressLoading = false;
			state.videoLessonProgress = action.payload.data.videoProgress;
		},
		[getLessonVideoProgress.rejected.type]: state => {
			state.videoLessonProgressLoading = false;
		},
		[getLastViewedSectionId.fulfilled.type]: (state, { payload }) => {
			state.lastViewedSectionId = payload.sectionLessonId;
		},
		[getLastViewedSectionId.rejected.type]: state => {
			state.lastViewedSectionId = 0;
		},
		[endUserActivityTracking.fulfilled.type]: state => {
			state.userActivityTracking = null;
		},
		[getUserLessonAttemptHistory.fulfilled.type]: (state, { payload }) => {
			state.selectedLessonAttemptsHistory = payload;
			state.loadingSelectedLessonAttemptsHistory = false;
		},
		[getUserLessonAttemptHistory.pending.type]: state => {
			state.loadingSelectedLessonAttemptsHistory = true;
		},
		[getUserLessonAttemptHistory.rejected.type]: state => {
			state.loadingSelectedLessonAttemptsHistory = false;
		}
	}
});
export const {
	clearLessonState,
	retakeTest,
	setNotesQuestionId,
	setCommentsOrder,
	clearNotes,
	setFeedbackAllowed,
	resetLessonComments,
	moveGroupQuestionAnswerOption,
	mockUpdateLessonComment,
	clearLessonProgress,
	completeCaseStudyQuestion,
	setCSQuestions,
	setCreatePayload,
	setOptionsError,
	setHandleNextLoading,
	setIsLessonFinished,
	startUserActivityTracking
} = lessonSlice.actions;

// region Selectors
export const getFullDiscussionBoardState = ({ lesson }: RootState): DiscussionBoardProps => lesson.discussionBoard;
export const selectedLessonProgress = ({ lesson }: RootState): UserLessonProgressItem => lesson.selectedLessonProgress;
export const lessonIsLoading = ({ lesson }: RootState): boolean => lesson.isLoading;
export const isLessonProgressFetched = ({ lesson }: RootState): boolean => lesson.isSelectedLessonProgressFetched;

export const getInteractiveBlocksByQuestionId = ({
	lesson
}: RootState): { [questionId: number]: InteractiveBlockMap } => lesson.interactiveBlocksByQuestionId;
export const getQuizLessonQuestions = ({ lesson }: RootState): Question[] => lesson.quizLessonQuestions ?? [];
export const getQuizLessonAttemptId = ({ lesson }: RootState): number => lesson.quizLessonAttemptId ?? 0;
export const getQuizQuestionAnswers = ({ lesson }: RootState): { [key: string]: SavedAnswer } | undefined =>
	lesson.quizLessonAnswers;

export const getQuizError = ({ lesson }: RootState): string => lesson.quizError ?? "";

export const getIsLessonFinished = ({ lesson }: RootState): boolean => lesson.isLessonFinished;
export const selectQuestionNotes = ({ lesson }: RootState): UserQuestionNote => lesson.questionNotes;
export const getAttemptedCSQuestions = (state: RootState): CSQuestions[] => state.lesson.attemptedCSQuestions ?? [];

export const getquizTestAnswersAlreadyAttemptedCaseStudy = ({ lesson }: RootState): UserAnswerQuestionIdDto =>
	lesson.quizTestAnswersAlreadyAttemptedCaseStudy;

export const getFullState = ({ lesson }: RootState): LessonState => lesson;
//endregion

export default lessonSlice.reducer;
