import React from "react";

import { Box, Button } from "@material-ui/core";

import { BlurBox, LockScreenWrapper, TitleText, UpgradeTopBannerText } from "./syles";

import LockSvg from "../../assets/icons/icon-lock.svg";

interface Props {
	onButtonClick: () => void;
}

const LockScreenBanner = ({ onButtonClick }: Props) => (
	<>
		<LockScreenWrapper>
			<Box>
				<img src={LockSvg} alt="lock icon" />
				<TitleText>Upgrade to Unlock</TitleText>
				<UpgradeTopBannerText>
					You are currently in free trial mode. Please upgrade to preview all questions.
				</UpgradeTopBannerText>
				<Button variant="contained" color="primary" onClick={onButtonClick}>
					Upgrade Now
				</Button>
			</Box>
		</LockScreenWrapper>
		<BlurBox>&nbsp;</BlurBox>
	</>
);

export default LockScreenBanner;
