import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import { ReactComponent as FolderSvg } from "@remar/shared/dist/assets/icons/icon-folder.svg";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import {
	ColumnHeader,
	InvoiceDownloadIcon,
	StyledCellMultipleText,
	StyledCellText,
	StyledCellWrapper
} from "@remar/shared/dist/components/Table/styles";
import WithTableContentLayout, { EmptyState } from "@remar/shared/dist/layouts/TableContentLayout";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils/helpers";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectCurrentlyAllowedFullCourses } from "store/features/Auth/authSlice";

import { fetchFiles, getFullState } from "store/features/FileVault/fileVault";

import { routes } from "core/constants";

import { SvgIcon } from "../styles";

let analytics = ({ eventName, eventIdentifier }) => console.log(eventName, eventIdentifier); // todo: do we need it??

const tableColumns: Array<IColumn<unknown>> = [
	{
		alignment: "left",
		label: <ColumnHeader>Lesson Name</ColumnHeader>,
		Cell: ({ rowData: { lessons } }) => {
			const lessonNames = lessons?.length > 0 ? lessons?.map(lesson => lesson.name).join(", ") : "N/A";
			return (
				<Box>
					<StyledCellMultipleText title={lessonNames}>{lessonNames}</StyledCellMultipleText>
				</Box>
			);
		},
		dataKey: "name"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Date</ColumnHeader>,
		Cell: ({ rowData: { createdAt } }) => (
			<StyledCellWrapper>
				<StyledCellText>{formatDate(createdAt)}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "date"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Files</ColumnHeader>,
		Cell: ({ rowData: { name } }) => (
			<StyledCellWrapper>
				<StyledCellText>{name || "N/A"}</StyledCellText>
			</StyledCellWrapper>
		),
		dataKey: "files"
	},
	{
		alignment: "left",
		label: <ColumnHeader>Download Files</ColumnHeader>,
		Cell: ({ rowData: { fileUrl, name } }) => (
			<StyledCellWrapper>
				<InvoiceDownloadIcon>
					<DownloadIconSvg
						onClick={() => {
							analytics({
								eventName: "downloaded_file",
								eventIdentifier: `${name}`
							});
							fileUrl && downloadURI(fileUrl, name);
						}}
					/>
				</InvoiceDownloadIcon>
			</StyledCellWrapper>
		),
		dataKey: "downloadFiles"
	}
];

const FileVaultSubFoldersFiles = () => {
	const dispatch = useAppDispatch();
	const { subFolderId } = useParams<{ subFolderId: string }>();
	analytics = useAnalyticsEventTracker("File Vault");

	const {
		isLoading,
		selectedFolder: parentFolder,
		selectedSubFolder: currentFolder,
		files,
		page,
		perPage,
		totalItems,
		totalCount
	} = useAppSelector(getFullState);

	const currentlyAllowedFullCourses = useAppSelector(selectCurrentlyAllowedFullCourses);

	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		if (!isLoading && currentlyAllowedFullCourses && currentlyAllowedFullCourses.length) {
			dispatch(
				fetchFiles({ courseId: currentlyAllowedFullCourses[0].id, folderId: +subFolderId, name: inputValue, page: 1 })
			);
		}
	}, [dispatch, subFolderId, currentlyAllowedFullCourses]);

	const handleSearchBarChange = useCallback(
		(searchText: string, courseId: number, subFolderId: number) => {
			return dispatch(fetchFiles({ courseId, folderId: subFolderId, name: searchText, page }));
		},
		[dispatch]
	);

	const breadcrumbs = useMemo(() => {
		const crumbs = [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Course Resources", key: 1, link: routes.fileVault.getPath() }
		];

		if (parentFolder) {
			crumbs.push({ title: parentFolder.name, key: 2, link: `${routes.fileVault.getPath()}/${parentFolder.id}` });
		}

		if (currentFolder && parentFolder) {
			crumbs.push({
				title: currentFolder.name,
				key: 2,
				link: `${routes.fileVault.getPath()}/${parentFolder.id}/subFolders/${currentFolder.id}/files`
			});
		}

		return crumbs;
	}, [currentFolder, parentFolder]);

	return (
		<WithTableContentLayout
			heading="Course Resources"
			breadcrumb={breadcrumbs}
			isLoading={isLoading}
			emptyState={
				<EmptyState
					description="There are no files yet"
					placeHolder={
						<SvgIcon>
							<FolderSvg />
						</SvgIcon>
					}
				/>
			}
			onChangePage={page =>
				dispatch(
					fetchFiles({
						courseId: currentlyAllowedFullCourses![0].id,
						folderId: +subFolderId,
						name: inputValue,
						page
					})
				)
			}
			tableTitle="FileVault"
			totalItems={totalItems}
			totalEntities={totalCount}
			perPage={perPage}
			page={page}
			searchText={inputValue}
			setSearchText={setInputValue}
			handleSearchBarChange={value => {
				const currentCourseId = currentlyAllowedFullCourses![0]?.id;
				if (currentCourseId) {
					handleSearchBarChange(value, currentCourseId, +subFolderId);
				}
			}}
			tableColumns={tableColumns}
			data={files}
		/>
	);
};

export default FileVaultSubFoldersFiles;
