import React from "react";

export const FeedbackSent = ({ width, height }) => (
	<svg width={width} height={height} viewBox="0 0 104 104" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fillRule="evenodd">
			<path d="M20 0h64v64H20z" />
			<path
				d="M72.28 13.396 43.12 43.05 32.406 32.153a1.315 1.315 0 0 0-1.881 0l-3.135 3.189a1.37 1.37 0 0 0 0 1.913l14.79 15.04c.52.529 1.361.529 1.88 0l33.236-33.797a1.37 1.37 0 0 0 0-1.913l-3.135-3.189a1.315 1.315 0 0 0-1.882 0z"
				fill="#23B34A"
			/>
			<text opacity=".866" fontFamily="OpenSans-SemiBold, Open Sans" fontSize="15" fontWeight="500" fill="#23B34A">
				<tspan x="0" y="96">
					Feedback sent
				</tspan>
			</text>
		</g>
	</svg>
);
