import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Delete, EditSharp } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { IBreadCrumb } from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";

import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { format } from "date-fns";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { useAppDispatch, useAppSelector } from "store";
import {
	createNote,
	deleteNote as deleteNoteAction,
	fetchNotes,
	getFullState,
	initialState,
	populateInputs,
	setStateValue,
	validateForm
} from "store/features/Note/Notes.slice";
import { NoteRawData } from "store/features/Note/models";

import { _emit } from "store/features/notifications/notifications.slice";

import {
	ButtonsRow,
	ColumnHeader,
	DeleteConfirmContent,
	DeleteModalTitle,
	FieldLabel,
	FormModal,
	NoteTextContainer,
	StyledDateTitleCellText,
	StyledIllustration,
	CustomInput as StyledInput,
	StyledTimeTitleCellText,
	useStyles
} from "./styles";

const breadCrumbInitState: IBreadCrumb[] = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "My Notes", key: 0 }
];

const Notes = () => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useAppDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const [showModal, setShowModal] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteableNoteId, setDeleteableNoteId] = useState<number | null>(null);
	const [searchText, setSearchText] = useState("");
	const [breadCrumb, setBreadCrumb] = useState<IBreadCrumb[]>(breadCrumbInitState);
	const { noteForm, isLoading, notes, perPage, page, totalItems, totalCount } = useAppSelector(getFullState);
	const analytics = useAnalyticsEventTracker("Question Bank");

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };

	useEffect(() => {
		dispatch(fetchNotes({}));
	}, [dispatch]);

	const editNoteHandle = useCallback(
		(name, id, text) => {
			setShowModal(true);
			dispatch(setStateValue({ key: "noteForm.valid", value: false }));
			dispatch(
				populateInputs({
					inputsStatePath: "noteForm.inputs",
					templatesStatePath: "noteForm.templates",
					values: { name, id, text }
				})
			);
		},
		[dispatch]
	);

	const clearNoteForm = useCallback(() => {
		dispatch(setStateValue({ key: "noteForm", value: initialState.noteForm }));
		setShowModal(false);
	}, [dispatch]);

	const initNote = () => {
		setShowModal(true);
		dispatch(setStateValue({ key: "noteForm.valid", value: false }));
	};

	useEffect(() => {
		const doesBreadCrumbExist = breadCrumb.some(({ key }) => key === 1);
		if (showModal) {
			if (!doesBreadCrumbExist) {
				setBreadCrumb([
					{ title: "Dashboard", key: 0, link: "/" },
					{
						title: "My Notes",
						key: 1,
						link: "/notes",
						onClick: () => clearNoteForm()
					},
					{
						title: noteForm?.inputs?.id?.value ? "Edit Note" : "Add New Note",
						key: 2
					}
				]);
			}
		} else if (doesBreadCrumbExist) {
			setBreadCrumb(breadCrumbInitState);
		}
	}, [breadCrumb, clearNoteForm, dispatch, noteForm?.inputs?.id?.value, showModal]);

	const handleDelete = (id: number) => {
		setDeleteableNoteId(id);
		setShowDeleteConfirm(true);
	};

	const handleSearchBarChange = useCallback(searchText => dispatch(fetchNotes({ searchText })), [dispatch]);

	const tableColumns: Array<IColumn<NoteRawData>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Note Name</ColumnHeader>,
				Cell: ({ rowData: { name } }) => (
					<Box display="flex" flexDirection="column">
						<StyledDateTitleCellText>{name}</StyledDateTitleCellText>
					</Box>
				),
				dataKey: "name"
			},
			{
				alignment: "right",
				label: <ColumnHeader>Date & Time</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => {
					const date = new Date(createdAt as string);
					return (
						<Box display="flex" flexDirection="column">
							<StyledDateTitleCellText>{format(date, "dd LLLL yyyy")}</StyledDateTitleCellText>
							<StyledTimeTitleCellText>{format(date, "hh:mm aa")}</StyledTimeTitleCellText>
						</Box>
					);
				},
				dataKey: "createdAt"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Options</ColumnHeader>,
				Cell: ({ rowData: { name, text, id } }) => (
					<Box className={classes.optionsIconsGap} display="flex" justifyContent="center">
						<EditSharp onClick={() => editNoteHandle(name, id, text)} />
						<Delete onClick={() => handleDelete(id)} />
					</Box>
				),
				dataKey: "menu"
			}
		],
		[classes.optionsIconsGap, editNoteHandle]
	);

	return (
		<>
			<WithTableContentLayout
				heading={"My Notes"}
				breadcrumb={breadCrumb}
				customActionStyle={{
					width: isMobile && showModal ? "100%" : "50%",
					display: "flex",
					justifyContent: "flex-end"
				}}
				actions={
					<Box display="flex" justifyContent="space-between" gridGap={8}>
						{showModal && (
							<Button variant="filled" color="secondary" onClick={clearNoteForm}>
								Cancel
							</Button>
						)}
						<Button
							variant="filled"
							color="primary"
							onClick={() => {
								if (!showModal) {
									initNote();
								} else {
									analytics({ eventName: "start_creating_note" });
									dispatch(
										createNote({
											sideEffect: () => {
												clearNoteForm();
												analytics({ eventName: "finish_creating_note" });
											}
										})
									);
								}
							}}
							disabled={isLoading || (showModal && !noteForm?.valid)}
						>
							{noteForm?.inputs?.id?.value ? "Save Changes" : "Create New Note"}
						</Button>
					</Box>
				}
				hideTable={showModal}
				isLoading={isLoading}
				tableTitle="My Notes"
				emptyState={
					<EmptyState
						description="No notes yet"
						placeHolder={<StyledIllustration />}
						buttonTitle="Create New Note"
						onButtonClick={() => {
							initNote();
						}}
					/>
				}
				onChangePage={page => dispatch(fetchNotes({ page }))}
				totalItems={totalItems}
				totalEntities={totalCount}
				perPage={perPage}
				page={page}
				searchText={searchText}
				setSearchText={setSearchText}
				handleSearchBarChange={handleSearchBarChange}
				tableColumns={tableColumns}
				data={notes}
			>
				{showModal && (
					<>
						<FieldLabel>
							Note Name
							<StyledInput
								width={150}
								mr={2}
								options={{ ...defaultCustomInputOptions, inputData: noteForm.inputs.name }}
							/>
						</FieldLabel>
						<Box mt={4} mr={isMobile ? 0 : 3}>
							<StyledInput
								width={150}
								mr={2}
								options={{ ...defaultCustomInputOptions, inputData: noteForm.inputs.text }}
							/>
						</Box>
					</>
				)}
			</WithTableContentLayout>
			<FormModal
				open={showDeleteConfirm}
				onClose={() => setShowDeleteConfirm(false)}
				disableEnforceFocus
				disableAutoFocus
			>
				<DeleteConfirmContent>
					<DeleteModalTitle variant="h3">Delete Note</DeleteModalTitle>
					<NoteTextContainer>
						<div>Are you sure you want to delete this note?</div>
					</NoteTextContainer>
					<ButtonsRow>
						<Button variant="outlined" color="basic" onClick={() => setShowDeleteConfirm(false)}>
							No, Cancel
						</Button>
						<Button
							variant={"filled"}
							color={"danger"}
							onClick={() => {
								setShowDeleteConfirm(false);
								dispatch(deleteNoteAction(deleteableNoteId as number));
							}}
						>
							Yes, Delete
						</Button>
					</ButtonsRow>
				</DeleteConfirmContent>
			</FormModal>
		</>
	);
};

export default Notes;
