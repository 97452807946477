import React from "react";

import { AvatarProps as MuiAvatarProps } from "@material-ui/core";

import { Avatar as CustomAvatar } from "./styles";

type AvatarProps = MuiAvatarProps & {
	fullName?: string;
	profileImageUrl?: string;
};
export const Avatar = ({ fullName, profileImageUrl, ...rest }: AvatarProps) => {
	const title =
		fullName
			?.split(" ")
			.filter(x => x)
			.map(i => i[0].toUpperCase())
			.join("") || "A";

	return (
		<CustomAvatar alt={fullName} width={32} height={32} src={profileImageUrl ?? ""} {...rest}>
			{title}
		</CustomAvatar>
	);
};
