import { useEffect } from "react";

import { GLOBAL_CONSTANTS } from "../constants";

interface PostAffTracker {
	setAccountId: (arg0: string) => void;
	track: () => void;
	executeOnResponseFinished: (() => void)[];
	_getAccountId: () => string;
	_cmanager: { getVisitorIdOrSaleCookieValue: () => string };
}

export const usePostAffiliateProTrack = (cb: (value) => void): void => {
	const target = window as typeof window & { PostAffTracker: PostAffTracker };
	useEffect(() => {
		if (target.PostAffTracker) {
			console.log("Post Affiliate Pro: Available");
			target.PostAffTracker.setAccountId(GLOBAL_CONSTANTS.PAP_ACCOUNT_ID);
			try {
				console.log("Post Affiliate Pro: Start Tracking");
				target.PostAffTracker.track();
			} catch (err) {
				console.log("Post Affiliate Pro: Tracking Failed", err);
			}
			target.PostAffTracker.executeOnResponseFinished.push(function () {
				const cookie =
					target.PostAffTracker._getAccountId() + target.PostAffTracker._cmanager.getVisitorIdOrSaleCookieValue();
				console.log("Post Affiliate Pro: Cookie Set");
				cb(cookie);
			});
		} else {
			console.log("Post Affiliate Pro: Not Tracking");
		}
	}, [cb]);
};
