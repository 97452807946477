// noinspection HtmlUnknownBooleanAttribute

import React, { useCallback, useEffect, useRef, useState } from "react";

import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { getVideoPlayer } from "@remar/shared/dist/utils/serviceUtils/helpers";

import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { useParams } from "react-router-dom";

import { RootState, useAppDispatch, useAppSelector } from "store";
import {
	clearLessonState,
	createLastViewedSectionId,
	endUserActivityTracking,
	getLessonVideoProgress,
	getSelectedLessonProgress,
	isLessonProgressFetched,
	lessonIsLoading,
	selectedLessonProgress,
	setSelectedLessonProgress,
	startUserActivityTracking,
	updateLessonVideoProgress,
	updateSelectedLessonProgress
} from "store/features/Lesson/lesson.slice";
import { ACTIVITY_TYPE } from "store/services";

import videojs from "video.js";

import { Autoplay } from "./Autoplay";

interface VideoLessonProps {
	url: string;
	sectionLessonId: number;
	courseChapterId: number;
	hideAutoplay?: boolean;
	handleNextLesson: () => void;
	setShowCompletionCertificate: (param) => void;
	sideEffect: () => void;
	certificateAlreadyDisplayed: boolean;
	hasNextLesson: boolean;
}

const VIDEO_UPDATE_INTERVAL = 10;
const VideoLesson = ({
	url,
	sectionLessonId,
	courseChapterId,
	hideAutoplay,
	sideEffect,
	handleNextLesson,
	setShowCompletionCertificate,
	certificateAlreadyDisplayed,
	hasNextLesson
}: VideoLessonProps) => {
	const analytics = useAnalyticsEventTracker("Lesson");
	const videoRef = useRef<HTMLVideoElement>(null);
	const player = React.useRef<videojs.Player>();
	const dispatch = useAppDispatch();
	const { id: lessonId } = useParams<{ id: string }>();
	const isLoading = useAppSelector(lessonIsLoading);
	const lessonProgressFetched = useAppSelector(isLessonProgressFetched);
	const lessonProgress = useAppSelector(selectedLessonProgress);
	const { videoLessonProgressLoading, videoLessonProgress } = useAppSelector((state: RootState) => state.lesson);
	const { user } = useAppSelector((state: RootState) => state.auth);

	const [isWatched, setIsWatched] = useState(false);
	const [lessonProgressId, setLessonProgressId] = useState(0);
	const [totalDuration, setTotalDuration] = useState(0);
	const [watchedDuration, setWatchedDuration] = useState(0);
	const [autoplayVisible, setAutoplayVisible] = useState(hideAutoplay);
	const [pausePlayer, setPausePlayer] = useState(false);
	const [updatedPlayerTime, setUpdatedPlayerTime] = useState(0);

	useEffect(() => {
		analytics({ eventName: "start_video_only_lesson", eventIdentifier: `${sectionLessonId}` });

		return () => {
			dispatch(clearLessonState());
		};
	}, [dispatch, sectionLessonId]);

	useEffect(() => {
		if (Object.keys(lessonProgress).length !== 0) {
			if (lessonProgress.isComplete) {
				setIsWatched(true);
			}
			setLessonProgressId(lessonProgress.id);
		}

		return () => {
			setIsWatched(false);
			setLessonProgressId(0);
			setTotalDuration(0);
			setWatchedDuration(0);
		};
	}, [lessonProgress]);

	const markVideoLessonAsCompleted = useCallback(
		(progressInSeconds: number) => {
			setIsWatched(true);

			if (lessonProgressId) {
				dispatch(
					updateSelectedLessonProgress({
						data: { progressInSeconds, isComplete: true },
						filters: { id: lessonProgressId }
					})
				);
			} else {
				dispatch(setSelectedLessonProgress({ sectionLessonId, isComplete: true, progressInSeconds }));
			}

			analytics({ eventName: "finish_video_only_lesson", eventIdentifier: `${sectionLessonId}` });
		},
		[analytics, dispatch, lessonProgressId, sectionLessonId]
	);

	const handleUpdateVideoProgress = useCallback(
		currentTime => {
			dispatch(
				updateLessonVideoProgress({ sectionLessonId: sectionLessonId, videoProgress: currentTime, userId: user.id })
			);
		},
		[dispatch, sectionLessonId, user.id]
	);

	const onTimeUpdate = useCallback(
		(totalDuration: number, watchedTime: number, watched: boolean) => {
			const durationToMarkAsWatched = Math.round(0.3 * totalDuration);

			//Mark as watched if beyond durationToMarkAsWatched and not already marked as watched
			if (watchedTime >= durationToMarkAsWatched && !watched) {
				markVideoLessonAsCompleted(watchedTime);
			}
		},
		[markVideoLessonAsCompleted]
	);

	const setPlayer = useCallback(
		(resetPlayer: boolean) => {
			const videoPlayer = !resetPlayer && player.current ? player.current : getVideoPlayer(videoRef!.current!, url);

			if (lessonProgress && lessonProgress.progressInSeconds && !lessonProgress.isComplete) {
				videoPlayer.currentTime(lessonProgress.progressInSeconds);
			}

			videoPlayer.on("loadedmetadata", () => setTotalDuration(Math.round(videoPlayer.duration())));
			videoPlayer.on("timeupdate", () => setWatchedDuration(Math.round(videoPlayer.currentTime())));
			player.current = videoPlayer;
		},
		[lessonProgress, player, url]
	);

	useEffect(() => {
		if (videoLessonProgress && !videoLessonProgressLoading && player.current) {
			// update video time with the latest saved progress
			let updatedTime = videoLessonProgress;
			const localStorageSavedProgress = localStorage.getItem(`videoLessonProgress-${user.id}-${sectionLessonId}`);

			if (localStorageSavedProgress) {
				// update video progress from local storage
				updatedTime = +localStorageSavedProgress;
				localStorage.removeItem(`videoLessonProgress-${user.id}-${sectionLessonId}`);
			}
			setUpdatedPlayerTime(updatedTime);
		}
	}, [sectionLessonId, user.id, videoLessonProgress, videoLessonProgressLoading]);

	useEffect(() => {
		if (sectionLessonId) {
			dispatch(getLessonVideoProgress({ sectionLessonId, userId: user.id }));
		}

		// save last video progress before unload
		const saveProgress = () => {
			player.current &&
				localStorage.setItem(
					`videoLessonProgress-${user.id}-${sectionLessonId}`,
					"" + Math.round(player.current.currentTime())
				);
		};
		window.addEventListener("beforeunload", saveProgress);
		return () => window.removeEventListener("beforeunload", saveProgress);
	}, [dispatch, sectionLessonId, user.id]);

	useEffect(() => {
		if (totalDuration > 0) {
			onTimeUpdate(totalDuration, watchedDuration, isWatched);
			//	 update video progress every 10 seconds
			if (watchedDuration && watchedDuration !== totalDuration && watchedDuration % VIDEO_UPDATE_INTERVAL === 0) {
				handleUpdateVideoProgress(watchedDuration);
			}
		}
	}, [totalDuration, watchedDuration, isWatched, onTimeUpdate, handleUpdateVideoProgress]);

	useEffect(() => {
		if (lessonProgressFetched) {
			setAutoplayVisible(false);
			setPlayer(true);
		}
	}, [lessonProgressFetched, setPlayer]);

	useEffect(() => {
		dispatch(getSelectedLessonProgress(sectionLessonId));
	}, [dispatch, sectionLessonId]);

	useEffect(() => {
		if (lessonProgressFetched && url) {
			setPlayer(false);

			return () => {
				player.current = undefined;
			};
		}
	}, [lessonProgressFetched, url, lessonProgress, setPlayer, player]);

	useEffect(() => {
		if (lessonProgressFetched && player.current) {
			player.current.on("play", () => {
				setPausePlayer(false);
				// set latest watched lesson
				dispatch(createLastViewedSectionId({ sectionLessonId, courseChapterId }));
				dispatch(startUserActivityTracking({ activityType: ACTIVITY_TYPE.videoWatch, id: +lessonId }));
			});
			player.current.on("pause", () => {
				setPausePlayer(true);
				dispatch(endUserActivityTracking());
			});
			player.current.on("ended", () => {
				// todo: bug: ended is triggered twice'
				sideEffect();
				if (player.current?.isFullscreen()) {
					player.current.exitFullscreen();
				}
				setAutoplayVisible(true);
				handleUpdateVideoProgress(0);
				dispatch(endUserActivityTracking());
			});
		}
		//TODO: update dep hooks after testing to ensure only one trigger
	}, [dispatch, handleUpdateVideoProgress, lessonProgressFetched, sectionLessonId, courseChapterId]);

	useEffect(() => {
		if (player.current) {
			player.current.on("loadeddata", () => {
				updatedPlayerTime && player.current.currentTime(updatedPlayerTime);
			});
		}
	}, [updatedPlayerTime]);

	useEffect(() => {
		if (pausePlayer && watchedDuration < totalDuration) {
			// updates video progress on video pause,
			// watched duration has to be less than total as paused event is triggered when video ends
			handleUpdateVideoProgress(watchedDuration);
		}
	}, [handleUpdateVideoProgress, pausePlayer, totalDuration, watchedDuration]);

	if (isLoading && !lessonProgressFetched) {
		return <ContentLoader height={450} />;
	}

	return (
		<div data-vjs-player>
			<video
				ref={videoRef}
				preload={"auto"}
				className="video-js vjs-big-play-centered"
				style={{ aspectRatio: "16/9", padding: 0, height: "auto", position: "static" }}
			/>

			{autoplayVisible && (
				<Autoplay
					hasNextLesson={hasNextLesson}
					isVideoOnlyLesson
					certificateAlreadyDisplayed={certificateAlreadyDisplayed}
					handleNextLesson={handleNextLesson}
					setShowCompletionCertificate={setShowCompletionCertificate}
				/>
			)}
		</div>
	);
};

export default VideoLesson;
