import { useEffect, useMemo, useState } from "react";

import { differenceInSeconds } from "date-fns";

import convertSecToRemainingTime from "../utils/convertSecToRemainingTime";

interface IUseTimeLeftCountDownReturnProps {
	onReset: () => void;
	onStart: (date: Date) => void;
	timeLeft: string;
	isStarted: boolean;
}

export const useTimeLeftCountDown = (): IUseTimeLeftCountDownReturnProps => {
	const [isStarted, setIsStarted] = useState(false);
	const [[d, h, m, s], setTimer] = useState([0, 0, 0, 0]);

	const onStartTimer = () => {
		setTimer(prevState => {
			const [_d, _h, _m, _s] = [...prevState];
			if (_h === 0 && _m === 0 && _s === 0) {
				return [_d - 1, 23, 59, 59];
			} else if (_m === 0 && _s === 0) {
				return [_d, _h - 1, 59, 59];
			} else if (_s === 0) {
				return [_d, _h, _m - 1, 59];
			} else {
				return [_d, _h, _m, _s - 1];
			}
		});
	};

	useEffect(() => {
		const timerID = isStarted ? setInterval(() => onStartTimer(), 1000) : undefined;
		return () => {
			clearInterval(timerID);
		};
	}, [isStarted]);

	useEffect(() => {
		if (d === 0 && h === 0 && m === 0 && s === 0) {
			onReset();
		}
	}, [d, h, m, s]);

	const onStart = (endTime: Date) => {
		const endDate = new Date(endTime);
		const currentDate = new Date();
		const differenceSeconds = endDate > currentDate ? differenceInSeconds(endDate, currentDate) : 0;
		const { remainingDays, remainingHours, remainingMinutes, remainingSeconds } =
			convertSecToRemainingTime(differenceSeconds);
		setTimer([remainingDays, remainingHours, remainingMinutes, remainingSeconds]);
		setIsStarted(endDate > currentDate);
	};

	const onReset = () => {
		setIsStarted(false);
		setTimer([0, 0, 0, 0]);
	};

	const timeLeft = useMemo(() => {
		if (d === 0 && h === 0 && m === 0 && s === 0) {
			return "";
		}
		return `${d ? d + "d" : ""} ${h ? h + "h" : ""} ${m}min ${s}sec`;
	}, [d, h, m, s]);

	return {
		onStart,
		onReset,
		timeLeft,
		isStarted
	};
};
